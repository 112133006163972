import { FC } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

type Props = {
  number: number;
};

export const Chevron: FC<Props> = ({ number }) => (
  <div className={`chevron ${number > 0 ? 'dark' : 'light'}`}>
    {number > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
    {`${number.toFixed(1)}%`}
  </div>
);
