/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewUnits
 */
export interface NewUnits {
    /**
     * 
     * @type {string}
     * @memberof NewUnits
     */
    createdDate: string;
    /**
     * 
     * @type {number}
     * @memberof NewUnits
     */
    newUnits: number;
}

/**
 * Check if a given object implements the NewUnits interface.
 */
export function instanceOfNewUnits(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "newUnits" in value;

    return isInstance;
}

export function NewUnitsFromJSON(json: any): NewUnits {
    return NewUnitsFromJSONTyped(json, false);
}

export function NewUnitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUnits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': json['created_date'],
        'newUnits': json['new_units'],
    };
}

export function NewUnitsToJSON(value?: NewUnits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_date': value.createdDate,
        'new_units': value.newUnits,
    };
}

