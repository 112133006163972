import { FC, useCallback, useEffect, useState } from 'react';
import { App, Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { ErrorBoundary } from './ErrorBoundary';
import { SideBar, TopBar } from '.';
import { useStoreActions, useStoreState } from '../hooks';
import { StorageKeys } from '../utils';
import { ButtonWithTooltip } from './ButtonWithTooltip';

const { Header, Content } = Layout;

const timeoutDuration = process.env.REACT_APP_INACTIVITY_DURATION_MILISECONDS
  ? Number(process.env.REACT_APP_INACTIVITY_DURATION_MILISECONDS)
  : 360_000;

export const LOGOUT_NOTIFICATION_KEY = 'inactivity-logout';

export const AppLayout: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const logout = useStoreActions((actions) => actions.reset);
  const isLoggedIn = useStoreState((state) => state.user.isLoggedIn);

  const { notification } = App.useApp();
  const location = useLocation();

  const onIdle = useCallback((): void => {
    logout();
    notification.open({
      message: t(
        'inactivity.message',
        'You have been logged out due to inactivity.',
      ),
      duration: 0,
      key: LOGOUT_NOTIFICATION_KEY,
    });
  }, [logout, notification, t]);

  const onExpiry = useCallback((): void => {
    logout();
    notification.open({
      message: t(
        'inactivity.session_expired',
        'Your session has expired. Please log in.',
      ),
      duration: 0,
      key: LOGOUT_NOTIFICATION_KEY,
    });
  }, [logout, notification, t]);

  useIdleTimer({
    timeout: timeoutDuration,
    throttle: 1_000,
    crossTab: true,
    onIdle,
  });

  useEffect(() => {
    const lastActiveTimeItem = localStorage.getItem(
      StorageKeys.LAST_ACTIVE_TIME,
    );
    if (lastActiveTimeItem && isLoggedIn) {
      const lastActiveTime = Number(lastActiveTimeItem);
      const currentTime = new Date().getTime();
      if (currentTime - lastActiveTime > timeoutDuration) {
        localStorage.removeItem(StorageKeys.LAST_ACTIVE_TIME);
        onExpiry();
      }
    }
    return () =>
      localStorage.setItem(
        StorageKeys.LAST_ACTIVE_TIME,
        new Date().getTime().toString(),
      );
  }, [onExpiry, isLoggedIn]);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <Layout className='app'>
      <SideBar collapsed={isSidebarCollapsed} onCollapse={setIsSidebarCollapsed} />
      <Layout className='page-layout'>
        <Header className='header'>
          <div className='flex justify-between items-center'>
            {isSidebarCollapsed && (
              <ButtonWithTooltip
                buttonContent={
                  <Bars3Icon className='h-10- w-10 stroke-2 text-bmblue select-none' onClick={() => setIsSidebarCollapsed(false)} />
                }
                title={t('menu.open', 'Open menu')}
              />
            )}
          <TopBar />
          </div>
        </Header>
        <Content className='content'>
          <ErrorBoundary key={location.pathname}>
            <Outlet />
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
};
