/* eslint-disable @typescript-eslint/ban-ts-comment */
//  @ts-nocheck
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DualAxesChart from '@ant-design/plots/es/components/dual-axes';
import { BLUE_2, AVAILABLE } from '../../styles/constants';
import { useRegionFormatting, useStoreState } from '../../hooks';
import { DailyNewsData } from '../../store/dailyNews';

type Props = {
  height: number;
  chartData: DailyNewsData['graph_data'];
};

type TooltipProps = {
  date: string;
  AVAILABLE: number;
  avg_price: number;
};
const JANUARY = new Date('2023-01').getTime();

export const SupplyChart: FC<Props> = ({ height, chartData }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const { available_availability_timeline, available_timeline_price_per_sm } = chartData;
  const { area } = useStoreState((state) => state.filters);
  const config = useMemo(
    () => ({
      data: [
        available_availability_timeline.map((data) => ({
          ...data,
          date_short: new Date(data.date).toLocaleDateString(language, {
            month: 'numeric',
            year: '2-digit',
          }),
          name: 'enums.state.available_units',
        })),
        available_timeline_price_per_sm.map((data) => ({
          ...data,
          date_short: new Date(data.date).toLocaleDateString(language, {
            month: 'numeric',
            year: '2-digit',
          }),
          name: 'daily_news.kpi.price_per_sm',
        })),
      ].map((d) => d.filter((data) => {
        // DATA for Bratislava should start from January 2023, idk
        if (area === 517) {
          const date = new Date(data.date).getTime();
          if (JANUARY > date) {
            return false;
          }
          return true;
        }
        return true;
      })),
      color: AVAILABLE,
      xField: 'date_short',
      yField: ['AVAILABLE', 'avg_price'],
      legend: false,
      showTitle: false,
      height,
      meta: {
        MetaOption: {
          maxTickCount: 2,
        },
      },
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
      geometryOptions: [
        {
          geometry: 'column',
          isGroup: true,
          smooth: true,
          color: BLUE_2,
          marginRatio: 0.3,
          columnStyle: {
            radius: [3, 3, 0, 0],
          },
        },
        {
          geometry: 'line',
          smooth: true,
          lineStyle: {
            lineWidth: 6,
            stroke: AVAILABLE,
          },
        },
      ],
      yAxis: [
        {
          title: null,
          label: null,
          grid: null,
          line: null,
          tickLine: null,
          min: 0,
        },
        {
          title: null,
          label: null,
          grid: null,
          line: null,
          tickLine: null,
          min:
            Math.min(
              ...available_timeline_price_per_sm.map((o) => o.avg_price),
            ) * 0.95,
          max:
            Math.max(
              ...available_timeline_price_per_sm.map((o) => o.avg_price),
            ) * 1.05,
        },
      ],
      xAxis: [
        {
          type: 'time',
          max: new Date(
            Math.max(
              ...[
                ...available_availability_timeline,
                ...available_timeline_price_per_sm,
              ].map((data) => new Date(data.date)),
            ),
          ),
        },
        {
          type: 'time',
          max: new Date(
            Math.max(
              ...[
                ...available_availability_timeline,
                ...available_timeline_price_per_sm,
              ].map((data) => new Date(data.date)),
            ),
          ),
        },
      ],
      tooltip: {
        formatter: (datum) => {
          const value = datum as TooltipProps;
          return {
            name: value.date,
            value: value.AVAILABLE,
          };
        },
        title: (_title: string, item) =>
          new Date(item.date).toLocaleDateString(language, {
            month: 'long',
            year: 'numeric',
          }),
        customContent: (title: string, items: TooltipProps[]) => {
          let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
          items.forEach((item) => {
            htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item.data.AVAILABLE ? BLUE_2 : AVAILABLE
              }"></div>
              <div class="bmt-label">${t(item.data.name)}:</div>
              <div class="bmt-value">${
                item.data.AVAILABLE ?? formatCurrency(item.data.avg_price)
              }</div>
            </div>`;
          });
          htmlStr += '</div></div>';
          return htmlStr;
        },
      },
    }),
    [
      area,
      available_availability_timeline,
      available_timeline_price_per_sm,
      height,
      language,
      t,
      formatCurrency,
    ],
  );
  return <DualAxesChart {...config} />;
};
