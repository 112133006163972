import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Props = {
  handleClick?: () => void;
  disabled?: boolean;
  className?: string;
};

export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  handleClick,
  disabled,
  className,
}) => (
  <button
    type='button'
    className={clsx(
      'bg-[#5A72B1] text-white font-lg font-bold leading-7 py-5 px-44 rounded-[30px] max-w-[400px]',
      disabled ? 'opacity-50 cursor-not-allowed' : '',
      className,
    )}
    onClick={handleClick}
    disabled={disabled}
  >
    {children}
  </button>
);
