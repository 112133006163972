import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getPriceListData, getPriceListUpdateData } from '../api';
import { PriceListTableData } from '../api/mockData/priceListData';
import {
  GetPriceListDataParams,
  GetPriceListUpdateDataParams,
} from '../api/types';
import { getValidPriceListDate, logError, sortByString } from '../utils/utils';
import { BuildingData, StoreModel } from './types';
import { PriceListUpdateData } from '../api/mockData/priceUpdateData';

export interface PriceListStore {
  showPricePerSM: boolean;
  showAnalytics: Computed<PriceListStore, boolean, StoreModel>;
  setShowPricePerSM: Action<PriceListStore, boolean>;
  layoutFilter: string[];
  setLayoutFilter: Action<PriceListStore, string[]>;
  selectedId?: number;
  setSelectedId: Action<PriceListStore, number>;
  selectedData: Computed<
    PriceListStore,
    PriceListTableData | undefined,
    StoreModel
  >;
  data?: PriceListTableData[];
  buildingsData: Computed<PriceListStore, BuildingData[], StoreModel>;
  setData: Action<PriceListStore, PriceListTableData[]>;
  fetchData: Thunk<PriceListStore, GetPriceListDataParams, unknown, StoreModel>;
  fetchUpdateData: Thunk<
    PriceListStore,
    GetPriceListUpdateDataParams,
    unknown,
    StoreModel
  >;
  pageSize?: number;
  setPageSize: Action<PriceListStore, number>;
  setUpdateData: Action<PriceListStore, PriceListUpdateData[]>;
  updateData?: PriceListUpdateData[];
}

export const priceListStore: PriceListStore = {
  showPricePerSM: false,
  showAnalytics: computed([(state) => state.data], (data) => {
    const analyticsData = data?.map((d) => ({
      unique_sessions: d.unique_sessions,
      average_duration_seconds: d.average_duration_seconds,
      google_analytics_normalized: d.google_analytics_normalized,
    }));

    const allValuesAreZero = analyticsData?.every(
      (d) =>
        !d.unique_sessions &&
        !d.average_duration_seconds &&
        !d.google_analytics_normalized,
    );
    return !allValuesAreZero;
  }),
  setShowPricePerSM: action((state, value) => {
    state.showPricePerSM = value;
  }),
  layoutFilter: [],
  setLayoutFilter: action((state, value) => {
    state.layoutFilter = value;
  }),
  setSelectedId: action((state, newId) => {
    state.selectedId = newId;
  }),
  selectedData: computed(
    [(state) => state.selectedId, (state) => state.data],
    (selectedId, data) => data?.find((value) => value.id === selectedId),
  ),
  buildingsData: computed(
    (state) =>
      state.data?.reduce<BuildingData[]>((prev, curr) => {
        const result = [...prev];
        const buildingIndex = result.findIndex(
          (val) => val.id === curr.building_id,
        );
        if (buildingIndex > -1) {
          const floorIndex = result[buildingIndex].floors.findIndex(
            (floor) => floor.floorNumber === curr.floor,
          );
          if (floorIndex > -1) {
            result[buildingIndex].floors[floorIndex].flats.push(curr);
            result[buildingIndex].floors[floorIndex].flats.sort((a, b) =>
              sortByString(a.internal_id, b.internal_id),
            );
          } else {
            result[buildingIndex].floors.push({
              floorNumber: curr.floor,
              flats: [curr],
            });
            result[buildingIndex].floors.sort(
              (a, b) => b.floorNumber - a.floorNumber,
            );
          }
        } else {
          result.push({
            name: curr.building_internal_id,
            id: curr.building_id,
            floors: [{ floorNumber: curr.floor, flats: [curr] }],
          });
        }
        return result;
      }, []) ?? [],
  ),
  setData: action((state, data) => {
    state.data = data;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    const {
      filters: { overviewData },
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      const lastValidDate = getValidPriceListDate(null, 120);
      const { data } = await getPriceListData({
        ...payload,
        date: lastValidDate,
        price_per_sm_calculation: profile?.price_per_sm_calculation,
      });
      actions.setData(data);
      if (data.length > 0) {
        actions.setSelectedId(data[0].id);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  fetchUpdateData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const { data } = await getPriceListUpdateData(payload);
      actions.setUpdateData(data);
      if (data.length > 0) {
        actions.setSelectedId(data[0].id);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  setUpdateData: action((state, data) => {
    state.updateData = data;
  }),
  setPageSize: action((state, data) => {
    state.pageSize = data;
  }),
};
