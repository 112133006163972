import { css } from '@emotion/react/macro';

export const styles = {
  center: css({ margin: '1.2rem auto', width: '100%' }),
  competitorsContainer: css({
    flex: 'auto',
    marginLeft: '1.2rem',
    marginRight: '1.2rem',
  }),
  saleSpeed: {
    popoverContainer: css({ maxWidth: 300 }),
  },
};
