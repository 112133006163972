/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  SOLD,
} from '../../../../styles/constants';
import { formatNumber } from '../../../../utils/formatters';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { translateBlockChartValue } from '../../../../utils/utils';
import { useStoreState } from '../../../../hooks';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface OverallData {
  month: string,
  totalDisappearedUnits: number,
  series: string,
}

interface Props {
  data: OverallData[];
}

export const SaleSpeedOverallChart: FC<Props> = ({ data }) => {
  const { isRentals } = useMarketDetails();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const translateValue = (value: string): string =>
    translateBlockChartValue(value, t, isRentals);
  const { displayValues } = useStoreState((state) => state.filters);
  const config: ColumnConfig = getColumnOptions({
    data: data.sort(
      (a, b) =>
        new Date(a.month).getTime() - new Date(b.month).getTime(),
    ),
    color: SOLD,
    xField: 'month',
    yField: 'totalDisappearedUnits',
    seriesField: 'series',
    tooltip: {
      formatter: (date) => {
        const value = date as OverallData;
        return {
          name: String(value.series),
          value: value.totalDisappearedUnits ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${formatNumber(item?.value)}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
          });
        },
      },
    },
    legend: {
      itemName: {
        formatter: (value) => translateValue(value),
      },
    },
  });

  const chartConfig = {
    ...config,
    label: displayValues ? {
      // 可手动配置 label 数据标签位置
      position: 'top',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#000000',
        opacity: 0.6,
      },
    } : undefined,
  };

  return data.length > 0 ? (
    <Column {...chartConfig} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
