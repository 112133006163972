import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../routes/Paths';
import { useStoreState } from '../hooks';
import { ProjectType } from '../components/dynamicPricingOnboarding/multistepForm/steps/ProjectType';
import { SaleOrRental } from '../components/dynamicPricingOnboarding/multistepForm/steps/SaleOrRental';
import { WeAreOnIt } from '../components/dynamicPricingOnboarding/multistepForm/steps/WeAreOnIt';
import { GetStarted } from '../components/dynamicPricingOnboarding/multistepForm/steps/GetStarted';
import { EmailNotifications } from '../components/dynamicPricingOnboarding/multistepForm/steps/EmailNotifications';
import { UploadPriceList } from '../components/dynamicPricingOnboarding/multistepForm/steps/uploadPriceList/UploadPriceList';
import { DataUpdatesComponent } from '../components/dynamicPricingOnboarding/multistepForm/steps/DataUpdates';
import { FloorSiteVisuals } from '../components/dynamicPricingOnboarding/multistepForm/steps/FloorSiteVisuals';
import { StartingPrices } from '../components/dynamicPricingOnboarding/multistepForm/steps/StartingPrices';
import { SalesTargetsPlans } from '../components/dynamicPricingOnboarding/multistepForm/steps/SalesTargetsPlans';

export const DynamicPricingOnboarding: FC = () => {
  const currentPage = useStoreState((state) => state.onboarding.currentPage);
  const developer_id = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (developer_id !== 122) {
      navigate(Paths.DAILYNEWS);
    }
  }, []);

  return (
    <div className='h-[calc(100vh-50px)]'>
      {currentPage === 0 && <GetStarted />}
      {currentPage === 1 && <ProjectType />}
      {currentPage === 2 && <SaleOrRental />}
      {currentPage === 3 && <UploadPriceList />}
      {currentPage === 4 && <StartingPrices />}
      {currentPage === 5 && <DataUpdatesComponent />}
      {currentPage === 6 && <SalesTargetsPlans />}
      {currentPage === 7 && <FloorSiteVisuals />}
      {currentPage === 8 && <EmailNotifications />}
      {currentPage === 9 && <WeAreOnIt />}
    </div>
  );
};
