import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../FormContainer';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Button } from '../../button/Button';
import { useStoreActions } from '../../../../hooks';
import { Paths } from '../../../../routes/Paths';

export const WeAreOnIt: FC = () => {
  const navigate = useNavigate();
  const { resetOnboardingStore } = useStoreActions(
    (actions) => actions.onboarding,
  );

  const handleClick = (): void => {
    resetOnboardingStore();
    navigate(Paths.DAILYNEWS);
  };

  return (
    <FormContainer>
      <div>
        <Heading className='pt-5 pb-6'>That’s it!</Heading>
        <Subtitle>
          We’ll process your data and well be in touch by e-mail in the next 3
          business days.
        </Subtitle>
      </div>
      <div className='flex justify-center'>
        <Button handleClick={handleClick}>Exit</Button>
      </div>
    </FormContainer>
  );
};
