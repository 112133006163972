import { FC, ReactNode, useMemo } from 'react';
import { Line, LineConfig, Area } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useRegionFormatting } from '../../../../hooks';
import {
  AVAILABLE,
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
  GREY_2,
  GREY_3,
  SOLD,
} from '../../../../styles/constants';
import { PriceHistoryData } from '../../../../api/types';
import { translateBlockChartValue } from '../../../../utils/utils';
import { getLineOptions } from '../../../../utils/defaultChartConfig';
import { AvailabilityFilterEnum } from '.';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface Data extends PriceHistoryData {
  count: number;
}
interface Props {
  isOverall: boolean,
  availability: AvailabilityFilterEnum,
  data: Data[],
}

const getChart = (availability: AvailabilityFilterEnum, config: LineConfig): ReactNode => {
  if (availability === AvailabilityFilterEnum.ALL) {
    return <Line {...config} />;
  }
  return <Area {...config} />;
};

export const PriceHistoryChart: FC<Props> = ({ data, isOverall, availability }) => {
  const { isRentals } = useMarketDetails();
  const { formatCurrency } = useRegionFormatting();
  const { getLayout } = useFlatLayout();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const minPrice = useMemo(() => {
    const minAvgPrice = data.reduce((min, item) => {
      if (item.avg_price === null) {
        return min; // Skip this item if the price is null
      }
      return Math.min(min, item.avg_price);
    }, Infinity);
    return Math.max(0, minAvgPrice * 0.95);
  }, [data]);

  const getColor = (): string[] => {
    if (isOverall && availability === AvailabilityFilterEnum.SOLD) {
      return [SOLD];
    } if (isOverall && availability === AvailabilityFilterEnum.AVAILABLE) {
      return [AVAILABLE];
    }
    return isOverall
      ? [AVAILABLE, SOLD, BLUE_3, BLUE_2, BLUE_5]
      : [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1];
  };

  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t, isRentals) : getLayout(value);

  const config: LineConfig = getLineOptions({
    smooth: true,
    data: data.sort((a, b) => a.date.localeCompare(b.date)),
    color: getColor(),
    xField: 'date',
    yField: 'avg_price',
    seriesField: isOverall ? 'series' : 'layout',
    tooltip: {
      formatter: (datum) => {
        const value = datum as PriceHistoryData;
        return {
          name: isOverall ? String(value.series) : value.layout,
          value: formatCurrency(value.avg_price) ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
          });
        },
      },
      tickMethod: 'time',
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
      min: minPrice,
    },
    legend: {
      itemName: {
        formatter: translateValue,
      },
    },
  });
  return data.length > 0 ? (
    <>{getChart(availability, config)}</>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
