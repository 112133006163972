/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const BackLink: FC<LinkProps> = (props) => (
  <Link
    css={css({
      label: 'back-link',
      color: '#bfc4cd',
      '&:hover': {
        color: '#7e92bd;',
      },
    })}
    {...props}
  />
);
