/* eslint-disable @typescript-eslint/ban-ts-comment */
//  @ts-nocheck
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DualAxesChart from '@ant-design/plots/es/components/dual-axes';
import { GREY_2, SOLD } from '../../styles/constants';
import { useRegionFormatting, useStoreState } from '../../hooks';
import { DailyNewsData } from '../../store/dailyNews';

type Props = {
  height: number;
  chartData: DailyNewsData['graph_data']
};

type TooltipProps = {
  date: string;
  value: number;
  avg_price: number;
  show: boolean;
};
const JANUARY = new Date('2023-01').getTime();

export const DemandChart: FC<Props> = ({ height, chartData }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const { sold_availability_timeline, sold_timeline_price_per_sm } = chartData;
  const { area } = useStoreState((state) => state.filters);

  const lineData = useMemo(() => {
    if (sold_timeline_price_per_sm.length < sold_availability_timeline.length) {
      return [
        ...sold_availability_timeline.map((columnData) => {
          const intersection = sold_timeline_price_per_sm.find(
            (line) => line.date === columnData.date,
          );
          if (intersection) {
            return {
              ...intersection,
              nullValue: 0,
              show: true,
            };
          }
          return {
            ...columnData,
            nullValue: 0,
            show: false,
          };
        }),
      ];
    }
    return [
      ...sold_timeline_price_per_sm.map((line) => ({
        ...line,
        nullValue: 0,
        show: true,
      })),
    ];
  }, [sold_availability_timeline, sold_timeline_price_per_sm]);

  const config = useMemo(
    () => ({
      data: [
        sold_availability_timeline.map((data) => ({
          ...data,
          date_short: new Date(data.date).toLocaleDateString(language, {
            month: 'numeric',
            year: '2-digit',
          }),
          show: true,
          name: 'enums.state.sold_units',
        })),
        lineData.map((data) => ({
          ...data,
          date_short: new Date(data.date).toLocaleDateString(language, {
            month: 'numeric',
            year: '2-digit',
          }),
          name: 'daily_news.kpi.price_per_sm',
        })),
      ].map((d) => d.filter((data) => {
        // DATA for Bratislava should start from January 2023, idk
        if (area === 517) {
          const date = new Date(data.date).getTime();
          if (JANUARY > date) {
            return false;
          }
          return true;
        }
        return true;
      })),
      color: SOLD,
      xField: 'date_short',
      yField: ['value', 'avg_price'],
      legend: false,
      showTitle: false,
      height,
      meta: {
        MetaOption: {
          maxTickCount: 2,
        },
      },
      animation: {
        appear: {
          animation: 'path-in',
          duration: 3000,
        },
      },
      geometryOptions: [
        {
          geometry: 'column',
          isGroup: false,
          isStack: false,
          smooth: true,
          color: SOLD,
          marginRatio: 0.3,
          columnStyle: {
            radius: [3, 3, 0, 0],
          },
        },
        {
          geometry: 'line',
          smooth: true,
          isStack: false,
          seriesField: 'nullValue',
          lineStyle: {
            lineWidth: 6,
            stroke: GREY_2,
          },
        },
      ],
      yAxis: [
        {
          title: null,
          label: null,
          grid: null,
          line: null,
          tickLine: null,
          min: 0,
        },
        {
          title: null,
          label: null,
          grid: null,
          line: null,
          tickLine: null,
          min:
            Math.min(...sold_timeline_price_per_sm.map((o) => o.avg_price)) *
            0.95,
          max:
            Math.max(...sold_timeline_price_per_sm.map((o) => o.avg_price)) *
            1.05,
        },
      ],
      xAxis: [
        {
          type: 'time',
          max: new Date(
            Math.max(
              ...[
                ...sold_availability_timeline,
                ...sold_timeline_price_per_sm,
              ].map((data) => new Date(data.date)),
            ),
          ),
        },
        {
          type: 'time',
          max: new Date(
            Math.max(
              ...[
                ...sold_availability_timeline,
                ...sold_timeline_price_per_sm,
              ].map((data) => new Date(data.date)),
            ),
          ),
        },
      ],
      tooltip: {
        formatter: (datum) => {
          const value = datum as TooltipProps;
          return {
            name: value.date,
            value: value.value,
          };
        },
        title: (_title: string, item) =>
          new Date(item.date).toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          }),
        customContent: (title: string, items: TooltipProps[]) => {
          let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
          items.forEach((item) => {
            htmlStr += item.data.show ? `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item.data.value ? SOLD : GREY_2
              }"></div>
              <div class="bmt-label">${t(item.data.name)}:</div>
                <div class="bmt-value">${
                  item.data.value ?? formatCurrency(item.data.avg_price)
                }</div>
            </div>` : '';
          });
          htmlStr += '</div></div>';
          return htmlStr;
        },
      },
    }),
    [
      sold_availability_timeline,
      lineData,
      height,
      sold_timeline_price_per_sm,
      language,
      area,
      t,
      formatCurrency,
    ],
  );
  return <DualAxesChart {...config} />;
};
