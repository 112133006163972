import { FC, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Image, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Project } from '../../api/mockData/projectData';

const DEFAULT_IMG_URL =
  'https://built-mind-internaldata.s3.eu-central-1.amazonaws.com/DEFAULT/buildings_illustration.jpg';
interface Props {
  data: Project;
}

export const Preview: FC<Props> = ({
  data: { project_name, image_url, address, developer, project_url },
}) => {
  const [imgUrl, setImgUrl] = useState(image_url || DEFAULT_IMG_URL);
  const { t } = useTranslation();

  const onError = (): void => {
    setImgUrl(DEFAULT_IMG_URL);
  };

  return (
    <Card
      className='preview'
      cover={<Image alt='project_preview' src={imgUrl} onError={onError} />}
    >
      <div className='title-container'>
        <div className='title'>{project_name}</div>
        {project_url && (
          <div className='project-link-container'>
            <Popover
              content={t(
                'project.links.project_detail',
                'Open detail of the project',
              )}
            >
              <div className='project-link'>
                <a
                  className='project-link'
                  href={project_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkOutlined />
                </a>
              </div>
            </Popover>
          </div>
        )}
      </div>
      <div>{address}</div>
      <div>{developer}</div>
    </Card>
  );
};
