/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { Spin } from 'antd';
import { FC } from 'react';

export const CenteredSpinner: FC = () => (
  <div
    css={css({
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    })}
  >
    <Spin spinning />
  </div>
);
