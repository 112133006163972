import { FC, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useRegionFormatting, useStoreState } from '../../hooks';
import {
  AVAILABLE,
  RESERVED,
  SOLD,
} from '../../styles/constants';
import { ChartCard } from '../ChartCard';
import { getColumnOptions } from '../../utils/defaultChartConfig';
import { useReservedData } from '../../hooks/useReservedData';
import { Availability } from '../../store/types';
import { shouldUseShorten } from '../../utils/utils';
import { CardPopover } from '../market/blocks/CardPopover';

enum FilterEnum {
  PRICE,
  PRICE_PER_SM,
}

export interface ProjectComparisonData {
  layout: string;
  availability: string;
  size: number | null;
  floor_area: number | null;
  exterior_area: number | null;
  market: {
    count: number;
    price: number;
    price_per_sm: number;
  }
}

export const Comparison: FC = () => {
  const { getLayout } = useFlatLayout();
  const { formatCurrency, areaUnit, calculateVatPrice } = useRegionFormatting();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<SegmentedValue>(
    FilterEnum.PRICE_PER_SM,
  );
  const { transformReserved } = useReservedData();
  const { displayValues } = useStoreState((state) => state.filters);
  const layoutCounts = useStoreState((state) => state.project.data?.counts?.by_layout);
  const data: ProjectComparisonData[] = useStoreState((state) =>
    state.project.aggregatedTableData
      .map(
        ({
          layout,
          availability,
          price,
          price_per_sm,
          exterior_sm,
          interior_sm,
        }) => ({
          layout,
          availability,
          market: {
            // @ts-ignore
            count: layoutCounts && layoutCounts[layout.toUpperCase()] ? layoutCounts[layout.toUpperCase()][availability] : 0,
            price: price ?? 0,
            price_per_sm: price_per_sm ?? 0,
          },
          size: Number(exterior_sm) + Number(interior_sm),
          floor_area: Number(interior_sm),
          exterior_area: Number(exterior_sm),
        }),
      )
      .filter(({ market }) => market.price > 0)
      .sort((a, b) => {
        const getLayoutValue = (layout: string): number => {
        // Split the layout string by underscores and parse the numeric part
        const parts = layout.split('_');
        // Concatenate the numeric parts into a float (handling cases like LAYOUT_1_5)
        return parseFloat(`${parts[1]}.${parts[2] || '0'}`);
    };

    const layoutA = getLayoutValue(a.layout);
    const layoutB = getLayoutValue(b.layout);
    return layoutA - layoutB;
    }),
  );

  const mappedData = useMemo(
    () => transformReserved(data) as ProjectComparisonData[],
    [data, transformReserved],
  );

  const colors = useMemo(() => {
    const availabilities = [...new Set(mappedData.map((d) => d.availability))];
    const result = [];
    for (const a of availabilities) {
      switch (a) {
        case Availability.AVAILABLE:
          result.push(AVAILABLE);
          break;
        case Availability.RESERVED:
          result.push(RESERVED);
          break;
        case Availability.SOLD:
          result.push(SOLD);
          break;
        default:
          break;
      }
    }
    return result;
  }, [mappedData]);

  const config: ColumnConfig = getColumnOptions({
    data: mappedData.map((obj) => ({
      ...obj,
      value: filterValue === FilterEnum.PRICE ? calculateVatPrice(obj.market.price, obj.floor_area, obj.exterior_area) : calculateVatPrice(obj.market.price_per_sm, obj.floor_area, obj.exterior_area),
    })),
    isGroup: true,
    xField: 'layout',
    yField: 'value',
    seriesField: 'availability',
    color: colors,
    label: displayValues
      ? {
          formatter: ({ value }) => {
            if (value === 0) return '';
            const { shortenThousands, shortenMillions } =
              shouldUseShorten(value);
            return (
              formatCurrency(
                Number(value),
                undefined,
                shortenMillions,
                shortenThousands,
              ) ?? ''
            );
          },
          position: 'top',
          rotate: -Math.PI / 2,
          offsetX: 15,
          offsetY: 6,
        }
      : undefined,
    legend: {
      itemName: {
        formatter: (value) => t(`enums.state.${value}`),
      },
    },
    yAxis: {
      max:
        Math.max(
          ...data.map((d) =>
            filterValue === FilterEnum.PRICE ? d.market.price : d.market.price_per_sm,
          ),
        ) * 1.2,
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
    },
    xAxis: {
      label: {
        formatter: (value) => getLayout(value),
      },
    },
    tooltip: {
      formatter: (datum) => {
        const value = datum;
        return {
          name: t(`enums.state.${value.availability}`),
          value: formatCurrency(value.value) ?? '',
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });

  const handleChange = (value: SegmentedValue): void => {
    setFilterValue(value);
  };
  return (
    <ChartCard
      className='comparison'
      title={t('project.comparison.title', 'Comparison')}
      extra={<CardPopover showValues />}
      subtitle={t(
        'project.comparison.subtitle',
        'The comparisons of the available and solds and reserved units.',
      )}
      chart={
        data.length > 0 ? (
          <Column className='chart-container' {...config} />
        ) : (
          <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      controls={
        <Segmented
          value={filterValue}
          options={[
            {
              value: FilterEnum.PRICE_PER_SM,
              label: t('common.price_per_unit', 'Price per {{unit}}', {
                unit: areaUnit,
              }),
            },
            {
              value: FilterEnum.PRICE,
              label: t('project.comparison.price', 'Price'),
            },
          ]}
          onChange={handleChange}
        />
      }
    />
  );
};
