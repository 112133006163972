import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './AvailbilityBadge.module.css';
import { State } from '../../api/enums';
import { useStoreState } from '../../hooks';
import { ProjectType } from '../../store/types';

type Props = {
  unitAvailability: string;
  type?: 'detailPreview' | 'table';
};

export const AvailabilityBadge: FC<Props> = ({
  unitAvailability,
  type = 'detailPreview',
}) => {
  const { t } = useTranslation();

  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const availability =
    isRent && unitAvailability === State.SOLD ? State.LEASED : unitAvailability;

  return (
    <div className={clsx(styles[type], styles[unitAvailability])}>
      {t(`enums.state.${availability}`)}
    </div>
  );
};
