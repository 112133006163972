import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import { getUniqueId } from '../../../utils';
import { Card } from '../../Card';

interface DiffInterface {
  label: ReactNode;
  value: ReactNode;
}
interface KPIProps {
  title: ReactNode;
  icon: ReactNode;
  value: ReactNode;
  secondary?: {
    secondaryTitle?: ReactNode;
    secondaryValue?: ReactNode;
    secondaryLabel?: ReactNode;
  };
  label: ReactNode;
  diff: DiffInterface[];
}

export const KPI: React.FC<KPIProps> = ({
  title,
  icon,
  value,
  diff,
  label,
  secondary,
}) => {
  const { secondaryTitle, secondaryLabel, secondaryValue } = secondary || {};
  return (
    <Card className='kpi' bordered={false}>
      <Row className='kpi-container' align='top'>
        <Col span={23}>
          <Row>
            <Col xs={24} xl={secondary ? 12 : 24}>
              <Row className='title'>{title}</Row>
              <Row className='value'>{value}</Row>
              <Row>
                <div className='label'>{label}</div>
              </Row>
            </Col>
            {secondary && (
              <Col xs={24} xl={12}>
                {secondaryTitle && (
                  <Row className='title'>{secondaryTitle}</Row>
                )}
                {secondaryValue && (
                  <Row className='value'>
                    <div>{secondaryValue}</div>
                  </Row>
                )}
                {secondaryLabel && (
                  <Col xs={24} xxl={12}>
                    <div className='label'>{secondaryLabel}</div>
                  </Col>
                )}
              </Col>
            )}
          </Row>
          <Row>
            {diff.map(({ value: diffValue, label: diffLabel }) => (
              <div
                key={getUniqueId()}
                className={`summary-${diff.length > 1 ? 'multiple' : 'single'}`}
              >
                <div className='diff-value'>{diffValue}</div>
                <div className='diff-label'>{diffLabel}</div>
              </div>
            ))}
          </Row>
        </Col>
        <Col span={1}>
          <div className='icon'>{icon}</div>
        </Col>
      </Row>
    </Card>
  );
};
