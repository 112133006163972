import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { Button, Checkbox, Divider, Popover, Space } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutIcon from '../../assets/icons/layout-icon.svg';

export const LayoutFilter = React.memo(
  ({
    layoutOptions,
    layoutFilterValue,
    onLayoutFilterChange,
  }: {
    layoutOptions: string[];
    layoutFilterValue: string[];
    onLayoutFilterChange: (layoutFilter: CheckboxValueType[]) => void;
  }) => {
    const { t } = useTranslation();

    if (!layoutOptions.length) {
      return null;
    }

    return (
      <Popover
        trigger='click'
        placement='bottom'
        overlayInnerStyle={{ padding: 0 }}
        content={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox.Group
              style={{ width: '100%', margin: 8 }}
              value={layoutFilterValue}
              onChange={onLayoutFilterChange}
            >
              <Space direction='vertical'>
                {layoutOptions.map((layout) => (
                  <Checkbox value={layout}>{layout}</Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
            <Divider style={{ margin: '0px' }} />
            <Space style={{ padding: '8px' }}>
              <Button
                type='text'
                onClick={() => {
                  onLayoutFilterChange([]);
                }}
              >
                {t('common.reset', 'Reset')}
              </Button>
            </Space>
          </div>
        }
      >
        <div className='layout-filter-btn'>
          <img
            src={LayoutIcon}
            alt='Layout'
            style={{ width: '20px', marginRight: '8px' }}
          />
          {t('enums.house_parts.layout', 'Layout')}{' '}
          <DownOutlined
            style={{ opacity: '25%', width: '12px', marginLeft: '4px' }}
          />
        </div>
      </Popover>
    );
  },
);
