import dayjs from 'dayjs';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { DatePicker } from 'antd';
import clsx from 'clsx';
import './DateSelect.css';
import { ActionCreator } from 'easy-peasy';
import { FC } from 'react';

type Props = {
  label: string;
  setSelectedDate: ActionCreator<dayjs.Dayjs | null>;
  selectedDate?: dayjs.Dayjs;
  className?: string;
  disabled?: boolean;
  disableDate?: dayjs.Dayjs;
};

export const DateSelect: FC<Props> = ({
  label,
  setSelectedDate,
  className,
  selectedDate,
  disabled,
  disableDate = dayjs().startOf('day'),
}) => (
  <div className={clsx('flex justify-center items-center relative', className)}>
    <span className='absolute left-8 top-4 z-10 text-bmgray text-sm'>
      {label}
    </span>
    <DatePicker
      picker='date'
      disabled={disabled}
      value={selectedDate ?? undefined}
      placeholder='DD/MM/YYYY'
      suffixIcon={undefined}
      allowClear={false}
      format='DD/MM/YYYY'
      onChange={(date) => setSelectedDate(dayjs(date))}
      className='w-[50rem] pt-8 pb-2 px-8 rounded-[25px] border-[#BDBDBD] custom-date-picker-input'
      disabledDate={(current: dayjs.Dayjs) => current < disableDate}
    />
    <CalendarIcon className='absolute right-8' color='#828282' width='1.5rem' />
  </div>
);
