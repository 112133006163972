import { FC } from 'react';
import { Bar, BarConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useStoreState } from '../../hooks';
import { formatPercent } from '../../utils';
import { AVAILABLE, RESERVED, SOLD } from '../../styles/constants';
import { State } from '../../api/enums';
import { ChartCard } from '../ChartCard';
import { getBarOptions } from '../../utils/defaultChartConfig';
import { ProjectType } from '../../store/types';

interface Data {
  category: string;
  state: State;
  value: number;
}

export const SaleDetail: FC = () => {
  const { t } = useTranslation();
  const { getLayout } = useFlatLayout();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const data: Data[] = useStoreState(
    (state) => state.dashboard.data?.sale_detail ?? [],
  ).flatMap(({ key, availabilities: { available, reserved, sold } }) => [
    {
      category: key,
      state: isRent ? State.LEASED : State.SOLD,
      value: sold,
    },
    {
      category: key,
      state: State.RESERVED,
      value: reserved,
    },
    {
      category: key,
      state: State.AVAILABLE,
      value: available,
    },
  ]);
  const config: BarConfig = getBarOptions({
    data,
    isStack: true,
    isPercent: true,
    xField: 'value',
    yField: 'category',
    seriesField: 'state',
    color: [SOLD, RESERVED, AVAILABLE],
    legend: {
      itemName: {
        formatter: (value) => t(`enums.state.${value}`),
      },
    },
    yAxis: {
      label: {
        formatter: (value) => getLayout(value),
      },
    },
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: t(`enums.state.${value.state}`),
          value: formatPercent(value.value),
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });
  return (
    <ChartCard
      title={t(`dashboard.${isRent ? 'rent' : 'sale'}_detail.title`)}
      subtitle={t(`dashboard.${isRent ? 'rent' : 'sale'}_detail.subtitle`)}
      chart={<Bar {...config} />}
    />
  );
};
