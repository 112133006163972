/** @jsxImportSource @emotion/react */
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { css, Global } from '@emotion/react/macro';
import { App as AntApp } from 'antd';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './i18n.js';
import * as serviceWorker from './serviceWorkerRegistration';

const globalStyles = css({
  html: { fontSize: '62.5%', height: '100%' },
  body: {
    height: '100%',
    margin: 0,
  },
  '& .ant-popover .ant-popover-content .ant-popover-inner': {
    borderRadius: '1.6rem',
  },
});

const antStyles = css({ height: '100%' });

const container = document.getElementById('app');
if (container === null) throw new Error('Root container missing in index.html');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Global styles={globalStyles} />
    <AntApp css={antStyles}>
      <App />
    </AntApp>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register({ skipWaiting: true, reload: true });
