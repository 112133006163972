import React from 'react';
import { Menu } from 'antd';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';

export const TopBar: React.FC = () => {
  const username = useStoreState((actions) => actions.user.profile?.username);
  const logout = useStoreActions((actions) => actions.reset);
  const handleLogout = (): void => {
    logout();
  };
  const { t } = useTranslation();

  return (
    <Menu
      mode='horizontal'
      className='title-bar'
      items={[
        {
          key: 'user',
          icon: <UserCircleIcon width={24} height={24} />,
          label: username,
          children: [
            { key: 'logout', onClick: handleLogout, label: t('menu.logout') },
          ],
        },
      ]}
    />
  );
};
