import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { TagIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { StartingPrice } from '../../../store/onboarding';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { useFilter } from '../../market/blocks/useFilter';
import './StartingPrices.css';

export enum FilterTypeEnum {
  LAYOUTS,
  OVERALL,
}

enum FilterPriceEnum {
  PRICE,
  PRICE_PER_SM,
}

type Props = {
  className: string;
  prices: StartingPrice[];
  onInputChange: (layout: string, value: string) => void;
  filterType: {
    filterValue: string | number;
    handleChange: (value: string | number) => void;
  };
  overallPrices: string | null;
  setOverallPrices: Dispatch<SetStateAction<string | null>>;
};

export const StartingPricesComponent: FC<Props> = ({
  className,
  prices,
  onInputChange,
  filterType,
  overallPrices,
  setOverallPrices,
}) => {
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.onboarding);

  const isRental = projectType.rental;
  const { areaUnit, formatCurrency } = useRegionFormatting();
  const filterPriceType = useFilter(FilterPriceEnum.PRICE);

  const [activeInput, setActiveInput] = useState<string | null>(null);

  const handleInputBlur = (): void => {
    setActiveInput(null);
  };

  return (
    <div
      className={clsx(
        'p-6 flex flex-col gap-2 bg-white border-[1px] border-bmgray rounded-[25px] w-[50rem]',
        className,
      )}
    >
      <div className='flex items-center pb-4 pl-2'>
        <h1 className='font-bold text-xl text-bmblue'>
          {t(
            'onboarding.starting_prices.starting_prices',
            'Starting {{type}}',
            {
              type: isRental
                ? t('onboarding.common.rents', 'Rents')
                : t('onboarding.common.prices', 'Prices'),
            },
          )}
        </h1>
        <Segmented
          className='custom-segmeted'
          style={{
            fontSize: '1rem',
            marginLeft: '1.2rem',
          }}
          value={filterPriceType.filterValue}
          options={[
            {
              value: FilterPriceEnum.PRICE,
              label: t('common.price', 'Price'),
            },
            {
              value: FilterPriceEnum.PRICE_PER_SM,
              label: t('common.price_per_unit', 'Price per {{unit}}', {
                unit: areaUnit,
              }),
            },
          ]}
          onChange={filterPriceType.handleChange}
        />
        <Segmented
          className='custom-segmeted'
          style={{
            marginLeft: '1.2rem',
            fontSize: '1rem',
          }}
          value={filterType.filterValue}
          options={[
            {
              value: FilterTypeEnum.LAYOUTS,
              label: t('dashboard.sale_progress.layouts', 'Layouts'),
            },
            {
              value: FilterTypeEnum.OVERALL,
              label: t('dashboard.sale_progress.overall', 'Overall'),
            },
          ]}
          onChange={filterType.handleChange}
        />
      </div>
      <div
        className={clsx(
          'grid pl-4',
          filterType.filterValue === FilterTypeEnum.OVERALL
            ? 'w-[20rem] grid-cols-1'
            : 'w-[30rem] grid-cols-2',
        )}
      >
        {filterType.filterValue !== FilterTypeEnum.OVERALL ? (
          <div className='flex items-center'>
            <Squares2X2Icon width='20px' className='mr-2' />
            <span className='font-bold text-bmblue text-lg'>
              {t('onboarding.common.layout', 'Layout')}
            </span>
          </div>
        ) : null}
        <div className='flex items-center'>
          <TagIcon width='20px' className='mr-2' />
          <span className='font-bold text-bmblue text-lg'>
            {t(
              filterPriceType.filterValue === FilterPriceEnum.PRICE
                ? 'onboarding.starting_prices.average_price'
                : 'onboarding.starting_prices.average_price_per_unit',
              filterPriceType.filterValue === FilterPriceEnum.PRICE
                ? 'Average {{type}}'
                : 'Average {{type}} per {{unit}}',
              {
                type: isRental
                  ? t('onboarding.common.rent', 'Rent')
                  : t('onboarding.common.price', 'Price'),
                unit: areaUnit,
              },
            )}
          </span>
        </div>
      </div>
      <div
        className={clsx(
          'border-t-2 border-[#F1F1F1] text-lg pl-4',
          filterType.filterValue === FilterTypeEnum.OVERALL
            ? 'w-[20rem] grid-cols-1'
            : 'w-[30rem] grid-cols-2',
        )}
      >
        {filterType.filterValue === FilterTypeEnum.OVERALL ? (
          <div className='grid grid-cols-2 py-2 border-b-[1px] border-[#F1F1F1]'>
            {activeInput === 'overall' ? (
              <input
                type='number'
                className='text-bmblue outline-none w-9/12'
                placeholder={t(
                  'onboarding.email_notification.placeholder',
                  'Type in ...',
                )}
                value={overallPrices || ''}
                onChange={(e) => setOverallPrices(e.target.value ?? '')}
                onBlur={handleInputBlur}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            ) : (
              <span
                className={clsx(
                  'text-bmblue cursor-pointer',
                  overallPrices ? 'font-bold' : '',
                )}
                onClick={() => setActiveInput('overall')}
              >
                {overallPrices
                  ? `${formatCurrency(Number(overallPrices))}`
                  : t(
                      'onboarding.email_notification.placeholder',
                      'Type in ...',
                    )}
              </span>
            )}
          </div>
        ) : (
          prices?.map(({ layout, price }) => (
            <div
              key={layout}
              className='grid grid-cols-2 gap-4 py-2 border-b-[1px] border-[#F1F1F1]'
            >
              <span className='font-bold text-bmblue'>{layout}</span>
              <div>
                {activeInput === layout ? (
                  <input
                    type='number'
                    className='text-bmblue outline-none w-fit'
                    placeholder={t(
                      'onboarding.email_notification.placeholder',
                      'Type in ...',
                    )}
                    value={price || ''}
                    onChange={(e) => onInputChange(layout, e.target.value)}
                    onBlur={handleInputBlur}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                ) : (
                  <span
                    className={clsx(
                      'text-bmblue cursor-pointer',
                      price ? 'font-bold' : '',
                    )}
                    onClick={() => setActiveInput(layout)}
                  >
                    {price
                      ? `${formatCurrency(price)}`
                      : t(
                          'onboarding.email_notification.placeholder',
                          'Type in ...',
                        )}
                  </span>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
