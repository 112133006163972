import { Tooltip } from 'antd';
import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  items: { title: string; tooltip: string }[];
  bold?: boolean;
  headline: string;
  className?: string;
};

export const List: FC<Props> = ({ items, headline, bold, className }) => (
  <div className={(clsx(className), 'text-bmgray')}>
    <h1 className='font-bold text-base'>{headline}</h1>
    <ul className={clsx(bold ? 'font-bold' : '', 'list-disc pl-8 text-base')}>
      {items.map(({ title, tooltip }) => {
        if (!title) return null;
        if (tooltip) {
          return (
            <Tooltip title={tooltip}>
              <li>{title}</li>
            </Tooltip>
          );
        }
        return <li>{title}</li>;
      })}
    </ul>
  </div>
);
