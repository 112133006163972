// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, useEffect, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
  GREY_2,
  GREY_3,
  SOLD,
  RESERVED,
} from '../../../../styles/constants';
import { formatNumber } from '../../../../utils/formatters';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { fillMissingMonths, translateBlockChartValue } from '../../../../utils/utils';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import { DemandByMonthModal } from './DemandByMonthModal';
import { Availability } from '../../../../store/types';

export interface Data {
  date: string;
  value: number;
  series: string | number;
  layout: string | null;
  availability: string;
  count?: number;
  counts_by_type: {
    [key: string]: number;
  };
}
interface Props {
  isOverall: boolean;
  data: Data[];
  isSold: boolean;
  showPrivate: boolean;
  showB2B: boolean;
}

export const SaleSpeedChart: FC<Props> = ({ data, isOverall, isSold, showPrivate, showB2B }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getLayout } = useFlatLayout();
  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);
  const { displayValues } = useStoreState((state) => state.filters);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState();

  const chartData = useMemo(() => fillMissingMonths<Data>(data, (date) => ({
    date,
    layout: null,
    series: '',
    value: 0,
    availability: isSold ? Availability.SOLD : Availability.RESERVED,
    series: isSold ? Availability.SOLD : Availability.RESERVED,
    count: 0,
  })), [data]);

  const getColors = (configData: Data[]): string[] => {
    const index = configData.findIndex(
      (d) => d.series === 'sold' || d.series === 'reserved',
    );
    const color = isSold ? SOLD : RESERVED;
    const arr = [BLUE_1, BLUE_3, BLUE_4, BLUE_5];
    arr.splice(index, 0, color);
    return arr;
  };

  const config: ColumnConfig = getColumnOptions({
    data: chartData.sort(
      (a, b) =>
        new Date(a.date).getTime() - new Date(b.date).getTime() ||
        b.value - a.value,
    ),
    color: isOverall
      ? getColors(
          data.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          ),
        )
      : [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1],
    xField: 'date',
    yField: 'value',
    ...(!isOverall ? { isStack: true } : {}),
    seriesField: isOverall ? 'series' : 'layout',
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: isOverall ? String(value.series) : String(value.layout),
          value: value.value ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color
            }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${formatNumber(item?.value)}</div>
            </div>
            `;
        });
        htmlStr += `<div class="bmt-item">
            <div class="bmt-label">${t(
          'common.click_to_see_more',
          'Click to see more.',
        )}</div>
            </div></div></div>`;
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
          });
        },
      },
    },
    legend: {
      itemName: {
        formatter: (value) => translateValue(value),
      },
    },
  });

  const chartConfig = {
    ...config,
    columnStyle: {
      radius: !isOverall ? 0 : [7, 7, 0, 0],
      cursor: 'pointer',
    },
    label: displayValues
      ? {
          position: isOverall ? 'top' : 'middle',
          style: {
            fill: '#000000',
            opacity: 0.6,
            padding: 2,
          },
          layout: [{ type: 'adjust-color' }],
          content: ({ value }) => {
            if (isOverall) return value;
            const max = Math.max(...data.map((d) => d.value));
            const percentage = (value / max) * 100;
            return percentage < 10 ? '' : `${value}`;
          },
        }
      : null,
    yAxis: {
      max: isOverall ? Math.max(...data.map((d) => d.value)) * 1.1 : undefined,
      grid: null,
    },
    onReady: (plot) => {
      plot.on('plot:mousemove', (evt) => {
        // pointer cursor
        const canvasElement = plot.chart.getCanvas().get('el');
        const elements = plot.chart.getTooltipItems({ x: evt.x, y: evt.y });
        if (elements.length) {
          canvasElement.style.cursor = 'pointer';
        } else {
          canvasElement.style.cursor = 'default';
        }
      });
      plot.on('plot:click', (evt) => {
        const { x, y } = evt;
        // Get the elements at the click position
        const elements = plot.chart.getTooltipItems({ x, y });
        if (elements.length) {
          // Assuming the first item corresponds to the clicked column
          if (elements[0].data.value !== 0) {
            setIsModalVisible(true);
            setModalData(elements[0].data);
          }
        }
      });
    },
  };

  return data.length > 0 ? (
    <>
      <Column {...chartConfig} />
      <DemandByMonthModal
        open={isModalVisible}
        setOpen={setIsModalVisible}
        columnData={modalData}
        showPrivate={showPrivate}
        showB2B={showB2B}
      />
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
