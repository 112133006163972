import i18next from 'i18next';
import Backend from 'i18next-http-backend';
// import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
// import LastUsed from 'locize-lastused';

// const isProduction = process.env.NODE_ENV === 'production';

/* const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  refLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
}; */

/* if (!isProduction) {
  i18next.use(LastUsed);
} */

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultLocale: 'en',
    supportedLngs: ['en', 'sk', 'cs', 'de'],
    keySeparator: false,
    backend: {
      loadPath: 'https://1ki66xm0jc.execute-api.eu-central-1.amazonaws.com/Prod/translations/{{lng}}',
      allowMultiLoading: false,
      request: (options, url, payload, callback) => {
        axios.get(url).then((res) => {
            callback(null, { status: 200, data: res.data });
        }).catch((err) => callback(err));
      },
    },
    react: {
      defaultTransParent: 'div',
      bindI18n: 'languageChanged editorSaved',
    },
    saveMissing: false,
    returnNull: false,
  });

/* i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultLocale: 'en',
    ns: ['frontend'],
    defaultNS: 'frontend',
    supportedLngs: ['en', 'sk', 'cs'],
    backend: locizeOptions,
    react: {
      defaultTransParent: 'div',
      bindI18n: 'languageChanged editorSaved',
    },
    locizeLastUsed: locizeOptions,
    saveMissing: !isProduction,
    returnNull: false,
  }); */
