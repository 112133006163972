/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { Data, PriceHistoryChart } from './PriceHistoryChart';
import { styles } from '../styles';
import { PriceHistoryData } from '../../../../api/types';
import { ChartCard } from '../../../ChartCard';
import { Availability } from '../../../../store/types';
import { getPriceHistoryAvailableLayout, getPriceHistoryAvailableOverall, getPriceHistoryDisappearedLayout, getPriceHistoryDisappearedOverall } from '../../../../api/secondary';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

export enum AvailabilityFilterEnum {
  SOLD = 'sold',
  AVAILABLE = 'available',
  ALL = 'all'
}

export const PriceHistory: FC = () => {
  const { isRentals } = useMarketDetails();
  const [data, setData] = useState<PriceHistoryData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const filterAvailability = useFilter(AvailabilityFilterEnum.AVAILABLE);
  const { calculateVatPrice, sortLayouts } = useRegionFormatting();
  const isOverall = filterValue === FilterEnum.OVERALL;
  const pngRef = useRef(null);

  const chartData: Data[] = useMemo(() =>
    data.map(
      ({
        series,
        availability,
        avg_price,
        date,
        project_name,
        ...rest
      }) => ({
        series:
          series === 'current_dashboard' ? availability : project_name,
        availability,
        avg_price: calculateVatPrice(avg_price, 120),
        date,
        count: 1,
        project_name,
        ...rest,
      }),
    ), [data, calculateVatPrice]);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      const startDate = date.toISOString().split('T')[0];
      const endDate = paramsSM.date;
      const payload = {
        reportId,
        startDate,
        endDate,
      };
      switch (filterAvailability.filterValue) {
        case AvailabilityFilterEnum.AVAILABLE:
          if (filterValue === FilterEnum.OVERALL) {
           getPriceHistoryAvailableOverall(payload)
              .then((response) => {
                setData([...response.map((d) => ({
                  date: d.createdDate,
                  availability: Availability.AVAILABLE,
                  avg_price: Number(d.availableUnitsAveragePrice) || null,
                  layout: '',
                  series: 'current_dashboard',
                  project_name: 'current_dashboard',
                }))]);
              }).finally(() => {
                setLoading(false);
              });
          } else {
            getPriceHistoryAvailableLayout(payload)
              .then((response) => {
                const newData: PriceHistoryData[] = [];
                response.forEach((d) => {
                  d.availableLayout.forEach((l) => {
                    newData.push({
                      date: d.createdDate,
                      availability: Availability.AVAILABLE,
                      avg_price: Number(l.price),
                      layout: `layout_${l.countOfRegularRooms}`,
                      series: 'current_dashboard',
                      project_name: 'current_dashboard',
                    });
                  });
                });
                setData(newData);
              }).finally(() => {
                setLoading(false);
              });
          }
          break;
        case AvailabilityFilterEnum.SOLD:
          if (filterValue === FilterEnum.OVERALL) {
            getPriceHistoryDisappearedOverall(payload)
              .then((response) => {
                setData([...response.map((d) => ({
                  date: d.createdDate,
                  availability: Availability.SOLD,
                  avg_price: Number(d.disappearedUnitsAveragePrice) || null,
                  layout: '',
                  series: 'current_dashboard',
                  project_name: 'current_dashboard',
                }))]);
              }).finally(() => {
                setLoading(false);
              });
          } else {
            getPriceHistoryDisappearedLayout(payload)
              .then((response) => {
                const newData: PriceHistoryData[] = [];
                response.forEach((d) => {
                  d.disappearedLayout.forEach((l) => {
                    newData.push({
                      date: d.createdDate,
                      availability: Availability.SOLD,
                      avg_price: Number(l.price),
                      layout: `layout_${l.countOfRegularRooms}`,
                      series: 'current_dashboard',
                      project_name: 'current_dashboard',
                    });
                  });
                });
                setData(newData);
              }).finally(() => {
                setLoading(false);
              });
          }
          break;
        default:
          if (filterValue === FilterEnum.OVERALL) {
            Promise.all([
              getPriceHistoryAvailableOverall(payload),
              getPriceHistoryDisappearedOverall(payload),
            ]).then((response) => {
              const [available, disappeared] = response;
              setData([
                ...available.map((d) => ({
                  date: d.createdDate,
                  availability: Availability.AVAILABLE,
                  avg_price: Number(d.availableUnitsAveragePrice) || null,
                  layout: '',
                  series: 'available',
                  project_name: 'available',
                })),
                ...disappeared.map((d) => ({
                  date: d.createdDate,
                  availability: Availability.SOLD,
                  avg_price: Number(d.disappearedUnitsAveragePrice) || null,
                  layout: '',
                  series: 'sold',
                  project_name: 'sold',
                })),
            ]);
            }).finally(() => {
              setLoading(false);
            });
          } else {
            Promise.all([
              getPriceHistoryAvailableLayout(payload),
              getPriceHistoryDisappearedLayout(payload),
            ]).then((response) => {
              const [available, disappeared] = response;
              const newData: PriceHistoryData[] = [];
              disappeared.forEach((d) => {
                d.disappearedLayout.forEach((l) => {
                  newData.push({
                    date: d.createdDate,
                    availability: Availability.SOLD,
                    avg_price: Number(l.price),
                    layout: `layout_${l.countOfRegularRooms}`,
                    series: 'sold',
                    project_name: 'sold',
                  });
                });
              });
              available.forEach((d) => {
                d.availableLayout.forEach((l) => {
                  newData.push({
                    date: d.createdDate,
                    availability: Availability.AVAILABLE,
                    avg_price: Number(l.price),
                    layout: `layout_${l.countOfRegularRooms}`,
                    series: 'available',
                    project_name: 'available',
                  });
                });
              });
              setData(newData);
            }).finally(() => {
              setLoading(false);
            });
          }
          break;
      }
    }
  }, [filterAvailability.filterValue, filterValue, reportId, sortLayouts, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.price_history.title', 'Price History'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={isRentals ? t('market.reports.rent_history.title', 'Rent History') : t('market.reports.price_history.title', 'Price History')}
      subtitle={
        isRentals
          ? t('market.reports.rent_history.subtitle')
          : t('market.reports.price_history.subtitle', 'The history of the average price per square meter of the selected projects.')
        }
      extra={!isLoading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      chart={
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          <PriceHistoryChart
            availability={filterAvailability.filterValue as AvailabilityFilterEnum}
            data={chartData}
            isOverall={isOverall}
          />
        )
      }
      controls={
        <>
          <Segmented
            style={{ marginRight: '1.2rem' }}
            value={filterAvailability.filterValue}
            options={[
              {
                value: AvailabilityFilterEnum.AVAILABLE,
                label: t('enums.state.available'),
              },
              {
                value: AvailabilityFilterEnum.SOLD,
                label: t(isRentals ? 'enums.state.leased' : 'enums.state.sold'),
              },
              {
                value: AvailabilityFilterEnum.ALL,
                label: t('enums.state.both'),
              },
            ]}
            onChange={filterAvailability.handleChange}
          />
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: FilterEnum.LAYOUTS,
                label: t('dashboard.sale_progress.layouts', 'Layouts'),
              },
            ]}
            onChange={handleChange}
          />
        </>
      }
    />
    </div>
  );
};
