/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  FormItemProps,
  FormProps,
  Input,
  Modal,
  Row,
  Tag,
  message,
} from 'antd';
import type { CheckableTagProps, TagProps } from 'antd/es/tag';
import {
  DocumentDuplicateIcon,
  LockClosedIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { Mode } from '../../../../store/types';
import { Card } from '../../../Card';
import { styles } from './styles';
import {
  MarketTypeEnum,
  BuildingConditionEnum,
  CountryEnum,
} from '../../../../api/enums';
import { getCountryCode } from '../../../../utils/utils';
import { MarketIcon } from './icons/MarketIcon';

const { Item } = Form;
const { CheckableTag } = Tag;

const AddReportFormItem: FC<FormItemProps> = (props) => (
  <Item colon={false} labelAlign='left' {...props} />
);

const AddReportFormItemSelect: FC<
  CheckableTagProps & Pick<TagProps, 'icon'>
> = ({ children, ...props }) => (
  <CheckableTag
    css={!props.checked ? styles.selector : styles.checkedSelector}
    {...props}
  >
    {children}
  </CheckableTag>
);

interface AddReportForm {
  name: string;
  city: string[];
  market_type: MarketTypeEnum;
  include_new_projects: boolean;
}

interface AddReportProps {
  onClick?: () => void;
}

export const AddReport: FC<AddReportProps> = ({ onClick }) => {
  const [includeNewProjects, setIncludeNewProjects] = useState(true);
  const [buildingConditions, setBuildingConditions] = useState<
    Array<BuildingConditionEnum>
  >([BuildingConditionEnum.NEW]);

  const user = useStoreState((state) => state.user.profile);
  const isSlovakOrCzech = user?.country === CountryEnum.SLOVAKIA || user?.country === CountryEnum.CZECHIA;
  const mode = useStoreState((state) => state.market.mode);
  const activeStep = useStoreState((state) => state.market.addReportStep);
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const setActiveStep = useStoreActions(
    (actions) => actions.market.setAddReportStep,
  );
  const resetPolygons = useStoreActions(
    (actions) => actions.market.resetPolygons,
  );
  const fetchAllProjects = useStoreActions(
    (actions) => actions.market.fetchAllProjects,
  );
  // const primaryParameters = useStoreState((state) => state.market.primaryParameters);
  // const secondaryParameters = useStoreState((state) => state.market.secondaryParameters);
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setSecondaryParameters = useStoreActions(
    (actions) => actions.market.setSecondaryParameters,
  );
  const setSelectedReportName = useStoreActions(
    (actions) => actions.filters.setSelectedReportName,
  );
  const setProjects = useStoreActions((actions) => actions.market.setProjects);

  const secondaryReports = useStoreState(
    (state) => state.secondaryMarket.reports,
  );
  const selectedReportName = useStoreState(
    (state) => state.filters.selectedReportName,
  );
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};
  const isStandardPlan =
    frequency === 'daily' || frequency === 'weekly' || frequency === null;
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const allReports = [
    ...(overviewData?.market_dashboards || []).map((d) => ({
      name: d.name,
      reportType: MarketTypeEnum.PRIMARY,
    })),
    ...(secondaryReports || []).map((d) => ({
      name: d.name,
      reportType: d.reportType,
    })),
  ] as { name: string; reportType: MarketTypeEnum }[];

  const [markets, setMarkets] = useState<MarketTypeEnum[]>(
    allReports
      .filter((r) => r.name === selectedReportName)
      .map((r) => r.reportType),
  );

  const secModalCheck =
    markets.includes(MarketTypeEnum.SECONDARY) ||
    markets.includes(MarketTypeEnum.RENTAL);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (activeStep === null) {
      // Initialize the first step
      setActiveStep(1);
    }
  }, [activeStep, setActiveStep]);

  useEffect(() => {
    if (
      markets.includes(MarketTypeEnum.PRIMARY) &&
      mode !== Mode.READ &&
      activeStep === 2
    ) {
      // Fetch all projects
      fetchAllProjects();
    }
  }, [mode, fetchAllProjects, activeStep, markets]);

  const [form] = Form.useForm<AddReportForm>();
  const [isVisible, setVisibility] = useState(
    mode === Mode.START || mode === Mode.EDITSTART,
  );
  const dashboardId = useStoreState(
    (state) => state.filters.paramsPM?.dashboardId,
  );
  const dashboardName = useStoreState(
    (state) => state.filters.overviewData?.market_dashboards,
  );
  const { t } = useTranslation();

  const handleMarketToggle = (checked: boolean, market: MarketTypeEnum): void =>
    checked
      ? setMarkets([...markets, market])
      : setMarkets((prevState) => prevState.filter((m) => m !== market));

  const handleClick = (): void => {
    if (onClick) {
      setSelectedReportName('');
      onClick();
    }
    setVisibility(true);
  };
  const handleCancel = (): void => {
    setVisibility(false);
    setPrimaryParameters(null);
    setSecondaryParameters(null);
    setActiveStep(null);
    setMode(Mode.READ);
  };

  const handleBuildingConditions = (value: BuildingConditionEnum): void => {
    if (buildingConditions.includes(value)) {
      if (buildingConditions.length === 1) {
        return;
      }
      setBuildingConditions((prev) => prev.filter((v) => v !== value));
    } else {
      setBuildingConditions((prev) => [...prev, value]);
    }
  };

  const handleSubmit: FormProps<AddReportForm>['onFinish'] = (values): void => {
    // Check if the user selected PRIMARY PROJECTS market type
    const hasPrimaryOption = markets.includes(MarketTypeEnum.PRIMARY);
    // Check if the user selected SECONDARY or RENTALS market type
    const hasSecondaryOptions =
      markets.includes(MarketTypeEnum.SECONDARY) ||
      markets.includes(MarketTypeEnum.RENTAL);

    if (hasPrimaryOption) {
      // Initialize params from the step 1
      setPrimaryParameters({
        dashboard_name: values.name,
        included_project_ids: [],
        remaining_project_ids: [],
        geometry: null,
        include_new_projects: includeNewProjects,
        markets: null,
      });
    } else {
      setPrimaryParameters(null);
      setProjects([]);
    }

    const country = getCountryCode(user?.country);
    // If the user selects secondary market or rentals, continue to STEP 2
    // otherwise, skip and continue to STEP 3
    if (hasSecondaryOptions && user && (country === 'CZ' || country === 'SK')) {
      setSecondaryParameters({
        country,
        name: values.name,
        geometry: null,
        user_id: user.sub,
        market_types: markets,
        building_conditions: buildingConditions,
      });
    }
    setActiveStep(2);
    setVisibility(false);
    if (mode === Mode.EDITSTART) {
      setMode(Mode.EDIT);
    } else {
      setMode(Mode.CREATE);
      resetPolygons();
    }
  };

  useEffect(() => {
    const hasSecondaryOptions =
      markets.includes(MarketTypeEnum.SECONDARY) ||
      markets.includes(MarketTypeEnum.RENTAL);
    if (!hasSecondaryOptions) {
      setBuildingConditions([]);
    } else {
      setBuildingConditions([BuildingConditionEnum.NEW]);
    }
  }, [markets]);

  const copyToClipboard = (): void => {
    navigator.clipboard.writeText('info@builtmind.com');
    messageApi.open({
      type: 'success',
      content: t('common.copy', 'Copied'),
      duration: 2,
      style: {
        position: 'absolute',
        top: 10,
        right: 20,
      },
    });
  };

  const reportName = dashboardName?.find((d) => d.id === dashboardId)?.name;

  return (
    <>
      {mode !== Mode.EDITSTART && (
        <Card className='add-report-card report-card' onClick={handleClick}>
          <div className='title'>
            + {t('market.projects.add_report_form.title')}
          </div>
        </Card>
      )}
      <Modal
        className='add-report-modal'
        open={activeStep !== null && mode !== Mode.READ}
        style={{
          top: 20,
          width: 'auto',
        }}
        maskStyle={{
          pointerEvents: 'none',
          zIndex: 9999,
        }}
        wrapProps={{
          style: {
            pointerEvents: 'none',
            zIndex: 9999,
          },
        }}
        closable={false}
        footer={null}
        mask={false}
      >
        <Row
          className='form-header'
          justify='space-around'
          style={{ width: '100%' }}
        >
          <div className={`form-header-item ${activeStep === 1 && 'active'}`}>
            <div className='icon'>1</div>
            <div className='title'>{t('enums.start', 'Start')}</div>
          </div>
          <div className={`form-header-item ${activeStep === 2 && 'active'}`}>
            <div className='icon'>2</div>
            <div className='title'>
              {t('enums.data_selection', 'Data Selection')}
            </div>
          </div>
        </Row>
      </Modal>
      <Modal
        open={isVisible && activeStep !== 2}
        centered
        footer={null}
        className='add-report-modal'
        closable={false}
        onCancel={handleCancel}
      >
        <Form
          className='form'
          form={form}
          data-cy='add-report-modal-step-1'
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <AddReportFormItem
            label={t('market.projects.add_report_form.name')}
            name='name'
            rules={[
              {
                required: true,
                validator: async (_, value) => {
                  if (
                    allReports
                      .map((r) => r.name)
                      .filter((name) => name !== reportName)
                      .includes(value)
                  ) {
                    throw new Error(t('market.validations.name_unique'));
                  }
                  if (!value) {
                    throw new Error(t('market.validations.name'));
                  }
                },
              },
            ]}
            initialValue={mode === Mode.EDITSTART ? reportName : ''}
            required={false}
          >
            <Input
              css={styles.input}
              className='input'
              placeholder={t(
                'market.projects.add_report_form.name.placeholder',
                'Untitled',
              )}
            />
          </AddReportFormItem>
          <AddReportFormItem
            name='market_type'
            label={t(
              'market.projects.add_report_form.markets.title',
              'Markets',
            )}
            rules={[
              {
                required: markets.length === 0,
                message: t(
                  'market.validations.market_type',
                  'It is required to pick at least one market type.',
                ),
              },
            ]}
            required={false}
          >
            <Checkbox.Group>
              <AddReportFormItemSelect
                checked={markets.includes(MarketTypeEnum.PRIMARY)}
                onChange={(checked) =>
                  handleMarketToggle(checked, MarketTypeEnum.PRIMARY)}
              >
                <MarketIcon
                  market='primary'
                  disabled={!markets.includes(MarketTypeEnum.PRIMARY)}
                />
                <span>{t('reports.topbar.primary', 'Primary Projects')}</span>
              </AddReportFormItemSelect>
              {isSlovakOrCzech ? (
                <>
                  {isStandardPlan ? (
                    <>
                      <AddReportFormItemSelect
                        checked={markets.includes(MarketTypeEnum.SECONDARY)}
                        onChange={(checked) =>
                          handleMarketToggle(checked, MarketTypeEnum.SECONDARY)}
                      >
                        <MarketIcon
                          market='secondary'
                          disabled={!markets.includes(MarketTypeEnum.SECONDARY)}
                        />
                        <span>
                          {t('reports.topbar.secondary', 'Secondary')}
                        </span>
                      </AddReportFormItemSelect>
                      <AddReportFormItemSelect
                        checked={markets.includes(MarketTypeEnum.RENTAL)}
                        onChange={(checked) =>
                          handleMarketToggle(checked, MarketTypeEnum.RENTAL)}
                      >
                        <MarketIcon
                          market='rental'
                          disabled={!markets.includes(MarketTypeEnum.RENTAL)}
                        />
                        <span>{t('reports.topbar.rentals', 'Rentals')}</span>
                      </AddReportFormItemSelect>
                    </>
                  ) : (
                    <>
                      <div
                        className='flex border border-gray-300 pl-3 pr-4 items-center rounded-full space-x-2 select-none mr-3 bg-[#F1F1F1] text-bmgray'
                        onClick={() => setIsInfoModalOpen(true)}
                      >
                        <LockClosedIcon className='w-8 stroke-2' />
                        <span>
                          {t('reports.topbar.secondary', 'Secondary')}
                        </span>
                      </div>
                      <div
                        className='flex border border-gray-300 pl-3 pr-4 items-center rounded-full space-x-2 select-none bg-[#F1F1F1] text-bmgray'
                        onClick={() => setIsInfoModalOpen(true)}
                      >
                        <LockClosedIcon className='w-8 stroke-2' />
                        <span>{t('reports.topbar.rentals', 'Rentals')}</span>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </Checkbox.Group>
          </AddReportFormItem>
          {(((mode === Mode.EDITSTART || mode === Mode.EDIT) &&
            markets.includes(MarketTypeEnum.PRIMARY)) ||
            mode !== Mode.EDIT) && (
            <AddReportFormItem
              label={t(
                'market.projects.add_report_form.update_policy.title',
                'Include New Projects',
              )}
              name='update_policy'
              required={false}
            >
              <Checkbox.Group>
                <AddReportFormItemSelect
                  checked={includeNewProjects}
                  onClick={() => setIncludeNewProjects(true)}
                >
                  <span>
                    {t(
                      'market.projects.add_report_form.update_policy.automatic',
                      'Automatically',
                    )}
                  </span>
                </AddReportFormItemSelect>
                <AddReportFormItemSelect
                  checked={!includeNewProjects}
                  onClick={() => setIncludeNewProjects(false)}
                >
                  <span>
                    {t(
                      'market.projects.add_report_form.update_policy.manual',
                      'Manually',
                    )}
                  </span>
                </AddReportFormItemSelect>
              </Checkbox.Group>
            </AddReportFormItem>
          )}
          {secModalCheck && (
            <AddReportFormItem
              label={t(
                'market.projects.add_report_form.secondary_age.title',
                'Secondary Age',
              )}
              name='secondary_age'
              required={false}
            >
              <Checkbox.Group>
                <AddReportFormItemSelect
                  checked={buildingConditions.includes(
                    BuildingConditionEnum.NEW,
                  )}
                  onClick={() =>
                    handleBuildingConditions(BuildingConditionEnum.NEW)}
                >
                  <div
                    className={`w-5 h-5 ml-2 rounded-full ${
                      buildingConditions.includes(BuildingConditionEnum.NEW)
                        ? 'bg-[#5A72B1]'
                        : 'bg-[#9EB9DA80]'
                    }`}
                  />
                  <span>{t('enums.new', 'New')}</span>
                </AddReportFormItemSelect>
                <AddReportFormItemSelect
                  checked={buildingConditions.includes(
                    BuildingConditionEnum.UNKNOWN,
                  )}
                  onClick={() =>
                    handleBuildingConditions(BuildingConditionEnum.UNKNOWN)}
                >
                  <div
                    className={`w-5 h-5 ml-2 rounded-full ${
                      buildingConditions.includes(BuildingConditionEnum.UNKNOWN)
                        ? 'bg-[#5A72B1]'
                        : 'bg-[#9EB9DA80]'
                    }`}
                  />
                  <span>{t('enums.old', 'Old')}</span>
                </AddReportFormItemSelect>
              </Checkbox.Group>
            </AddReportFormItem>
          )}
          <Row className='footer'>
            <Button
              css={styles.selectBtn}
              className='submit-btn'
              shape='round'
              onClick={() => handleCancel()}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              css={styles.selectBtn}
              className='submit-btn'
              shape='round'
              htmlType='submit'
            >
              {t('common.next', 'Next')}
            </Button>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={isInfoModalOpen}
        footer={null}
        className='rounded-[13px] max-w-md'
        closable={false}
        onCancel={() => setIsInfoModalOpen(false)}
        mask={false}
      >
        <div className='flex flex-col items-start text-base relative'>
          {contextHolder}
          <XMarkIcon
            className='w-6 stroke-[3px] absolute top-0 right-0 cursor-pointer text-[#BDBDBD]'
            onClick={() => setIsInfoModalOpen(false)}
          />
          <span>
            {t(
              'market.projects.add_report_form.info_title',
              'To access this module reach out to',
            )}
            :
          </span>
          <div className='flex items-center space-x-3 mt-5'>
            <span className='font-semibold'>info@builtmind.com</span>
            <Button
              className='border-none shadow-none w-6 h-6 p-0 rounded-full'
              onClick={() => copyToClipboard()}
            >
              <DocumentDuplicateIcon className='w-6' />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
