import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowData, InfoPopover } from '../../../../../InfoPopover';

interface Props {
  building_use: string;
}

export const BuildingUse: FC<Props> = ({
  building_use,
}) => {
  const { t } = useTranslation();

  const rows: RowData[] = useMemo(
    () => [
      {
        label: t(`project.overview.building_use.${building_use}`),
        value: t(`project.overview.building_use.info.${building_use}`),
      },
    ],
    [building_use, t],
  );

  return (
    <InfoPopover
      label={t(`project.overview.building_use.${building_use}`)}
      popoverProps={{ rows }}
    />
  );
};
