import { FC } from 'react';
import { MarketProjectExtended } from '../../store/types';
import { Footer } from '../map/ProjectDetail/Footer';
import { Content } from '../map/ProjectDetail/Content';
import { Header } from '../map/ProjectDetail/Header';

interface ProjectDetailProps {
  selectedProject: MarketProjectExtended;
  onClose: () => void;
}

export const ProjectDetail: FC<ProjectDetailProps> = ({
  selectedProject,
  onClose,
}) => (
    <div className='market-project-detail'>
      <div className='single-project'>
        <Header data={selectedProject} onClose={onClose} />
        <Content data={selectedProject} />
        <Footer
          project_id={selectedProject.project_id}
          include={selectedProject.include}
        />
      </div>
    </div>
  );
