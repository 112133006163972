import { FC, useEffect, useMemo } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BuildingOfficeIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useStoreActions, useStoreState } from '../hooks';

const { Option } = Select;

type ProjectSelectProps = SelectProps & {
  isPriceUpdate?: boolean;
}

export const ProjectSelect: FC<ProjectSelectProps> = (props) => {
  const { isPriceUpdate } = props;
  const { overviewData, projectId, isLoading, projectType } = useStoreState(
    (state) => state.filters,
  );

  const { setProjectId } = useStoreActions((actions) => actions.filters);
  const { t } = useTranslation();
  const options = useMemo(() => (
    isPriceUpdate
    ? overviewData?.updates
    : overviewData?.projects) || [],
    [isPriceUpdate, overviewData],
  );

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isValidOption = options.find((option) => option.project_id === projectId);
      if (!isValidOption && options.length > 0) {
        setProjectId(options[0].project_id);
      }
    }, [options, projectId, setProjectId]);

  return isLoading ? (
    <Spin spinning />
  ) : (
    <div className='flex items-center'>
    <BuildingOfficeIcon className='filters-icon' />
    <Select
      bordered={false}
      value={projectId}
      onChange={setProjectId}
      placeholder={t('header.project')}
      suffixIcon={<ChevronDownIcon className='filters-chevron-icon' />}
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {(options || []).map(({ project_id, name }) => (
        <Option key={project_id} value={project_id}>
          {name}
        </Option>
      ))}
    </Select>
    </div>
  );
};
