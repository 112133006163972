import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../hooks';

export const OverviewDataProvider: React.FC = () => {
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const fetchOverviewData = useStoreActions(
    (actions) => actions.filters.fetchOverviewData,
  );

  useEffect(() => {
    if (developerId) {
      fetchOverviewData();
    }
  }, [fetchOverviewData, developerId]);

  return null;
};
