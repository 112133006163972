/** @jsxImportSource @emotion/react */
import { FC, MouseEvent } from 'react';
import { Popover, Checkbox, Button, message } from 'antd';
import { css } from '@emotion/react/macro';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { ExportToCsv, Options } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { useFlatLayout, useRegionFormatting, useStoreActions, useStoreState } from '../../../../hooks';
import { getFlats, getFlatsFromS3 } from '../../../../api';
import { round } from '../../../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_flats`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type Props = {
  showValues?: boolean;
  onDownloadPNG?: () => void;
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
};

export const csvExporter = new ExportToCsv(options);

export const SupplyCardPopover: FC<Props> = ({ showValues = false, onDownloadPNG }) => {
  const { displayValues } = useStoreState((state) => state.filters);
  const { setDisplayValues } = useStoreActions((action) => action.filters);
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { calculateVatPrice, getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayout } = useFlatLayout();

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    handlePropagation(e);
    setDisplayValues(!displayValues);
  };

  const handleButtonClick = async (exportFormat: string): Promise<void> => {
    message.loading(t('market.export.preparing_data'), 0);
    if (paramsPM) {
      getFlats(paramsPM.dashboardId, paramsPM.date)
        .then(({ data: exportData }) => getFlatsFromS3(exportData.s3url))
        .then(({ data: flats }) => {
          const filteredFlats = flats.filter((flat) => {
            if (isSold) {
              // Only include flats that are 'available' if isSold is true
              return flat.availability === 'available';
            }
            return flat.availability === 'available' || flat.availability === 'reserved';
          });
          const updatedExportData = filteredFlats.map(
            ({
              project_name,
              developer,
              address,
              id,
              building,
              category,
              orientation,
              DOM,
              availability,
              exterior_area,
              floor,
              floor_area,
              layout,
              price,
              price_per_sm,
              total_area,
              first_seen,
              parking_indoor_price,
              parking_outdoor_price,
              url,
              original_price,
            }) => ({
              project: project_name,
              developer,
              address,
              id,
              building: building ?? '',
              category,
              availability: t(`enums.state.${availability.toLowerCase()}`),
              exterior_area: exterior_area ?? 0,
              floor: floor ?? '',
              floor_area: floor_area ?? '',
              layout: getLayout(layout),
              ...{
                ...withVAT ? {
                  price_with_vat: round(calculateVatPrice(price, floor_area, Number(exterior_area)) || 0, 2),
                  original_price_with_vat: round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2),
                  price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
                  price_per_sm_with_vat: round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2),
                  parking_indoor_price_with_vat: calculateVatPrice(parking_indoor_price, 1) ?? '',
                  parking_outdoor_price_with_vat: calculateVatPrice(parking_outdoor_price, 1) ?? '',
                } : {
                  price_without_vat: calculateVatPrice(price, floor_area, Number(exterior_area)),
                  original_price_without_vat: round(calculateVatPrice(original_price, floor_area, Number(exterior_area)) || 0, 2),
                  price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
                  price_per_sm_without_vat: round(calculateVatPrice(price_per_sm, floor_area, Number(exterior_area)) || 0, 2),
                  parking_indoor_price_without_vat: calculateVatPrice(parking_indoor_price, 1) ?? '',
                  parking_outdoor_price_without_vat: calculateVatPrice(parking_outdoor_price, 1) ?? '',
                },
              },
              total_area: total_area ?? '',
              orientation: orientation ?? '',
              first_seen,
              days_on_market: DOM,
              currency: getCurrency(),
              url: url ?? '',
            }),
          );

          if (exportFormat === 'CSV') {
            csvExporter.options.filename = `${options.filename}${getVATText()}`;
            csvExporter.generateCsv(updatedExportData);
          } else {
            const worksheet = XLSX.utils.json_to_sheet(updatedExportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
          }

          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Popover
      overlayClassName='report-actions'
      placement='bottom'
      content={
        <div css={styles.container}>
          {showValues && (
            <div className='actions' role='presentation' onClick={handleClick}>
              <div>
                {t('common.display_values')}
              </div>
              <Checkbox checked={displayValues} />
            </div>
          )}
          {onDownloadPNG && (
            <Button
              css={styles.download}
              icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
              onClick={onDownloadPNG}
              style={{ marginTop: showValues ? 10 : 0 }}
              type='text'
            >
              PNG
            </Button>
          )}
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('CSV')}
            type='text'
          >
            {t('market.button.export_csv')}
          </Button>
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('XLSX')}
            type='text'
          >
            {t('market.button.export_xlsx')}
          </Button>
        </div>
      }
    >
      <div
        role='button'
        tabIndex={0}
        className='actions-download'
        onClick={handlePropagation}
      >
        <ArrowDownTrayIcon />
      </div>
    </Popover>
  );
};
