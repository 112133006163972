import { useState } from 'react';

export const useFilter = (
  defaultValue: string | number,
): {
  filterValue: string | number;
  handleChange: (value: string | number) => void;
} => {
  const [filterValue, setFilterValue] = useState<string | number>(defaultValue);
  const handleChange = (value: string | number): void => {
    setFilterValue(value);
  };

  return { filterValue, handleChange };
};
