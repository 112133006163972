import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getPriceListDetailData } from '../api';
import { StoreModel } from './types';
import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { getValidPriceListDate, logError } from '../utils/utils';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PriceDetailFilter } from '../api/enums';

export interface PriceListDetailStore {
  data?: PriceListDetailData;
  comparisonData?: PriceListDetailData[];
  showPrediction: Computed<PriceListDetailStore, boolean, StoreModel>;
  comparisonPrediction: Computed<PriceListDetailStore, number[], StoreModel>;
  setData: Action<PriceListDetailStore, PriceListDetailData>;
  setComparisonData: Action<PriceListDetailStore, PriceListDetailData[]>;
  fetchData: Thunk<
    PriceListDetailStore,
    number,
    unknown,
    StoreModel,
    Promise<void>
  >;
  fetchComparisonData: Thunk<
    PriceListDetailStore,
    number[],
    unknown,
    StoreModel,
    Promise<PriceListDetailData[] | undefined>
  >;
  priceFilter?: PriceDetailFilter;
  setPriceDetailFilter: Action<PriceListDetailStore, PriceDetailFilter>;
}

export const priceListDetailStore: PriceListDetailStore = {
  showPrediction: computed(
    [
      (state) => state.data,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (data, predictionAvalability) => {
      if (data && predictionAvalability) {
        return predictionAvalability.includes(data.description.availability);
      }
      return false;
    },
  ),
  comparisonPrediction: computed(
    [
      (state) => state.comparisonData,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (comparisonData, predictionAvalability) => {
      if (comparisonData && predictionAvalability) {
        return comparisonData.filter((cd) => predictionAvalability.includes(cd.description.availability)).map((cd) => cd.id);
      }
      return [];
    },
  ),
  setData: action((state, newData: PriceListDetailData) => {
    state.data = newData;
  }),
  setComparisonData: action((state, data) => {
    state.comparisonData = data;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setPhaseId, setProjectId, setLoading },
    } = helpers.getStoreActions();
    const {
      filters: { overviewData },
      user: { profile },
    } = helpers.getStoreState();

    const lastValidDate = getValidPriceListDate(null, 120);
    const { price_per_sm_calculation } = profile || {};
    try {
      setGlobalLoading(true);
      setLoading(true);
      const { data } = await getPriceListDetailData(
        payload,
        lastValidDate,
        price_per_sm_calculation as PricePerSmCalcType,
      );
      if (data.phase_id) {
        setPhaseId(data.phase_id);
      }
      if (data.project_id) {
        setProjectId(data.project_id);
      }
      actions.setData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
  }),
  // eslint-disable-next-line consistent-return
  fetchComparisonData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setLoading },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();

    const lastValidDate = getValidPriceListDate(null, 120);
    const { price_per_sm_calculation } = profile || {};
    try {
      setGlobalLoading(true);
      setLoading(true);
      const comparisonData = await Promise.all(payload.map(async (id) => {
        const { data } = await getPriceListDetailData(
          id,
          lastValidDate,
          price_per_sm_calculation as PricePerSmCalcType,
        );
        return data;
      }));
      actions.setComparisonData(comparisonData);
      return comparisonData;
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
    return [];
  }),
  setPriceDetailFilter: action((state, data) => {
    state.priceFilter = data;
  }),
};
