/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableUnitsPricePerSmResponseSchema
 */
export interface AvailableUnitsPricePerSmResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof AvailableUnitsPricePerSmResponseSchema
     */
    availableUnitsPricePerSm: number | null;
}

/**
 * Check if a given object implements the AvailableUnitsPricePerSmResponseSchema interface.
 */
export function instanceOfAvailableUnitsPricePerSmResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableUnitsPricePerSm" in value;

    return isInstance;
}

export function AvailableUnitsPricePerSmResponseSchemaFromJSON(json: any): AvailableUnitsPricePerSmResponseSchema {
    return AvailableUnitsPricePerSmResponseSchemaFromJSONTyped(json, false);
}

export function AvailableUnitsPricePerSmResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableUnitsPricePerSmResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableUnitsPricePerSm': json['available_units_price_per_sm'],
    };
}

export function AvailableUnitsPricePerSmResponseSchemaToJSON(value?: AvailableUnitsPricePerSmResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_units_price_per_sm': value.availableUnitsPricePerSm,
    };
}

