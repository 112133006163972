import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreModel } from './types';
import { getAvailableUnitsLayout, getDisappearedUnitsLayout, getReports } from '../api/secondary';
import { logError } from '../utils/utils';
import { AvailableUnitsLayoutResponseSchemaWithTotals, DisappearedUnitsLayoutResponseSchemaWithTotals } from '../api/secondaryClient';

export interface SecondaryReport {
  reportId: string,
  name: string,
  reportType: string,
}

export interface AvailableUnitsLayoutItem {
  countOfRegularRooms: number,
  units: number,
  interiorSm: number,
  pricePerSm: number,
  price: number,
}

export interface AvailableUnits {
  availableUnits: number,
}

export interface AvailableUnitsPricePerSm {
  availableUnitsPricePerSm: number | null,
}

export interface AvailableUnitsAveragePrice {
  availableUnitsAveragePrice: number | null,
}

export interface AvailableUnitsAverageSm {
  availableUnitsAverageSm: number | null,
}

export interface DisappearedUnitsLayoutItem {
    countOfRegularRooms: number,
    units: number,
    interiorSm: number,
    pricePerSm: number,
    price: number,
  }

export interface DisappearedUnitsLayout {
    disappearedUnitsLayout: DisappearedUnitsLayoutItem[],
  }

export interface DisappearedUnits {
    disappearedUnits: number,
  }

export interface DisappearedUnitsPricePerSm {
    disappearedUnitsPricePerSm: number | null,
  }

export interface DisappearedUnitsAveragePrice {
    disappearedUnitsAveragePrice: number | null,
  }

export interface DisappearedUnitsAverageSm {
    disappearedUnitsAverageSm: number | null,
  }

export interface DemandOverview extends
  DisappearedUnits,
  DisappearedUnitsPricePerSm,
  DisappearedUnitsLayout,
  DisappearedUnitsAveragePrice,
  DisappearedUnitsAverageSm {}

interface FetchSupplyOverviewParams {
  reportId: string,
  date: string,
}

export interface SecondaryMarketStore {
  reports?: SecondaryReport[],
  setReports: Action<SecondaryMarketStore, SecondaryReport[]>,
  fetchReports: Thunk<SecondaryMarketStore, undefined, unknown, StoreModel>,
  activeReportId?: string,
  setActiveReportId: Action<SecondaryMarketStore, string>,
  supplyOverview?: AvailableUnitsLayoutResponseSchemaWithTotals,
  setSupplyOverview: Action<SecondaryMarketStore, AvailableUnitsLayoutResponseSchemaWithTotals>,
  fetchSupplyOverview: Thunk<SecondaryMarketStore, FetchSupplyOverviewParams, unknown, StoreModel>,
  demandOverview?: DisappearedUnitsLayoutResponseSchemaWithTotals,
  setDemandOverview: Action<SecondaryMarketStore, DisappearedUnitsLayoutResponseSchemaWithTotals>,
  fetchDemandOverview: Thunk<SecondaryMarketStore, FetchSupplyOverviewParams, unknown, StoreModel>,
}

export const secondaryMarketStore: SecondaryMarketStore = {
  setReports: action((state, reports: SecondaryReport[]) => {
    state.reports = reports;
  }),
  fetchReports: thunk(async (actions) => {
    try {
      const data = await getReports();
      if (data.length > 0) {
        actions.setReports(data);
        actions.setActiveReportId(data[0].reportId);
      }
    } catch (error) {
      console.error(error);
      // logError(error);
    }
  }),
  setActiveReportId: action((state, reportId) => {
    state.activeReportId = reportId;
  }),
  setSupplyOverview: action((state, data) => {
    state.supplyOverview = data;
  }),
  fetchSupplyOverview: thunk(async (actions, payload) => {
    try {
      const data = await getAvailableUnitsLayout(payload);
      actions.setSupplyOverview(data);
    } catch (error) {
      console.error(error);
      // logError(error);
    }
  }),
  setDemandOverview: action((state, data) => {
    state.demandOverview = data;
  }),
  fetchDemandOverview: thunk(async (actions, payload) => {
    try {
      const data = await getDisappearedUnitsLayout(payload);

      actions.setDemandOverview(data);
    } catch (error) {
      console.error(error);
      // logError(error);
    }
  }),
};
