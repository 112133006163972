import { FC, useState } from 'react';
import { AutoComplete } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../hooks';
import { Territory } from '../../store/types';

type Option = {
  value: string;
  key: string;
};

type Props = {
  onSelect: (val: Territory) => void;
};

export const TerritoryAutocomplete: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const { territories } = useStoreState((state) => state.filters);
  const [currentOptions, setCurrentOptions] = useState<Option[]>([]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!territories) return <></>;

  const handleSearch = (text: string): void => {
    const normalizedSearch = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    setCurrentOptions(
      territories
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((opt) => {
          const normalizedName = opt.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          return normalizedName.startsWith(normalizedSearch);
        })
        .map((opt) => ({ key: opt.key, value: opt.name })),
      );
  };

  const handleSelect = (_id: string, option: BaseOptionType): void => {
    const found = territories.find((territory) => territory.key === option.key);
    if (found) {
      onSelect(found);
    }
  };

  return (
    <AutoComplete
      className='map-search'
      placeholder={t('map.helper.territory', 'Type in an area ...')}
      options={currentOptions}
      onSearch={handleSearch}
      onSelect={handleSelect}
    />
  );
};
