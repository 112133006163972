import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  checked: boolean;
  onChange?: () => void;
  style?: React.CSSProperties;
  className?: string;
}

export const Checkbox: FC<Props> = ({ checked, onChange, style, className }) =>
<CheckCircleIcon
  className={clsx('w-10 h-10 cursor-pointer self-center rounded-full', checked ? 'text-[#5A72B1]' : 'text-[#D9D9D9] hover:shadow-xl hover:text-[#979ba8]', className)}
  style={style}
  onClick={onChange}
/>;
