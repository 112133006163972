/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchBlockUnitMixData } from '../../../../api';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { Data, UnitMixChart } from './UnitMixChart';
import { Availability } from '../../../../store/types';
import { UnitMixData } from '../../../../api/types';
import { ChartCard } from '../../../ChartCard';
import { useReservedData } from '../../../../hooks/useReservedData';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { CardPopover } from '../CardPopover';
import { getReportExportFileName } from '../../../../utils/utils';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  AVAILABLE,
  SOLD,
  TOTAL,
}

export const UnitMix: FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.AVAILABLE);
  const { mapReservedData } = useReservedData();
  const pngRef = useRef(null);

  const chart = useMemo(() => {
    let chartData: Data[] = [];
    const mappedData = mapReservedData(data);
    const getReduceCallback =
      (availabilityValue: string) =>
      (previous: Data[], current: Data): Data[] => {
        const index = previous.findIndex((v) => v.layout === current.layout);
        if (index > -1) {
          const newArr: Data[] = [...previous];
          const { availability, value, layout } = previous[index];
          const newData: Data = {
            availability,
            value: value + current.value,
            layout,
          };
          newArr[index] = newData;
          return newArr;
        }
        return [...previous, { ...current, availability: availabilityValue }];
      };
    switch (filterValue) {
      case FilterEnum.AVAILABLE:
        chartData = mappedData
          .filter(({ availability }) => availability === Availability.AVAILABLE)
          .reduce(getReduceCallback(Availability.AVAILABLE), []);
        break;
      case FilterEnum.SOLD:
        chartData = mappedData
          .filter(({ availability }) => availability === Availability.SOLD)
          .reduce(getReduceCallback(Availability.SOLD), []);
        break;
      case FilterEnum.TOTAL:
        chartData = mappedData.reduce(getReduceCallback('total'), []);
        break;
      default:
        break;
    }
    return isLoading ? (
      <Spin css={styles.center} spinning />
    ) : (
      <UnitMixChart data={chartData} />
    );
  }, [data, filterValue, isLoading, mapReservedData]);

  const controls = useMemo(
    () => (
      <Segmented
        value={filterValue}
        options={[
          {
            value: FilterEnum.AVAILABLE,
            label: t('enums.state.available', 'Available'),
          },
          {
            value: FilterEnum.SOLD,
            label: t('enums.state.sold', 'Sold'),
          },
          {
            value: FilterEnum.TOTAL,
            label: t('project.unit_mix.total', 'Total'),
          },
        ]}
        onChange={handleChange}
      />
    ),
    [filterValue, handleChange, t],
  );

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockUnitMixData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then((response) => {
          const groupedData = response.data.reduce<UnitMixData[]>(
            (prev, curr) => {
              const index = prev.findIndex(
                (p) =>
                  p.availability === curr.availability &&
                  p.layout === curr.layout,
              );
              if (index > -1) {
                const newArr = [...prev];
                newArr[index].value += curr.value;
                return newArr;
              }
              return [...prev, curr];
            },
            [],
          );
          setData(groupedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.unit_mix.title', 'unit-mix'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={t('market.reports.unit_mix.title', 'Unit Mix')}
      extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      subtitle={t(
        'market.reports.unit_mix.subtitle',
        'The distribution of units by layouts in the selected projects.',
        )}
      controls={controls}
      chart={chart}
    />
    </div>
  );
};
