import { css } from '@emotion/react/macro';

export const styles = {
  table: css({
    width: '100%',
  }),
  segmentedContainer: css({
    margin: '1.2rem 0',
  }),
};
