import { FC, PropsWithChildren } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const FormContainer: FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => (
  <div className='h-full overflow-scroll h-[calc(100vh-50px)]'>
    <AnimatePresence>
      <motion.div
        className='flex flex-col h-full justify-between py-14 w-[50rem] m-auto'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  </div>
);
