/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LayoutDataclass } from './LayoutDataclass';
import {
    LayoutDataclassFromJSON,
    LayoutDataclassFromJSONTyped,
    LayoutDataclassToJSON,
} from './LayoutDataclass';
import type { LayoutDataclassRoom } from './LayoutDataclassRoom';
import {
    LayoutDataclassRoomFromJSON,
    LayoutDataclassRoomFromJSONTyped,
    LayoutDataclassRoomToJSON,
} from './LayoutDataclassRoom';

/**
 * 
 * @export
 * @interface AvailableUnitsLayoutResponseSchemaWithTotals
 */
export interface AvailableUnitsLayoutResponseSchemaWithTotals {
    /**
     * 
     * @type {Array<LayoutDataclass>}
     * @memberof AvailableUnitsLayoutResponseSchemaWithTotals
     */
    availableUnitsLayout: Array<LayoutDataclass>;
    /**
     * 
     * @type {LayoutDataclassRoom}
     * @memberof AvailableUnitsLayoutResponseSchemaWithTotals
     */
    totals: LayoutDataclassRoom;
}

/**
 * Check if a given object implements the AvailableUnitsLayoutResponseSchemaWithTotals interface.
 */
export function instanceOfAvailableUnitsLayoutResponseSchemaWithTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableUnitsLayout" in value;
    isInstance = isInstance && "totals" in value;

    return isInstance;
}

export function AvailableUnitsLayoutResponseSchemaWithTotalsFromJSON(json: any): AvailableUnitsLayoutResponseSchemaWithTotals {
    return AvailableUnitsLayoutResponseSchemaWithTotalsFromJSONTyped(json, false);
}

export function AvailableUnitsLayoutResponseSchemaWithTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableUnitsLayoutResponseSchemaWithTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableUnitsLayout': ((json['available_units_layout'] as Array<any>).map(LayoutDataclassFromJSON)),
        'totals': LayoutDataclassRoomFromJSON(json['totals']),
    };
}

export function AvailableUnitsLayoutResponseSchemaWithTotalsToJSON(value?: AvailableUnitsLayoutResponseSchemaWithTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_units_layout': ((value.availableUnitsLayout as Array<any>).map(LayoutDataclassToJSON)),
        'totals': LayoutDataclassRoomToJSON(value.totals),
    };
}

