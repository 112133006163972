/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MarketType } from './MarketType';
import {
    MarketTypeFromJSON,
    MarketTypeFromJSONTyped,
    MarketTypeToJSON,
} from './MarketType';

/**
 * 
 * @export
 * @interface UserReport
 */
export interface UserReport {
    /**
     * 
     * @type {string}
     * @memberof UserReport
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof UserReport
     */
    name: string;
    /**
     * 
     * @type {MarketType}
     * @memberof UserReport
     */
    reportType: MarketType;
}

/**
 * Check if a given object implements the UserReport interface.
 */
export function instanceOfUserReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reportId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "reportType" in value;

    return isInstance;
}

export function UserReportFromJSON(json: any): UserReport {
    return UserReportFromJSONTyped(json, false);
}

export function UserReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportId': json['report_id'],
        'name': json['name'],
        'reportType': MarketTypeFromJSON(json['report_type']),
    };
}

export function UserReportToJSON(value?: UserReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_id': value.reportId,
        'name': value.name,
        'report_type': MarketTypeToJSON(value.reportType),
    };
}

