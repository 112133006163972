import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../FormContainer';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { ImageSwitch } from '../../button/ImageSwitch';
import { Button } from '../../button/Button';
import { BackButton } from '../../button/BackButton';
import { useStoreActions, useStoreState } from '../../../../hooks';

export const ProjectType: FC = () => {
  const { t } = useTranslation();

  const { buildingType } = useStoreState((state) => state.onboarding);
  const { profile } = useStoreState((state) => state.user);
  const { handleNext, handleBack, setBuildingType } = useStoreActions(
    (actions) => actions.onboarding,
  );
  const isUS = profile?.localization === 'US';

  return (
    <FormContainer>
      <div>
        <BackButton handleClick={handleBack} />
        <Heading className='pt-5 pb-6'>
          {t('onboarding.project_type.title', 'Project Type')}
        </Heading>
        <Subtitle>
          {t(
            'onboarding.project_type.subtitle',
            'Is this project offering units or houses? Combined projects should be uploaded as two projects.',
          )}
        </Subtitle>
        <div className='flex justify-center pt-20 gap-20'>
          <ImageSwitch
            label={
              isUS
                ? t('onboarding.project_type.single_family', 'Single-family')
                : t('onboarding.project_type.houses', 'Houses')
            }
            type='house'
            selected={buildingType.house}
            state={buildingType}
            setState={setBuildingType}
          />
          <ImageSwitch
            label={
              isUS
                ? t('onboarding.project_type.multi_family', 'Multi-family')
                : t('onboarding.project_type.units', 'Units')
            }
            type='unit'
            selected={buildingType.unit}
            state={buildingType}
            setState={setBuildingType}
          />
        </div>
      </div>
      <div className='flex justify-center'>
        <Button
          disabled={!(buildingType.house || buildingType.unit)}
          handleClick={handleNext}
        >
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
