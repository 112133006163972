import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DocumentIcon from '../../icons/documentIcon.svg';
import CheckIcon from '../../icons/checkIcon.svg';
import PieIcon from '../../icons/pieIcon.svg';
import TargetsIcon from '../../icons/targetsIcon.svg';
import PlanIcon from '../../icons/planIcon.svg';
import { TargetRow } from './TargetRow';
import { Target, Targets } from '../../../../api/mockData/sales';
import { useStoreActions } from '../../../../hooks';

type TargetsSectionProps = {
  onClose: () => void;
  targets: Targets;
}

export const TargetsSection: FC<TargetsSectionProps> = ({ onClose, targets }) => {
  const { t } = useTranslation();
  const { setIsEditingSales } = useStoreActions((actions) => actions.sales);
  const [targetsData, setTargetsData] = useState<Target[]>(targets.targets || []);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState<{ isEditing: boolean; index: number | null}>({ isEditing: false, index: null });
  const sortedTargets = targetsData.sort((a, b) => {
    if (a.mandatory === b.mandatory) {
      return dayjs(a.target_month).isBefore(dayjs(b.target_month)) ? -1 : 1;
    }
    return a.mandatory ? -1 : 1;
  });

  // const bigNumberFormatter = (num: number): string => {
  //   if (num > 1e6) {
  //     return `${(num / 1e6).toFixed(2)}m`;
  //   } if (num < 1e6 && num > 1e4) {
  //     return `${(num / 1e3).toFixed(2)}K`;
  //   }
  //     return `${num}`;
  // };

  useEffect(() => {
    if (targets.targets !== targetsData) {
      setTargetsData(targets.targets);
    }
  }, [targets, targetsData]);

  const addNew = (): void => {
    setIsCreating(true);
    setIsEditingSales(true);
  };

  const cancelTarget = (): void => {
    setIsCreating(false);
    setIsEditingSales(false);
  };

  const deleteTarget = (index: number): void => {
    setTargetsData(targetsData.filter((_, itemIndex) => itemIndex !== index));
  };

  const onEdit = (value: boolean, index: number): void => {
    if (isCreating) {
      message.info(t('sales.error.finish_creating', 'Please, finish creating target first.'));
      return;
    }
    if (value) {
      setIsEditing({ isEditing: true, index });
      setIsEditingSales(true);
    } else {
      setIsEditing({ isEditing: false, index: null });
      setIsEditingSales(false);
    }
  };

  return (
  <div className='flex flex-col space-y-8'>
    <div className='flex justify-between items-center'>
      <div className='flex space-x-5 items-center font-bold'>
        <div className='flex items-center h-14 w-14 bg-[#9EB9DA] rounded-full p-2'>
          <img src={TargetsIcon} alt='Sales Targets' />
        </div>
        <span className='select-none'>{t('sales.targets.title', 'Sales Targets')}</span>
      </div>
      <XMarkIcon className='w-8 h-8 stroke-[3px] text-[#BDBDBD] cursor-pointer' onClick={onClose} />
    </div>
    <div className='grid auto-rows-auto space-y-2 max-w-fit'>
      <div className='grid grid-cols-5 gap-6 border-b-[3px] pl-8 pb-2 border-[#F1F1F1]'>
        <div className='flex space-x-2 items-center'>
          <img src={DocumentIcon} alt='Type' />
          <span className='font-bold'>{t('sales.targets.subtitle.type', 'Type')}</span>
        </div>
        <div className='flex space-x-2 items-center'>
          <img src={TargetsIcon} alt='Target' />
          <span className='font-bold'>{t('sales.targets.subtitle.target', 'Target')}</span>
        </div>
        <div className='flex space-x-2 items-center justify-center'>
          <img src={PlanIcon} alt='Deadline' />
          <span className='font-bold'>{t('sales.targets.subtitle.deadline', 'Deadline')}</span>
        </div>
        <div className='flex space-x-2 items-center justify-center'>
          <img src={CheckIcon} alt='Updates' />
          <span className='font-bold'>{t('sales.targets.subtitle.updates', 'Updates')}</span>
        </div>
        <div className='flex space-x-2 items-center justify-center'>
          <img src={PieIcon} alt='Progress' />
          <span className='font-bold'>{t('sales.targets.subtitle.progress', 'Progress')}</span>
        </div>
      </div>
      {sortedTargets.map((target, index) => (
        <TargetRow
          target={target}
          targets={targets}
          removeTarget={() => deleteTarget(index)}
          isEditing={isEditing.isEditing && isEditing.index === index}
          onEdit={() => onEdit(true, index)}
          onEditCancel={() => onEdit(false, index)}
        />
      ))}
      {isCreating && (
        <TargetRow
          targets={targets}
          removeTarget={() => cancelTarget()}
        />
      )}
      <div className='flex space-x-4 pl-8 py-2'>
        <span
          className={`text-bmgray cursor-pointer ${(isCreating || isEditing.isEditing) && 'opacity-25'}`}
          onClick={() => isCreating || isEditing.isEditing
            ? message.info(isCreating ? t('sales.error.finish_creating', 'Please, finish creating target first.') : t('sales.error.finish_editing', 'Please, finish editing target first.'))
            : addNew()}
        >
          + {t('sales.targets.add_new', 'Add New')}
        </span>
      </div>
    </div>
  </div>
);
};
