import { parse, isValid } from 'date-fns';
import { ZodIssue } from 'zod';
import { formatRowRanges } from '../../components/dynamicPricingOnboarding/multistepForm/steps/uploadPriceList/ErrorsBox';

export const areIdsUnique = (
  units: any[],
): { unique: boolean; issues: ZodIssue[] } => {
  if (!units) {
    return { unique: true, issues: [] };
  }

  const idMap = new Map<string, number[]>();

  units.forEach((unit, index) => {
    const { id } = unit;
    if (idMap.has(id)) {
      idMap.get(id)?.push(index + 1);
    } else {
      idMap.set(id, [index + 1]);
    }
  });

  const issues: ZodIssue[] = [];

  idMap.forEach((rows, id) => {
    if (rows.length > 1) {
      issues.push({
        code: 'custom',
        message: `Duplicated ID '${id}' found in rows: ${formatRowRanges(
          rows,
        )}`,
        path: ['id'],
      } as ZodIssue);
    }
  });

  return { unique: issues.length === 0, issues };
};

// SizeValidator utility functions
const less50sm = (value: number): boolean =>
  value == null || (value >= 0 && value < 50);
const less100sm = (value: number): boolean =>
  value == null || (value >= 0 && value < 100);
const less500sm = (value: number): boolean =>
  value == null || (value >= 0 && value < 500);
const less2000sm = (value: number): boolean =>
  value == null || (value >= 0 && value < 2000);

const parseDate = (value: unknown): Date | undefined => {
  if (typeof value === 'string' && value.trim() !== '') {
    const formats = [
      'dd MM yyyy',
      'dd.MM.yyyy',
      'dd-MM-yyyy',
      'dd/MM/yyyy',
      'dd/MM/yy',
    ];
    for (const format of formats) {
      const parsedDate = parse(value, format, new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }
  }
  return undefined; // return undefined for invalid or empty date strings
};

const parsePrice = (value: any): number | undefined => {
  if (value == null) return undefined;
  if (typeof value === 'string') {
    return parseInt(value.replace(/[\s\xa0Kč€$]/g, ''), 10);
  }
  if (typeof value === 'number') {
    return value;
  }
  throw new Error('Invalid price format');
};

const strToSet = (value: any): Set<string> => {
  if (value == null) return new Set();
  if (typeof value === 'string') {
    return new Set(
      value
        .split(',')
        .flatMap((part) => part.split('/'))
        .map((v) => v.trim().toLowerCase()),
    );
  }
  if (value instanceof Set) {
    return new Set(Array.from(value).map((v) => v.toLowerCase()));
  }
  throw new Error('Invalid set format');
};

export { less50sm, less100sm, less500sm, less2000sm, parseDate, parsePrice, strToSet };
