import clsx from 'clsx';
import { ActionCreator } from 'easy-peasy';
import { Dispatch, FC, SetStateAction } from 'react';

type Props = {
  placeholder: string;
  value: string;
  setValue: ActionCreator<string> | Dispatch<SetStateAction<string>>;
  label?: string;
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const Input: FC<Props> = ({
  placeholder,
  label,
  className,
  value,
  setValue,
  onKeyDown,
}) => (
  <div
    className={clsx(
      className,
      'gap-0 flex flex-col px-8 bg-white border-[1px] border-[#BDBDBD] w-[50rem] rounded-[25px] text-bmblue',
      label ? 'pb-3.5 pt-3' : 'py-5',
    )}
  >
    {label ? <span className='text-sm leading-none'>{label}</span> : null}
    <input
      className='w-full outline-none'
      placeholder={placeholder}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onKeyDown={onKeyDown}
    />
  </div>
);
