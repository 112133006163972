import { FC, useState, Dispatch, SetStateAction } from 'react';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button, DatePicker, InputNumber, Select, message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Target } from '../../../../api/mockData/sales';
import './TargetRow.css';
import { Dropdown } from './Dropdown';
import { useRegionFormatting, useStoreState } from '../../../../hooks';

type TargetRowProps = {
  target?: Partial<Target>;
  targets: Partial<Target>[];
  isEditing?: boolean;
  onEdit?: () => void;
  onEditCancel?: () => void;
  setTargets: Dispatch<SetStateAction<Partial<Target>[]>>;
  setIsCreating?: Dispatch<SetStateAction<boolean>>;
};

export const TargetRow: FC<TargetRowProps> = ({
  target,
  targets,
  isEditing,
  onEdit,
  onEditCancel,
  setTargets,
  setIsCreating,
}) => {
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.onboarding);
  const isRental = projectType.rental;
  const [isHovered, setIsHovered] = useState(false);
  const [targetType, setTargetType] = useState<
    'units' | 'floor_area' | 'revenue' | undefined
  >(target?.target_type);
  const [targetValue, setTargetValue] = useState(
    target ? target.target_value : null,
  );
  const [deadline, setDeadline] = useState(target ? target.target_month : '');
  const { areaUnit, formatCurrency } = useRegionFormatting();
  const formatedTargetValue =
    target?.target_type === 'floor_area'
      ? `${target.target_value} ${areaUnit}`
      : formatCurrency(target?.target_value ?? null);

  const targetTypes: { label: string; value: string }[] = [
    {
      label: 'Units',
      value: 'units',
    },
    {
      label: 'Floor Area',
      value: 'floor_area',
    },
    {
      label: 'Revenue',
      value: isRental ? 'monthly_revenue' : 'revenue',
    },
  ];

  const handleSaveTarget = (): void => {
    if (!targetType || !targetValue || !deadline) {
      message.error(
        t('sales.error.empty_fields', 'Please fill all fields.'),
        3,
      );
      return;
    }
    if (targetValue < 1) {
      message.error(
        t('sales.error.target_null', 'Target value must be greater than 0.'),
        3,
      );
      return;
    }

    const newTarget: Partial<Target> = {
      id: target ? target.id : Date.now(), // Use existing ID if updating
      target_type: targetType,
      target_value: targetValue,
      target_month: dayjs(deadline).format('YYYY-MM-DD'),
    };

    // Create a new targets array
    const updatedTargets = target
      ? targets.map((trg) => (trg.id === target.id ? newTarget : trg)) // Update the existing target by ID
      : [...targets, newTarget]; // Create a new target

    setTargets(updatedTargets);
    message.success(
      t('sales.success.target_saved', 'Target saved successfully!'),
      3,
    );
    if (setIsCreating) setIsCreating(false);
    if (onEditCancel) onEditCancel();
  };

  const handleEditing = (): void => {
    if (target && onEdit) {
      onEdit();
    }
  };

  const handleCancel = (): void => {
    if (setIsCreating) setIsCreating(false);
    if (onEditCancel) onEditCancel();
  };

  const deteleteTarget = (): void => {
    const updatedTargets = targets.filter((trg) => trg.id !== target?.id);

    setTargets(updatedTargets);
    message.success(
      t('sales.success.target_deleted', 'Target deleted successfully!'),
      3,
    );
  };

  return (
    <div className='flex items-center'>
      <div
        className='w-[30rem] grid grid-cols-3 gap-6 pl-4 cursor-pointer text-lg border-b-[1px] py-[1px] border-[#F1F1F1]'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!target || (isEditing && target) ? (
          <>
            <Dropdown
              value={targetType}
              setValue={setTargetType}
              items={targetTypes.map((types) =>
                t(`onboarding.target_types.${types.value}`, `${types.value}`),
              )}
            />
            <div className='col-span-1'>
              <InputNumber
                bordered={false}
                controls={false}
                value={targetValue || undefined}
                onChange={(value) => setTargetValue(value)}
                placeholder='0'
                min={0}
                className='custom-number-input'
              />
            </div>
            <div className='col-span-1'>
              <DatePicker
                allowClear={false}
                bordered={false}
                suffixIcon={
                  deadline ? undefined : (
                    <ChevronDownIcon className='text-[#BDBDBD] w-5 h-5 ml-2' />
                  )
                }
                format='MMMM YYYY'
                className='custom-date-picker-input p-0'
                value={deadline ? dayjs(deadline) : undefined}
                onChange={
                  (date) => setDeadline(date ? date.format('YYYY-MM-DD') : '') // eslint-disable-next-line
                }
                picker='month'
                placeholder={t('', 'Select')}
              />
            </div>
          </>
        ) : (
          <>
            <div className='col-span-1 flex items-center'>
              <span
                className='text-bmblue font-bold text-lg'
                onClick={() => handleEditing()}
              >
                {t(
                  `sales.targets.${target?.target_type}`,
                  target?.target_type || '',
                )}
              </span>
            </div>
            <div className='col-span-1 flex items-center'>
              <span
                className='text-bmblue font-bold text-lg'
                onClick={() => handleEditing()}
              >
                {target?.target_value && formatedTargetValue}
              </span>
            </div>
            <div className='col-span-1 flex items-center'>
              <span
                className='text-bmblue font-bold text-lg'
                onClick={() => handleEditing()}
              >
                {target?.target_month &&
                  dayjs(target.target_month).format('MMMM YYYY')}
              </span>
            </div>
          </>
        )}
      </div>
      <div>
        {!target || (isEditing && target) ? (
          <div className='col-span-3 flex justify-end space-x-4 pl-2'>
            <Button
              type='primary'
              className='custom-button'
              onClick={handleSaveTarget}
            >
              {t('sales.targets.save', 'Save')}
            </Button>
            <Button
              type='default'
              onClick={() => handleCancel()}
              className='custom-button'
            >
              {t('sales.targets.cancel', 'Cancel')}
            </Button>
          </div>
        ) : null}
      </div>
      <div className='flex items-center'>
        {isHovered && target && !isEditing && (
          <TrashIcon
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => deteleteTarget()}
            className='w-5 cursor-pointer'
          />
        )}
      </div>
    </div>
  );
};
