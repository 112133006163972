import { css } from '@emotion/react/macro';
import { BOLD_FONT_WEIGHT, GREY_5, NORMAL_FONT_WEIGHT, GREY_2 } from '../../../styles/constants';

export const styles = {
    footer: css({
        paddingTop: '2rem',
        borderTop: `1px solid ${GREY_5}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
    }),
    recommendedPrice: css({
        marginRight: '4rem',
    }),
    description: css({
        fontWeight: NORMAL_FONT_WEIGHT,
        fontSize: '1.5rem',
        lineHeight: '21px',
        marginBottom: '1.25rem',
        textAlign: 'right',
    }),
    pricePositive: css({
        fontSize: '2rem',
        fontWeight: BOLD_FONT_WEIGHT,
        lineHeight: '2.25rem',
        textAlign: 'right',
    }),
    priceNegative: css({
        fontSize: '2rem',
        fontWeight: BOLD_FONT_WEIGHT,
        color: GREY_2,
        lineHeight: '2.25rem',
        textAlign: 'right',
    }),
    chevron: css({
        fontSize: '2.5rem',
        marginRight: '0.5rem',
    }),
};
