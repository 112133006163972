import { FC, useState } from 'react';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlanData, Targets as TargetsData } from '../../../api/mockData/sales';
import { Plan } from './plans/Plan';
import { PlanSection } from './plans/PlanSection';
import { TargetsSection } from './targets/TargetsSection';
import { Targets } from './targets/Targets';
import { useStoreActions, useStoreState } from '../../../hooks';
import { Card } from '../../Card';
import { ProjectType } from '../../../store/types';

type SalesProps = {
  targetsData?: TargetsData;
  planData?: PlanData[];
  type: 'targets' | 'plan'
}

export const Sales: FC<SalesProps> = ({ targetsData, planData, type }) => {
  const { t } = useTranslation();
  const { setIsEditingSales } = useStoreActions((actions) => actions.sales);
  const { isEditingSales } = useStoreState((state) => state.sales);
  const [isMaximized, setIsMaximized] = useState(false);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const targetsError = isRent
  ? t('rent.error.targets_unavailable', 'Sales Targets are not available for rental projects.')
  : t(
      'sales.error.targets_unavailable',
      'Sales Targets are not available for one-time price lists.',
    );
  const plansError = isRent
  ? t('rent.error.plan_unavailable', 'Sales Plan is not available for rental projects.')
  : t(
      'sales.error.plan_unavailable',
      'Sales Plan is not available for one-time price lists.',
    );
  if (!targetsData || (!planData || planData.length < 1)) {
    return (
      <Card className='flex flex-col flex-1 h-full justify-center items-center text-center'>
        {
          type === 'targets'
            ? targetsError
            : plansError
        }
      </Card>
    );
  }

  const handleOpen = (): void => {
    setIsMaximized(true);
    setIsEditingSales(false);
  };

  const handleClose = (): void => {
    if (isEditingSales) {
      message.error(t('sales.error.close_modal', 'Please save or cancel your changes before closing the modal.'));
    } else {
      setIsMaximized(false);
    }
  };

  const sortedPlanData = planData.sort((a, b) => new Date(a.month || '').getTime() - new Date(b.month || '').getTime());

  return (
    <>
      {type === 'targets' ? (
        <Targets targets={targetsData} onMaximize={() => handleOpen()} />
      ) : (
        <Plan plan={sortedPlanData} onMaximize={() => handleOpen()} />
      )}
      <Modal open={isMaximized} footer={null} centered closable={false} className='sales-modal'>
        <div className='flex flex-col space-y-12 relative overflow-y-auto max-h-[50%]'>
          <TargetsSection onClose={() => handleClose()} targets={targetsData} />
          <PlanSection plan={sortedPlanData} targets={targetsData} />
        </div>
      </Modal>
    </>
  );
};
