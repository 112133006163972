import { FC, PropsWithChildren } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

type Props = {
  handleClick?: () => void;
};

export const BackButton: FC<Props> = ({ handleClick }) => (
  <button type='button' onClick={handleClick}>
    <ArrowLeftIcon className='w-6 stroke-2' color='#313B5E' />
  </button>
);
