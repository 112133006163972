/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiPolygonalSchema
 */
export interface MultiPolygonalSchema {
    /**
     * 
     * @type {string}
     * @memberof MultiPolygonalSchema
     */
    type: string;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof MultiPolygonalSchema
     */
    coordinates: Array<Array<Array<Array<number>>>>;
}

/**
 * Check if a given object implements the MultiPolygonalSchema interface.
 */
export function instanceOfMultiPolygonalSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "coordinates" in value;

    return isInstance;
}

export function MultiPolygonalSchemaFromJSON(json: any): MultiPolygonalSchema {
    return MultiPolygonalSchemaFromJSONTyped(json, false);
}

export function MultiPolygonalSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiPolygonalSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function MultiPolygonalSchemaToJSON(value?: MultiPolygonalSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'coordinates': value.coordinates,
    };
}

