/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { PriceComparisonChart, Data } from './PriceComparisonChart';
import { ChartCard } from '../../../ChartCard';
import { getAvailableUnitsLayout, getDisappearedUnits7DaysLayout } from '../../../../api/secondary';
import { Availability } from '../../../../store/types';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

enum FilterEnum {
  PRICE_PER_SM,
  PRICE,
}

export const PriceComparison: FC = () => {
  const { isRentals } = useMarketDetails();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.PRICE_PER_SM);
  const { areaUnit, calculateVatPrice } = useRegionFormatting();
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      setLoading(true);
      const payload = {
        reportId,
        date: paramsSM.date,
      };
      Promise.all([
        getAvailableUnitsLayout(payload),
        getDisappearedUnits7DaysLayout({
          reportId,
          startDate: paramsSM.date,
          endDate: paramsSM.date,
        }),
      ])
        .then((response) => {
          const [available, disappeared] = response;
          const dateSpecificDisappeared = disappeared.find((d) => d.createdDate === paramsSM.date);
          setData([
            ...available.availableUnitsLayout
            .filter((obj) => obj.countOfRegularRooms !== undefined)
            .map((obj) => ({
              ...obj,
              price:
                calculateVatPrice(
                  obj.price,
                  obj.interiorSm,
                ) ?? 0,
              pricePerSm:
                calculateVatPrice(
                  obj.pricePerSm,
                  obj.interiorSm,
                ) ?? 0,
              availability: Availability.AVAILABLE,
              value: 0,
              layout: `layout_${obj.countOfRegularRooms}`,
              type: Availability.AVAILABLE,
            })),
            ...(dateSpecificDisappeared?.disappearedLayout || []).map((obj) => ({
              ...obj,
              price:
                calculateVatPrice(
                  obj.price,
                  obj.interiorSm,
                ) ?? 0,
              pricePerSm:
                calculateVatPrice(
                  obj.pricePerSm,
                  obj.interiorSm,
                ) ?? 0,
              availability: Availability.SOLD,
              value: 0,
              layout: `layout_${obj.countOfRegularRooms}`,
              type: Availability.SOLD,
            })),
          ]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportId, calculateVatPrice, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.price_comparison.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={isRentals ? t('market.reports.rent_comparison.title') : t('market.reports.price_comparison.title', 'Price Comparison')}
      subtitle={isRentals
        ? t('market.reports.rent_comparison.subtitle')
        : t(
        'market.reports.price_comparison.subtitle',
        'The comparison of the average price of units in the selected projects.',
      )}
      extra={!isLoading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      chart={
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          data.length > 0 ?
          <PriceComparisonChart
            data={data}
            isPrice={filterValue === FilterEnum.PRICE}
          />
          : null
        )
      }
      controls={
        <Segmented
          value={filterValue}
          options={[
              {
                value: FilterEnum.PRICE_PER_SM,
                label: t('common.price_per_unit', 'Price per {{unit}}', {
                  unit: areaUnit,
                }),
              },
              {
                value: FilterEnum.PRICE,
                label: t('common.price', 'Price'),
              },
            ]}
          onChange={handleChange}
        />
      }
    />
    </div>
  );
};
