import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { ChartCard } from '../../../ChartCard';
import { Availability } from '../../../../store/types';
import { statisticsApi } from '../../../../api/secondary';
import { SaleDetailChart } from './SaleDetailChart';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface Data {
  category: string;
  state: Availability;
  value: number;
}

export const SaleDetail: FC = () => {
  const { isRentals } = useMarketDetails();
  const { t } = useTranslation();
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const payload = {
        reportId,
        date: paramsSM.date,
      };
      Promise.all([
        statisticsApi.availableUnitsUnitMix(payload),
        statisticsApi.disappearedUnitsUnitMix(payload),
      ]).then((response) => {
        const [available, disappeared] = response;
        setData([
          ...(
            available.availableUnitsLayout180 ||
            available.availableUnitsLayout ||
            []
            ).map((d) => ({
            category: `layout_${d.countOfRegularRooms}`,
            value: d.units,
            state: Availability.AVAILABLE,
          })),
          ...(
            disappeared.disappearedUnitsLayout180 ||
            disappeared.disappearedUnitsLayout ||
            []
            ).map((d) => ({
            category: `layout_${d.countOfRegularRooms}`,
            value: d.units,
            state: Availability.SOLD,
          })),
        ]);
      }).finally(() => {
          setLoading(false);
        });
    }
  }, [reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('dashboard.sale_detail.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      extra={!loading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      loading={loading}
      title={isRentals ? t('rentals.lease_detail.title') : t('dashboard.sale_detail.title')}
      subtitle={isRentals ? t('rentals.lease_detail.subtitle') : t('dashboard.sale_detail.subtitle')}
      chart={data.length > 0 ? <SaleDetailChart data={data} /> : null}
    />
    </div>
  );
};
