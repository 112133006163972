import { FC } from 'react';
import { useRegionFormatting } from '../../../../../hooks';
import { ProjectDetailTableProps } from './types';
import { ProjectDetailTable as ProjectDetailTableOther } from './other';
import { ProjectDetailTable as ProjectDetailTableUS } from './us';

export const ProjectDetailTable: FC<ProjectDetailTableProps> = (props) => {
  const { isUsRegion } = useRegionFormatting();

  if (isUsRegion) {
    return <ProjectDetailTableUS {...props} />;
  }
  return <ProjectDetailTableOther {...props} />;
};
