import { FC, ReactNode, useMemo } from 'react';
import { Row, Col } from 'antd';
import { t } from 'i18next';
import { useRegionFormatting } from '../../../../../../hooks';

import { Data } from '../../types';
import { Rating } from './Rating';

interface RowData {
  label: string;
  value: ReactNode;
}

interface Props {
  data: Data;
}

export const Description: FC<Props> = ({
  data: {
    max_price_per_sm,
    min_price_per_sm,
    amenities,
    amenities_detail,
    schools,
    schools_detail,
  },
}) => {
  const { formatCurrency, areaUnit } = useRegionFormatting();
  const data: RowData[] = useMemo(
    () => [
      {
        label: t('project.overview.amenities', 'Amenities'),
        value: <Rating value={amenities} tooltipValue={amenities_detail} />,
      },
      {
        label: t('common.price_per_unit', 'Price per {{unit}}', {
          unit: areaUnit,
        }),
        value:
          min_price_per_sm === null && max_price_per_sm === null
            ? '-'
            : `${min_price_per_sm && `${formatCurrency(min_price_per_sm)} - `}${
                max_price_per_sm && formatCurrency(max_price_per_sm)
              }`,
      },
      {
        label: t('project.overview.schools', 'Schools'),
        value: <Rating value={schools} tooltipValue={schools_detail} />,
      },
    ],
    [
      amenities,
      amenities_detail,
      areaUnit,
      formatCurrency,
      max_price_per_sm,
      min_price_per_sm,
      schools,
      schools_detail,
    ],
  );
  return (
    <Row>
      {data.map(({ label, value }, index) => (
        <Col key={label} span={index % 2 === 0 ? 13 : 11}>
          <Row>
            <Col span={11}>
              <b>{label}</b>
            </Col>
            <Col span={13}>{value}</Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};
