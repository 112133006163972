import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Button } from '../../button/Button';
import { FormContainer } from '../FormContainer';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { FileSelector } from '../../inputs/FileSelector';
import { BackButton } from '../../button/BackButton';

export const FloorSiteVisuals: FC = () => {
  const { t } = useTranslation();

  const { handleNext, handleBack, setSelectedFiles } = useStoreActions(
    (actions) => actions.onboarding,
  );

  const selectedFiles = useStoreState(
    (state) => state.onboarding.selectedFiles,
  );

  const [localSelectedFiles, setLocalSelectedFiles] = useState(selectedFiles);

  const handleFilesChange = (fileName: string, file: File | null): void => {
    if (file) {
      setLocalSelectedFiles((prev) => [...prev, { name: fileName, file }]);
    } else {
      setLocalSelectedFiles([]);
    }
  };

  const handleContinue = (): void => {
    setSelectedFiles(localSelectedFiles);
    handleNext();
  };

  const handleBackButton = (): void => {
    setSelectedFiles(localSelectedFiles);
    handleBack();
  };

  return (
    <FormContainer>
      <div className='flex flex-col'>
        <BackButton handleClick={handleBackButton} />
        <Heading className='mt-4'>
          {t(
            'onboarding.floor_site_visuals.title',
            'Floor Plans, Site Plan & Visuals',
          )}
        </Heading>
        <Subtitle className='mt-4'>
          {t(
            'onboarding.floor_site_visual.subtitle',
            'Upload floor plans, a site plan, visuals and other important materials you find necessary. We’ll use them to extract qualitative features. If anything isn’t clear, we’ll follow up with you',
          )}
        </Subtitle>
        <FileSelector
          className='mt-8'
          fileNames={localSelectedFiles.map((f) => f.name)}
          onFileChange={handleFilesChange}
          multiple
          uploadText={t(
            'onboarding.file_selector.drag_drop',
            'or drag and drop {{files}}.',
            { files: 'PDF, ZIP, JPG or PNG' },
          )}
          uploadTextBold={t(
            'onboarding.file_selector.click_upload',
            'Click to upload',
          )}
          accept={{
            'application/pdf': ['.pdf'],
            'application/zip': ['.zip'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
          }}
        />
      </div>
      <div className='flex justify-center'>
        <Button
          disabled={localSelectedFiles.length === 0}
          handleClick={handleContinue}
        >
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
