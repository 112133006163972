import { FC, useEffect, useMemo, useState } from 'react';
import { Line, LineConfig } from '@ant-design/plots';
import { Empty, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPriceProgressChartData } from '../../../api';
import { PriceProgressData } from '../../../api/mockData/priceProgressData';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { AVAILABLE, SOLD } from '../../../styles/constants';
import { getLineOptions } from '../../../utils/defaultChartConfig';
import { Availability } from '../../../store/types';
import { FilterEnum } from '../useFilter';
import { PricePerSmCalcType } from '../../TopBar/SettingsPopover';

export const PricePerSM: FC = () => {
  const [data, setData] = useState<PriceProgressData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { projectId } = useParams();

  const { formatCurrency, calculateVatPrice } = useRegionFormatting();
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const isAvailable = useStoreState((state) => state.filters.availability);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const mappedData = useMemo(
    () =>
      data
        .filter((d) => {
          if (
            isSold &&
            (d.availability === Availability.SOLD ||
              d.availability === 'available_reserved')
          ) {
            return false;
          }
          if (
            !isSold &&
            (d.availability === Availability.AVAILABLE ||
              d.availability === 'reserved_sold')
          ) {
            return false;
          }
          if (d.availability === Availability.RESERVED) {
            return false;
          }
          return true;
        })
        .reduce(
          (
            previous: PriceProgressData[],
            current: PriceProgressData,
          ): PriceProgressData[] => {
            const {
              availability: currAvailability,
              avg_price: currAvgPrice,
              date: currDate,
            } = current;
            const index = previous.findIndex(
              (v) => v.date === currDate && v.availability === currAvailability,
            );
            let newAvailability = currAvailability;
            if (isSold && currAvailability === 'reserved_sold') newAvailability = Availability.SOLD;
            if (!isSold && currAvailability === 'available_reserved') newAvailability = Availability.AVAILABLE;
            current.availability = newAvailability;
            if (index > -1) {
              const newArr: PriceProgressData[] = [...previous];
              const { avg_price: prevAvgPrice } = previous[index];
              let newAvgPrice = 0;
              if (currAvgPrice !== null) {
                if (prevAvgPrice !== null) {
                  newAvgPrice = (currAvgPrice + currAvgPrice) / 2;
                } else newAvgPrice = currAvgPrice;
              } else {
                newAvgPrice = prevAvgPrice;
              }
              const newData: PriceProgressData = {
                availability: newAvailability,
                avg_price: newAvgPrice,
                date: currDate,
              };
              newArr[index] = newData;
              return newArr;
            }
            return [...previous, current];
          },
          [],
        ),
    [data, isSold],
  );

  useEffect(() => {
    const convertedProjectId = Number(projectId);
    if (!Number.isNaN(convertedProjectId)) {
      setLoading(true);
      getPriceProgressChartData(convertedProjectId, pricePerSmCalculation)
        .then(({ data: receivedData }) => {
          setData(receivedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [projectId, pricePerSmCalculation]);

  const hasOneSold = mappedData.some((d) => d.availability === Availability.SOLD);

  const config: LineConfig = getLineOptions({
    data: mappedData.map((obj) => ({
      ...obj,
      avg_price: calculateVatPrice(obj.avg_price, 120),
    })),
    color: hasOneSold ? [SOLD, AVAILABLE] : [AVAILABLE],
    xField: 'date',
    yField: 'avg_price',
    seriesField: 'availability',
    tooltip: {
      formatter: (datum) => {
        const value = datum as PriceProgressData;
        return {
          name: t(`enums.state.${value.availability}`),
          value: formatCurrency(value.avg_price) ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
          });
        },
      },
      tickMethod: 'time',
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
      style: { textAlign: 'right' },
    },
    legend: {
      itemName: {
        formatter: (value) => t(`enums.state.${value}`),
      },
    },
  });
  if (isLoading) {
    return <Spin className='center' spinning />;
  }
  const chartData = {
    ...config,
    data: config.data.filter((d) => {
      if (
        isAvailable === FilterEnum.AVAILABLE &&
        (d.availability === Availability.AVAILABLE ||
          d.availability === Availability.SOLD)
      ) {
        return true;
      }
      if (
        isAvailable === FilterEnum.SOLD &&
        (d.availability === Availability.SOLD ||
          d.availability === Availability.AVAILABLE)
      ) {
        return true;
      }
      return false;
    }),
  };

  return data.length > 0 ? (
    <Line className='chart-container' {...chartData} />
  ) : (
    <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
