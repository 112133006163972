import { FC } from 'react';
import { t } from 'i18next';
import { Exterior as ExteriorData } from '../../../api/mockData/priceListDetailData';
import { useRegionFormatting } from '../../../hooks';
import { PriceBreakdownRow } from './PriceBreakdownRow';

interface Props {
  data: ExteriorData;
}

export const Exterior: FC<Props> = ({
  data: { balcony_area, garden_area, terrace_area, has_no_exterior },
}) => {
  const { formatAreaUnits } = useRegionFormatting();
  return (
    <div>
      <PriceBreakdownRow
        className='row-top-spacing'
        houseAreaKey='exterior'
        housePartKey='balcony_area'
        valueContent={formatAreaUnits(balcony_area.value)}
        price={balcony_area.price}
        updatePrice={balcony_area.update_price}
      />
      <PriceBreakdownRow
        housePartKey='terrace_area'
        valueContent={formatAreaUnits(terrace_area.value)}
        price={terrace_area.price}
        updatePrice={terrace_area.update_price}
      />
      <PriceBreakdownRow
        housePartKey='garden_area'
        valueContent={formatAreaUnits(garden_area.value)}
        price={garden_area.price}
        updatePrice={garden_area.update_price}
      />
      {has_no_exterior && has_no_exterior.value !== '' && (
        <PriceBreakdownRow
          housePartKey='has_no_exterior'
          updatePrice={has_no_exterior.update_price}
          valueContent={
            <>
              {has_no_exterior.value === '1' && t('enums.house_parts.has_no_exterior.value.1', 'No')}
              {has_no_exterior.value === '0' && t('enums.house_parts.has_no_exterior.value.0', 'Yes')}
            </>
          }
          price={has_no_exterior.price}
          showMinus={has_no_exterior.price > 0}
        />
      )}
    </div>
  );
};
