import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../FormContainer';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { ImageSwitch } from '../../button/ImageSwitch';
import { Button } from '../../button/Button';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { BackButton } from '../../button/BackButton';

export const SaleOrRental: FC = () => {
  const { t } = useTranslation();

  const { projectType } = useStoreState((state) => state.onboarding);
  const { profile } = useStoreState((state) => state.user);
  const { setProjectType } = useStoreActions((actions) => actions.onboarding);
  const { handleNext, handleBack } = useStoreActions(
    (actions) => actions.onboarding,
  );
  const isUS = profile?.localization === 'US';

  return (
    <FormContainer>
      <div>
        <BackButton handleClick={handleBack} />

        <Heading className='pt-5 pb-6'>
          {t('onboarding.sale_rental.title', 'For Sale or Rental?')}
        </Heading>
        <Subtitle>
          {isUS
            ? t(
                'onboarding.sale_rental.subtitle.us',
                'Is this a multi-family or a single-family project?',
              )
            : t(
                'onboarding.sale_rental.subtitle',
                'Is this a for sale or a rental project? Combined projects should be uploaded as two projects.',
              )}
        </Subtitle>
        <div className='flex justify-center pt-20 gap-20'>
          <ImageSwitch
            label={t('onboarding.sale_rental.sale', 'For Sale')}
            type='sale'
            selected={projectType.sale}
            state={projectType}
            setState={setProjectType}
          />
          <ImageSwitch
            label={t('onboarding.sale_rental.rental', 'Rental')}
            type='rental'
            selected={projectType.rental}
            state={projectType}
            setState={setProjectType}
          />
        </div>
      </div>
      <div className='flex justify-center'>
        <Button
          disabled={!(projectType.rental || projectType.sale)}
          handleClick={handleNext}
        >
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
