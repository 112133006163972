import { FC } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import {
  BLUE_4,
  BLUE_5,
  BLUE_1,
  BLUE_2,
  BLUE_3,
} from '../../../../styles/constants';
import { formatPercent, getSum } from '../../../../utils';
import { getPieOptions } from '../../../../utils/defaultChartConfig';

export interface Data {
  availability: string;
  value: number;
  layout: string;
}

interface Props {
  data: Data[];
}

export const UnitMixChart: FC<Props> = ({ data }) => {
  const { getLayout } = useFlatLayout();
  const { displayValues } = useStoreState((state) => state.filters);

  const total = getSum(data.map((v) => v.value));

  const config: PieConfig = getPieOptions({
    data,
    angleField: 'value',
    colorField: 'layout',
    label: displayValues
      ? {
          type: 'spider', // Use spider labels for better positioning
          content: ({ percent }) =>
            `${Math.round(percent * 100 * 10) / 10} %`,
        }
      : undefined,
    legend: {
      itemName: {
        formatter: (value) => getLayout(value),
      },
    },
    color: [BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5],
    tooltip: {
      customContent: (title, values) =>
        `<div class="bm-tooltip-2">
            <div class="bmt-title">${getLayout(title)}</div>
            <div class="bmt-subtitle">${values[0]?.value}/${total}</div>
            <div class="bmt-content">${formatPercent(
              (values[0]?.value ?? 0) / total,
            )}</div>
          </div>`,
    },
  });

  return data.length > 0 ? (
    <Pie {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
