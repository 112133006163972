/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LayoutDataclassRoom
 */
export interface LayoutDataclassRoom {
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclassRoom
     */
    units: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclassRoom
     */
    interiorSm: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclassRoom
     */
    pricePerSm: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclassRoom
     */
    price: number;
}

/**
 * Check if a given object implements the LayoutDataclassRoom interface.
 */
export function instanceOfLayoutDataclassRoom(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "units" in value;
    isInstance = isInstance && "interiorSm" in value;
    isInstance = isInstance && "pricePerSm" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function LayoutDataclassRoomFromJSON(json: any): LayoutDataclassRoom {
    return LayoutDataclassRoomFromJSONTyped(json, false);
}

export function LayoutDataclassRoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): LayoutDataclassRoom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'units': json['units'],
        'interiorSm': json['interior_sm'],
        'pricePerSm': json['price_per_sm'],
        'price': json['price'],
    };
}

export function LayoutDataclassRoomToJSON(value?: LayoutDataclassRoom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'units': value.units,
        'interior_sm': value.interiorSm,
        'price_per_sm': value.pricePerSm,
        'price': value.price,
    };
}

