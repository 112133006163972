import { css } from '@emotion/react/macro';
import { BLUE_4, BLUE_5, BOLD_FONT_WEIGHT } from '../../../../styles/constants';

export const styles = {
  container: css({ marginBottom: '1.2rem' }),
  titleContainer: css({ display: 'flex', justifyContent: 'space-between' }),
  title: css({
    fontSize: '2rem',
    fontWeight: BOLD_FONT_WEIGHT,
    a: css({ color: BLUE_5, '&:hover': css({ color: BLUE_4 }) }),
  }),
};
