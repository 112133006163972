import { FC, useState, useRef, useEffect } from 'react';
import { Col, Row } from 'antd';
import { shuffle } from '../../utils/utils';

type PropsItem = {
  date: string;
  value: FC;
};

type Props = {
  data: PropsItem[];
};

export const NewsList: FC<Props> = ({ data }) => {
  const [shuffledData, setShuffledData] = useState<PropsItem[]>([]);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
  setShuffledData([
      ...shuffle(data),
    ].sort((a, b) => b.date.localeCompare(a.date)));
  }, [data]);

  return (
    <Col flex='auto' className='infinite-container daily_news_list'>
      {shuffledData.map((d, i) => {
        const Value = d.value;
        return (
          <Row
            // eslint-disable-next-line react/no-array-index-key
            key={`daily-news-${d.value}-${i}`}
            className='daily_news_list_item'
          >
            <Value />
          </Row>
        );
      })}
      {/* Sentinel element to trigger loading more data */}
      <div ref={sentinelRef} style={{ height: '1px', width: '100%' }} />
    </Col>
  );
};
