import { FC } from 'react';
import { AreaChartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { useFlatLayout, useStoreState } from '../../../hooks';
import { formatDifference, formatPercent, getSum } from '../../../utils';
import { ProjectType } from '../../../store/types';

export const SoldUnits: FC = () => {
  const { t } = useTranslation();
  const { getLayoutAbbr } = useFlatLayout();

  const { projectType } = useStoreState((state) => state.filters);
  const { sold_units_diff, time_progress, units } = useStoreState(
    (state) =>
      state.dashboard.data?.top_metrics.sold_units ?? {
        sold_units_diff: 0,
        time_progress: 0,
        units: [],
      },
  );

  const soldSum = getSum(units.map(({ sold }) => sold));
  const totalSum = getSum(units.map(({ total }) => total));
  const diffs = units.map(({ layout, sold, total }) => ({
    label: getLayoutAbbr(layout),
    value: formatPercent(sold / total),
  }));
  const isRent = projectType === ProjectType.RENT;
  let secondaryTitleType;

  if (!isRent) {
    secondaryTitleType = time_progress;
  } else if (sold_units_diff) {
    secondaryTitleType = sold_units_diff;
  }

  return (
    <KPI
      title={
        isRent ? t('dashboard.kpi.occupancy') : t('dashboard.kpi.sold_reserved')
      }
      icon={<AreaChartOutlined />}
      value={formatPercent(soldSum / totalSum)}
      diff={diffs}
      label={
        <>
          {soldSum}/{totalSum} {formatDifference(sold_units_diff, true)}{' '}
          {t('dashboard.kpi.from_last_month')}
        </>
      }
      secondary={{
        secondaryTitle: isRent
          ? t('dashboard.kpi.monthly_change')
          : t('dashboard.kpi.time_progress'),
        secondaryValue: (
          <>
            {secondaryTitleType &&
              formatPercent(Math.min(1, secondaryTitleType))}
          </>
        ),
      }}
    />
  );
};
