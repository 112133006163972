import { FC } from 'react';
import { Floor as FloorData } from '../../../api/mockData/priceListDetailData';
import { PriceBreakdownRow } from './PriceBreakdownRow';

interface Props {
  data: FloorData;
}

export const Floor: FC<Props> = ({
  data: { bottom_floor, floor, top_floor },
}) => (
  <div>
    <PriceBreakdownRow
      className='row-top-spacing'
      houseAreaKey='floor'
      housePartKey='floor'
      valueContent={floor.value}
      price={floor.price}
      updatePrice={floor.update_price}
    />
    <PriceBreakdownRow
      housePartKey='top_floor'
      valueContent={top_floor.value}
      price={top_floor.price}
      updatePrice={top_floor.update_price}
    />
    <PriceBreakdownRow
      housePartKey='bottom_floor'
      valueContent={bottom_floor.value}
      price={bottom_floor.price}
      updatePrice={bottom_floor.update_price}
    />
  </div>
);
