import { Action, action, thunk, Thunk } from 'easy-peasy';
import { message } from 'antd';
import { deleteSubscribedEmails, getSubscribedEmails } from '../api';
import { StoreModel } from './types';
import { logError } from '../utils/utils';
import { DeleteSubscribedEmailsParameters, SubscribedEmails } from '../api/types';

export interface SubscribeStore {
  data?: SubscribedEmails;
  setSubscribedEmails: Action<SubscribeStore, SubscribedEmails>;
  fetchSubscribedEmails: Thunk<SubscribeStore, number, unknown, StoreModel>;
  deleteSubscribedEmail: Thunk<SubscribeStore, DeleteSubscribedEmailsParameters, unknown, StoreModel>;
}

export const subscribeStore: SubscribeStore = {
  setSubscribedEmails: action((state, data: SubscribedEmails) => {
    state.data = data;
  }),
  fetchSubscribedEmails: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const { data } = await getSubscribedEmails(payload);
      actions.setSubscribedEmails(data);
    } catch (error) {
      message.error('No emails found');
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
  // eslint-disable-next-line consistent-return
  deleteSubscribedEmail: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const resposne = await deleteSubscribedEmails({ ...payload });
      return { ...resposne };
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
};
