/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string
}

const styles = {
  content: css({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '52rem',
    flex: 1,
  }),
};

export const NoDataContainer: FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <Row css={styles.content}>
      {message ?? t('common.no_data', "We're currently updating this data.")}
    </Row>
    );
};
