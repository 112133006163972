import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, message } from 'antd';
import { MapIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { LanguageIcon } from './icons/LanguageIcon';
import styles from './MarketAnalysis.module.css';
import { City, getAnalysisData, reports } from './reports';
import { useStoreState } from '../../hooks';
import { LanguageEnum } from '../../api/enums';

export const MarketAnalysisPage: FC = () => {
  const { t } = useTranslation();
  const profile = useStoreState((state) => state.user.profile);

  const defaultLanguage = profile?.language as LanguageEnum;
  const localization = profile?.localization;
  const localizationLanguage = localization === 'cs' ? 'cs' : 'sk';
  const cities = Object.keys(reports[localizationLanguage]) as City[];
  const [city, setCity] = useState(cities[0]);
  const quartals = Object.keys(reports[localizationLanguage][city]);
  const [quartal, setQuartal] = useState(quartals[0]);
  const languages = Object.keys(reports[localizationLanguage][city][quartal]) as LanguageEnum[];
  const [language, setLanguage] = useState(languages.includes(defaultLanguage) ? defaultLanguage : languages[0]);
  // @ts-ignore
  const cityData = getAnalysisData(localizationLanguage, city, quartal, language);
  const [analysisData, setAnalysisData] = useState(cityData);
  const { Option } = Select;

  useEffect(() => {
    setAnalysisData(cityData);
  }, [cityData]);

  const handleDownload = (downloadUrl: string): void => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    } else {
      message.info(t('enums.market_analysis.download.message', 'Download URL is coming soon!'));
    }
  };

  useEffect(() => {
    const newQuartals = Object.keys(reports[localizationLanguage][city]);
    const newLanguages = Object.keys(reports[localizationLanguage][city][quartal]) as LanguageEnum[];
    setQuartal(newQuartals[0]);
    setLanguage(newLanguages.includes(defaultLanguage) ? defaultLanguage : newLanguages[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, defaultLanguage, localizationLanguage]);

  return (
  <div className={styles.marketAnalysis}>
    <div className={styles.header}>
      <div className={styles.dataContainer}>
        <div className={styles.dropdown}>
          <MapIcon className={styles.icon} />
          <Select
            bordered={false}
            value={city}
            onChange={setCity}
            dropdownMatchSelectWidth={false}
            suffixIcon={<ChevronDownIcon className={styles.chevron} />}
          >
          {cities.map((v) => (
            <Option key={v} value={v}>
              {v}
            </Option>
          ))}
          </Select>
        </div>
        <div className={styles.dropdown}>
          <PresentationChartLineIcon className={styles.icon} />
          <Select
            bordered={false}
            value={quartal}
            onChange={(value) => setQuartal(value)}
            dropdownMatchSelectWidth={false}
            suffixIcon={<ChevronDownIcon className={styles.chevron} />}
          >
          {quartals.map((v) => (
            <Option key={v} value={v}>
              {v}
            </Option>
          ))}
          </Select>
        </div>
          <div className={styles.dropdown}>
            <LanguageIcon className={styles.icon} />
            <Select
              bordered={false}
              value={language}
              style={{ textTransform: 'uppercase' }}
              onChange={(value) => setLanguage(value)}
              dropdownMatchSelectWidth={false}
              suffixIcon={<ChevronDownIcon className={styles.chevron} />}
            >
            {languages.map((v) => (
              <Option key={v} value={v} style={{ textTransform: 'uppercase' }}>
                {v}
              </Option>
            ))}
            </Select>
          </div>
      </div>
      <Button
        className={styles.download}
        icon={<ArrowDownTrayIcon className={styles.downloadIcon} />}
        onClick={() => handleDownload(analysisData?.downloadUrl || 'https://www.builtmind.com/wp-content/uploads/2024/03/BuiltMind-Praha-2023-Q4-Report.pdf')}
      >
        {t('enums.market_analysis.download', 'Download')}
      </Button>
    </div>
    <div className={styles.video}>
      <iframe
        loading='lazy'
        src={analysisData?.embedUrl || 'https://www.canva.com/design/DAF6tTC1pKM/ageL6Y5CiwfpbUBeKKlDbQ/view?embed'}
        allow='fullscreen'
        title='Canva report'
      />
    </div>
  </div>
  );
};
