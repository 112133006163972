import { Action, action, thunk, Thunk } from 'easy-peasy';
import { getDashboardData } from '../api';
import { StoreModel } from './types';
import { DashboardData } from '../api/mockData/dashboardData';
import { DashboardDataParameters } from '../api/types';
import { logError } from '../utils/utils';

export interface DashboardStore {
  data?: DashboardData;
  setData: Action<DashboardStore, DashboardData>;
  fetchData: Thunk<
    DashboardStore,
    DashboardDataParameters,
    unknown,
    StoreModel
  >;
}

export const dashboardStore: DashboardStore = {
  setData: action((state, newData: DashboardData) => {
    state.data = newData;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      const { data } = await getDashboardData({
        ...payload,
        price_per_sm_calculation: profile?.price_per_sm_calculation,
      });
      actions.setData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
};
