import { FC, ReactNode } from 'react';
import { InfoPopover } from '../../../../../InfoPopover';

interface Props {
  value: number;
  tooltipValue: ReactNode;
}

export const Rating: FC<Props> = ({ value, tooltipValue }) => (
  <InfoPopover
    label={
      <u>
        <b>{value}</b> / 10
      </u>
    }
    popoverProps={{ content: tooltipValue }}
  />
);
