export enum StorageKeys {
  ACCESS_TOKEN = 'access_token',
  LAST_ACTIVE_TIME = 'lastActiveTime',
  USER_UUID = 'user_uuid'
}

export interface Layout {
  layout: string;
}

export interface LatLng {
  lat: number;
  lng: number;
}
