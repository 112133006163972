/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LayoutUnits } from './LayoutUnits';
import {
    LayoutUnitsFromJSON,
    LayoutUnitsFromJSONTyped,
    LayoutUnitsToJSON,
} from './LayoutUnits';

/**
 * 
 * @export
 * @interface DisappearedUnitsLayoutUnitMixResponseSchema
 */
export interface DisappearedUnitsLayoutUnitMixResponseSchema {
    /**
     * 
     * @type {Array<LayoutUnits>}
     * @memberof DisappearedUnitsLayoutUnitMixResponseSchema
     */
    disappearedUnitsLayout180: Array<LayoutUnits>;

    /**
     * 
     * @type {Array<LayoutUnits>}
     * @memberof DisappearedUnitsLayoutUnitMixResponseSchema
     */
    disappearedUnitsLayout: Array<LayoutUnits>;
}

/**
 * Check if a given object implements the DisappearedUnitsLayoutUnitMixResponseSchema interface.
 */
export function instanceOfDisappearedUnitsLayoutUnitMixResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disappearedUnitsLayout180" in value;

    return isInstance;
}

export function DisappearedUnitsLayoutUnitMixResponseSchemaFromJSON(json: any): DisappearedUnitsLayoutUnitMixResponseSchema {
    return DisappearedUnitsLayoutUnitMixResponseSchemaFromJSONTyped(json, false);
}

export function DisappearedUnitsLayoutUnitMixResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsLayoutUnitMixResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'disappearedUnitsLayout180': ((json['disappeared_units_layout_180'] as Array<any>)?.map(LayoutUnitsFromJSON)),
        'disappearedUnitsLayout': ((json['disappeared_units_layout'] as Array<any>)?.map(LayoutUnitsFromJSON)),
    };
}

export function DisappearedUnitsLayoutUnitMixResponseSchemaToJSON(value?: DisappearedUnitsLayoutUnitMixResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disappeared_units_layout_180': ((value.disappearedUnitsLayout180 as Array<any>).map(LayoutUnitsToJSON)),
    };
}

