import { Action, action } from 'easy-peasy';

export interface GlobalStore {
  isLoading: boolean;
  setGlobalLoading: Action<GlobalStore, boolean>;
  isLoaded: boolean;
  setIsLoaded: Action<GlobalStore, boolean>;
}

export const globalStore: GlobalStore = {
  isLoading: false,
  setGlobalLoading: action((state, value) => {
    state.isLoading = value;
  }),
  isLoaded: false,
  setIsLoaded: action((state, value) => {
    state.isLoaded = value;
  }),
};
