/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewUnits } from './NewUnits';
import {
    NewUnitsFromJSON,
    NewUnitsFromJSONTyped,
    NewUnitsToJSON,
} from './NewUnits';

/**
 * 
 * @export
 * @interface NewUnitsResponseSchema
 */
export interface NewUnitsResponseSchema {
    /**
     * 
     * @type {Array<NewUnits>}
     * @memberof NewUnitsResponseSchema
     */
    newUnits: Array<NewUnits>;
}

/**
 * Check if a given object implements the NewUnitsResponseSchema interface.
 */
export function instanceOfNewUnitsResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newUnits" in value;

    return isInstance;
}

export function NewUnitsResponseSchemaFromJSON(json: any): NewUnitsResponseSchema {
    return NewUnitsResponseSchemaFromJSONTyped(json, false);
}

export function NewUnitsResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUnitsResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newUnits': ((json['new_units'] as Array<any>).map(NewUnitsFromJSON)),
    };
}

export function NewUnitsResponseSchemaToJSON(value?: NewUnitsResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_units': ((value.newUnits as Array<any>).map(NewUnitsToJSON)),
    };
}

