/** @jsxImportSource @emotion/react */
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import { Button, message } from 'antd';
import { ExportToCsv, Options } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import {
  useRegionFormatting,
  useFlatLayout,
  useStoreState,
} from '../../../hooks';
import { getFlats, getFlatsFromS3 } from '../../../api';
import { logError } from '../../../utils/utils';
import { useReservedData } from '../../../hooks/useReservedData';
import { Availability } from '../../../store/types';
import { ExportDropdown } from '../../ExportDropdown/ExportDropdown';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_flats`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

const styles = {
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
    marginTop: '10px',
    // justifyContent: 'center',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
  formatSelect: css({
    borderRadius: '15px',
    width: '240px',
  }),
};

export const csvExporter = new ExportToCsv(options);

type PDFProps = {
  onDownloadPDF: () => void;
  isLoadingDownload: boolean;
  marginRight?: boolean;
};

type FilterProps = {
  filter: string | number;
};

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const PDFExport: FC<PDFProps> = ({
  onDownloadPDF,
  isLoadingDownload,
  marginRight = false,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      css={styles.download}
      icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
      onClick={onDownloadPDF}
      disabled={isLoadingDownload}
      style={{ marginRight: marginRight ? '10px' : 0 }}
    >
      {isLoadingDownload
        ? `${t('download.processing_data', 'Preparing your data')}...`
        : 'PDF'}
    </Button>
  );
};

export const ReportExport: FC<FilterProps> = ({ filter }) => {
  const { calculateVatPrice, formatCurrency, formatAreaUnits, getVATText } =
    useRegionFormatting();
  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { mapReservedData } = useReservedData();

  const handleButtonClick = async (format: string): Promise<void> => {
    if (paramsPM) {
      try {
        message.loading(t('market.export.preparing_data'), 0);
        const { data } = await getFlats(paramsPM.dashboardId, paramsPM.date);
        const { is_prepared, s3url } = data;

        const { data: flats } = await getFlatsFromS3(s3url);

        const preparedFlats = mapReservedData(flats);
        if (is_prepared && (flats || []).length > 0) {
          const filterValue =
            filter === FilterEnum.AVAILABLE
              ? Availability.AVAILABLE
              : Availability.SOLD;
          const exportData = preparedFlats
            .filter((flat) => flat.availability === filterValue)
            .map(
              ({
                project_name,
                address,
                id,
                availability,
                exterior_area,
                floor,
                floor_area,
                layout,
                price,
                price_per_sm,
                total_area,
              }) => ({
                project: project_name,
                address,
                id,
                availability: t(`enums.state.${availability.toLowerCase()}`),
                exterior_area: formatAreaUnits(exterior_area),
                floor,
                floor_area: formatAreaUnits(floor_area),
                layout: getLayout(layout),
                price: formatCurrency(calculateVatPrice(price, 120)) ?? '',
                price_per_sm:
                  formatCurrency(calculateVatPrice(price_per_sm, 120)) ?? '',
                total_area: formatAreaUnits(total_area),
              }),
            );
          if (exportData.length === 0) {
            message.destroy();
            message.info(t('market.export.no_data'), 2);
            return;
          }
          if (format === 'csv') {
            csvExporter.options.filename = `${options.filename}${getVATText()}`;
            csvExporter.generateCsv(exportData);
          }
          if (format === 'xlsx') {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
          }
          if (format === 'realpad') {
            const CRMData = preparedFlats?.map((flat) => ({
              internalId: flat.id,
              buildingNo: flat.building,
              floorNo: flat.floor,
              price: calculateVatPrice(
                flat.price,
                flat.floor_area,
                Number(flat.exterior_area),
                false,
              ),
              priceVAT: calculateVatPrice(
                flat.price,
                flat.floor_area,
                Number(flat.exterior_area),
                true,
              ),
            }));
            if (CRMData) {
              const worksheet = XLSX.utils.json_to_sheet(CRMData);
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
              XLSX.writeFile(workbook, `${options.filename}.xlsx`);
            }
          }
          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
        } else {
          logError('Data is not prepared for the export yet.');
        }
      } catch (error) {
        logError(error);
        message.error(t('error.generic'), 2);
      }
    }
  };

  return (
    // <>
    // <Button
    //   css={styles.download}
    //   icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
    //   onClick={() => handleButtonClick('CSV')}
    //   type='text'
    // >
    //   {t('market.button.export_csv')}
    // </Button>
    // <Button
    //   css={styles.download}
    //   icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
    //   onClick={() => handleButtonClick('XLSX')}
    //   type='text'
    // >
    //   {t('market.button.export_xlsx')}
    // </Button>
    // </>
    <ExportDropdown type='marketData' onDownload={handleButtonClick} />
  );
};
