/* eslint-disable guard-for-in */
import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { useStoreState, useStoreActions } from '../../../hooks';
import { formatDailyNewsObject } from '../../../utils/utils';
import { NewsList } from '../NewsList';
import { formatDailyNewsObjectHelper } from '../DailyNewsTrans';
import { DailyNewsReportFilters } from '../DailyNewsFilters';
import { fetchMarketDashboardOverview } from '../../../api';
import { Competitor } from '../../../api/types';
import { DailyNewsData } from '../../../store/dailyNews';
import { SubareaEnum } from '../../../api/enums';

type Item = {
  variables: Record<string, string>[];
};

type NewArr = {
  project_name: string;
  difference: number;
}

export const CompetitorsNews: FC = () => {
  const { t, i18n: { language } } = useTranslation();
  const { data, subarea, partialData } = useStoreState((state) => state.dailyNews);
  const { area, selectedReportId } = useStoreState((state) => state.filters);
  const marketDashboards = useStoreState((state) => state.filters.overviewData?.market_dashboards);
  const competitors = useStoreState((state) => state.market.dashboardOverviewData?.competitors);
  const [selectedCompetitors, setSelectedCompetitors] = useState<Competitor[]>();
  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );
  const projects = useStoreState((state) => state.market.simplifiedProjects);

  useEffect(() => {
    if (area && selectedReportId && marketDashboards && area !== selectedReportId) {
      fetchMarketDashboardOverview(
        selectedReportId,
      ).then((res) => setSelectedCompetitors(res.data.competitors));
    }
  }, [area, marketDashboards, selectedReportId]);

  const handleClick = useCallback((item: Item) => {
    if (projects && projects.length > 0) {
      setSelectedProjectId(
        Number(projects
        .find(
          (p) =>
            p.project_name === item.variables[0].project_name,
        )?.project_id) ?? null,
      );
    }
  }, [projects, setSelectedProjectId]);

  const filteredData = useMemo(() => {
    if (!data || !area) return undefined;
    if (
      !selectedReportId ||
      !marketDashboards ||
      !selectedCompetitors ||
      selectedReportId === area
    ) return data;
    return {
      ...data,
      discounts: data.discounts?.filter(
        (d) => selectedCompetitors.filter((comp) => comp.id === d.project_id).length > 0),
      new_sold_out_projects: data?.new_sold_out_projects ?
        Object.entries(data.new_sold_out_projects)
        .map(([key, value]) => {
          const newObj: typeof value = {};
          selectedCompetitors.forEach((comp) => {
            if (value[comp.id]) {
              newObj[comp.id] = value[comp.id];
            }
          });
          return {
            [key]: newObj,
          };
        }).reduce((acc, cur) => ({
            ...acc,
            ...cur,
          }), {} as DailyNewsData['new_sold_out_projects']) : undefined,
      added_units: data.added_units ? Object.entries(data.added_units)
      .map(([key, value]) => {
        const newArr: NewArr[] = [];
        const selectedArea = value.find((val) => String(Object.keys(val)) === String(area));
        if (selectedArea) {
          selectedCompetitors.forEach((comp) => {
            const foundValues = selectedArea[area]
            .find((p) => typeof p.project_name === 'string' && p.project_name.toLowerCase() === comp.name.toLowerCase());
            if (foundValues) {
              newArr.push({ ...foundValues });
            }
          });
        }
        return {
          [String(key)]: [{
            [String(area)]: newArr,
          }],
        };
      }).reduce((acc, cur) => ({
        ...acc,
        ...cur,
      }), {} as DailyNewsData['added_units']) : undefined,
    };
  }, [area, selectedCompetitors, data, marketDashboards, selectedReportId]);

  const added_units = useMemo(() => {
    if (!data?.added_units || !area) return [];
    return [
      ...formatDailyNewsObjectHelper(
        Object.entries(data.added_units).map(([key, value]) => {
          const val = value.filter((o) => o[area] && o[area].length > 0);
          return {
            date: key,
            language,
            icon: {
              source: 'home-dark-blue.png',
              color: 'light_blue',
            },
            items: val.length > 0 ? val[0][area].map((o) => ({
              i18key: 'added_units',
            project_id: competitors?.find((comp) => comp.name === o.project_name)?.id ?? -1,
              variables: [{
                count: String(o.difference),
                project_name: o.project_name,
              }],
              onClick: handleClick,
            })) : [],
          };
        }).filter((o) => o.items.length > 0), t),
    ];
  }, [area, competitors, data?.added_units, handleClick, language, t]);

  const new_sold_out_projects = useMemo(() => {
    if (!filteredData?.new_sold_out_projects) return [];
    return [
      ...formatDailyNewsObject(
        filteredData.new_sold_out_projects!,
        'sold_out_project',
        language,
        t,
        {
          source: 'wallet-dark-grey.png',
          color: 'grey',
        },
        handleClick,
      ),
    ];
  }, [filteredData?.new_sold_out_projects, handleClick, language, t]);

  const new_projects = useMemo(() => {
    if (!filteredData?.new_projects) return [];
    return [
      ...formatDailyNewsObject(
        filteredData.new_projects!,
        'new_project',
        language,
        t,
        {
          source: 'price-tag-dark-blue.png',
          color: 'light_blue',
        },
        handleClick,
      ),
    ];
  }, [filteredData?.new_projects, handleClick, language, t]);

  const discounts = useMemo(() => {
    if (!filteredData?.discounts || !competitors) return [];
    return [
      ...formatDailyNewsObjectHelper(
        filteredData.discounts!.map((d) => ({
            date: d.date,
            language,
            icon: {
              source: 'price-tag-grey.png',
              color: 'grey',
            },
            items: [{
              i18key: 'discounts',
              project_id: d.project_id,
              variables: [{
                project_id: String(d.project_id),
                project_name: competitors?.find((comp) => comp.id === d.project_id)?.name ?? '',
                discount_text: d.discount_text,
              }],
              onClick: handleClick,
            }],
          })), t),
    ];
  }, [competitors, filteredData?.discounts, handleClick, language, t]);

  const newsData = useMemo(() => {
    if (
      !filteredData
      || !area
    ) return [];
    return [
      ...new_sold_out_projects,
      ...added_units,
      ...new_projects,
      ...discounts,
    ].filter((d) => {
      if (!partialData || !partialData.city_ids || !partialData.outskirts_ids) return true;
      if (subarea === SubareaEnum.CITY) {
        return partialData.city_ids.includes(d.project_id);
      }
      if (subarea === SubareaEnum.OUTSKIRT) {
        return partialData.outskirts_ids.includes(d.project_id);
      }
      return true;
    });
  }, [
    area,
    filteredData,
    partialData,
    subarea,
    new_sold_out_projects,
    added_units,
    new_projects,
    discounts,
  ]);

  return (
    <KPI
      controls={
        <DailyNewsReportFilters />
      }
      title={
      <>
        <div className='daily_news_kpi_competitors_news title'>
          <img alt='magnifying_glass' src='assets/magnifying-glass-dark-blue.png' />
        </div>
        {t('daily_news.kpi.competitors_news.title')}
      </>
      }
      subtitle={t('daily_news.kpi.competitors_news.subtitle')}
      type='vertical'
    >
      {filteredData && <NewsList data={newsData} />}
    </KPI>
  );
};
