/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Segmented } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { PriceBreakdown as PriceBreakdownType, PriceSumsType } from '../../../api/mockData/priceListDetailData';
import { Interior } from './Interior';
import { Exterior } from './Exterior';
import { Floor } from './Floor';
import { Orientation } from './Orientation';
import { Layout } from './Layout';
// import { Analytics } from './Analytics';
import { Card } from '../../Card';
import { PriceDetailFilter } from '../../../api/enums';
import { useRegionFormatting, useStoreActions, useStoreState } from '../../../hooks';
import { styles } from './styles';
import { SalesPlan } from './SalesPlan';
import { ProjectType } from '../../../store/types';

interface PriceBreakdownProps {
  data: PriceBreakdownType;
  priceData?: PriceSumsType;
}

export const PriceBreakdown: FC<PriceBreakdownProps> = (props) => {
  const { formatCurrency, areaUnit } = useRegionFormatting();
  const setPriceDetailFilter = useStoreActions(
    (actions) => actions.priceListDetail.setPriceDetailFilter,
  );
  const showPrediction = useStoreState(
    (state) => state.priceListDetail.showPrediction,
  );
  const priceDetailFilter = useStoreState(
    (state) => state.priceListDetail.priceFilter,
  );
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  const {
    data: propsData,
    priceData,
  } = props;

  const { analytics, exterior, floor, interior, layout, orientation, sales_plan } = propsData;

  const handleChange = (value: PriceDetailFilter): void => {
    setPriceDetailFilter(value);
  };

  const [data, setData] = useState(
    { analytics, exterior, floor, interior, layout, orientation, sales_plan },
  );

  const priceSums = useMemo(() => {
    if (!priceData) return null;
    const {
      recommendedPrice,
      priceUpdate,
      recommendedPricePsm,
      priceUpdatePsm,
      priceUpdatePercentage,
    } = priceData;
    // const exposedParts = Object.values(propsData).reduce((result, currentPart) => ({
    //     ...result,
    //     ...currentPart,
    //   }), {});

    // const exposedValues = Object.values(exposedParts) as PriceBreakdownPart[];
    // const allSums = exposedValues.reduce((result, currentPart) => ({
    //     recommendedPrice: result.recommendedPrice + (currentPart?.price ?? 0),
    //     priceUpdate: result.priceUpdate + (currentPart?.update_price ?? 0),
    //     recommendedPricePsm: result.recommendedPricePsm + (currentPart?.price_psm ?? 0),
    //     priceUpdatePsm: result.priceUpdatePsm + (currentPart?.update_price_psm ?? 0),
    //     priceUpdatePercentage:
    //       result.priceUpdatePercentage + (currentPart?.update_price_percentage ?? 0),
    //   }), {
    //     recommendedPrice: 0,
    //     priceUpdate: 0,
    //     recommendedPricePsm: 0,
    //     priceUpdatePsm: 0,
    //     priceUpdatePercentage: 0,
    //   } as PriceSumsType);

      switch (priceDetailFilter) {
        case PriceDetailFilter.PRICE_PER_SM:
          return {
            recommendedPrice: recommendedPricePsm,
            priceUpdate: priceUpdatePsm,
          };
        case PriceDetailFilter.PERCENTAGE:
          return {
            recommendedPrice,
            priceUpdate: priceUpdatePercentage,
          };
        default:
          return {
            recommendedPrice,
            priceUpdate,
          };
      }
    },
    [priceData, priceDetailFilter],
  );

  const isPercentage = priceDetailFilter === PriceDetailFilter.PERCENTAGE;

  useEffect(() => {
    switch (priceDetailFilter) {
      case PriceDetailFilter.PRICE_PER_SM:
        setData({
          analytics: {
            ...analytics,
          },
          interior: {
            floor_area: {
              ...interior.floor_area,
              price: interior.floor_area.price_psm,
              update_price: interior.floor_area.update_price_psm,
            },
            difference_floor_area: {
              ...interior.difference_floor_area,
              price: interior.difference_floor_area.price_psm,
              update_price: interior.difference_floor_area.update_price_psm,
            },
            layout: {
              ...interior.layout,
              price: interior.layout.price_psm,
              update_price: interior.layout.update_price_psm,
            },
            layout_class: {
              ...interior.layout_class,
              price: interior.layout_class.price_psm,
              update_price: interior.layout_class.update_price_psm,
            },
          },
          exterior: {
            ...exterior,
            balcony_area: {
              ...exterior.balcony_area,
              price: exterior.balcony_area.price_psm,
              update_price: exterior.balcony_area.update_price_psm,
            },
            garden_area: {
              ...exterior.garden_area,
              price: exterior.garden_area.price_psm,
              update_price: exterior.garden_area.update_price_psm,
            },
            terrace_area: {
              ...exterior.terrace_area,
              price: exterior.terrace_area.price_psm,
              update_price: exterior.terrace_area.update_price_psm,
            },
            has_no_exterior: exterior.has_no_exterior && {
              ...exterior.has_no_exterior,
              price: exterior.has_no_exterior.price_psm,
              update_price: exterior.has_no_exterior.update_price_psm,
            },
          },
          floor: {
            ...floor,
            floor: {
              ...floor.floor,
              price: floor.floor.price_psm,
              update_price: floor.floor.update_price_psm,
            },
            top_floor: {
              ...floor.top_floor,
              price: floor.top_floor.price_psm,
              update_price: floor.top_floor.update_price_psm,
            },
            bottom_floor: {
              ...floor.bottom_floor,
              price: floor.bottom_floor.price_psm,
              update_price: floor.bottom_floor.update_price_psm,
            },
          },
          orientation: {
            ...orientation,
            cardinal_direction: {
              ...orientation.cardinal_direction,
              price: orientation.cardinal_direction.price_psm,
              update_price: orientation.cardinal_direction.update_price_psm,
            },
            distance: {
              ...orientation.distance,
              price: orientation.distance.price_psm,
              update_price: orientation.distance.update_price_psm,
            },
            view: {
              ...orientation.view,
              price: orientation.view.price_psm,
              update_price: orientation.view.update_price_psm,
            },
            num_sides: {
              ...orientation.num_sides,
              price: orientation.num_sides.price_psm,
              update_price: orientation.num_sides.update_price_psm,
            },
          },
          layout: {
            ...layout,
            category: {
              ...layout.category,
              price: layout.category.price_psm,
              update_price: layout.category.update_price_psm,
            },
            kitchen_luminosity: {
              ...layout.kitchen_luminosity,
              price: layout.kitchen_luminosity.price_psm,
              update_price: layout.kitchen_luminosity.update_price_psm,
            },
            next_to_elevator: {
              ...layout.next_to_elevator,
              price: layout.next_to_elevator.price_psm,
              update_price: layout.next_to_elevator.update_price_psm,
            },
            noise: {
              ...layout.noise,
              price: layout.noise.price_psm,
              update_price: layout.noise.update_price_psm,
            },
            num_of_bathrooms: {
              ...layout.num_of_bathrooms,
              price: layout.num_of_bathrooms.price_psm,
              update_price: layout.num_of_bathrooms.update_price_psm,
            },
            permit_regular: {
              ...layout.permit_regular,
              price: layout.permit_regular.price_psm,
              update_price: layout.permit_regular.update_price_psm,
            },
            ratio_bedrooms: {
              ...layout.ratio_bedrooms,
              price: layout.ratio_bedrooms.price_psm,
              update_price: layout.ratio_bedrooms.update_price_psm,
            },
            ratio_living: {
              ...layout.ratio_living,
              price: layout.ratio_living.price_psm,
              update_price: layout.ratio_living.update_price_psm,
            },
            walk_through_room: {
              ...layout.walk_through_room,
              price: layout.walk_through_room.price_psm,
              update_price: layout.walk_through_room.update_price_psm,
            },
          },
          sales_plan: {
            price_effect: {
              ...sales_plan.price_effect,
              price: sales_plan.price_effect.price_psm,
              update_price: sales_plan.price_effect.update_price_psm,
            },
          },
        });
        break;
      case PriceDetailFilter.PERCENTAGE:
        setData({
          analytics: {
            ...analytics,
          },
          interior: {
            floor_area: {
              ...interior.floor_area,
              price: interior.floor_area.price_percentage,
              update_price: interior.floor_area.update_price_percentage,
            },
            difference_floor_area: {
              ...interior.difference_floor_area,
              price: interior.difference_floor_area.price_percentage,
              update_price: interior.difference_floor_area.update_price_percentage,
            },
            layout: {
              ...interior.layout,
              price: interior.layout.price_psm,
              update_price: interior.layout.update_price_percentage,
            },
            layout_class: {
              ...interior.layout_class,
              price: interior.layout_class.price_psm,
              update_price: interior.layout_class.update_price_percentage,
            },
          },
          exterior: {
            ...exterior,
            balcony_area: {
              ...exterior.balcony_area,
              price: exterior.balcony_area.price_percentage,
              update_price: exterior.balcony_area.update_price_percentage,
            },
            garden_area: {
              ...exterior.garden_area,
              price: exterior.garden_area.price_percentage,
              update_price: exterior.garden_area.update_price_percentage,
            },
            terrace_area: {
              ...exterior.terrace_area,
              price: exterior.terrace_area.price_percentage,
              update_price: exterior.terrace_area.update_price_percentage,
            },
            has_no_exterior: exterior.has_no_exterior && {
              ...exterior.has_no_exterior,
              price: exterior.has_no_exterior.price_percentage,
              update_price: exterior.has_no_exterior.update_price_percentage,
            },
          },
          floor: {
            ...floor,
            floor: {
              ...floor.floor,
              price: floor.floor.price_percentage,
              update_price: floor.floor.update_price_percentage,
            },
            top_floor: {
              ...floor.top_floor,
              price: floor.top_floor.price_percentage,
              update_price: floor.top_floor.update_price_percentage,
            },
            bottom_floor: {
              ...floor.bottom_floor,
              price: floor.bottom_floor.price_percentage,
              update_price: floor.bottom_floor.update_price_percentage,
            },
          },
          orientation: {
            ...orientation,
            cardinal_direction: {
              ...orientation.cardinal_direction,
              price: orientation.cardinal_direction.price_percentage,
              update_price: orientation.cardinal_direction.update_price_percentage,
            },
            distance: {
              ...orientation.distance,
              price: orientation.distance.price_percentage,
              update_price: orientation.distance.update_price_percentage,
            },
            view: {
              ...orientation.view,
              price: orientation.view.price_percentage,
              update_price: orientation.view.update_price_percentage,
            },
            num_sides: {
              ...orientation.num_sides,
              price: orientation.num_sides.price_percentage,
              update_price: orientation.num_sides.update_price_percentage,
            },
          },
          layout: {
            ...layout,
            category: {
              ...layout.category,
              price: layout.category.price_percentage,
              update_price: layout.category.update_price_percentage,
            },
            kitchen_luminosity: {
              ...layout.kitchen_luminosity,
              price: layout.kitchen_luminosity.price_percentage,
              update_price: layout.kitchen_luminosity.update_price_percentage,
            },
            next_to_elevator: {
              ...layout.next_to_elevator,
              price: layout.next_to_elevator.price_percentage,
              update_price: layout.next_to_elevator.update_price_percentage,
            },
            noise: {
              ...layout.noise,
              price: layout.noise.price_percentage,
              update_price: layout.noise.update_price_percentage,
            },
            num_of_bathrooms: {
              ...layout.num_of_bathrooms,
              price: layout.num_of_bathrooms.price_percentage,
              update_price: layout.num_of_bathrooms.update_price_percentage,
            },
            permit_regular: {
              ...layout.permit_regular,
              price: layout.permit_regular.price_percentage,
              update_price: layout.permit_regular.update_price_percentage,
            },
            ratio_bedrooms: {
              ...layout.ratio_bedrooms,
              price: layout.ratio_bedrooms.price_percentage,
              update_price: layout.ratio_bedrooms.update_price_percentage,
            },
            ratio_living: {
              ...layout.ratio_living,
              price: layout.ratio_living.price_percentage,
              update_price: layout.ratio_living.update_price_percentage,
            },
            walk_through_room: {
              ...layout.walk_through_room,
              price: layout.walk_through_room.price_percentage,
              update_price: layout.walk_through_room.update_price_percentage,
            },
          },
          sales_plan: {
            price_effect: {
              ...sales_plan.price_effect,
              price: sales_plan.price_effect.price_percentage,
              update_price: sales_plan.price_effect.update_price_percentage,
            },
          },
        });
        break;
      default:
        setData({ analytics, exterior, floor, interior, layout, orientation, sales_plan });
    }
  }, [analytics, exterior, floor, interior, layout, orientation, priceDetailFilter, sales_plan]);

  return (
    <Card
      className='price-breakdown'
      titleExtra={
        data.interior.floor_area.price_psm ? (<Segmented
          style={{ marginLeft: 'auto' }}
          value={priceDetailFilter ?? PriceDetailFilter.PRICE}
          options={[
            {
              value: PriceDetailFilter.PRICE,
              label: isRent ? t('common.rent') : t('common.price'),
            },
            {
              value: PriceDetailFilter.PRICE_PER_SM,
              label: isRent ? t('rentals.rent_per_sm') : t('daily_news.kpi.price_per_sm'),
            },
            {
              value: PriceDetailFilter.PERCENTAGE,
              label: '%',
            },
          ]}
          onChange={(val) => handleChange(val as PriceDetailFilter)}
        />) : null
      }
      title={t('price_list.detail.price_breakdown.title')}
      subtitle={t('price_list.detail.price_breakdown.subtitle')}
    >
      <div className='grid auto-rows-auto gap-10 pb-10'>
        <div className='grid grid-cols-4 gap-10 text-center items-center font-bold'>
          <div className='col-span-2' />
          <div className='flex justify-between'>
            <span>
              {t('price_list.detail.price_breakdown.value', 'Value')}
            </span>
            <span>
              {t('price_list.detail.price_breakdown.price_part', 'Price Part')}
            </span>
          </div>
          <div>{t('price_list.detail.price_breakdown.update', 'Update')}</div>
        </div>
        <Interior data={data.interior} />
        <Exterior data={data.exterior} />
        <Floor data={data.floor} />
        <Orientation data={data.orientation} />
        <Layout data={data.layout} />
        {data.sales_plan.price_effect.value && (
          <SalesPlan data={data.sales_plan} />
        )}
        {/* <Analytics data={data.analytics} /> */}
      </div>
      { showPrediction && priceSums && (
        <section css={styles.footer}>
          <div css={styles.recommendedPrice}>
            <div css={styles.description}>
              {isRent
              ? t('price_list.detail.price_breakdown.recommended_rent', { unit: areaUnit })
              : t('price_list.detail.price_breakdown.recommended_price')}
            </div>
            <div css={styles.pricePositive}>
              {formatCurrency(priceSums.recommendedPrice)}
            </div>
          </div>
          {(priceSums.priceUpdate <= -0.1 || priceSums.priceUpdate >= 0.1) && (
            <div>
              <div css={styles.description}>
                {isRent
                  ? t(
                      'price_list.detail.price_breakdown.rent_update',
                      'Rent update',
                    )
                  : t(
                      'price_list.detail.price_breakdown.price_update',
                      'Price update',
                    )}
              </div>
              {
                priceSums.priceUpdate > 0 && (
                  <div css={styles.pricePositive}>
                    <CaretUpFilled css={styles.chevron} />
                    {`+ ${isPercentage ? `${priceSums.priceUpdate.toFixed(1) ?? 0} %`
                    : formatCurrency(priceSums.priceUpdate)}`}
                  </div>
                )
              }
              {
                priceSums.priceUpdate < -0.1 && (
                  <div css={styles.priceNegative}>
                    <CaretDownFilled css={styles.chevron} /> {`${isPercentage ?
                      `${priceSums.priceUpdate.toFixed(1) ?? 0} %`
                      : formatCurrency(priceSums.priceUpdate)}`}
                  </div>
                )
              }
            </div>
          )}
        </section>
    )}
    </Card>
  );
};
