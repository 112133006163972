import { css } from '@emotion/react/macro';
import { BOLD_FONT_WEIGHT } from '../../../../styles/constants';

export const styles = {
  descriptionContainer: css({
    display: 'grid',
    gridTemplateColumns: 'auto minmax(0, 1fr)',
    gridColumnGap: '4rem',
    marginBottom: '1.2rem',
  }),
  segmentedContainer: css({
    marginBottom: '1.2rem',
  }),
  label: css({ fontWeight: BOLD_FONT_WEIGHT }),
  projectNams: css({ width: '100%' }),
  card: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.ant-card-body': {
      flex: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  popoverContainer: css({ maxWidth: 600 }),
  table: css({
    width: '100%',
  }),
};
