import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Props = {
  className?: string;
};

export const Heading: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}: PropsWithChildren<Props>) => (
  <h1 className={clsx(className, 'text-bmblue text-4xl font-bold')}>
    {children}
  </h1>
);
