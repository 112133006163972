import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChartBarSquareIcon,
  MapIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropResult } from 'react-beautiful-dnd';
import { useStoreActions, useStoreState } from '../hooks';
import { MenuItem, SidebarMenu } from './SidebarMenu';
import { MarketTypeEnum } from '../api/enums';
import { MergedReport } from './market/reports';
import {
  getUpdateFrequencyDates,
  moveItemInArray,
  sortByString,
} from '../utils/utils';
import { Mode } from '../store/types';
import { defaultTableData } from '../store/market';
import { ReportItemActions } from './ReportItemActions';
import { getReportsSidebarOrdering, setReportsSidebarOrdering } from '../api';

export const ProjectsSubmenu: FC = () => {
  const { t } = useTranslation();
  const itemRef = useRef(null);
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const primaryReports = useStoreState(
    (state) => state.filters.overviewData?.market_dashboards,
  );

  const SMReports = useStoreState((state) => state.secondaryMarket.reports);
  const selectedReportName = useStoreState(
    (state) => state.filters.selectedReportName,
  );

  const setMode = useStoreActions((actions) => actions.market.setMode);
  const setSelectedReportName = useStoreActions(
    (actions) => actions.filters.setSelectedReportName,
  );
  const resetPolygons = useStoreActions(
    (actions) => actions.market.resetPolygons,
  );
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const setParamsSM = useStoreActions((actions) => actions.filters.setParamsSM);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const reportsOrder = useStoreState((state) => state.market.reportsOrder);
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};
  const isStandardPlan =
    frequency === 'daily' || frequency === 'weekly' || frequency === null;

  const navigate = useNavigate();
  const location = useLocation();
  const secondaryReports = useMemo(
    () =>
      isStandardPlan
        ? SMReports?.filter(
            (report) => report.reportType === MarketTypeEnum.SECONDARY,
          )
        : [],
    [SMReports, isStandardPlan],
  );

  const rentalReports = useMemo(
    () =>
      isStandardPlan
        ? SMReports?.filter(
            (report) => report.reportType === MarketTypeEnum.RENTAL,
          )
        : [],
    [SMReports, isStandardPlan],
  );
  const setActiveReportId = useStoreActions(
    (actions) => actions.secondaryMarket.setActiveReportId,
  );
  const setReportsOrder = useStoreActions(
    (actions) => actions.market.setReportsOrder,
  );
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const fetchDashboardOverview = useStoreActions(
    (actions) => actions.market.fetchDashboardOverview,
  );
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setTableData = useStoreActions(
    (actions) => actions.market.setTableData,
  );
  const deleteDashboard = useStoreActions(
    (actions) => actions.market.deleteDashboard,
  );
  const fetchDashboardSettings = useStoreActions(
    (actions) => actions.market.fetchDashboardSettings,
  );
  const fetchOverviewData = useStoreActions(
    (actions) => actions.filters.fetchOverviewData,
  );

  const [reportOrderLoaded, setReportOrderLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (reportOrderLoaded) return;

    getReportsSidebarOrdering()
      .then((orderingData) => {
        const reportOrderData = orderingData
          .sort((a, b) => a.ordering - b.ordering)
          .map((r) => r.dashboard_name);
        setReportsOrder(reportOrderData);
        setReportOrderLoaded(true);
      })
      .catch((error) => {
        setReportOrderLoaded(false);
        console.error(error);
      });
  }, [reportOrderLoaded]);

  const mergedReports = useMemo(
    () =>
      [
        ...(primaryReports || []),
        ...(secondaryReports || []),
        ...(rentalReports || []),
      ].reduce((acc, report) => {
        const mergedReport: MergedReport = {
          name: report.name,
          primary: null,
          secondary: null,
          rental: null,
        };
        if (acc.find((v) => v.name === report.name)) {
          return acc;
        }
        const pReport = primaryReports?.find((v) => v.name === report.name);
        const sReport = secondaryReports?.find((v) => v.name === report.name);
        const rReport = rentalReports?.find((v) => v.name === report.name);
        if (pReport) {
          mergedReport.primary = pReport;
        }
        if (sReport) {
          mergedReport.secondary = sReport;
        }
        if (rReport) {
          mergedReport.rental = rReport;
        }
        return [...acc, mergedReport];
      }, [] as MergedReport[]) ?? [],
    [primaryReports, secondaryReports, rentalReports],
  );

  const handleReportClick = useCallback(
    (report: MergedReport) => (): void => {
      if (location.pathname !== '/market/reports') {
        navigate('/market/reports');
      }
      const { primary, secondary, rental, name } = report;
      const validDates =
        primary?.dates &&
        getUpdateFrequencyDates(primary.dates.sort(sortByString), frequency);
      const primaryInitialDate =
        validDates && validDates[validDates.length - 1];

      setMode(Mode.READ);
      resetPolygons();
      if (primary && (!primary.dates || primary.dates.length === 0)) {
        fetchOverviewData();
        message.error(
          t('market.error', 'Data is not ready yet, try again later.'),
        );
        return;
      }

      setSelectedReportName(name);
      if (primaryInitialDate && primary) {
        setParamsPM({
          dashboardId: primary.id,
          date: primaryInitialDate,
          name,
        });
        fetchDashboardSettings(paramsPM);
      } else {
        setParamsPM(null);
      }

      const reportTypes = [
        ...(primary ? [MarketTypeEnum.PRIMARY] : []),
        ...(secondary ? [MarketTypeEnum.SECONDARY] : []),
        ...(rental ? [MarketTypeEnum.RENTAL] : []),
      ];

      if (secondary?.reportId || rental?.reportId) {
        setParamsSM({
          reportId: secondary?.reportId || rental?.reportId || '',
          name,
          reportTypes,
          date: paramsSM?.date || new Date().toISOString().split('T')[0],
        });
        setActiveReportId(secondary?.reportId || rental?.reportId || '');
      } else {
        setParamsSM(null);
      }

      if (location.pathname === '/market/projects') return;

      if (reportTypes.includes(MarketTypeEnum.PRIMARY)) {
        navigate('/market/reports');
      } else if (reportTypes.includes(MarketTypeEnum.SECONDARY)) {
        navigate('/market/reports/secondary');
      } else if (reportTypes.includes(MarketTypeEnum.RENTAL)) {
        navigate('/market/reports/rentals');
      }
    },
    [
      location.pathname,
      frequency,
      setMode,
      resetPolygons,
      setSelectedReportName,
      navigate,
      fetchOverviewData,
      t,
      setParamsPM,
      fetchDashboardSettings,
      paramsPM,
      setParamsSM,
      paramsSM?.date,
      setActiveReportId,
    ],
  );

  const handleReportEditClick = useCallback(
    (report: MergedReport) => (event: MouseEvent) => {
      event.stopPropagation();
      navigate('/market/projects');

      const { primary, secondary, rental, name } = report;
      const validDates =
        primary?.dates &&
        getUpdateFrequencyDates(primary.dates.sort(sortByString), frequency);
      const primaryInitialDate =
        validDates && validDates[validDates.length - 1];

      setSelectedReportName(name);
      resetPolygons();
      let error = false;
      if (primaryInitialDate && primary) {
        setParamsPM({
          dashboardId: primary.id,
          date: primaryInitialDate,
          name,
        });
        fetchDashboardOverview({
          dashboardId: primary.id,
          date: primaryInitialDate,
        });
        setPrimaryParameters({
          included_project_ids: [],
          remaining_project_ids: [],
          dashboard_name: name,
          geometry: null,
          include_new_projects: true,
          markets: [MarketTypeEnum.PRIMARY],
        });
        setTableData({ ...defaultTableData, filteredData: projects });
      }
      if (secondary || rental) {
        const types: MarketTypeEnum[] = [];
        if (secondary) {
          types.push(MarketTypeEnum.SECONDARY);
        } else {
          types.push(MarketTypeEnum.RENTAL);
        }
        setParamsSM({
          reportId: secondary?.reportId || rental?.reportId || '',
          name: secondary?.name || rental?.name || '',
          reportTypes: types,
        });
      }
      if ((primary || secondary) && !error) {
        setMode(Mode.EDITSTART);
      } else {
        error = true;
        message.error(
          t('market.error', 'Data is not ready yet, try again later.'),
        );
      }
    },
    [
      t,
      setParamsPM,
      fetchDashboardOverview,
      setPrimaryParameters,
      setTableData,
      projects,
      setMode,
      resetPolygons,
      setSelectedReportName,
      frequency,
      setParamsSM,
      navigate,
    ],
  );

  const handleReportDeleteClick = useCallback(
    (report: MergedReport) => (event?: MouseEvent) => {
      if (event) {
        event.stopPropagation();
        const { primary, secondary, rental } = report;
        deleteDashboard({
          primaryId: primary?.id,
          secondaryId: secondary?.reportId,
          rentId: rental?.reportId,
        });
        setSelectedReportName('');
      }
    },
    [deleteDashboard, setSelectedReportName],
  );

  const marketMode = useStoreState((state) => state.market.mode);

  const handleAddClick = useCallback((): void => {
    resetPolygons();
    if (marketMode !== Mode.EDIT) {
      setMode(Mode.START);
    }
    if (location.pathname !== '/market/projects') {
      navigate('/market/projects');
    }
  }, [marketMode, setMode, resetPolygons, location, navigate]);

  const addNewReportItem: MenuItem = {
    type: 'button',
    key: 'add-new-report',
    label: t('market.projects.add_report_form.title', 'Add a report'),
    onClick: handleAddClick,
    icon: <PlusIcon className='stroke-2' />,
    class: 'text-gray-500',
    dataCy: 'add-new-report-btn',
  };

  const loadingItem: MenuItem = {
    type: 'button',
    key: 'loading',
    label: t('market.projects.loading', 'Loading...'),
    class: 'text-gray-500',
  };

  const orderedReports = useMemo(() => {
    if (reportsOrder) {
      const ordered = reportsOrder
        .map((reportName) => mergedReports.find((r) => r.name === reportName))
        .filter((r) => Boolean(r)) as MergedReport[];
      const unordered = mergedReports.filter(
        (r) => !ordered.find((o) => o.name === r.name),
      );
      const unorderedDefault = unordered.filter((r) => r.primary?.default);
      const unorderedOther = unordered.filter((r) => !r.primary?.default);

      return [...unorderedDefault, ...ordered, ...unorderedOther];
    }
    return mergedReports;
  }, [reportsOrder, mergedReports]);

  const items: MenuItem[] = useMemo(
    () =>
      orderedReports.map((report, idx) => ({
        type: 'button',
        key: report.name,
        label: report.name,
        sortIdx: idx,
        onClick: handleReportClick(report),
        icon: report.primary?.default ? (
          <MapIcon className='stroke-2' />
        ) : (
          <ChartBarSquareIcon className='stroke-2' />
        ),
        iconTooltip: report.primary?.default
          ? t('market.reports.default_report', 'Default Report')
          : t('market.reports.users_report', "User's Report"),
        actionsMenu: !report.primary?.default ? (
          <div ref={itemRef}>
            <ReportItemActions
              onEdit={handleReportEditClick(report)}
              onRemove={handleReportDeleteClick(report)}
              itemRef={itemRef}
            />
          </div>
        ) : undefined,
        inactiveClass: 'font-normal',
      })) ?? [],
    [
      t,
      orderedReports,
      handleReportClick,
      handleReportEditClick,
      handleReportDeleteClick,
    ],
  );

  const isItemActive = useCallback(
    (item: MenuItem) => {
      if (location.pathname !== '/market/reports') {
        return false;
      }
      if (item.type === 'button') {
        return item.label === selectedReportName;
      }
      return false;
    },
    [selectedReportName, location],
  );

  const onDragEndHandler = useCallback(
    (result: DropResult) => {
      const sourceIdx = result.source.index;
      const destinationIdx = result.destination?.index;

      if (destinationIdx === undefined) {
        return;
      }

      const oldOrder = orderedReports.map((r) => r.name);

      const newOrder = moveItemInArray(oldOrder, sourceIdx, destinationIdx);
      const newOrderData = Object.fromEntries(
        newOrder.map((name, idx) => [name, idx]),
      );

      setReportsOrder(newOrder);
      setReportsSidebarOrdering({ order: newOrderData }).catch((error) => {
        console.error(error);
        setReportsOrder(oldOrder);
      });
    },
    [orderedReports],
  );

  return (
    <div className='pl-4'>
      <SidebarMenu
        items={reportOrderLoaded ? [addNewReportItem, ...items] : [loadingItem]}
        itemSize='small'
        isItemActive={isItemActive}
        onDragEnd={onDragEndHandler}
      />
    </div>
  );
};
