import { FC, useEffect } from 'react';
import { useStoreRehydrated } from 'easy-peasy';
import { useStoreActions } from '../hooks';

export const RehydrationLoading: FC = () => {
  const setGlobalLoading = useStoreActions(
    (actions) => actions.global.setGlobalLoading,
  );
  const isRehydrated = useStoreRehydrated();

  useEffect(() => {
    setGlobalLoading(!isRehydrated);
  }, [isRehydrated, setGlobalLoading]);

  return null;
};
