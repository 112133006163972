// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { Availability } from '../../../../store/types';
import { MarketShareSaleSpeedChartData } from '.';
import { useStoreState } from '../../../../hooks';

const MAX_CHAR_LENGTH = 20;
interface Props {
  data: (MarketShareSaleSpeedChartData)[];
  availability: Availability;
}

export const MarketShareChart: FC<Props> = ({
  data,
  availability,
}) => {
  const { displayValues } = useStoreState((state) => state.filters);
  const config: ColumnConfig = getColumnOptions({
    data: data.sort((a, b) => b.count - a.count),
    xField: 'district_name',
    yField: 'count',
    color: availability === Availability.SOLD ? SOLD : AVAILABLE,
    legend: false,
    xAxis: {
      label: {
        formatter: (text) =>
          text.length > MAX_CHAR_LENGTH
            ? `${text.substring(0, MAX_CHAR_LENGTH - 2)}..`
            : text,
      },
    },
    tooltip: {
      customContent: (title, items) => {
        let htmlStr = '<div class="bm-tooltip"><div class="bmt-items">';
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${title}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    slider: {
      start: 0,
      end:
        availability === Availability.AVAILABLE
          ? 19 / data.length
          : 50 / data.length,
    },
  });
  const chartConfig = {
    ...config,
    label: displayValues ? {
      // 可手动配置 label 数据标签位置
      position: 'top',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#000000',
        opacity: 0.6,
      },
    } : null,
    appendPadding: 20,
  };

  return data.length > 0 ? (
    <Column {...chartConfig} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
