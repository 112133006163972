import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { useStoreActions, useStoreState } from '../../../hooks';
import { formatDailyNewsObject } from '../../../utils/utils';
import { formatDailyNewsObjectHelper } from '../DailyNewsTrans';
import { NewsList } from '../NewsList';
import { SaleSpeedPopver } from './SaleSpeedPopover';
import { MarketSharePopover } from './MarketSharePopover';
import { SubareaEnum } from '../../../api/enums';

const todayDate = new Date();
const todayYear = todayDate.getFullYear();
const todayMonth = todayDate.getMonth() + 1;

const today = `${todayYear}-${todayMonth > 9 ? todayMonth : `0${todayMonth}`}`;

type Item = {
  variables: Record<string, string>[];
  selectedDate?: string;
};

export const MarketNews: FC = () => {
  const { t, i18n: { language } } = useTranslation();
  const { data, subarea, partialData } = useStoreState((state) => state.dailyNews);
  const { area } = useStoreState((state) => state.filters);
  const [open, setOpen] = useState(false);
  const [marketShareOpen, setMarketShareOpen] = useState(false);
  const competitors = useStoreState((state) => state.market.dashboardOverviewData?.competitors);
  // const { setSelectedDate } = useStoreActions((action) => action.market);
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleMarketShareClose = (): void => {
    setMarketShareOpen(false);
  };

  // const handleMarketShareOpen = useCallback((item: Item): void => {
  //   setSelectedDate(item?.selectedDate ?? '');
  //   setMarketShareOpen(true);
  // }, [setSelectedDate]);

  const lastMonth = useMemo(() =>
    new Date(new Date().setDate(0)).toLocaleDateString(language, {
      month: 'long',
    },
  ), [language]);

  const handleClick = useCallback((item: Item) => {
    if (projects) {
      setSelectedProjectId(
        Number(projects
        .find(
          (p) =>
            p.project_name === item.variables[0].project_name,
        )?.project_id) ?? null,
      );
    }
  }, [projects, setSelectedProjectId]);

  // const top_sellers_last_month = useMemo(() => {
  //   if (!data?.top_sellers_last_month || !area) return [];
  //   return [
  //     ...formatDailyNewsObjectHelper(Object.keys(data.top_sellers_last_month)
  //     .map((key) => Object.entries(data.top_sellers_last_month![key]).map(([nestedKey, value]) => {
  //         const project = value.find((o) => Object.keys(o)[0] === String(area));
  //         if (project && Object.values(project).length > 0) {
  //           return Object.values(project).map((o) => ({
  //               ...o,
  //               date: nestedKey,
  //               parentDate: key,
  //             }));
  //         }
  //         return [];
  //       })[0][0]).map((d) => ({
  //           date: `${d.parentDate.split('-')[0]}-${d.parentDate.split('-')[1]}-01`,
  //           language,
  //           icon: {
  //             source: 'calendar-dark-blue.png',
  //             color: 'dark_blue',
  //           },
  //           items: [
  //             {
  //               selectedDate: `${d.parentDate.split('-')[0]}-${(Number(d.parentDate.split('-')[1]) - 1) > 9
  //               ? Number(d.parentDate.split('-')[1]) - 1 : `0${Number(d.parentDate.split('-')[1]) - 1}`}`,
  //               project_id: competitors?.find(
  //                 (comp) => comp.name === Object.values(d)[0],
  //               )?.id ?? -1,
  //               variables: [
  //                   ...Object.entries(d).map(([key, value], i) => ({
  //                     [`project_name_${i}`]: key,
  //                     [`project_count_${i}`]: value.toString(),
  //                     month_name: new Date(d.date).toLocaleString(language, {
  //                       month: 'long',
  //                     }),
  //                   })),
  //               ],
  //               onClick: (item: Item) => {
  //                 handleMarketShareOpen(item);
  //               },
  //               i18key: 'top_sellers',
  //             },
  //           ],
  //     })), t),
  //   ];
  // }, [data?.top_sellers_last_month, t, area, language, competitors, handleMarketShareOpen]);

  const added_units = useMemo(() => {
    if (!data?.added_units || !area) return [];
    return [
      ...formatDailyNewsObjectHelper(
        Object.entries(data.added_units).map(([key, value]) => {
          const val = value.filter((o) => o[area] && o[area].length > 0);
          return {
            date: key,
            language,
            icon: {
              source: 'home-dark-blue.png',
              color: 'light_blue',
            },
            items: val.length > 0 ? val[0][area].map((o) => ({
              i18key: 'added_units',
            project_id: competitors?.find((comp) => comp.name === o.project_name)?.id ?? -1,
              variables: [{
                count: String(o.difference),
                project_name: o.project_name,
              }],
              onClick: handleClick,
            })) : [],
          };
        }).filter((o) => o.items.length > 0), t),
    ];
  }, [area, competitors, data?.added_units, handleClick, language, t]);

  const new_sold_out_projects = useMemo(() => {
    if (!data?.new_sold_out_projects) return [];
    return [
      ...formatDailyNewsObject(
        data.new_sold_out_projects!,
        'sold_out_project',
        language,
        t,
        {
          source: 'wallet-dark-grey.png',
          color: 'grey',
        },
        handleClick,
      ),
    ];
  }, [data?.new_sold_out_projects, handleClick, language, t]);

  const new_projects = useMemo(() => {
    if (!data?.new_projects) return [];
    return [
      ...formatDailyNewsObject(
        data.new_projects!,
        'new_project',
        language,
        t,
        {
          source: 'price-tag-dark-blue.png',
          color: 'light_blue',
        },
        handleClick,
      ),
    ];
  }, [data?.new_projects, handleClick, language, t]);

  const graph_data = useMemo(() => {
    if (!data?.graph_data) return [];
    return [
      ...formatDailyNewsObjectHelper(
        [{
          date: today,
          language,
          icon: {
            color: 'dark_blue',
            source: 'trophy-dark-blue.png',
          },
          items: [
            {
            i18key: 'sold_units_number',
            project_id: 0,
            variables: [{
              month_name: lastMonth,
              sold_units_count: data.graph_data!.sold_last_month.toString(),
            }],
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick: (_item: Item) => {
              handleOpen();
            },
          }],
        }],
        t,
      ),
    ];
  }, [data?.graph_data, language, lastMonth, t]);

  const discounts = useMemo(() => {
    if (!data?.discounts || !competitors) return [];
    return [
      ...formatDailyNewsObjectHelper(
        data.discounts!.map((d) => ({
            date: d.date,
            language,
            icon: {
              source: 'price-tag-grey.png',
              color: 'grey',
            },
            items: [{
              i18key: 'discounts',
              project_id: d.project_id,
              variables: [{
                project_id: String(d.project_id),
                project_name: competitors?.find((comp) => comp.id === d.project_id)?.name ?? '',
                discount_text: d.discount_text,
              }],
              onClick: handleClick,
            }],
          })), t),
    ];
  }, [competitors, data?.discounts, handleClick, language, t]);

  const newsData = useMemo(() => {
    if (
      !data
      || !area
      || !projects
    ) return [];
    return [
      // ...top_sellers_last_month,
      ...added_units,
      ...new_sold_out_projects,
      ...new_projects,
      ...graph_data,
      ...discounts,
    ].filter((d) => {
      if (!partialData || !partialData.city_ids || !partialData.outskirts_ids) return true;
      if (subarea === SubareaEnum.CITY) {
        return partialData.city_ids.includes(d.project_id);
      }
      if (subarea === SubareaEnum.OUTSKIRT) {
        return partialData.outskirts_ids.includes(d.project_id);
      }
      return true;
    });
  }, [
    data,
    area,
    projects,
    // top_sellers_last_month,
    discounts,
    new_projects,
    graph_data,
    added_units,
    new_sold_out_projects,
    partialData,
    subarea,
  ]);

  return (
    <KPI
      title={
        <>
        <div className='daily_news_kpi_competitors_news title'>
          <img alt='ruler' src='assets/ruler-dark-blue.png' />
        </div>
        {t('daily_news.kpi.market_news.title')}
        </>
      }
      subtitle={t('daily_news.kpi.market_news.subtitle')}
      type='vertical'
    >
      <NewsList
        data={newsData}
      />
      <MarketSharePopover
        isOpen={marketShareOpen}
        handleClose={handleMarketShareClose}
      />
      <SaleSpeedPopver isOpen={open} handleClose={handleClose} />
    </KPI>
  );
};
