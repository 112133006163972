import { FC } from 'react';

type IconProps = {
  source: string;
  color: string;
}

export const Icon: FC<IconProps> = ({ source, color }) => (
    <div className={`daily_news_icon ${color}`}>
      <img alt='icon' src={`assets/${source}`} />
    </div>
);
