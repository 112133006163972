/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { Component, ErrorInfo, ReactNode } from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { styles } from './Card/styles';
import { BLUE_5, BOLD_FONT_WEIGHT } from '../styles/constants';
import { Card } from './Card';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='page'>
      <div className='content'>
        <Card css={styles.card}>
          <Result
            status='warning'
            title={
              <div
                style={{
                  color: BLUE_5,
                  fontWeight: BOLD_FONT_WEIGHT,
                  fontSize: '2.4rem',
                }}
              >
                {t('error.generic')}
              </div>
            }
          />
        </Card>
      </div>
    </div>
  );
};

export class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
