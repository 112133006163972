import { Col, Modal, Row } from 'antd';
import { FC, useRef, useState, useMemo, useEffect, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Chevron } from '../../../secondaryMarket/blocks/Overview/Chevron';
import { ProjectDetailTable } from '../../../map/ProjectDetail/Content/ProjectDetailTable';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { styles } from './styles';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { Availability } from '../../../../store/types';
import { fetchDetailSaleSpeedData } from '../../../../api';
import {
  getReportExportFileName,
} from '../../../../utils/utils';
import { Card } from '../../../Card';
import { SaleSpeedDetailData } from '../../../../api/types';
import { DemandCardPopover } from './DemandCardPopover';

export interface Data {
  date: string;
  value: number;
  series: string | number;
  layout: string | null;
  availability: string;
  count?: number;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  columnData: Data;
  showPrivate: boolean;
  showB2B: boolean;
}

export const DemandByMonthModal: FC<Props> = ({ open, setOpen, columnData, showPrivate, showB2B }) => {
  const [isLoading, setLoading] = useState(true);
  const { formatCurrency, calculateVatPrice, formatAreaUnits } =
    useRegionFormatting();
  const { t } = useTranslation();
  const pngRef = useRef(null);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const [data, setData] = useState<SaleSpeedDetailData>();

  useEffect(() => {
    setData(undefined);
    if (paramsPM && columnData) {
      setLoading(true);

      // prepare what types to get
      const types = ['public'];
      if (showPrivate) {
        types.push('private');
      }
      if (showB2B) {
        types.push('b2b');
      }

      fetchDetailSaleSpeedData(
        paramsPM?.dashboardId, paramsPM?.date, columnData.date, columnData.availability, types,
      ).then((response) => {
        setData(response.data);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [columnData, paramsPM, showPrivate, showB2B]);

  const {
    tableData,
    priceListData,
  } = useMemo(() => ({
    tableData: data?.metrics?.map((metric) => ({
      id: '',
      availability: Availability.SOLD,
      available: metric.total,
      baths: [0],
      exterior_sm: null,
      equivalent_area: null,
      garages: [0],
      interior_sm: metric.average_interior,
      layout: metric.layout.toLowerCase(),
      nonNullInteriorSM: 1,
      nonNullPrice: 1,
      nonNullPricePerSmCount: 1,
      price: calculateVatPrice(
        metric.average_price,
        120,
      ),
      price_per_sm: calculateVatPrice(
        metric.average_price_per_sm,
        120,
      ),
      original_price: 0,
      building: '',
      category: '',
      orientation: '',
      DOM: 0,
      first_seen: '',
      url: '',
    })) || [],
    priceListData: data?.metrics?.map((metric) => ({
      id: '',
      availability: Availability.SOLD,
      available: metric.total,
      baths: 0,
      exterior_area: null,
      equivalent_area: null,
      floor: null,
      floor_area: metric.average_interior,
      garages: 0,
      layout: metric.layout.toLowerCase(),
      price: calculateVatPrice(
        metric.average_price,
        120,
      ),
      price_per_sm: calculateVatPrice(
        metric.average_price_per_sm,
        120,
      ),
      original_price: 0,
      total_area: 0,
      building: '',
      category: '',
      orientation: '',
      DOM: 0,
      first_seen: '',
      url: '',
    })) || [],
  }), [data,
    calculateVatPrice,
  ]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('daily_news.kpi.demand'));

  return (
    <Modal
      width={700}
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      <div ref={pngRef} style={{ height: '100%' }}>
        <Card
          css={styles.modalCard}
          loading={isLoading}
          dataMissing={data?.metrics === undefined && !isLoading}
          dataMissingMessage={t('market.reports.sale_speed.detail.data_unavailable', 'Detailed data for this date are not available.')}
        >
          <DemandCardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} exportData={data?.units} />
          <Row className='reports_overview_header'>
            <Col xs={6}>
              <Row justify='center' className='title bold'>
                {t('daily_news.kpi.demand')}
              </Row>
              <Row justify='center' className='icon'>
                <div className='demand daily_news_kpi_icon'>
                  <img src='/assets/wallet_icon_grey_compressed.png' alt='wallet' />
                </div>
              </Row>
            </Col>
            <Col xs={9}>
              <Row justify='center'>
                {t('daily_news.kpi.sold_units')}
              </Row>
              <Row justify='center' className='title big'>
                {data?.total_units}
              </Row>
              <Row justify='center' className='subtitle'>
                {data?.growth_total && <Chevron number={data?.growth_total} />}
              </Row>
            </Col>
            <Col xs={9}>
              <Row justify='center'>
                {t('daily_news.kpi.price_per_sm')}
              </Row>
              <Row justify='center' className='title big'>
                {formatCurrency(
                  calculateVatPrice(data?.average_price_per_sm || null, 120),
                  false)}
              </Row>
              <Row justify='center' className='subtitle'>
                {data?.growth_price_per_sm && <Chevron number={data?.growth_price_per_sm || 0} />}
              </Row>
            </Col>
          </Row>
          <div>
            <ProjectDetailTable
              allowSorting
              loading={isLoading}
              priceList={priceListData}
              dataSource={tableData}
              customSummary={[
                {
                  key: 'price',
                  value: formatCurrency(
                    calculateVatPrice(data?.average_price || null, 120),
                  ),
                },
                {
                  key: 'price_per_sm',
                  value: formatCurrency(
                    calculateVatPrice(data?.average_price_per_sm || null, 120),
                  ),
                },
                {
                  key: 'available',
                  value: data?.total_units || null,
                },
                {
                  key: 'interior_sm',
                  value: formatAreaUnits(data?.average_interior || null),
                },
              ]}
            />
          </div>
        </Card>
      </div>
    </Modal>
  );
};
