/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisappearedUnitsAverageSmResponseSchema
 */
export interface DisappearedUnitsAverageSmResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof DisappearedUnitsAverageSmResponseSchema
     */
    disappearedUnitsAverageSm: number | null;
}

/**
 * Check if a given object implements the DisappearedUnitsAverageSmResponseSchema interface.
 */
export function instanceOfDisappearedUnitsAverageSmResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disappearedUnitsAverageSm" in value;

    return isInstance;
}

export function DisappearedUnitsAverageSmResponseSchemaFromJSON(json: any): DisappearedUnitsAverageSmResponseSchema {
    return DisappearedUnitsAverageSmResponseSchemaFromJSONTyped(json, false);
}

export function DisappearedUnitsAverageSmResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsAverageSmResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disappearedUnitsAverageSm': json['disappeared_units_average_sm'],
    };
}

export function DisappearedUnitsAverageSmResponseSchemaToJSON(value?: DisappearedUnitsAverageSmResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disappeared_units_average_sm': value.disappearedUnitsAverageSm,
    };
}

