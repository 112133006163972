import { FC } from 'react';
import { PieChartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { useStoreState, useRegionFormatting } from '../../../hooks';
import { formatDifference } from '../../../utils';
import { ProjectType } from '../../../store/types';

export const PricePerSM: FC = () => {
  const { diff, price_per_sm, revenue } = useStoreState(
    (state) =>
      state.dashboard.data?.top_metrics.price_per_sm ?? {
        diff: 0,
        price_per_sm: 0,
        revenue: 0,
      },
  );
  const { price_per_sm: price_per_sm_recommended } = useStoreState(
    (state) =>
      state.dashboard.data?.top_metrics.price_per_sm_recommended ?? {
        price_per_sm: 0,
        revenue: 0,
      },
  );
  const { formatCurrency, areaUnit, calculateVatPrice } = useRegionFormatting();
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  return (
    <KPI
      title={
        isRent
          ? t('dashboard.kpi.current_rent')
          : t('common.price_per_unit', 'Price per {{unit}}', {
              unit: areaUnit,
            })
      }
      secondary={{
        secondaryTitle: isRent
          ? t('dashboard.kpi.recommended_rent')
          : t('dashboard.kpi.revenue'),
        secondaryValue: formatCurrency(
          calculateVatPrice(isRent ? price_per_sm_recommended : revenue, 120),
          false,
          !isRent,
        ),
        secondaryLabel: isRent
          ? t('dashboard.kpi.available_per_unit', {
              unit: areaUnit,
            })
          : t('dashboard.kpi.total_available'),
      }}
      icon={<PieChartOutlined />}
      value={formatCurrency(calculateVatPrice(price_per_sm, 120))}
      diff={[
        {
          value: formatDifference(diff, true),
          label: t('dashboard.kpi.from_last_month'),
        },
      ]}
      label={
        isRent
          ? t('dashboard.kpi.available_per_unit', {
              unit: areaUnit,
            })
          : t('dashboard.kpi.average_available')
      }
    />
  );
};
