import { FC, ReactNode } from 'react';
import { Column, ColumnConfig, AreaConfig, Area } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  AVAILABLE,
} from '../../../../styles/constants';
import { getLineOptions } from '../../../../utils/defaultChartConfig';
import { FilterEnum } from '.';
import { SupplyHistoryDataResponse, SupplyHistoryNewData, SupplyHistoryOverallData } from '../../../../api/types';
import { useStoreState } from '../../../../hooks';

interface Props {
  filter: FilterEnum,
  data: SupplyHistoryDataResponse,
}

const getChart = (filter: FilterEnum, config: AreaConfig | ColumnConfig): ReactNode => {
  if (filter === FilterEnum.NEW) {
    return <Column {...config as ColumnConfig} />;
  }
  return <Area {...config as AreaConfig} />;
};

export const SupplyHistoryChart: FC<Props> = ({ data, filter }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { displayValues } = useStoreState((state) => state.filters);

  const overallLabel = displayValues ? {
    style: {
      color: '#000',
    },
    offsetX: 0.5,
    rotate: -Math.PI / 3,
  } : undefined;

  const newLabel = displayValues ? {
    style: {
      color: '#000',
    },
    position: 'top' as const,
  } : undefined;

  const config: AreaConfig = getLineOptions({
    smooth: true,
    data: filter === FilterEnum.OVERALL ? data.overall : data.new,
    color: AVAILABLE,
    xField: 'date',
    yField: filter === FilterEnum.OVERALL ? 'available' : 'new',
    label: filter === FilterEnum.OVERALL ? overallLabel : newLabel,
    tooltip: {
      formatter: (datum) => {
        if (filter === FilterEnum.OVERALL) {
          const value = datum as SupplyHistoryOverallData;
          return {
            name: value.date,
            value: value.available ?? '-',
          };
        }
        const value = datum as SupplyHistoryNewData;
        return {
          name: value.date,
          value: value.new ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${t('charts.state.available')}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    marginRatio: 0.3,
    columnStyle: {
      radius: [7, 7, 0, 0],
      color: AVAILABLE,
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            year: 'numeric',
          });
        },
        autoHide: false,
        autoRotate: true,
      },
      tickMethod: 'time',
    },
    yAxis: {
      max: filter !== FilterEnum.OVERALL ? Math.max(...data.new.map((d) => d.new)) * 1.1 : undefined,
      label: {
        formatter: (value) => value,
      },
    },
    legend: {
      itemName: {
        formatter: () => t('charts.state.available'),
      },
    },
    stroke: 'black',
    fill: 'red',
  });

  return data ? (
    <>{getChart(filter, config)}</>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
