import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';
import styles from './PriceUpdateNotifications.module.css';
import { Paths } from '../../routes/Paths';

type Props = {
  projectName: string
  userSeen: boolean
  handleHasSeen: () => void
};

export const NotificationCard: FC<Props> = ({ projectName, userSeen, handleHasSeen }) => {
  const { t } = useTranslation();
  const [hasSeen, setHasSeen] = useState(userSeen);

  useEffect(() => {
    if (userSeen === true) {
      setHasSeen(true);
    }
  }, [userSeen]);

  return (
    hasSeen === false ? (
      <div className={styles.notification}>
        <XMarkIcon className={styles.close} onClick={() => setHasSeen(true)} />
        <Link to={Paths.PRICE_UPDATE} className={styles.message} onClick={handleHasSeen}>
        <span className={styles.title}>{projectName}</span>
        <p className={styles.text}>: {t('price_update.notification.message', 'New price update is available.')}</p>
        </Link>
      </div>
    ) : null
  );
};
