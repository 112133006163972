import { Cog8ToothIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportActions } from '../hooks/useReportActions';

export const ReportItemActions = React.memo(
  ({
    onEdit,
    onRemove,
    itemRef,
  }: {
    onEdit: (event: MouseEvent) => void;
    onRemove: (event: MouseEvent) => void;
    itemRef: React.RefObject<HTMLDivElement>;
  }) => {
    const { t } = useTranslation();
    const { isBottom } = useReportActions(itemRef);

    return (
      <div
        className={`bg-white rounded-3xl text-gray-500 drop-shadow-lg py-4 px-2 font-medium absolute ${
          isBottom ? 'bottom-[3rem]' : 'top-0'
        } right-[-3rem]`}
      >
        <button
          type='button'
          data-cy='report-item-edit'
          onClick={onEdit}
          className='flex w-full py-1 px-2 rounded-xl hover:bg-gray-200 mb-2'
        >
          <Cog8ToothIcon className='w-8 mr-2 stroke-2' /> {t('common.edit')}
        </button>
        <button
          type='button'
          className='flex w-full py-1 px-2 rounded-xl hover:bg-gray-200'
          onClick={onRemove}
        >
          <TrashIcon className='w-8 mr-2 stroke-2' /> {t('common.delete')}
        </button>
      </div>
    );
  },
);
