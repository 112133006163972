/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState, ReactNode, useRef } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { OverallData, SaleSpeedOverallChart } from './SaleSpeedOverallChart';
import { getLastUpdateText, getReportExportFileName } from '../../../../utils/utils';
import { ChartCard } from '../../../ChartCard';
import { Availability } from '../../../../store/types';
import { CardPopover } from '../CardPopover';
import { getSaleSpeedLayoutData, getSaleSpeedOverallData } from '../../../../api/secondary';
import { LayoutData, SaleSpeedLayoutChart } from './SaleSpeedLayoutChart';
import { DisappearedUnitsLayoutsResponseSchema } from '../../../../api/secondaryClient';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

const getChart = (isOverall: boolean, data: (LayoutData | OverallData)[]): ReactNode => {
  if (isOverall) {
    return <SaleSpeedOverallChart data={data as OverallData[]} />;
  }
  return <SaleSpeedLayoutChart data={data as LayoutData[]} />;
};

export const SaleSpeed: FC = () => {
  const [overallData, setOverallData] = useState<OverallData[]>([]);
  const [layoutData, setLayoutData] = useState<LayoutData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const [filterValue, handleChange] = useState(FilterEnum.OVERALL);
  const filterAvailability = useFilter(Availability.SOLD);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const { t } = useTranslation();
  const { sortLayouts } = useRegionFormatting();
  const pngRef = useRef(null);
  const { isRentals } = useMarketDetails();

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      const startDate = date.toISOString().split('T')[0];
      const endDate = paramsSM.date;
      if (filterValue === FilterEnum.OVERALL) {
        getSaleSpeedOverallData({ reportId, startDate, endDate })
          .then((response) => {
            const receivedData = [...response].map(
              (d) => ({
                series: 'sold',
                availability: Availability.SOLD,
                ...d,
              }));
              setOverallData(receivedData);
            })
          .finally(() => {
            setLoading(false);
          });
      } else {
        getSaleSpeedLayoutData({ reportId, startDate, endDate })
          .then((response) => {
            const data: LayoutData[] = [];
            const formattedResponse = response
            .filter((d) => d.disappearedLayout?.length > 0)
            .reduce((acc, d) => {
              const yearAndMonth = d.createdDate.slice(0, 7);
              const existingData = acc.find((existingD) => existingD.createdDate.includes(yearAndMonth));
              if (existingData) {
                const newData = [...acc].filter((existingD) => existingD.createdDate !== yearAndMonth);
                newData.push({
                  createdDate: yearAndMonth,
                  disappearedLayout: existingData.disappearedLayout.map((l) => {
                    const newLayout = d.disappearedLayout.find((dl) => dl.countOfRegularRooms === l.countOfRegularRooms);
                    if (newLayout) {
                      return {
                        countOfRegularRooms: l.countOfRegularRooms,
                        units: l.units + newLayout.units,
                      };
                    }
                    return l;
                  }),
                });
                return newData;
              }
              return [
                ...acc,
                {
                  createdDate: yearAndMonth,
                  disappearedLayout: d.disappearedLayout,
                },
              ];
            }, [] as DisappearedUnitsLayoutsResponseSchema[]);

            formattedResponse.forEach((d) => {
              d.disappearedLayout.forEach((l) => {
                data.push({
                  date: d.createdDate,
                  layout: `layout_${l.countOfRegularRooms}`,
                  count: 0,
                  availability: Availability.SOLD,
                  value: l.units,
                });
              });
            });
            setLayoutData(data);
          }).finally(() => {
            setLoading(false);
          });
      }
    }
  }, [filterValue, reportId, sortLayouts, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.sale_speed.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={isRentals ? t('market.reports.lease_speed.title', 'Lease Speed') : t('market.reports.sale_speed.title', 'Sale Speed')}
      subtitle={`${isRentals
        ? t('market.reports.lease_speed.subtitle')
        : t(
        'market.reports.sale_speed.subtitle',
        'The number of sold units in the last 30 days in the selected projects.',
      )} ${getLastUpdateText(5)}`}
      extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      infoPopoverContent={
        <div css={styles.saleSpeed.popoverContainer}>
          <p>
          {isRentals
            ? t('market.reports.lease_speed.popover.description')
            : t('market.reports.sale_speed.popover.description', "This chart doesn't display reservations as sales regardless of your settings.")}
          </p>
        </div>
      }
      chart={
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : getChart(isOverall, isOverall ? overallData : layoutData)
      }
      controls={
        <>
          <Segmented
            style={{ marginRight: '1.2rem' }}
            value={filterAvailability.filterValue}
            options={[
              {
                value: Availability.SOLD,
                label: t(isRentals ? 'enums.state.leased' : 'enums.state.sold'),
              },
            ]}
            onChange={filterAvailability.handleChange}
          />
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: FilterEnum.LAYOUTS,
                label: t('dashboard.sale_progress.layouts', 'Layouts'),
              },
            ]}
            onChange={(val) => handleChange(val as FilterEnum)}
          />
        </>
      }
    />
    </div>
  );
};
