import { FC, useMemo, useState, useEffect } from 'react';
import { Segmented, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { styles } from '../../ChartCard/styles';
import { Card, CardProps } from '../../Card';
import { styles as customStyles } from '../../Zoom/styles';
import {
  Data,
  SaleSpeedChart,
} from '../../market/blocks/SaleSpeed/SaleSpeedChart';
import { useStoreState, useRegionFormatting } from '../../../hooks';
import { useFilter } from '../../market/blocks/useFilter';
import { SaleSpeedData } from '../../../api/types';
import { Availability, ProjectType } from '../../../store/types';
import { fetchBlockSaleSpeedData } from '../../../api';
import { sortByString, getUniqueValues } from '../../../utils/utils';
import { PricePerSmCalcType } from '../../TopBar/SettingsPopover';

interface Props extends CardProps {
  isOpen: boolean;
  handleClose: () => void;
}

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

export const SaleSpeedPopver: FC<Props> = ({ isOpen, handleClose }) => {
  const [data, setData] = useState<SaleSpeedData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const { area } = useStoreState((state) => state.filters);
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const filterAvailability = useFilter(Availability.SOLD);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { t } = useTranslation();
  const { sortLayouts } = useRegionFormatting();

  useEffect(() => {
    if (paramsPM && area) {
      setLoading(true);
      fetchBlockSaleSpeedData(
        area,
        paramsPM.date,
        competitors,
        pricePerSmCalculation,
      )
        .then((response) => {
          const receivedData = [...response.data].filter(
            (d) => typeof d.availability === 'string',
          );
          const layouts = getUniqueValues(receivedData.map((v) => v.layout));
          const dates = getUniqueValues(receivedData.map((v) => v.date));
          dates.forEach((date) => {
            layouts.forEach((layout) => {
              if (
                receivedData.findIndex(
                  (rd) => rd.date === date && rd.layout === layout,
                ) === -1
              ) {
                receivedData.push({
                  date,
                  layout,
                  project_name: 'current_dashboard',
                  series: 'current_dashboard',
                  count: 0,
                  counts_by_type: {},
                  availability: Availability.AVAILABLE,
                });
              }
            });
          });

          const transformedData = receivedData
            .map(({ layout, series, ...rest }) => ({
              layout: layout ? layout.toLowerCase() : null,
              series:
                !series || series === 'current_dashboard'
                  ? 'current_dashboard'
                  : series,
              ...rest,
            }))
            .sort(
              (
                { date: dateA, layout: layoutA },
                { date: dateB, layout: layoutB },
              ) => {
                if (dateA === dateB) {
                  return sortLayouts(String(layoutA), String(layoutB));
                }
                return sortByString(dateA, dateB);
              },
            );
          setData(transformedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, sortLayouts, area, pricePerSmCalculation]);

  const chartData: Data[] = useMemo(() => {
    switch (filterValue) {
      case FilterEnum.OVERALL:
        return data
          .map((value) => ({
            ...value,
            series:
              value.series === 'current_dashboard'
                ? value.availability
                : value.project_name,
            value: value.count,
          }))
          .reduce<Data[]>((prev, curr) => {
            const index = prev.findIndex(
              (p) =>
                p.date === curr.date &&
                p.series === curr.series &&
                p.availability === curr.availability,
            );
            if (index > -1) {
              const newArr: Data[] = [...prev];
              const newCnt = newArr[index].count ?? 0 + 1;
              const prevValue = newArr[index].value;
              if (prevValue !== null && curr.value !== null) {
                newArr[index].value =
                  (prevValue * (newArr[index].count ?? 0) + curr.value) /
                  newCnt;
              }
              newArr[index].count = newCnt;
              return newArr;
            }
            return [...prev, { ...curr, count: 1 }];
          }, [])
          .filter((d) => d.availability === filterAvailability.filterValue);
      case FilterEnum.LAYOUTS:
        return data
          .filter(
            (d) =>
              d.series === 'current_dashboard' && typeof d.layout === 'string',
          )
          .map((v) => ({ ...v, value: v.count }))
          .filter((d) => d.availability === filterAvailability.filterValue);

      default:
        return [];
    }
  }, [filterValue, data, filterAvailability.filterValue]);

  return isOpen ? (
    <div css={styles.searchContainer}>
      <Modal
        className='bm-modal'
        css={customStyles.modal}
        open={isOpen}
        footer={null}
        onCancel={handleClose}
      >
        <Card css={styles.card}>
          <div css={styles.controlsContainer}>
            <Segmented
              style={{ marginRight: '1.2rem' }}
              value={filterAvailability.filterValue}
              options={[
                {
                  value: Availability.SOLD,
                  label: isRent
                    ? t('enums.state.leased')
                    : t('enums.state.sold'),
                },
                {
                  value: Availability.RESERVED,
                  label: t('enums.state.reserved'),
                },
              ]}
              onChange={filterAvailability.handleChange}
            />
            <Segmented
              value={filterValue}
              options={[
                {
                  value: FilterEnum.OVERALL,
                  label: t('dashboard.sale_progress.overall', 'Overall'),
                },
                {
                  value: FilterEnum.LAYOUTS,
                  label: t('dashboard.sale_progress.layouts', 'Layouts'),
                },
              ]}
              onChange={handleChange}
            />
          </div>
          <div css={styles.chartContainer}>
            {!isLoading && (
              <SaleSpeedChart
                data={chartData}
                isSold={filterAvailability.filterValue === Availability.SOLD}
                isOverall={isOverall}
                showPrivate={false}
                showB2B={false}
              />
            )}
          </div>
        </Card>
      </Modal>
    </div>
  ) : (
    <div> </div>
  );
};
