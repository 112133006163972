import { useState } from 'react';
import * as XLSX from 'xlsx';
import { ZodIssue } from 'zod';
import { getSchema } from '../utils/validator/validationSchema';
import { areIdsUnique } from '../utils/validator/validators';
import { useStoreState } from '.';

export type ValidationError = {
  row: number;
  error: ZodIssue;
};

export type ValidationWarning = {
  row: number;
  warning: ZodIssue;
};

export type StartingPrice = { layout: string; price: number | null };

type UsePriceListUploader = {
  selectedFileNames: string[];
  header: string[] | null;
  errors: ValidationError[];
  warnings: ValidationWarning[];
  handleFileChange: (fileName: string, file: File | null) => Promise<void>;
};

export const usePriceListUploader = (
  isUS: boolean,
  isHouse: boolean,
  setPriceList: (data: any[]) => void,
  setStartingPrices: (prices: StartingPrice[]) => void,
  setFileName: (fileName: string) => void,
): UsePriceListUploader => {
  const { fileName: selectedFileName } = useStoreState((state) => state.onboarding);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>(
    selectedFileName ? [selectedFileName] : [],
  );
  const [header, setHeader] = useState<string[] | null>(null);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [warnings, setWarnings] = useState<ValidationWarning[]>([]);

  const schema = getSchema(isUS, isHouse);

  const handleFileChange = async (
    fileName: string,
    file: File | null,
  ): Promise<void> => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: undefined,
        });

        if (json.length > 0) {
          const headerRow = json[0] as string[];
          const dataRows = json.slice(1) as (string | number | null)[][];

          setHeader(headerRow);

          const rowDataObjects = dataRows.map((row) =>
            Object.fromEntries(
              headerRow.map((key, i) => {
                let value = row[i];
                if (
                  typeof value === 'number' &&
                  key.toLowerCase().includes('date')
                ) {
                  const date = XLSX.SSF.parse_date_code(value);
                  if (date) {
                    value = `${date.m}/${date.d}/${date.y}`;
                  }
                }
                return [key, value];
              }),
            ),
          );

          const normalizedData = rowDataObjects.map((row) =>
            Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key === 'rent' ? 'price' : key,
                value,
              ]),
            ),
          );

          setPriceList(normalizedData);

          const validationErrors: ValidationError[] = [];
          const validationWarnings: ValidationWarning[] = [];
          const rowsData: Record<string, string | number | null>[] = [];
          const layoutPrices: Record<string, number> = {};

          for (let rowIndex = 0; rowIndex < normalizedData.length; rowIndex++) {
            const rowData = normalizedData[rowIndex];
            rowsData.push(rowData);

            const { layout, price } = rowData;
            if (layout) {
              if (layout in layoutPrices && price) {
                layoutPrices[layout] = Math.min(
                  layoutPrices[layout],
                  Number(price),
                );
              } else {
                layoutPrices[layout] = Number(price) ?? 0;
              }
            }

            const parsedData = schema.safeParse(rowData);
            if (!parsedData.success) {
              parsedData.error.issues.forEach((issue) => {
                if (
                  ['id', 'layout', 'floor_area', 'floor', 'floors'].includes(
                    issue.path[0] as string,
                  )
                ) {
                  validationErrors.push({ row: rowIndex + 1, error: issue });
                } else {
                  validationWarnings.push({
                    row: rowIndex + 1,
                    warning: issue,
                  });
                }
              });
            }
          }

          const { issues } = areIdsUnique(rowsData);

          const duplicateIdErrors = issues.map((issue) => ({
            row: -1,
            error: issue,
          }));

          setErrors(validationErrors.concat(duplicateIdErrors));
          setWarnings(validationWarnings);

          const startingPricesArray = Object.entries(layoutPrices).map(
            ([layout, price]) => ({ layout, price }),
          );
          setStartingPrices(startingPricesArray);
        }
      };
      reader.readAsArrayBuffer(file);

      // Set the selected file name and replace any existing file
      setSelectedFileNames([fileName]);
      setFileName(fileName);
    } else {
      // Clear all states if the file is removed
      setHeader(null);
      setErrors([]);
      setWarnings([]);
      setSelectedFileNames([]);
      setFileName('');
      setStartingPrices([]);
    }
  };

  return {
    selectedFileNames,
    header,
    errors,
    warnings,
    handleFileChange,
  };
};
