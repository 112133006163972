/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import { RowData, InfoPopover } from '../../../../../InfoPopover';
import { Materials as MaterialsType } from '../../../../../../api/types';
import { useStoreState } from '../../../../../../hooks';
import { CountryEnum } from '../../../../../../api/enums';

const styles = {
  materials: css({
    textDecoration: 'underline',
  }),
};

interface Props {
  data: MaterialsType;
}

export const Materials: FC<Props> = ({
  data: {
    air_conditioning,
    cooling_ceilings,
    exterior_blinds,
    floors,
    heating,
    overall_quality,
    partition_walls,
    smart_home,
    windows,
  },
}) => {
  const { t } = useTranslation();
  const { profile } = useStoreState((state) => state.user);

  const showAttributes =
    profile?.country === CountryEnum.CZECHIA ||
    profile?.country === CountryEnum.SLOVAKIA;

  const rows: RowData[] = useMemo(
    () => [
      {
        label: t('project.materials_popup.heating.label'),
        value: heating
          ? t(`project.materials_popup.heating.values.${heating}`, heating)
          : '-',
      },
      {
        label: t('project.materials_popup.windows.label'),
        value: windows
          ? t(`project.materials_popup.windows.values.${windows}`, windows)
          : '-',
      },
      {
        label: t('project.materials_popup.floors.label'),
        value: floors
          ? floors
              .split(',')
              .map((floor) =>
                t(
                  `project.materials_popup.floors.values.${floor.trim()}`,
                  floor.trim(),
                ),
              )
              .join(', ')
          : '-',
      },
      {
        label: t('project.materials_popup.cooling'),
        value:
          cooling_ceilings !== null && cooling_ceilings !== undefined
            ? t(`enums.boolean.${cooling_ceilings}`)
            : '-',
      },
      {
        label: t('project.materials_popup.air_conditioning'),
        value:
          air_conditioning !== null && air_conditioning !== undefined
            ? t(`enums.boolean.${air_conditioning}`)
            : '-',
      },
      ...(showAttributes
        ? [
            {
              label: t(
                'project.materials_popup.partition_walls.label',
                'Partition walls',
              ),
              value:
                partition_walls
                  ?.split(', ')
                  ?.map((value) =>
                    t(
                      `project.materials_popup.partition_walls.values.${value}`,
                      value,
                    ),
                  )
                  .join(', ') ?? '-',
            },
          ]
        : []),
      {
        label: t('project.materials_popup.exterior_blinds'),
        value:
          exterior_blinds !== null && exterior_blinds !== undefined
            ? t(`enums.boolean.${exterior_blinds}`)
            : '-',
      },
      {
        label: t('project.materials_popup.smart_home'),
        value:
          smart_home !== null && smart_home !== undefined
            ? t(`enums.boolean.${smart_home}`)
            : '-',
      },
    ],
    [
      air_conditioning,
      cooling_ceilings,
      exterior_blinds,
      floors,
      heating,
      partition_walls,
      smart_home,
      t,
      windows,
    ],
  );
  return (
    <InfoPopover
      label={
        overall_quality === null ? (
          '-'
        ) : (
          <span css={styles.materials}>
            {t(`enums.materials.${overall_quality}`, '-')}
          </span>
        )
      }
      popoverProps={{ rows }}
    />
  );
};
