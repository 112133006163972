import { Button, Tooltip, TooltipProps } from 'antd';
import { FC, ReactNode } from 'react';

type Props = {
  buttonContent: ReactNode;
  onClick?: () => void;
  spinTrigger?: boolean
} & TooltipProps;

export const ButtonWithTooltip: FC<Props> = ({ buttonContent, onClick, spinTrigger = false, ...tooltipProps }) =>
  <Tooltip {...tooltipProps}>
    <Button onClick={onClick} type='link' className={`bm-tooltip-btn ${spinTrigger && 'animate-spin'}`}>
      {buttonContent}
    </Button>
  </Tooltip>;
