import { css } from '@emotion/react';
import { BLUE_5, BOLD_FONT_WEIGHT } from '../../../../styles/constants';

export const styles = {
  card: css({
    height: '100%',
    '.ant-card-body': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  mapContainer: css({
    flex: 'auto',
    margin: '-2.4rem -2.4rem 0 -2.4rem',
    borderRadius: '1.6rem 1.6rem',
    minHeight: '10rem',
  }),
  map: css({
    position: 'relative',
    minHeight: '300px',
    height: '100%',
    width: '100%',
    borderRadius: '1.6rem 1.6rem 0 0',
  }),
  metaContainer: css({ paddingTop: '1.2rem' }),
  metaTitle: css({
    color: BLUE_5,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '2.4rem',
  }),
  hoverContent: css({
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: BOLD_FONT_WEIGHT,
    textAlign: 'center',
  }),
};
