/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { Row, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProjectDetailTable } from '../ProjectDetailTable';
import { aggregatePriceListData } from '../../../../../utils/utils';
import { Description } from './Description';
import { Data } from '../types';
import { FilterEnum } from '../../../../project/useFilter';
import { styles } from '../styles';
import { useReservedData } from '../../../../../hooks/useReservedData';
import { Availability } from '../../../../../store/types';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../../../../hooks';

interface Props {
  data: Data;
}

export const Content: FC<Props> = ({ data }) => {
  const { price_list: priceList } = data;
  const setAvailable = useStoreActions(
    (state) => state.filters.setAvailability,
  );
  const available = useStoreState((state) => state.filters.availability);
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();
  const { sortByLayouts } = useRegionFormatting();

  const handleChange = (state: string | number): void => {
    setAvailable(String(state) as FilterEnum);
  };

  const { tableData, priceListData } = useMemo(() => {
    const mappedPriceList = mapReservedData(priceList);
    const aggregatedTableData = aggregatePriceListData(mappedPriceList);
    return {
      tableData: sortByLayouts(
        aggregatedTableData.filter(
          ({ availability }) =>
            availability ===
            (available === FilterEnum.AVAILABLE
              ? Availability.AVAILABLE
              : Availability.SOLD),
        ),
      ),
      priceListData: mappedPriceList.filter(
        ({ availability }) =>
          availability ===
          (available === FilterEnum.AVAILABLE
            ? Availability.AVAILABLE
            : Availability.SOLD),
      ),
    };
  }, [available, priceList, mapReservedData, sortByLayouts]);
  return (
    <>
      <Description data={data} />
      <Row css={styles.segmentedContainer}>
        <Segmented
          value={available}
          options={[
            {
              value: FilterEnum.AVAILABLE,
              label: t('enums.state.available', 'Available'),
            },
            {
              value: FilterEnum.SOLD,
              label: t('enums.state.sold', 'Sold'),
            },
          ]}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <ProjectDetailTable dataSource={tableData} priceList={priceListData} />
      </Row>
    </>
  );
};
