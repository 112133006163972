import { FC, ReactNode, useMemo, useState } from 'react';
import { Line, LineConfig } from '@ant-design/plots';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { SegmentedValue } from 'antd/lib/segmented';
import { useFlatLayout, useStoreState } from '../../hooks';
import { formatPercent } from '../../utils';
import {
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
  GREY_2,
  GREY_3,
} from '../../styles/constants';
import { ChartCard } from '../ChartCard';
import { getLineOptions } from '../../utils/defaultChartConfig';
import { ProjectType } from '../../store/types';

interface Data {
  layout: string;
  percent: number;
  day: Date;
}
enum FilterEnum {
  LAYOUTS,
  OVERALL,
}

export const SaleProgress: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getLayout } = useFlatLayout();
  const [filterValue, setFilterValue] = useState<SegmentedValue>(
    FilterEnum.OVERALL,
  );
  const progresses = useStoreState(
    (state) => state.dashboard.data?.sale_progress.progresses ?? [],
  );
  const projects = useStoreState(
    (state) => state.filters.overviewData?.projects,
  );
  const projectId = useStoreState((state) => state.filters.projectId);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const selectedProject = projects?.find((p) => p.project_id === projectId);
  const dynamicPricing = selectedProject ? selectedProject.dynamic_pricing : null;
  const sale_history =
    progresses.length === 0 ? [] : progresses[0].sale_history;
  const sale_start_date =
    progresses.length === 0 ? undefined : progresses[0].sale_start_date;
  const layoutData: Data[] = sale_history.flatMap(({ time, ...layouts }) => {
    const ret = [];
    const date = sale_start_date ? new Date(sale_start_date) : new Date();
    date.setDate(date.getDate() + time);
    const entries = Object.entries(layouts).sort(([ak], [bk]) => {
      if (ak === bk) {
        return 0;
      }
      if (ak < bk) return -1;
      return 1;
    });
    for (const [k, v] of entries) {
      if (k !== 'all') {
        ret.push({
          layout: k,
          percent: v,
          day: date,
        });
      }
    }
    return ret;
  });
  const saleHistoryNotNull = !!sale_history.find((sh) => sh.all !== 0);

  const overallData: Data[] = sale_history.map(({ time, ...layouts }) => {
    const date = sale_start_date ? new Date(sale_start_date) : new Date();
    date.setDate(date.getDate() + Number(time));
    return {
      layout: 'overall',
      percent: layouts.all,
      day: date,
    };
  });

  const data = useMemo(() => {
    switch (filterValue) {
      case FilterEnum.LAYOUTS:
        return layoutData;
      case FilterEnum.OVERALL:
        return overallData;
      default:
        return [];
    }
  }, [filterValue, layoutData, overallData]);

  const config: LineConfig = getLineOptions({
    data,
    color: [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1],
    xField: 'day',
    yField: 'percent',
    seriesField: 'layout',
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: getLayout(value.layout),
          value: formatPercent(value.percent / 100),
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),

      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
          });
        },
      },
      grid: null,
      tickMethod: 'time',
    },
    yAxis: {
      min: 0,
      max: 100,
      label: {
        formatter: (value) => formatPercent(Number(value) / 100),
      },
    },
    legend: {
      itemName: {
        formatter: (value) => getLayout(value),
      },
    },
  });

  const handleChange = (value: SegmentedValue): void => {
    setFilterValue(value);
  };

  const filterComponent: ReactNode = (
    <Segmented
      value={filterValue}
      options={[
        {
          value: FilterEnum.OVERALL,
          label: t('dashboard.sale_progress.overall'),
        },
        {
          value: FilterEnum.LAYOUTS,
          label: t('dashboard.sale_progress.layouts'),
        },
      ]}
      onChange={handleChange}
    />
  );

  const dataMissingMessage = isRent
  ? t('dashboard.rent_progress.data_missing', 'The lease progress is not available for one-time rent recommendations.')
  : t('dashboard.sale_progress.data_missing', 'The sale history chart is not available for static pricing.');

  return (
    <div className='sale-progress'>
      <ChartCard
        title={isRent ? t('dashboard.rent_progress.title') : t('dashboard.sale_progress.title')}
        titleExtra={filterComponent}
        extra={filterComponent}
        subtitle={isRent ? t('dashboard.rent_progress.subtitle') : t('dashboard.sale_progress.subtitle')}
        chart={dynamicPricing || (dynamicPricing && saleHistoryNotNull) ? <Line {...config} /> : null}
        dataMissingMessage={
            dynamicPricing === true || dynamicPricing === false
              ? t('dashboard.sale_progress.data_unavailable', 'The sale history chart is not available yet.')
              : dataMissingMessage
        }
      />
    </div>
  );
};
