/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios, { AxiosInstance } from 'axios';
import { getAccessToken } from '../utils';
import { CreateSecondaryDashboardParameters } from './types';
import { AvailableUnitsLayoutResponseSchemaWithTotals, Configuration, MultiPolygonalSchema, ReportsApi, BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum, StatisticsApi, TerritoriesApi, DisappearedUnitsPriceHistoryLayoutResponseSchemav2, DevelopmentApi, DisappearedUnitsLayoutResponseSchemaWithTotals } from './secondaryClient';
import { AvailableUnits, AvailableUnitsAveragePrice, AvailableUnitsAverageSm, AvailableUnitsPricePerSm, DisappearedUnits, DisappearedUnitsAveragePrice, DisappearedUnitsAverageSm, DisappearedUnitsPricePerSm, SecondaryReport } from '../store/secondary';

const token = getAccessToken();

class CustomConfig extends Configuration {
  get basePath(): string {
    return process.env.REACT_APP_SECONDARY_API_URL_BASE!;
  }

  get headers() {
    return {
      'X-Cognito-Access-Token': getAccessToken() as string,
    };
  }
}

const customConfig = new CustomConfig();

export const reportsApi = new ReportsApi(customConfig);
export const statisticsApi = new StatisticsApi(customConfig);
export const territoriesApi = new TerritoriesApi(customConfig);
export const developmentApi = new DevelopmentApi(customConfig);
export const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SECONDARY_API_URL,
    paramsSerializer: (params) => $.param(params, true),
  });

axiosInstance.interceptors.request.use(
  async (config) => {
    /* config.headers = { 'x-language': i18next.language }; */
    if (token) {
      config.headers = { 'X-Cognito-Access-Token': token, ...config.headers };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

type ReportIdDateProps = {
  reportId: string,
  date: string,
};

type ReportIdStartDateAndDateProps = {
  reportId: string,
  startDate: string,
  endDate: string,
};

  export const createSecondaryReport = async ({
    market_type,
    name,
    country,
    user_id,
    geometry,
    building_conditions,
    // number_of_days,
  }: CreateSecondaryDashboardParameters): Promise<void> =>
  reportsApi.reportsEndpointsCreateReport({
    marketType: market_type as BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum,
    name,
    country,
    userId: user_id,
    bodyParams: {
      multiPolygon: geometry,
      buildingConditions: building_conditions,
    },
    // numberOfDays: number_of_days,
  });

export const getSecondaryMultiPolygon = async (reportId: string): Promise<MultiPolygonalSchema> =>
  statisticsApi.getMultipolygonByReportId({ reportId });

/* export const getReports = async ({ userId }: { userId: string }): Promise<SecondaryReport[]> =>
  reportsApi.reportsEndpointsGetReportsByUserId({ userId }); */

type ReportResponse = {
  report_id: string;
  name: string;
  report_type: string;
}

export const getReports = async (): Promise<SecondaryReport[]> => {
  const data = await fetch('https://datastore.bmdevservices.eu/api/v1/report/', customConfig);
  const json: ReportResponse[] = await data.json();
  return json.map((d) => ({
    reportId: d.report_id,
    name: d.name,
    reportType: d.report_type,
  }));
};

export const getAvailableUnits =
  async ({ reportId, date }: ReportIdDateProps): Promise<AvailableUnits> =>
  statisticsApi.getAvailableUnits({ reportId, date });

export const getAvailableUnitsPricePerSm =
  async ({ reportId, date }: ReportIdDateProps): Promise<AvailableUnitsPricePerSm> =>
  statisticsApi.getAvailableUnitsPricePerSm({ reportId, date });

export const getAvailableUnitsLayout =
  async ({ reportId, date }: ReportIdDateProps):
  Promise<AvailableUnitsLayoutResponseSchemaWithTotals> =>
  statisticsApi.getAvailableUnitsLayout({ reportId, date });

export const getAvailableUnitsAverageSm =
  async ({ reportId, date }: ReportIdDateProps): Promise<AvailableUnitsAverageSm> =>
  statisticsApi.getAvailableUnitsAverageSm({ reportId, date });

export const getAvailableUnitsAveragePrice =
  async ({ reportId, date }: ReportIdDateProps): Promise<AvailableUnitsAveragePrice> =>
  statisticsApi.getAvailableUnitsAveragePrice({ reportId, date });

export const getDisappearedUnits =
  async ({ reportId, date }: ReportIdDateProps): Promise<DisappearedUnits> =>
  statisticsApi.getDisappearedUnits({ reportId, date });

export const getDisappearedUnitsPricePerSm =
  async ({ reportId, date }: ReportIdDateProps): Promise<DisappearedUnitsPricePerSm> =>
  statisticsApi.getDisappearedUnitsPricePerSm({ reportId, date });

export const getDisappearedUnitsLayout =
  async ({ reportId, date }: ReportIdDateProps):
    Promise<DisappearedUnitsLayoutResponseSchemaWithTotals> =>
  statisticsApi.getDisappearedUnitsLayout({ reportId, date });

export const getDisappearedUnits7DaysLayout = async ({
  reportId,
  startDate,
  endDate,
}: ReportIdStartDateAndDateProps): Promise<DisappearedUnitsPriceHistoryLayoutResponseSchemav2[]> =>
  developmentApi.disappearedUnits7DaysLayout({ reportId, startDate, endDate });

export const getDisappearedUnitsAverageSm =
  async ({ reportId, date }: ReportIdDateProps): Promise<DisappearedUnitsAverageSm> =>
  statisticsApi.getDisappearedUnitsAverageSm({ reportId, date });

export const getDisappearedUnitsAveragePrice =
  async ({ reportId, date }: ReportIdDateProps): Promise<DisappearedUnitsAveragePrice> =>
  statisticsApi.getDisappearedUnitsAveragePrice({ reportId, date });

export const getSaleSpeedOverallData =
  async (payload: ReportIdStartDateAndDateProps) =>
    statisticsApi.saleSpeedOverall(payload);

export const getSaleSpeedLayoutData =
  async (payload: ReportIdStartDateAndDateProps) => statisticsApi.saleSpeedByLayoutMonth(payload);

interface AvailableOverallResponse {
  createdDate: string,
  availableUnitsAveragePrice: string | null,
}

interface AvailableLayoutResponse {
  createdDate: string,
  availableLayout: {
    countOfRegularRooms: number,
    price: number,
  }[],
}

interface DisappearedOverallResponse {
  createdDate: string,
  disappearedUnitsAveragePrice: string | null,
}

interface DisappearedLayoutResponse {
  createdDate: string,
  disappearedLayout: {
    countOfRegularRooms: number,
    price: number,
  }[],
}

export const getPriceHistoryAvailableOverall =
  async (payload: ReportIdStartDateAndDateProps) => {
    const data = statisticsApi.availableUnitsPriceHistoryOverall(
    payload,
  ) as unknown as Promise<AvailableOverallResponse[]>;
  return data;
};

export const getPriceHistoryAvailableLayout =
  async (payload: ReportIdStartDateAndDateProps) =>
  statisticsApi.availableUnitsPriceHistoryByLayout(
    payload,
  ) as unknown as Promise<AvailableLayoutResponse[]>;

export const getPriceHistoryDisappearedOverall =
  async (payload: ReportIdStartDateAndDateProps) =>
  statisticsApi.disappearedUnitsPriceHistoryOverall(
    payload,
  ) as unknown as Promise<DisappearedOverallResponse[]>;

export const getPriceHistoryDisappearedLayout =
  async (payload: ReportIdStartDateAndDateProps) =>
  statisticsApi.disappearedUnitsPriceHistoryByLayout(
    payload,
  ) as unknown as Promise<DisappearedLayoutResponse[]>;
