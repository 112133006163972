import React, { useMemo, useState } from 'react';
import { Form, Input, Button, Typography, App } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';
import { LockFilled, MailFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../hooks';
import { Paths } from '../routes/Paths';
import { LoginValues } from '../api/types';
import { Card } from '../components';
import { logError } from '../utils/utils';
import { LOGOUT_NOTIFICATION_KEY } from '../components/AppLayout';

const { Title } = Typography;

export const LoginPage: React.FC = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const location = useLocation();
  const login = useStoreActions((actions) => actions.user.login);
  const isLoggedIn = useStoreState((state) => state.user.isLoggedIn);
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const isDailyNewsEnabled = useStoreState(
    (state) => state.user.profile?.daily_news_enabled,
  );
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const navigatePathname = useMemo(() => {
    const state = location.state as { from: Location };
    if (state && state.from) {
      return state.from;
    }
    if (isDailyNewsEnabled) {
      return Paths.DAILYNEWS;
    }

    return isDashboardEnabled ? Paths.DASHBOARD : Paths.MARKET_PROJECTS;
  }, [location, isDashboardEnabled, isDailyNewsEnabled]);

  const handleFinish = async (values: LoginValues): Promise<void> => {
    try {
      setLoadingLogin(true);
      await login(values);
      notification.destroy(LOGOUT_NOTIFICATION_KEY);
    } catch (error) {
      logError(error);
    } finally {
      setLoadingLogin(false);
    }
  };

  return isLoggedIn ? (
    <Navigate to={navigatePathname} />
  ) : (
    <div className='login'>
      <Card className='form'>
        <Form<LoginValues> onFinish={handleFinish}>
          <div className='logo'>
            <img className='m-auto' alt='logo' src='/logo.png' />
          </div>
          <Title className='title' level={4}>
            {t('login.title')}
          </Title>
          <div className='subtitle'>{t('login.subtitle')}</div>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: t('login.validations.emailRequired'),
              },
              {
                type: 'email',
                message: t('login.validations.email'),
              },
            ]}
          >
            <Input
              prefix={<MailFilled />}
              data-cy='login-email'
              placeholder={t('login.email_placeholder')}
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: t('login.validations.password'),
              },
            ]}
          >
            <Input
              prefix={<LockFilled />}
              type='password'
              data-cy='login-password'
              placeholder={t('login.password_placeholder')}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='submit'
              data-cy='login-submit'
              loading={loadingLogin}
            >
              {t('login.sign_in')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
