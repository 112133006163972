import { FC } from 'react';
import { useRegionFormatting } from '../../../../../hooks';
import { ProjectDetailWithHistoryTableProps } from './types';
import { ProjectDetailTableWithHistory as ProjectDetailTableOther } from './other';
import { ProjectDetailTable as ProjectDetailTableUS } from './us';

export const ProjectDetailTableWithHistory: FC<ProjectDetailWithHistoryTableProps> = (props) => {
  const { isUsRegion } = useRegionFormatting();

  if (isUsRegion) {
    return <ProjectDetailTableUS {...props} />;
  }
  return <ProjectDetailTableOther {...props} />;
};
