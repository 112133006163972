/** @jsxImportSource @emotion/react */
import { Segmented } from 'antd';
import { TFunction } from 'i18next';
import { FC, Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchBlockOverviewData } from '../../../../api';
import { MarketProject } from '../../../../api/types';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useReservedData } from '../../../../hooks/useReservedData';
import { Availability } from '../../../../store/types';
import {
  aggregatePriceListData,
  getMinMax,
  getReportExportFileName,
  getSumFromNotNullValues,
} from '../../../../utils/utils';
import { Card } from '../../../Card';
import { InfoPopover } from '../../../InfoPopover';
import { ProjectDetailTable } from '../../../map/ProjectDetail/Content/ProjectDetailTable';
import { useFilter } from '../useFilter';
import { styles } from './styles';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

const renderProjectNames = (names: string[], t: TFunction): ReactNode => {
  if (names.length <= 3) {
    return names.join(', ');
  }
  return (
    <>
      {names.slice(0, 3).join(', ')}
      {t('market.reports.overview.other', ' + {{count}} others', {
        count: names.length - 3,
      })}
      <InfoPopover
        popoverProps={{
          content: (
            <div css={styles.popoverContainer}>
              {names.slice(3, names.length - 1).join(', ')}
            </div>
          ),
          showArrow: false,
        }}
      />
    </>
  );
};
interface DescriptionData {
  label: string;
  value: ReactNode;
}

type Props = {
  handleChangeExternalFilter?: (value: string | number) => void;
  filterExternalValue?: string | number;
}

export const Overview: FC<Props> = ({ handleChangeExternalFilter, filterExternalValue }) => {
  const [data, setData] = useState<MarketProject[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();
  const { areaUnit, formatCurrency, calculateVatPrice, sortByLayouts } =
    useRegionFormatting();
  const { mapReservedData } = useReservedData();
  const { filterValue: filterBlockValue, handleChange } = useFilter(FilterEnum.AVAILABLE);
  const filterValue = filterExternalValue ?? filterBlockValue;
  const pngRef = useRef(null);

  const {
    tableData,
    priceListData,
    totalAvailableUnits,
    minMax,
    projectNames,
  } = useMemo(() => {
    const priceList = mapReservedData(
      data.flatMap(({ price_list }) => price_list),
    );
    const aggregatedTableData = aggregatePriceListData(priceList);
    return {
      tableData: sortByLayouts(
        aggregatedTableData
          .filter(({ availability }) =>
            filterValue === FilterEnum.AVAILABLE
              ? availability === Availability.AVAILABLE
              : availability !== Availability.AVAILABLE,
          )
          .map((obj) => ({
            ...obj,
            price: calculateVatPrice(
              Number(obj.price),
              Number(obj.interior_sm),
              Number(obj.exterior_sm),
            ),
            price_per_sm: calculateVatPrice(
              Number(obj.price_per_sm),
              Number(obj.interior_sm),
              Number(obj.exterior_sm),
            ),
          })),
      ),
      priceListData: priceList
        .filter(
          ({ availability }) =>
            availability ===
            (filterValue === FilterEnum.AVAILABLE
              ? Availability.AVAILABLE
              : Availability.SOLD),
        )
        .map((obj) => ({
          ...obj,
          price: calculateVatPrice(
            Number(obj.price),
            Number(obj.floor_area),
            Number(obj.exterior_area),
          ),
          price_per_sm: calculateVatPrice(
            Number(obj.price_per_sm),
            Number(obj.floor_area),
            Number(obj.exterior_area),
          ),
        })),
      totalAvailableUnits: getSumFromNotNullValues(
        aggregatedTableData
          .filter(({ availability }) => availability === Availability.AVAILABLE)
          .map((pd) => pd.available),
      ),
      minMax: getMinMax(
        priceList
          .filter(({ availability }) => availability === Availability.AVAILABLE)
          .map((obj) =>
            calculateVatPrice(
              Number(obj.price_per_sm),
              Number(obj.floor_area),
              Number(obj.exterior_area),
            ),
          ),
        formatCurrency,
      ),
      projectNames: data.map(({ project_name }) => project_name).sort(),
    };
  }, [
    mapReservedData,
    data,
    sortByLayouts,
    formatCurrency,
    filterValue,
    calculateVatPrice,
  ]);

  const descriptionsData: DescriptionData[] = [
    {
      label: t('market.reports.overview.projects', 'Projects'),
      value: renderProjectNames(projectNames, t),
    },
    {
      label: t('common.price_per_unit', 'Price per {{unit}}', {
        unit: areaUnit,
      }),
      value: minMax,
    },
    {
      label: t('market.reports.overview.units_count', 'Units Count'),
      value: totalAvailableUnits,
    },
  ];

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockOverviewData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then(({ data: { included_projects_statistics } }) => {
          setData(included_projects_statistics);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.overview.title', 'Overview'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <Card
      css={styles.card}
      extra={!isLoading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      title={t('market.reports.overview.title', 'Overview')}
    >
      <div css={styles.descriptionContainer}>
        {descriptionsData.map(({ label, value }) => (
          <Fragment key={label}>
            <div css={styles.label}>{label}</div>
            <div style={{ display: 'flex' }}>{value}</div>
          </Fragment>
        ))}
      </div>
      <div css={styles.segmentedContainer}>
        <Segmented
          value={filterValue}
          options={[
            {
              value: FilterEnum.AVAILABLE,
              label: t('enums.state.available', 'Available'),
            },
            {
              value: FilterEnum.SOLD,
              label: t('enums.state.sold', 'Sold'),
            },
          ]}
          onChange={handleChangeExternalFilter || handleChange}
        />
      </div>
      <div>
        <ProjectDetailTable
          loading={isLoading}
          priceList={priceListData}
          dataSource={tableData}
        />
      </div>
    </Card>
    </div>
  );
};
