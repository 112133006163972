import { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { InfoPopover } from '../InfoPopover';
import { Checkbox } from '../Checkbox/Checkbox';

type Props = {
  columnDataIndexes: string[];
  columnsFilterValues: string[];
  setColumnDataIndexes: (dataIndexes: string[]) => void;
  onChangeFilter: (dataIndex: string) => void;
  isMarketProjects?: boolean;
}

const transformDataIndex = (dataIndex: string): string => {
  switch (dataIndex) {
    case 'avg_price':
      return 'available_price';
    case 'avg_price_per_sm':
      return 'available_price';
    case 'sold_avg_price':
      return 'sold_price';
    case 'sold_avg_price_per_sm':
      return 'sold_price';
    default:
      return dataIndex;
  }
};

export const ColumnFiltersList: FC<Props> = ({ columnDataIndexes, columnsFilterValues, setColumnDataIndexes, onChangeFilter, isMarketProjects = false }) => {
  const { t } = useTranslation();

  const reorder = (list: string[], startIndex: number, endIndex: number): string[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      columnDataIndexes,
      result.source.index,
      result.destination.index,
    );

    setColumnDataIndexes(items);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnDataIndexes.map((item, index) => (
                item !== 'columnFilter' && (
                  <Draggable key={item} draggableId={item} index={index}>
                  {(nestedProvider, snapshot) => (
                    <div
                      ref={nestedProvider.innerRef}
                      {...nestedProvider.draggableProps}
                      {...nestedProvider.dragHandleProps}
                      className={snapshot.isDragging ? 'bg-gray-100 rounded-2xl' : 'bg-white'}
                    >
                      <div className='flex flex-1 justify-between items-center p-4 select-none' style={{ fontSize: 14, fontWeight: 400 }}>
                        <div className='flex space-x-2 items-center'>
                          <Checkbox checked={!columnsFilterValues.includes(item)} onChange={() => onChangeFilter(item)} />
                          <span>
                            {t(isMarketProjects ? `market.projects.table.${transformDataIndex(item)}` : `price_list.table_column_headers.${item}`)}
                          </span>
                        </div>
                        <InfoPopover popoverProps={{
                          content:
                            <span>
                              {t(isMarketProjects ? `market.projects.table.${transformDataIndex(item)}.description` : `price_list.table_column_headers.${item}.description`)}
                            </span>,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  </Draggable>
                )
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
    </DragDropContext>
    );
};
