import { css } from '@emotion/react/macro';
import { BLUE_5, BOLD_FONT_WEIGHT, GREY_2 } from '../../../../styles/constants';

export const styles = {
  // container: css({
  //   display: 'grid',
  //   gridAutoRows: 'auto',
  //   gridColumnGap: '0.5rem',
  //   gridRowGap: '0.5rem',
  //   gridTemplateColumns:
  //     '[first] 20% [line2] 25% [line3] 5% [col4-start] 10% [col5-start] 15% [col6-start] 10% [five] auto [end]',
  //   width: '100%',
  //   '&.row-spacing': { marginBottom: '1.6rem' },
  //   '&.row-top-spacing': { marginTop: '1.6rem' },
  //   alignItems: 'center',
  // }),
  infoButton: css({ textAlign: 'center' }),
  value: css({ color: BLUE_5 }),
  price: css({
    color: BLUE_5,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '1.5rem',
    textAlign: 'end',
  }),
  updatePricePositive: css({
    color: BLUE_5,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  updatePriceNegative: css({
    color: GREY_2,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  chevronPositive: css({
    color: BLUE_5,
    fontSize: '1.5rem',
    textAlign: 'right',
   }),
  chevronNegative: css({
    color: GREY_2,
    fontSize: '1.5rem',
    textAlign: 'right',
   }),
  title: css({
    color: BLUE_5,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '1.5rem',
    lineHeight: '2.3rem',
    textTransform: 'uppercase',
  }),
  popoverContainer: css({ maxWidth: 300 }),
};
