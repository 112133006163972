import { FC, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Page, PriceListTable } from '../components';
import { useStoreActions, useStoreState, useRegionFormatting } from '../hooks';
import {
  PriceBreakdown,
  PriceListDetailFilters,
  PriceOverview,
} from '../components/priceListDetail';
import { Paths } from '../routes/Paths';
import { transformPriceListData } from '../utils';
import { BackLink } from '../components/BackLink';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { DetailPreviewCard } from '../components/priceListDetail/DetailPreviewCard/DetailPreviewCard';
import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { transformPriceListBreakdown } from './transformPriceListData';
import { ComparisonBreakdown } from './PriceListComparison/ComparisonBreakdown';
import { ComparisonBreakdownUnit } from './PriceListComparison/ComparisonBreakdownUnit';
import { GoogleAnalyticsChart } from '../components/priceListDetail/GoogleAnalyticsChart';
import { PriceListDetailPagination } from './PriceListDetailPagination';

export const PriceListDetailPage: FC = () => {
  const { unitId } = useParams();
  const [isComparisonOpen, setIsComparisonOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState<number[]>(
    unitId ? [Number(unitId)] : [],
  );
  const { fetchComparisonData } = useStoreActions(
    (state) => state.priceListDetail,
  );
  const { calculateVatPrice } = useRegionFormatting();
  const data = useStoreState((state) => state.priceListDetail.data);
  const tableDataIds = useStoreState((state) => state.priceList.data)?.map(
    (unit) => unit.id,
  );
  const [comparisonData, setComparisonData] = useState<PriceListDetailData[]>(
    [],
  );
  const [shownData, setShownData] = useState(data);
  const { profile } = useStoreState((state) => state.user);
  const { showPrediction, comparisonPrediction } = useStoreState(
    (state) => state.priceListDetail,
  );
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  const priceBreakdown = shownData?.price_breakdown;
  const analyticsData = shownData?.analytics_data;
  const showPriceBreakdown =
    priceBreakdown && Object.entries(priceBreakdown).length > 0;
  const showPriceOverview =
    priceBreakdown &&
    showPrediction &&
    Object.entries(priceBreakdown).length > 0;

  const { t } = useTranslation();
  const fetchData = useStoreActions(
    (actions) => actions.priceListDetail.fetchData,
  );

  const selectCompare = (id: number, action: 'add' | 'remove'): void => {
    if (action === 'add') {
      if (selectedUnits.length === 3) {
        message.info('You can only compare up to 3 units');
        return;
      }
      setSelectedUnits([...selectedUnits, id]);
    } else {
      setSelectedUnits(selectedUnits.filter((unit) => unit !== id));
    }
  };

  const confirmCompare = async (): Promise<void> => {
    if (selectedUnits.length < 2) {
      message.info('You need to select at least 1 unit');
      return;
    }
    if (!data) {
      message.error('Data not found');
      return;
    }
    setIsLoading(true);
    try {
      const fetchIds = selectedUnits.filter((unit) => unit !== Number(unitId));
      const response = await fetchComparisonData(fetchIds);
      if (response) {
        setComparisonData([data, ...response]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsComparisonOpen(false);
    }
  };

  const closeCompare = (): void => {
    setIsComparisonOpen(false);
    setSelectedUnits(unitId ? [Number(unitId)] : []);
  };

  const cancelCompare = (): void => {
    setSelectedUnits(unitId ? [Number(unitId)] : []);
    setComparisonData([]);
  };

  useEffect(() => {
    if (!data) return;
    const unitsData = transformPriceListBreakdown(
      [data],
      calculateVatPrice,
      profile,
    );
    setShownData(unitsData[0]);
  }, [calculateVatPrice, data, setShownData, profile]);

  useEffect(() => {
    const convertedUnitId = Number(unitId);
    if (!Number.isNaN(convertedUnitId)) {
      fetchData(convertedUnitId);
    }
  }, [fetchData, unitId, pricePerSmCalculation]);

  const transformedData = shownData && transformPriceListData(shownData);
  const priceData = transformedData
    ? {
        recommendedPrice:
          calculateVatPrice(
            transformedData.new_price,
            transformedData.floor_area,
            transformedData.exterior_area,
          ) ?? 0,
        priceUpdate:
          calculateVatPrice(
            transformedData.new_price - transformedData.current_price,
            transformedData.floor_area,
            transformedData.exterior_area,
          ) ?? 0,
        recommendedPricePsm:
          calculateVatPrice(
            transformedData.new_price_per_sm,
            transformedData.floor_area,
            transformedData.exterior_area,
          ) ?? 0,
        priceUpdatePsm:
          calculateVatPrice(
            transformedData.new_price_per_sm -
              transformedData.current_price_per_sm,
            transformedData.floor_area,
            transformedData.exterior_area,
          ) ?? 0,
        priceUpdatePercentage: transformedData.price_difference,
      }
    : {
        recommendedPrice: 0,
        priceUpdate: 0,
        recommendedPricePsm: 0,
        priceUpdatePsm: 0,
        priceUpdatePercentage: 0,
      };
  const showPredictionUnits = [
    showPrediction && unitId && Number(unitId),
    ...comparisonPrediction,
  ];
  return (
    <Page
      pageClassname='price-list-detail overflow-x-auto'
      title={
        <div className='flex space-x-5 items-center'>
          <BackLink to={Paths.PRICE_LIST}>
            <ArrowLeftOutlined /> {t('price_list.title')}
          </BackLink>
          {' / '}
          {t('price_list.detail.title', {
            id: data?.description.internal_id,
          })}
          {tableDataIds && !!tableDataIds.length && (
            <PriceListDetailPagination tableDataIds={tableDataIds} />
          )}
          <div className='flex items-center space-x-2'>
            <Button
              className='bm-default-btn'
              onClick={() => setIsComparisonOpen(true)}
            >
              <div className='flex space-x-1 items-center'>
                <MagnifyingGlassIcon className='w-7 h-7 stroke-[3px]' />
                <span className='font-semibold'>
                  {t('market.projects.add_report_form.compare', 'Compare')}
                </span>
              </div>
            </Button>
            {!!comparisonData.length && (
              <Button
                className='bm-default-btn'
                onClick={() => cancelCompare()}
              >
                <div className='flex space-x-1 items-center'>
                  <XMarkIcon className='w-7 h-7 stroke-[3px]' />
                  <span className='font-semibold'>
                    {t('common.close', 'Close')}
                  </span>
                </div>
              </Button>
            )}
          </div>
        </div>
      }
      filters={<PriceListDetailFilters />}
    >
      {!!comparisonData.length ? (
        <div className='relative 2xl:justify-center flex space-x-10'>
          <ComparisonBreakdown />
          {comparisonData.map((unit) => (
            <ComparisonBreakdownUnit
              unit={unit}
              showPrediction={showPredictionUnits.includes(unit.id)}
            />
          ))}
        </div>
      ) : (
        <Row className='wrapper' gutter={[16, 16]}>
          <Col xs={12} xl={9}>
            <DetailPreviewCard data={transformedData} />
          </Col>
          {showPriceBreakdown && (
            <Col xs={24} xl={15}>
              <PriceBreakdown priceData={priceData} data={priceBreakdown} />
            </Col>
          )}
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              {showPriceOverview && (
                <Col xs={24} xl={12}>
                  <PriceOverview data={priceBreakdown} />
                </Col>
              )}
              <Col xs={24} xl={showPriceOverview ? 12 : 24}>
                <GoogleAnalyticsChart
                  data={analyticsData}
                  internalId={transformedData?.internal_id}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Modal
        footer={
          <div className='flex space-x-5 items-center justify-center w-full'>
            <Button className='bm-default-btn' onClick={() => closeCompare()}>
              {t('common.close', 'Close')}
            </Button>
            <Button
              className='bm-submit-btn'
              loading={isLoading}
              disabled={isLoading}
              onClick={() => confirmCompare()}
            >
              {t('market.projects.add_report_form.compare', 'Compare')}
            </Button>
          </div>
        }
        open={isComparisonOpen}
        centered
        width='80%'
        onCancel={() => setIsComparisonOpen(false)}
      >
        <PriceListTable
          isCompare
          selectCompare={selectCompare}
          selectedUnits={selectedUnits}
        />
      </Modal>
    </Page>
  );
};
