import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmented } from 'antd';
import { FormContainer } from '../FormContainer';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Button } from '../../button/Button';
import {
  FilterTypeEnum,
  StartingPricesComponent,
} from '../../startingPrices/StartingPrices';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { BackButton } from '../../button/BackButton';
import { StartingPrice } from '../../../../hooks/usePriceListUpload';
import { useFilter } from '../../../market/blocks/useFilter';

export const StartingPrices: FC = () => {
  const { t } = useTranslation();

  const { skipPriceList, startingPrices, projectType, startingPricesOverall } =
    useStoreState((state) => state.onboarding);
  const {
    handleNext,
    handleBack,
    setStartingPrices,
    setCurrentPage,
    setStartingPricesOverall,
  } = useStoreActions((actions) => actions.onboarding);

  const [prices, setPrices] = useState<StartingPrice[]>(startingPrices);
  const [overallPrices, setOverallPrices] = useState<string | null>(
    startingPricesOverall ? String(startingPricesOverall) : null,
  );
  const isRental = projectType.rental;

  const filterType = useFilter(FilterTypeEnum.LAYOUTS);

  useEffect(() => {
    if (skipPriceList) setCurrentPage(1);
  }, []);

  const handleInputChange = (layout: string, value: string): void => {
    setPrices((prevPrices) =>
      prevPrices.map((priceObj) =>
        priceObj.layout === layout
          ? { ...priceObj, price: Number(value) }
          : priceObj,
      ),
    );
  };

  const handleSubmit = (): void => {
    if (filterType.filterValue === FilterTypeEnum.OVERALL) {
      setStartingPrices(
        startingPrices.map((price) => ({ layout: price.layout, price: null })),
      );
      setStartingPricesOverall(Number(overallPrices));
    } else {
      setStartingPricesOverall(null);
      setStartingPrices(prices);
    }
    handleNext();
  };

  return (
    <FormContainer>
      <div>
        <BackButton handleClick={handleBack} />
        <div>
          <Heading className='pt-5 pb-6'>
            {t('onboarding.starting_prices.title', 'Starting {{type}}', {
              type: isRental
                ? t('onboarding.common.rents', 'Rents')
                : t('onboarding.common.prices', 'Prices'),
            })}
          </Heading>
        </div>
        <Subtitle>
          {t(
            'onboarding.starting_prices.subtitle',
            'It seems you haven’t uploaded any starting {{type}}. You can now determine them by layout or return to reupload the file. BuiltMind will then determine the new {{type}} and compare them to your starting {{type}}.',
            {
              type: isRental
                ? t('onboarding.common.rents', 'Rents').toLowerCase()
                : t('onboarding.common.prices', 'Prices').toLowerCase(),
            },
          )}
        </Subtitle>
        <StartingPricesComponent
          className='mt-5'
          prices={prices}
          onInputChange={handleInputChange}
          filterType={filterType}
          overallPrices={overallPrices}
          setOverallPrices={setOverallPrices}
        />
      </div>
      <div className='flex justify-center'>
        <Button handleClick={handleSubmit}>
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
