import { FC, createRef, useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { DemandChart } from '../DemandChart';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { Chevron } from '../Chevron';
import { InfoPopover } from '../..';

export const Demand: FC = () => {
  const { t } = useTranslation();
  const KPIRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);
  const currentData = useStoreState((state) => state.dailyNews.currentData);
  const { calculateVatPrice } = useRegionFormatting();

  useEffect(() => {
    if (KPIRef.current) {
      setHeight(KPIRef.current.offsetHeight);
    }
  }, [KPIRef]);

  const chartData = useMemo(() => {
    if (!currentData || !currentData?.graph_data) return undefined;
    return {
      ...currentData.graph_data,
      available_timeline_price_per_sm: currentData.graph_data.available_timeline_price_per_sm
        .map((d) => ({
          ...d,
          avg_price: Number(calculateVatPrice(d.avg_price, 119)),
        })),
        sold_timeline_price_per_sm: currentData.graph_data.sold_timeline_price_per_sm
        .map((d) => ({
          ...d,
          avg_price: Number(calculateVatPrice(d.avg_price, 119)),
        })),
    };
  }, [currentData, calculateVatPrice]);

  return (
    <KPI
      ref={KPIRef}
      small
      title={t('daily_news.kpi.demand')}
      icon={
        <div className='demand daily_news_kpi_icon'>
          <img src='/assets/wallet_icon_grey_compressed.png' alt='wallet' />
        </div>
      }
      secondary={{
        title: (
        <>
          {t('daily_news.kpi.sold_units')}
          <InfoPopover
            popoverProps={{
              content:
                <div className='popover-tooltip'>
                  {t(
                    'daily_news.kpi.tooltip.sold_units_count',
                    'Calculated as the sold units.',
                  )}
                </div>,
            }}
          />
        </>
        ),
        label: currentData?.graph_data?.sold_last_month ?? null,
        dateFormat: {
          month: 'long',
        },
        type: 'demand',
        value: currentData ? (
          <Chevron number={currentData?.graph_data?.sold_last_month_pct_change ?? 0} />
        ) : (
          <Spin spinning />
        ),
        percent: currentData?.graph_data?.sold_last_month_pct_change ?? 0,
      }}
      tertiary={{
        title: (
          <>
            {t('daily_news.kpi.price_per_sm')}
            <InfoPopover
              popoverProps={{
                content:
                  <div className='popover-tooltip'>
                    {t(
                      'daily_news.kpi.tooltip.price_per_sm_sold',
                      'Calculated as the price per SM of the sold units including the exterior.',
                    )}
                  </div>,
              }}
            />
          </>
          ),
        label: calculateVatPrice(
          currentData?.graph_data?.sold_price_per_sm_last_month ?? null,
          119,
        ),
        dateFormat: {
          month: 'long',
        },
        value: currentData ? (
          <Chevron
            number={
              calculateVatPrice(
                currentData?.graph_data?.sold_price_per_sm_last_month_pct_change ?? 0,
                119,
                )
              ?? 0
            }
          />
        ) : (
          <Spin spinning />
        ),
        percent: currentData?.graph_data?.sold_price_per_sm_last_month_pct_change ?? 0,
      }}
      chart={chartData && <DemandChart chartData={chartData} height={height} />}
    />
  );
};
