/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { Affix, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { Page } from '../../components';
import { useRegionFormatting, useStoreActions, useStoreState } from '../../hooks';
import { styles } from './styles';
import { PriceUpdateTable } from '../../components/priceUpdate/PriceUpdateTable';
import { PriceUpdateFilters } from '../../components/priceUpdate/PriceUpdateFilters';
import { DetailPreviewCard } from '../../components/priceListDetail/DetailPreviewCard/DetailPreviewCard';
import { PriceListUpdateData } from '../../api/mockData/priceUpdateData';
import { SubtitleContainer } from './SubtitleContainer/SubtitleContainer';
import { updatePriceListUserSeen } from '../../api';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';

export enum PriceTrendEnum {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export const PriceUpdatePage: FC = () => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const isDashboardEnabled = useStoreState((state) => state.user.profile?.dashboard_enabled);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const data = useStoreState((state) => state.priceList.selectedData);
  // @ts-ignore TODO: Fix typing
  const updateData = useStoreState((state) => state.priceList.updateData) as PriceListUpdateData;
  const { formatCurrency } = useRegionFormatting();
  const { phaseId, projectId, isLoading } = useStoreState(
    (state) => state.filters,
  );
  // const pricePerSmCalculation = useStoreState(
  //   (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  // );
  const fetchData = useStoreActions((actions) => actions.priceList.fetchData);
  const fetchUpdateData = useStoreActions((actions) => actions.priceList.fetchUpdateData);
  const projectUpdate = useMemo(() =>
    overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && projectId && phaseId && overviewData?.updates.find((update) => update.project_id === projectId)) {
      // Fetching Project data
      fetchData({
        projectId,
        phaseId,
      });
      if (projectUpdate) {
        fetchUpdateData({ price_list_update_id: projectUpdate.id });
        if (projectUpdate.user_seen === false) {
          updatePriceListUserSeen({ price_list_update_id: projectUpdate.id });
        }
      }
    }
  }, [fetchData, fetchUpdateData, isLoading, overviewData?.updates, phaseId, projectId, projectUpdate]);

const formattedData = useMemo(() => {
  if (!updateData) {
    return {};
  }
  const {
    elapsed_sale_days_percentage,
    elapsed_sale_days,
    initial_revenue,
    project_id,
    revenue_increase,
    total_flats,
    total_sale_days,
    unavailable_flats_percentage,
    unavailable_flats,
    updated_revenue,
    user_seen,
    tables,
    reserved_units,
    sold_units,
  } = updateData;

  const revenue_increase_percentage = Math
  .round((revenue_increase / initial_revenue) * 100).toFixed(1);

  const price_increases_tables = tables
    ?.filter(({ total_price_change }) => total_price_change > 0)
    .sort((a, b) => b.total_price_change - a.total_price_change)
    || [];
  const price_decreases_tables = tables
    ?.filter(({ total_price_change }) => total_price_change < 0)
    .sort((a, b) => a.total_price_change - b.total_price_change)
    || [];

  return {
    elapsed_sale_days_percentage,
    elapsed_sale_days,
    initial_revenue,
    project_id,
    revenue_increase,
    revenue_increase_percentage,
    total_flats,
    total_sale_days,
    unavailable_flats_percentage,
    unavailable_flats,
    updated_revenue,
    user_seen,
    price_decreases_tables,
    price_increases_tables,
    reserved_units,
    sold_units,
  };
}, [updateData]);

const {
  elapsed_sale_days_percentage,
  revenue_increase,
  total_flats,
  unavailable_flats_percentage,
  unavailable_flats,
  price_decreases_tables,
  price_increases_tables,
  reserved_units,
  sold_units,
  initial_revenue,
} = formattedData;

// const { interior, exterior, floor, orientation, layout } = priceData || {};

// const priceUpdates = {
//   floor_area: interior?.floor_area.update_price_percentage ?? 0,
//   difference_floor_area: interior?.difference_floor_area.update_price_percentage ?? 0,
//   layout: interior?.layout.update_price_percentage ?? 0,
//   layout_class: interior?.layout_class.update_price_percentage ?? 0,
//   balcony_area: exterior?.balcony_area.update_price_percentage ?? 0,
//   garden_area: exterior?.garden_area.update_price_percentage ?? 0,
//   terrace_area: exterior?.terrace_area.update_price_percentage ?? 0,
//   has_no_exterior: exterior?.has_no_exterior.update_price_percentage ?? 0,
//   floor: floor?.floor.update_price_percentage ?? 0,
//   top_floor: floor?.top_floor.update_price_percentage ?? 0,
//   bottom_floor: floor?.bottom_floor.update_price_percentage ?? 0,
//   cardinal_direction: orientation?.cardinal_direction.update_price_percentage ?? 0,
//   distance: orientation?.distance.update_price_percentage ?? 0,
//   view: orientation?.view.update_price_percentage ?? 0,
//   num_sides: orientation?.num_sides.update_price_percentage ?? 0,
//   category: layout?.category.update_price_percentage ?? 0,
//   kitchen_luminosity: layout?.kitchen_luminosity.update_price_percentage ?? 0,
//   next_to_elevator: layout?.next_to_elevator.update_price_percentage ?? 0,
//   noise: layout?.noise.update_price_percentage ?? 0,
//   num_of_bathrooms: layout?.num_of_bathrooms.update_price_percentage ?? 0,
//   permit_regular: layout?.permit_regular.update_price_percentage ?? 0,
//   ratio_bedrooms: layout?.ratio_bedrooms.update_price_percentage ?? 0,
//   ratio_living: layout?.ratio_living.update_price_percentage ?? 0,
//   walk_through_room: layout?.walk_through_room.update_price_percentage ?? 0,
// };
// const topPriceUpdates = getTopPriceUpdates(priceUpdates);

const unavailableFlatsPercentage = unavailable_flats_percentage && Math.round(unavailable_flats_percentage).toFixed(0);
const elapsedSaleDaysPercentage = elapsed_sale_days_percentage && Math.round(elapsed_sale_days_percentage).toFixed(0);
const revenueIncreaseMillions = revenue_increase && formatCurrency(revenue_increase, false, true);
const revenueIncreaseThousands = revenue_increase && formatCurrency(revenue_increase, false, false, true);
const revenueIncreaseText = revenue_increase && Math.abs(revenue_increase) > 1000000 ? revenueIncreaseMillions : revenueIncreaseThousands;

  return (
    <Page
      pageClassname='price-list'
      filters={isDashboardEnabled && <PriceUpdateFilters />}
      title={
        isDashboardEnabled ? (
          <div className='reports-title'>
            <span>{t('price_list.title')}</span>
            <span>/</span>
            <span className='active'>{t('price_update.title', 'Price Update')}</span>
          </div>
          ) : (
            <span>{t('price_list.title')}</span>
          )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isDashboardEnabled ? (
        !updateData ? (
          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', height: '100%' }}>{t('price_update.loading_message')}</div>
        ) : (
        <Row css={styles.contentContainer} gutter={[16, 16]}>
          <Col xs={24} md={18}>
            <Card css={styles.tableWrapper}>
              <div css={styles.cardWrapper}>
                <h1 css={styles.title}>{t('price_update.title', 'Price Update')}</h1>
                <div css={styles.titleWrapper}>
                  <div css={styles.projectIcon}><BuildingOfficeIcon /></div>
                  <div css={styles.projectName}>
                    {projectUpdate?.name || 'Mock Project'}
                  </div>
                </div>
              </div>
              {/* Subtitle */}
              {/* @ts-ignore */}
              <SubtitleContainer
                unavailable_flats={unavailable_flats}
                total_flats={total_flats}
                revenueIncreaseText={revenueIncreaseText}
                revenue_increase={revenue_increase || 0}
                initial_revenue={initial_revenue || 0}
                created_at={updateData.created_at}
                updated_at={updateData.updated_at}
                unavailableFlatsPercentage={unavailableFlatsPercentage}
                elapsedSaleDaysPercentage={elapsedSaleDaysPercentage}
                reserved_units={reserved_units}
                sold_units={sold_units}
              />
              {
                (price_increases_tables || []).length > 0 && (
                  <PriceUpdateTable
                    priceTrend={PriceTrendEnum.INCREASE}
                    // @ts-ignore TODO: Fix typing
                    data={price_increases_tables}
                  />
                )
              }
              {
                (price_decreases_tables || []).length > 0 && (
                  <PriceUpdateTable
                    priceTrend={PriceTrendEnum.DECREASE}
                    // @ts-ignore TODO: Fix typing
                    data={price_decreases_tables}
                  />
                )
              }
            </Card>
          </Col>
          <Col xs={24} md={6} css={styles.rightColumn} ref={setContainer}>
          <Affix css={styles.affix} offsetTop={1} target={() => container}>
            <div css={styles.affix}>
              <DetailPreviewCard showDetailButton data={data} />
            </div>
          </Affix>
          </Col>
        </Row>
        )
      ) : (
        <SubscribePage featureType='dashboard' />
      )}
    </Page>
  );
};
