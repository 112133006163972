import { FC, useState } from 'react';
import { ArrowsPointingOutIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { PieChart } from 'react-minimal-pie-chart';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../Card';
import TargetsIcon from '../../icons/targetsIcon.svg';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { Target, Targets as TargetsData } from '../../../../api/mockData/sales';
import { useRegionFormatting, useStoreState } from '../../../../hooks';

type TargetColumnProps = {
  target: Target;
}

type TargetProps = {
  onMaximize: () => void;
  targets: TargetsData;
}

const TargetColumn: FC<TargetColumnProps> = ({ target }) => {
  const { t } = useTranslation();
  const language = useStoreState((state) => state.user.profile?.language);
  const { getCurrency } = useRegionFormatting();
  const currency = getCurrency(true);
  const targetTypeValues: { [key: string]: string } = {
    floor_area: language === 'en' ? 'SM' : 'm2',
    revenue: currency,
  };

  const calculateRatio = (item: number): string | number => {
    const bigNumberFormatter = (num: number): string => {
      if (num > 1e6) {
        return `${(num / 1e6).toFixed(2)}m`;
      } if (num < 1e6 && num > 1e4) {
        return `${(num / 1e3).toFixed(2)}K`;
      }
        return `${num}`;
    };
    switch (target.target_type) {
      case 'revenue':
        return bigNumberFormatter(item);
      case 'floor_area':
        return bigNumberFormatter(item);
      default:
        return Math.round(item);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='font-bold capitalize xl:text-[1.25rem] 2xl:text-[1.5rem]'>{t(`sales.targets.subtitle.${target.target_type}`)}</div>
      <div className='flex items-center justify-center space-x-2'>
      <PieChart
        lineWidth={60}
        startAngle={-90}
        animate
        className='w-10 h-10'
        data={[
          { value: Math.round(target.progress || 0), color: AVAILABLE },
          { value: 100 - Math.round(target.progress || 0), color: SOLD },
        ]}
      />
      <span className='font-bold'>{Math.round(target.progress || 0)}%</span>
      </div>
      {/* <div className='flex text-base space-x-2'>
        <span>
          10/{calculateRatio(target.target_value)}
        </span>
        {target.target_type !== 'units' && (
          <span>
            {targetTypeValues[target.target_type]}
          </span>
        )}
      </div> */}
    </div>
  );
};

export const Targets: FC<TargetProps> = ({ onMaximize, targets }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const sortedTargets = targets.targets.sort((a, b) => {
    if (a.mandatory === b.mandatory) {
      return dayjs(a.target_month).isBefore(dayjs(b.target_month)) ? -1 : 1;
    }
    return a.mandatory ? -1 : 1;
  });
  const paginateTargets = (targetsArray?: Target[]): Record<number, Target[]> => {
    if (targetsArray && targetsArray.length > 0) {
      const results = targetsArray.reduce((pagesCount: Record<number, Target[]>, item, index) => {
        const pageNumber = Math.floor(index / 3) + 1;
        const pageNo = pagesCount[pageNumber] || [];
        pageNo.push(item);
        return { ...pagesCount, [pageNumber]: pageNo };
      }, {});
      if (results[Object.keys(results).length].length === 3) {
        results[Object.keys(results).length + 1] = [];
      }
      return results;
    }
    return [];
  };
  const pagesResults = paginateTargets(sortedTargets);
  const pages = Object.keys(pagesResults);
  const emptyDivsCount = Math.max(0, 3 - (pagesResults[page] ? pagesResults[page].length : 0));

  const handlePaginate = (direction: 'next' | 'prev'): void => {
    if (direction === 'next') {
      setPage(page < pages.length ? page + 1 : page);
    } else {
      setPage(page > 1 ? page - 1 : page);
    }
  };

  return (
    <Card bordered={false} className='targets'>
      <div className='relative flex flex-col space-y-4 flex-1 w-full h-full select-none'>
        <div className='flex justify-between items-center'>
          <div className='flex space-x-5 items-center font-bold'>
            <div className='flex items-center h-14 w-14 bg-[#9EB9DA] rounded-full p-2 capitalize'>
              <img src={TargetsIcon} alt='Sales Targets' />
            </div>
          <div>{t('sales.targets.title', 'Sales Targets')}</div>
          </div>
          <ArrowsPointingOutIcon className='w-9 h-9 stroke-2 cursor-pointer' onClick={onMaximize} />
        </div>
        {page > 1 && <ChevronLeftIcon className='absolute left-0 top-1/2 w-8 h-8 stroke-[3px] text-[#BDBDBD] cursor-pointer' onClick={() => handlePaginate('prev')} />}
        <div className='flex flex-1 items-center justify-evenly'>
          {pagesResults[page] && pagesResults[page].map((target) => (
            <TargetColumn target={target} />
          ))}
          {Array.from({ length: emptyDivsCount }).map(() => (
            <span className='text-bmgray cursor-pointer hover:bg-gray-50 rounded-lg p-3 capitalize' onClick={onMaximize}>+ {t('sales.targets.add_new', 'Add New')}</span>
          ))}
        </div>
        { pages && page < pages.length && <ChevronRightIcon className='absolute right-0 top-1/2 w-8 h-8 stroke-[3px] text-[#BDBDBD] cursor-pointer' onClick={() => handlePaginate('next')} />}
      </div>
    </Card>
  );
};
