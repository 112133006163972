import { css } from '@emotion/react/macro';
import { BOLD_FONT_WEIGHT } from '../../styles/constants';

export const styles = {
  title: css({
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '2rem',
  }),
  label: css({
    display: 'flex',
    fontSize: '1.4rem',
    fontWeight: BOLD_FONT_WEIGHT,
    marginTop: '0.8rem',
  }),
  value: css({ fontSize: '1.3rem' }),
  tooltip: css({ fontSize: '1.3rem', maxWidth: '60rem', lineHeight: '2rem' }),
  recipientsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }),
  recipients: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F1F1F1',
    borderRadius: 3,
    minWidth: '12rem',
    padding: '0.5rem 1rem',
    marginTop: '0.75rem',
  }),
  removeButton: css({
    marginLeft: '1rem',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    width: '1.5rem',
    height: '1.5rem',
    boxShadow: 'none',
  }),
  removeIcon: css({
    width: '1.5rem',
    strokeWidth: 3,
    color: '#828282',
  }),
};
