import { FC } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { formatPercent, getSum } from '../../utils';
import { useRegionFormatting } from '../../hooks';
import {
  HousePartBreakdown,
  PriceBreakdown,
} from '../../api/mockData/priceListDetailData';
import {
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
} from '../../styles/constants';
import { HouseArea } from '../../api/enums';
import { ChartCard } from '../ChartCard';

interface Props {
  data?: PriceBreakdown;
}

const getSumOfHouseArea = (
  houseAreaData?: HousePartBreakdown<unknown>,
): number => {
  let sum = 0;
  if (houseAreaData) {
    for (const [, value] of Object.entries(houseAreaData)) {
      sum += value?.price ?? 0;
    }
  }
  return sum;
};

export const PriceOverview: FC<Props> = ({ data: inputData }) => {
  const { exterior, floor, interior, layout, orientation } = inputData || {};
  const { t } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const data = [
    {
      part: t(`enums.house_areas.${HouseArea.INTERIOR}`),
      value: getSumOfHouseArea(interior),
    },
    {
      part: t(`enums.house_areas.${HouseArea.EXTERIOR}`),
      value: getSumOfHouseArea(exterior),
    },
    {
      part: t(`enums.house_areas.${HouseArea.FLOOR}`),
      value: getSumOfHouseArea(floor),
    },
    {
      part: t(`enums.house_areas.${HouseArea.ORIENTATION}`),
      value: getSumOfHouseArea(orientation),
    },
    {
      part: t(`enums.house_areas.${HouseArea.LAYOUT}`),
      value: getSumOfHouseArea(layout),
    },
  ];
  const total = getSum(data.map((v) => v.value));
  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'part',
    radius: 1,
    innerRadius: 0.6,
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    statistic: {
      title: false,
      content: false,
    },
    color: [GREY_1, BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5],
    label: false,
    tooltip: {
      shared: false,
      customContent: (title, values) =>
        `<div class="bm-tooltip-2">
          <div class="bmt-title">${t(`${title}`)}</div>
          <div class="bmt-subtitle">${formatCurrency(
            values[0]?.value ?? 0,
          )}</div>
          <div class="bmt-content">${formatPercent(
            (values[0]?.value ?? 0) / total,
          )}</div>
        </div>`,
    },
  };

  return (
    <ChartCard
      title={t('price_list.detail.price_overview')}
      chart={<Pie {...config} />}
    />
  );
};
