import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useStoreState } from '../../hooks';
import { ProjectSelect } from '..';
import { OverviewLoading } from '../OverviewLoading';

export const DashboardFilters: FC = () => {
  const { aggDate } = useStoreState((state) => state.filters);
  const {
    i18n: { language },
  } = useTranslation();

  const formattedDate = aggDate
    ? new Date(aggDate).toLocaleDateString(language, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      })
    : null;

  return (
    <>
      <div>
        <OverviewLoading>
          <ProjectSelect />
        </OverviewLoading>
      </div>
      <div>
        <OverviewLoading>
          <b className='flex items-center gap-4 text-bmblue'>
          <CalendarIcon className='filters-icon' />
          {formattedDate}
          </b>
        </OverviewLoading>
      </div>
    </>
  );
};
