import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStoreState } from '../hooks';
import { Paths } from './Paths';

export const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const isLoggedIn = useStoreState((state) => state.user.isLoggedIn);
  const location = useLocation();
  return isLoggedIn ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Navigate to={Paths.LOGIN} state={{ from: location }} replace />
  );
};
