/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BodyParams,
  UserReport,
} from '../models/index';
import {
    BodyParamsFromJSON,
    BodyParamsToJSON,
    UserReportFromJSON,
    UserReportToJSON,
} from '../models/index';

export interface BuiltmindApiReportsEndpointsCreateReportRequest {
    marketType: BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum;
    name: string;
    country: BuiltmindApiReportsEndpointsCreateReportCountryEnum;
    bodyParams: BodyParams;
    numberOfDays?: number;
    userId: string;
}

export interface BuiltmindApiReportsEndpointsDeleteReportRequest {
    reportId: string;
}

export interface BuiltmindApiReportsEndpointsUpdateReportNameRequest {
    reportId: string;
    name: string;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * N/A
     * Create a Report
     */
    async reportsEndpointsCreateReportRaw(requestParameters: BuiltmindApiReportsEndpointsCreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.marketType === null || requestParameters.marketType === undefined) {
            throw new runtime.RequiredError('marketType','Required parameter requestParameters.marketType was null or undefined when calling builtmindApiReportsEndpointsCreateReport.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling builtmindApiReportsEndpointsCreateReport.');
        }

        if (requestParameters.country === null || requestParameters.country === undefined) {
            throw new runtime.RequiredError('country','Required parameter requestParameters.country was null or undefined when calling builtmindApiReportsEndpointsCreateReport.');
        }

        if (requestParameters.bodyParams === null || requestParameters.bodyParams === undefined) {
            throw new runtime.RequiredError('bodyParams','Required parameter requestParameters.bodyParams was null or undefined when calling builtmindApiReportsEndpointsCreateReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.marketType !== undefined) {
            queryParameters['market_type'] = requestParameters.marketType;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        if (requestParameters.numberOfDays !== undefined) {
            queryParameters['number_of_days'] = requestParameters.numberOfDays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyParamsToJSON(requestParameters.bodyParams),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * N/A
     * Create a Report
     */
    async reportsEndpointsCreateReport(requestParameters: BuiltmindApiReportsEndpointsCreateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsEndpointsCreateReportRaw(requestParameters, initOverrides);
    }

    /**
     * N/A
     * Delete a Report
     */
    async reportsEndpointsDeleteReportRaw(requestParameters: BuiltmindApiReportsEndpointsDeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiReportsEndpointsDeleteReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.reportId !== undefined) {
            queryParameters['report_id'] = requestParameters.reportId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/report`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * N/A
     * Delete a Report
     */
    async reportsEndpointsDeleteReport(requestParameters: BuiltmindApiReportsEndpointsDeleteReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsEndpointsDeleteReportRaw(requestParameters, initOverrides);
    }

    /**
     * N/A
     * List user reports
     */
    async reportsEndpointsGetReportsByUserIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserReport>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/report/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(UserReportFromJSON));
    }

    /**
     * N/A
     * List user reports
     */
    async reportsEndpointsGetReportsByUserId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserReport>> {
        const response = await this.reportsEndpointsGetReportsByUserIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * N/A
     * Patch a Report name
     */
    async reportsEndpointsUpdateReportNameRaw(requestParameters: BuiltmindApiReportsEndpointsUpdateReportNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiReportsEndpointsUpdateReportName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling builtmindApiReportsEndpointsUpdateReportName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/report/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * N/A
     * Patch a Report name
     */
    async reportsEndpointsUpdateReportName(requestParameters: BuiltmindApiReportsEndpointsUpdateReportNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportsEndpointsUpdateReportNameRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum = {
    Rent: 'rent',
    Sale: 'sale'
} as const;
export type BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum = typeof BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum[keyof typeof BuiltmindApiReportsEndpointsCreateReportMarketTypeEnum];
/**
 * @export
 */
export const BuiltmindApiReportsEndpointsCreateReportCountryEnum = {
    Cz: 'CZ',
    Sk: 'SK'
} as const;
export type BuiltmindApiReportsEndpointsCreateReportCountryEnum = typeof BuiltmindApiReportsEndpointsCreateReportCountryEnum[keyof typeof BuiltmindApiReportsEndpointsCreateReportCountryEnum];
