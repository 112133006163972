import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { AVAILABLE, BLUE_5, SOLD } from '../../styles/constants';
import { State } from '../../api/enums';
import { ChartCard } from '../ChartCard';
import { getColumnOptions } from '../../utils/defaultChartConfig';
import { ProjectType } from '../../store/types';

interface Data {
  price: number;
  layout: string;
  state: State;
}

export const Price: FC = () => {
  const { t } = useTranslation();
  const { formatCurrency, isUsRegion, sortByLayouts, calculateVatPrice } =
    useRegionFormatting();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  const data = useStoreState((state) =>
    sortByLayouts(state.dashboard.data?.price ?? []),
  ).flatMap(({ available, layout, price_update, sold }) => [
    {
      price: calculateVatPrice(sold, 120),
      layout,
      state: isRent ? State.LEASED : State.SOLD,
    },
    {
      price: calculateVatPrice(available, 120),
      layout,
      state: State.AVAILABLE,
    },
    {
      price: calculateVatPrice(price_update, 120),
      layout,
      state: isRent ? State.RECOMMENDATION : State.PRICE_UPDATE,
    },
  ]);

  const { getLayout } = useFlatLayout();

  const config: ColumnConfig = getColumnOptions({
    data,
    isGroup: true,
    xField: 'layout',
    yField: 'price',
    seriesField: 'state',
    color: [SOLD, AVAILABLE, BLUE_5],
    legend: {
      itemName: {
        formatter: (value) => t(`enums.state.${value}`),
      },
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
    },
    xAxis: {
      label: {
        formatter: (value) => getLayout(value),
        autoHide: false,
        autoRotate: true,
      },
    },
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: t(`enums.state.${value.state}`),
          value: formatCurrency(value.price) ?? '',
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });

  return (
    <ChartCard
      title={t(`dashboard.${isRent ? 'rent' : 'price'}.title`)}
      subtitle={
        isRent
          ? t('dashboard.rent.subtitle')
          : t(`dashboard.price.subtitle_${isUsRegion ? 'SF' : 'SM'}`)
      }
      chart={<Column {...config} />}
    />
  );
};
