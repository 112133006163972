/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DaysOnTheMarket } from './DaysOnTheMarket';
import {
    DaysOnTheMarketFromJSON,
    DaysOnTheMarketFromJSONTyped,
    DaysOnTheMarketToJSON,
} from './DaysOnTheMarket';

/**
 * 
 * @export
 * @interface DaysOnTheMarketResponseSchema
 */
export interface DaysOnTheMarketResponseSchema {
    /**
     * 
     * @type {Array<DaysOnTheMarket>}
     * @memberof DaysOnTheMarketResponseSchema
     */
    daysOnTheMarket: Array<DaysOnTheMarket>;
}

/**
 * Check if a given object implements the DaysOnTheMarketResponseSchema interface.
 */
export function instanceOfDaysOnTheMarketResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "daysOnTheMarket" in value;

    return isInstance;
}

export function DaysOnTheMarketResponseSchemaFromJSON(json: any): DaysOnTheMarketResponseSchema {
    return DaysOnTheMarketResponseSchemaFromJSONTyped(json, false);
}

export function DaysOnTheMarketResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaysOnTheMarketResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daysOnTheMarket': ((json['days_on_the_market'] as Array<any>).map(DaysOnTheMarketFromJSON)),
    };
}

export function DaysOnTheMarketResponseSchemaToJSON(value?: DaysOnTheMarketResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days_on_the_market': ((value.daysOnTheMarket as Array<any>).map(DaysOnTheMarketToJSON)),
    };
}

