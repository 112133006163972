/** @jsxImportSource @emotion/react */
import { FC, PropsWithChildren, useState } from 'react';
import { Button, Modal, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ZoomInOutlined } from '@ant-design/icons';
import { styles as customStyles } from './styles';

export const Zoom: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <div css={customStyles.container}>
      <Popover content={t('common.zoom', 'Zoom')}>
        <Button
          css={customStyles.searchButton}
          type='primary'
          shape='circle'
          icon={<ZoomInOutlined />}
          onClick={handleOpen}
        />
      </Popover>
      <Modal
        className='bm-modal'
        css={customStyles.modal}
        open={isOpen}
        footer={null}
        onCancel={handleClose}
      >
        {children}
      </Modal>
    </div>
  );
};
