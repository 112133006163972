import { FC, useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = {
  className?: string;
  fileNames: string[];
  onFileChange: (fileName: string, file: File | null) => void;
  multiple?: boolean;
  uploadText: string;
  uploadTextBold: string;
  accept?: Accept;
};

export const FileSelector: FC<Props> = ({
  className,
  fileNames,
  onFileChange,
  multiple = false,
  uploadText,
  uploadTextBold,
  accept,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        if (multiple) {
          acceptedFiles.forEach((file) => {
            onFileChange(file.name, file);
          });
        } else {
          const file = acceptedFiles[0];
          onFileChange(file.name, file);
        }
      }
    },
    [onFileChange, multiple],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  const handleRemoveFiles = (): void => {
    fileNames.forEach((fileName) => {
      onFileChange(fileName, null);
    });
  };

  return (
    <div
      {...getRootProps()}
      className={clsx(
        className,
        'border-[2px] border-dotted rounded-[30px] w-full h-auto cursor-pointer',
        fileNames.length > 0
          ? 'border-bmblue bg-[#CCD9EC] pt-8 px-8'
          : 'border-bmgray bg-[#F1F1F1] py-24',
        isDragActive && 'border-bmblue bg-[#CCD9EC]',
      )}
    >
      <input {...getInputProps()} />
      <div className='flex justify-end'>
        {fileNames.length > 0 && (
          <div
            className='cursor-pointer'
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveFiles();
            }}
          >
            <XMarkIcon color='#313B5E' width='20px' />
          </div>
        )}
      </div>
      <div
        className={clsx(
          'flex flex-col items-center justify-center h-full',
          fileNames.length > 0 ? 'pt-8' : 'pt-0',
        )}
      >
        <CloudArrowUpIcon
          width='40px'
          color={fileNames.length > 0 ? '#313B5E' : '#828282'}
        />
        {fileNames.length > 0 ? (
          <div className='pb-24 w-full px-10 flex flex-col items-center'>
            <div className='overflow-y-scroll max-h-[200px]'>
              {fileNames.map((fileName) => (
                <span key={fileName} className='font-bold text-bmblue'>
                  {fileName}
                </span>
              ))}
            </div>
          </div>
        ) : (
          <p className='text-bmgray'>
            <span className='font-bold'>{uploadTextBold}</span> {uploadText}
          </p>
        )}
      </div>
    </div>
  );
};
