import { css } from '@emotion/react/macro';

export const styles = {
  templateOption: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  selectBtn: css({ border: '1px solid #BDBDBD' }),
  input: css({
    borderRadius: '30px',
  }),
  selector: css({
    padding: '3px',
    border: '1px solid #BDBDBD',
    borderRadius: '30px',
    fontSize: '14px',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    color: '#828282',
    msUserSelect: 'none',
    '& > span': {
      padding: '0px 10px',
    },
  }),
  checkedSelector: css({
    padding: '3px',
    backgroundColor: 'transparent',
    border: '1px solid #313B5E',
    borderRadius: '30px',
    color: '#313B5E',
    fontSize: '14px',
    fontWeight: 600,
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    '& > span': {
      padding: '0px 10px',
    },
  }),
};
