/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LayoutUnits } from './LayoutUnits';
import {
    LayoutUnitsFromJSON,
    LayoutUnitsFromJSONTyped,
    LayoutUnitsToJSON,
} from './LayoutUnits';

/**
 * 
 * @export
 * @interface DisappearedUnitsLayoutsResponseSchema
 */
export interface DisappearedUnitsLayoutsResponseSchema {
    /**
     * 
     * @type {string}
     * @memberof DisappearedUnitsLayoutsResponseSchema
     */
    createdDate: string;
    /**
     * 
     * @type {Array<LayoutUnits>}
     * @memberof DisappearedUnitsLayoutsResponseSchema
     */
    disappearedLayout: Array<LayoutUnits>;
}

/**
 * Check if a given object implements the DisappearedUnitsLayoutsResponseSchema interface.
 */
export function instanceOfDisappearedUnitsLayoutsResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "disappearedLayout" in value;

    return isInstance;
}

export function DisappearedUnitsLayoutsResponseSchemaFromJSON(json: any): DisappearedUnitsLayoutsResponseSchema {
    return DisappearedUnitsLayoutsResponseSchemaFromJSONTyped(json, false);
}

export function DisappearedUnitsLayoutsResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsLayoutsResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': json['created_date'],
        'disappearedLayout': ((json['disappeared_layout'] as Array<any>).map(LayoutUnitsFromJSON)),
    };
}

export function DisappearedUnitsLayoutsResponseSchemaToJSON(value?: DisappearedUnitsLayoutsResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_date': value.createdDate,
        'disappeared_layout': ((value.disappearedLayout as Array<any>).map(LayoutUnitsToJSON)),
    };
}

