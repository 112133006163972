import { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Input } from '../../inputs/Input';
import { Button } from '../../button/Button';
import { FormContainer } from '../FormContainer';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { BackButton } from '../../button/BackButton';

export const EmailNotifications: FC = () => {
  const { t } = useTranslation();

  const { emails, projectType } = useStoreState((state) => state.onboarding);
  const { data } = useStoreState((state) => state.subscribe);
  const {
    handleNext,
    handleBack,
    createOnboardingProject,
    setCurrentPage,
    setEmails,
  } = useStoreActions((actions) => actions.onboarding);

  const [emailError, setEmailError] = useState<string | null>(null);
  const [emailInput, setEmailInput] = useState<string>('');
  const isRental = projectType.rental;

  const emailSchema = z.string().email({
    message: t(
      'onboarding.email_notification.invalid',
      'Invalid email address',
    ),
  });

  useEffect(() => {
    if (data && data?.email_notification_list.length > 0) {
      setCurrentPage(1);
      createOnboardingProject();
    }
  }, []);

  useEffect(() => {
    setEmailError(null);
  }, [emailInput]);

  const validateEmail = (value: string): boolean => {
    try {
      emailSchema.parse(value);
      setEmailError(null);
      return true;
    } catch (e) {
      if (e instanceof z.ZodError) {
        setEmailError(e.errors[0].message);
      }
      return false;
    }
  };

  const handleNextClick = (): void => {
    handleNext();
    createOnboardingProject();
  };

  const handleEmailKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (validateEmail(emailInput)) {
        const updatedEmails = [...emails, emailInput];
        setEmails(updatedEmails);
        setEmailInput('');
      }
    }
  };

  const handleDeleteEmail = (emailToDelete: string): void => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    setEmails(updatedEmails);
  };

  return (
    <FormContainer>
      <div className='flex flex-col'>
        <BackButton handleClick={handleBack} />
        <Heading className='mt-4'>
          {t('onboarding.email_notification.title', 'E-mail notifications')}
        </Heading>
        <Subtitle className='mt-4'>
          {t(
            'onboarding.email_notification.subtitle',
            'We will notify you about {{type}} updates via e-mail.',
            {
              type: isRental
                ? t('onboarding.common.rent', 'Rent').toLowerCase()
                : t('onboarding.common.price', 'Price').toLowerCase(),
            },
          )}
        </Subtitle>
        <Input
          value={emailInput}
          setValue={setEmailInput}
          className='mt-10'
          placeholder={t(
            'onboarding.email_notification.placeholder',
            'Type in ...',
          )}
          label={t('onboarding.email_notification.label', 'E-mail Address')}
          onKeyDown={handleEmailKeyDown}
        />
        <motion.div
          className='text-red-500 text-lg h-[1.5rem]'
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: emailError ? 1 : 0, y: emailError ? 0 : -5 }}
          transition={{ duration: 0.1 }}
          style={{ height: '1.5rem' }}
        >
          {emailError}
        </motion.div>
        <div className='mt-4 text-base'>
          {emails.length > 0 && (
            <ul className='flex gap-4 flex-wrap'>
              {emails.map((mail) => (
                <li
                  key={mail}
                  className='flex justify-between items-center gap-4 py-2 px-4 rounded-[25px] bg-[#D9D9D9] text-bmgray w-fit'
                >
                  <span>{mail}</span>
                  <XMarkIcon
                    onClick={() => handleDeleteEmail(mail)}
                    className='w-6 stroke-2 cursor-pointer'
                    color='#828282'
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className='flex justify-center'>
        <Button
          disabled={emails.length === 0 || emailError !== null}
          handleClick={handleNextClick}
        >
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
