/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuildingConstructionPhase } from './BuildingConstructionPhase';
import {
    BuildingConstructionPhaseFromJSON,
    BuildingConstructionPhaseFromJSONTyped,
    BuildingConstructionPhaseToJSON,
} from './BuildingConstructionPhase';
import type { MultiPolygonalSchema } from './MultiPolygonalSchema';
import {
    MultiPolygonalSchemaFromJSON,
    MultiPolygonalSchemaFromJSONTyped,
    MultiPolygonalSchemaToJSON,
} from './MultiPolygonalSchema';

/**
 * 
 * @export
 * @interface BodyParams
 */
export interface BodyParams {
    /**
     * 
     * @type {MultiPolygonalSchema}
     * @memberof BodyParams
     */
    multiPolygon: MultiPolygonalSchema;
    /**
     * 
     * @type {Array<BuildingConstructionPhase>}
     * @memberof BodyParams
     */
    buildingConditions: Array<BuildingConstructionPhase>;
}

/**
 * Check if a given object implements the BodyParams interface.
 */
export function instanceOfBodyParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "multiPolygon" in value;
    isInstance = isInstance && "buildingConditions" in value;

    return isInstance;
}

export function BodyParamsFromJSON(json: any): BodyParams {
    return BodyParamsFromJSONTyped(json, false);
}

export function BodyParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'multiPolygon': MultiPolygonalSchemaFromJSON(json['multi_polygon']),
        'buildingConditions': ((json['building_conditions'] as Array<any>).map(BuildingConstructionPhaseFromJSON)),
    };
}

export function BodyParamsToJSON(value?: BodyParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'multi_polygon': MultiPolygonalSchemaToJSON(value.multiPolygon),
        'building_conditions': ((value.buildingConditions as Array<any>).map(BuildingConstructionPhaseToJSON)),
    };
}

