/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LayoutDataclass
 */
export interface LayoutDataclass {
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclass
     */
    countOfRegularRooms: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclass
     */
    units: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclass
     */
    interiorSm: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclass
     */
    pricePerSm: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutDataclass
     */
    price: number;
}

/**
 * Check if a given object implements the LayoutDataclass interface.
 */
export function instanceOfLayoutDataclass(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countOfRegularRooms" in value;
    isInstance = isInstance && "units" in value;
    isInstance = isInstance && "interiorSm" in value;
    isInstance = isInstance && "pricePerSm" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function LayoutDataclassFromJSON(json: any): LayoutDataclass {
    return LayoutDataclassFromJSONTyped(json, false);
}

export function LayoutDataclassFromJSONTyped(json: any, ignoreDiscriminator: boolean): LayoutDataclass {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countOfRegularRooms': json['count_of_regular_rooms'],
        'units': json['units'],
        'interiorSm': json['interior_sm'],
        'pricePerSm': json['price_per_sm'],
        'price': json['price'],
    };
}

export function LayoutDataclassToJSON(value?: LayoutDataclass | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count_of_regular_rooms': value.countOfRegularRooms,
        'units': value.units,
        'interior_sm': value.interiorSm,
        'price_per_sm': value.pricePerSm,
        'price': value.price,
    };
}

