import { useTranslation } from 'react-i18next';
import { useStoreState } from '.';
import { MetricSystemEnum } from '../api/enums';

export function useFlatLayout(): {
  getLayout: (value: string) => string;
  getLayoutAbbr: (value: string) => string;
} {
  const metricSystem = useStoreState(
    (state) => state.user.profile?.system_of_measurement,
  );
  const [t] = useTranslation();
  const getLayout = (value: string): string => {
    if (value === null) {
      return t('common.null', 'Unknown');
    }
    if (metricSystem === MetricSystemEnum.METRIC) {
      return t(`enums.eu_layouts.${value}`);
    }
    return t(`enums.layouts.${value}`);
  };

  const getLayoutAbbr = (value: string | null): string => {
    if (value === null) {
      return t('common.null', 'Unknown');
    }
    if (metricSystem === MetricSystemEnum.METRIC) {
      return t(`enums.eu_layouts_abbreviation.${value}`);
    }
    return t(`enums.layouts_abbreviation.${value}`);
  };

  return { getLayout, getLayoutAbbr };
}
