import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZodIssue } from 'zod';

type Props = {
  errors: any[];
  warnings: any[];
};

export const formatRowRanges = (rows: number[]): string => {
  if (rows.length === 0) return '';

  const ranges: string[] = [];
  let start = rows[0];
  let end = rows[0];

  for (let i = 1; i < rows.length; i++) {
    if (rows[i] === end + 1) {
      end = rows[i];
    } else {
      if (start === end) {
        ranges.push(`${start}`);
      } else {
        ranges.push(`${start}-${end}`);
      }
      start = rows[i];
      end = rows[i];
    }
  }

  if (start === end) {
    ranges.push(`${start}`);
  } else {
    ranges.push(`${start}-${end}`);
  }

  return ranges.join(', ');
};

export const ErrorsBox: FC<Props> = ({ errors, warnings }) => {
  const { t } = useTranslation();

  const groupByMessage = (
    items: { row: number; issue: ZodIssue }[],
  ): { message: string; path: string; rows: number[] }[] => {
    const groupedItems: { [key: string]: { path: string; rows: number[] } } =
      {};

    items.forEach(({ row, issue }) => {
      const key = `${issue.message}-${issue.path.join(' -> ')}`;
      if (!groupedItems[key]) {
        groupedItems[key] = { path: issue.path.join(' -> '), rows: [] };
      }
      groupedItems[key].rows.push(row);
    });

    return Object.keys(groupedItems).map((key) => ({
      message: key.split('-')[0],
      path: groupedItems[key].path,
      rows: groupedItems[key].rows,
    }));
  };

  return (
    <div className='mt-4 border-[1px] border-[#BDBDBD] py-6 pl-6 rounded-[25px] bg-white'>
      <div className='p-2 rounded overflow-scroll h-[100px]'>
        {errors.length > 0 && (
          <div>
            <h3 className='font-bold text-red-500'>
              {t('onboarding.common.errors', 'Errors')}
            </h3>
            <pre>
              {groupByMessage(
                errors.map(({ row, error }) => ({ row, issue: error })),
              ).map(({ message, path, rows }) => (
                <div key={message}>
                  <strong>{t('onboarding.common.error', 'Error')}</strong>{' '}
                  {message}
                  <br />
                  <strong>{t('onboarding.common.path', 'Path')}</strong> {path}
                  <br />
                  <strong>{t('onboarding.common.rows', 'Rows')}</strong>{' '}
                  {formatRowRanges(rows)}
                  <br />
                  <br />
                </div>
              ))}
            </pre>
          </div>
        )}
        {warnings.length > 0 && (
          <div>
            <h3 className='font-bold text-yellow-500'>
              {t('onboarding.common.warnings', 'Warnings')}
            </h3>
            <pre>
              {groupByMessage(
                warnings.map(({ row, warning }) => ({
                  row,
                  issue: warning,
                })),
              ).map(({ message, path, rows }) => (
                <div key={message}>
                  <strong>{t('onboarding.common.warning', 'Warning')}</strong>{' '}
                  {message}
                  <br />
                  <strong>{t('onboarding.common.path', 'Path')}</strong> {path}
                  <br />
                  <strong>{t('onboarding.common.rows', 'Rows')}</strong>{' '}
                  {formatRowRanges(rows)}
                  <br />
                  <br />
                </div>
              ))}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};
