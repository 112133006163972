import { FC, PropsWithChildren } from 'react';
import { Spin } from 'antd';
import { useStoreState } from '../hooks';

export const OverviewLoading: FC<PropsWithChildren> = ({ children }) => {
  const isLoading = useStoreState((state) => state.filters.isLoading);
  return isLoading ? (
    <div className='overview-loading-spin-wrapper'>
      <Spin spinning />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
