/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MarketType = {
    Rent: 'rent',
    Sale: 'sale'
} as const;
export type MarketType = typeof MarketType[keyof typeof MarketType];


export function MarketTypeFromJSON(json: any): MarketType {
    return MarketTypeFromJSONTyped(json, false);
}

export function MarketTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketType {
    return json as MarketType;
}

export function MarketTypeToJSON(value?: MarketType | null): any {
    return value as any;
}

