import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import enUS from 'antd/locale/en_US';
import enGB from 'antd/locale/en_GB';
import csCZ from 'antd/locale/cs_CZ';
import deDE from 'antd/locale/de_DE';
import skSK from 'antd/locale/sk_SK';
import { Locale } from 'antd/lib/locale';
import { useRegionFormatting, useStoreState } from '../hooks';
import { LanguageEnum } from '../api/enums';

export const LanguageHandler: FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(enGB);
  const language = useStoreState((state) => state.user.profile?.language);
  const { isUsRegion } = useRegionFormatting();
  const [, { changeLanguage }] = useTranslation();

  useEffect(() => {
    changeLanguage(language);
    switch (language) {
      case LanguageEnum.DE:
        setLocale(deDE);
        break;
      case LanguageEnum.CZ:
        setLocale(csCZ);
        break;
      case LanguageEnum.SK:
        setLocale(skSK);
        break;
      case LanguageEnum.EN:
        setLocale(isUsRegion ? enUS : enGB);
        break;
      default:
        setLocale(enGB);
        break;
    }
  }, [changeLanguage, language, isUsRegion]);
  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};
