import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketProjectExtended } from '../../../../../../store/types';
import { formatPercent } from '../../../../../../utils';
import { RowData, InfoPopover } from '../../../../../InfoPopover';

type Props = Pick<
  MarketProjectExtended,
  'payment_construction' | 'payment_contract' | 'payment_occupancy'
>;

export const Payments: FC<Props> = ({
  payment_construction,
  payment_contract,
  payment_occupancy,
}) => {
  const { t } = useTranslation();
  const rows: RowData[] = useMemo(
    () =>
      [
        { key: 'contract', value: payment_contract },
        { key: 'construction', value: payment_construction },
        { key: 'occupancy', value: payment_occupancy },
      ]
        .filter((row) => row.value !== null)
        .map(({ key, value }) => ({
          label: t(`project.payment_popup.payment_${key}`),
          value: formatPercent(value ?? 0),
        })),
    [payment_construction, payment_contract, payment_occupancy, t],
  );

  const label = useMemo(
    () =>
      [payment_contract, payment_construction, payment_occupancy]
        .map((v) => (v !== null ? formatPercent(v) : v))
        .filter((v) => v !== null)
        .join('-'),
    [payment_construction, payment_contract, payment_occupancy],
  );
  return (
    <InfoPopover
      label={label}
      popoverProps={{
        rows,
      }}
    />
  );
};
