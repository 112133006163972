import { useMemo } from 'react';
import { useStoreState } from '.';
import { Availability } from '../store/types';
import { PriceComparisonData } from '../api/types';
import { ProjectComparisonData } from '../components/project/Comparison';

interface Data {
  availability: string;
}

/**
 * Hook to map reserved data to sold/available units based on user settings
 */
export const useReservedData = (): {
  mapReservedData: <T extends Data>(data: T[]) => T[];
  transformReserved: (data: (PriceComparisonData | ProjectComparisonData)[]) => (PriceComparisonData | ProjectComparisonData)[];
  transformCompetitorsData: (data: PriceComparisonData[], competitors?: number[]) => PriceComparisonData[];
} => {
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const transformReserved = (data: (PriceComparisonData | ProjectComparisonData)[]): (PriceComparisonData | ProjectComparisonData)[] =>
    data.reduce<(PriceComparisonData | ProjectComparisonData)[]>((acc, layoutData) => {
      const mappedReservedValue = isSold
        ? Availability.SOLD
        : Availability.AVAILABLE;
      const current = {
        ...layoutData,
        availability: layoutData.availability === 'reserved' ? mappedReservedValue : layoutData.availability,
      };
      const index = acc.findIndex((x) => x.availability === current.availability && x.layout === current.layout);
      if (index > -1) {
        const existingItem = acc[index];
        const totalCount = existingItem.market.count + current.market.count;
        const totalPrice = existingItem.market.price + current.market.price;
        acc[index].market = {
          count: totalCount,
          price: totalPrice,
          price_per_sm: (existingItem.market.price_per_sm * existingItem.market.count + current.market.price_per_sm * current.market.count) / totalCount,
        };
      } else {
        acc.push(current);
      }
      return acc;
  }, []);

  const mapReservedData = useMemo(
    () =>
      <T extends Data>(data: T[]): T[] => {
        const mappedReservedValue = isSold
          ? Availability.SOLD
          : Availability.AVAILABLE;
        return data.map((d) => ({
          ...d,
          availability:
            d.availability === Availability.RESERVED
              ? mappedReservedValue
              : d.availability,
        }));
      },
    [isSold],
  );

  const transformCompetitorsData = (data: PriceComparisonData[], competitors?: number[]): PriceComparisonData[] => {
    if (competitors && competitors.length > 0) {
      const projectData = data.filter((d) => d.project_name === 'current_dashboard');
      const competitorsData = data.filter((d) => d.project_name !== 'current_dashboard');
      return [...transformReserved(projectData), ...competitorsData] as PriceComparisonData[];
    }
    return transformReserved(data) as PriceComparisonData[];
  };

  return { mapReservedData, transformReserved, transformCompetitorsData };
};
