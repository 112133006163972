import { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { GetRowKey } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Card } from '..';
import { useStoreState, useRegionFormatting } from '../../hooks';
import { FloorOverview as IFloorOverview } from '../../api/mockData/dashboardData';
import { formatPercent, getNumericSorter, getUniqueId } from '../../utils';
import { ProjectType } from '../../store/types';

export const FloorOverview: FC = () => {
  const data = useStoreState(
    (state) => state.dashboard.data?.floor_overview ?? [],
  );
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { formatCurrency, calculateVatPrice } = useRegionFormatting();
  const { t } = useTranslation();
  const columns: ColumnsType<IFloorOverview> = [
    {
      title: t('dashboard.floor_overview.columns.floor'),
      dataIndex: 'floor',
      align: 'center',
      sorter: getNumericSorter('floor'),
    },
    {
      title: t(
        `dashboard.floor_overview.columns.${isRent ? 'occupancy' : 'sold'}`,
      ),
      dataIndex: isSold ? 'sold_and_reserved' : 'sold',
      align: 'center',
      render: (value: number) => formatPercent(value),
      sorter: getNumericSorter(isSold ? 'sold_and_reserved' : 'sold'),
    },
    {
      title: t(
        `dashboard.floor_overview.columns.${
          isRent ? 'average_rent' : 'average'
        }`,
      ),
      dataIndex: 'average',
      align: 'center',
      render: (value: number) => formatCurrency(calculateVatPrice(value, 120)),
      sorter: getNumericSorter('average'),
    },
  ];

  const handleRowKey: GetRowKey<IFloorOverview> = () => getUniqueId();
  return (
    <Card
      title={t('dashboard.floor_overview.title')}
      subtitle={
        isRent
          ? t('dashboard.floor_overview.rent.subtitle')
          : t('dashboard.floor_overview.subtitle')
      }
    >
      <div className='floor-overview'>
        <Table
          pagination={{ pageSize: 7 }}
          size='small'
          rowKey={handleRowKey}
          dataSource={data}
          columns={columns}
          scroll={{ x: true }}
          className='table'
        />
      </div>
    </Card>
  );
};
