import { useState } from 'react';

export enum FilterEnum {
  AVAILABLE = 'Available',
  SOLD = 'Sold',
  TOTAL = 'Total',
}

export const useFilter = (): {
  filterValue: string | number;
  handleChange: (value: string | number) => void;
} => {
  const [filterValue, setFilterValue] = useState<string | number>(
    FilterEnum.AVAILABLE,
  );
  const handleChange = (value: string | number): void => {
    setFilterValue(value);
  };

  return { filterValue, handleChange };
};
