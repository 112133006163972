import { FC, ReactNode, useMemo } from 'react';
import { Line, LineConfig, Area } from '@ant-design/plots';
import { Datum } from '@antv/g2plot';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useFlatLayout,
  useRegionFormatting,
  useStoreState,
} from '../../../../hooks';
import {
  AVAILABLE,
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
  GREY_2,
  GREY_3,
  SOLD,
} from '../../../../styles/constants';
import { PriceHistoryPrimaryData } from '../../../../api/types';
import {
  translateBlockChartValue,
} from '../../../../utils/utils';
import { getLineOptions } from '../../../../utils/defaultChartConfig';
import { AvailabilityFilterEnum, PriceType } from '.';

export interface Data extends PriceHistoryPrimaryData {
  count: number;
}
interface Props {
  isOverall: boolean;
  availability: AvailabilityFilterEnum;
  data: Data[];
  priceType: PriceType
}

const getChart = (
  availability: AvailabilityFilterEnum,
  config: LineConfig,
  isOverall: boolean,
): ReactNode => {
  if (availability === AvailabilityFilterEnum.ALL || !isOverall) {
    return <Line {...config} />;
  }
  return <Area {...config} />;
};

export const PriceHistoryChart: FC<Props> = ({
  data,
  isOverall,
  availability,
  priceType,
}) => {
  const { formatCurrency } = useRegionFormatting();
  const { displayValues } = useStoreState((state) => state.filters);
  const { getLayout } = useFlatLayout();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const minPrice = useMemo(() => {
    const minAvgPrice = data.reduce((min, item) => {
      if (item.avg_price === null) {
        return min; // Skip this item if the price is null
      }
      return Math.min(min, item.avg_price);
    }, Infinity);
    return Math.max(0, minAvgPrice * 0.95);
  }, [data]);

  const getColor = (): string[] => {
    if (isOverall) {
      if (availability === AvailabilityFilterEnum.SOLD) {
        return [SOLD];
      }
      if (availability === AvailabilityFilterEnum.AVAILABLE) {
        return [AVAILABLE];
      }
      // make sure that available is always blue
      if (data[0].series === 'available') {
        return [AVAILABLE, SOLD, BLUE_3, BLUE_2, BLUE_5];
      }
      return [SOLD, AVAILABLE, BLUE_3, BLUE_2, BLUE_5];
    }
    return [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1];
  };

  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);

  const labelFormatter = (() => {
    let index = 0;
    return ({ avg_price }: Datum) => {
      const label = index % 4 === 0 ? formatCurrency(Number(avg_price), undefined, undefined, priceType !== PriceType.PRICE_PER_SM) ?? '' : '';
      index++;
      return label;
    };
  })();

  const config: LineConfig = getLineOptions({
    smooth: true,
    data: data.sort((a, b) => {
      if (
        a.project_name === 'current_dashboard' &&
        b.project_name !== 'current_dashboard'
      ) {
        return -1;
      }
      if (
        a.project_name !== 'current_dashboard' &&
        b.project_name === 'current_dashboard'
      ) {
        return 1;
      }
      return 0;
    }),
    color: getColor(),
    xField: 'date',
    yField: 'avg_price',
    seriesField: isOverall ? 'series' : 'layout',
    label: displayValues ? {
      formatter: labelFormatter,
      style: {
        color: '#000',
      },
      position: 'top',
      rotate: -Math.PI / 3,
      offsetX: 15,
    } : undefined,
    tooltip: {
      formatter: (datum) => {
        const value = datum as PriceHistoryPrimaryData;
        return {
          name: isOverall ? String(value.series) : value.layout,
          value: formatCurrency(value.avg_price) ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
          });
        },
      },
      tickMethod: 'time',
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
      min: minPrice,
    },
    legend: {
      itemName: {
        formatter: translateValue,
      },
    },
  });
  return data.length > 0 ? (
    <>{getChart(availability, config, isOverall)}</>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
