import { FC, useMemo } from 'react';
import { useStoreActions, useStoreState } from '../../hooks';
import styles from './PriceUpdateNotifications.module.css';
import { NotificationCard } from './NotificationCard';

export const PriceUpdateNotifications: FC = () => {
  // @ts-ignore TODO: Fix typing
  // const updateData = useStoreState((state) => state.priceList.updateData) as PriceListUpdateData;
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { setProjectId } = useStoreActions((actions) => actions.filters);
  const { phaseId, projectId, isLoading } = useStoreState(
    (state) => state.filters,
  );
  const fetchUpdateData = useStoreActions((actions) => actions.priceList.fetchUpdateData);
  const projectUpdate = useMemo(() =>
    overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );

  const handleSeeMore = (selectProjectId: number): void => {
    if (!isLoading && projectId && phaseId) {
      if (projectUpdate) {
        setProjectId(selectProjectId);
        fetchUpdateData({ price_list_update_id: projectUpdate.id });
        // if (projectUpdate.user_seen === false) {
        //   updatePriceListUserSeen({ price_list_update_id: projectUpdate.id });
        // }
      }
    }
  };

  return (
    <div className={styles.notificationsContainer}>
      {overviewData?.updates.map((update) => (
        <NotificationCard key={update.name} projectName={update.name || ''} userSeen={update.user_seen} handleHasSeen={() => handleSeeMore(update.project_id)} />
      ))}
    </div>
  );
};
