import { css } from '@emotion/react/macro';

export const styles = {
  titleContainer: css({ display: 'flex', alignTtems: 'center' }),
  title: css({ marginRight: '1.2rem' }),
  contentContainer: css({
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  }),
  rightColumn: css({ height: '100%' }),
  affix: css({ height: '100%' }),
  iconBtn: css({
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: '#828282',
  }),
};
