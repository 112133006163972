import { FC } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../hooks';
import { formatPercent, getSum } from '../../utils';
import { AVAILABLE, RESERVED, SOLD } from '../../styles/constants';
import { State } from '../../api/enums';
import { ChartCard } from '../ChartCard';
import { getPieOptions } from '../../utils/defaultChartConfig';
import { ProjectType } from '../../store/types';

export const SaleOverview: FC = () => {
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const data = useStoreState(
    (state) =>
      state.dashboard.data?.sale_overview ?? {
        sold: 0,
        reserved: 0,
        available: 0,
      },
  );
  const total = getSum(Object.values(data));
  const config: PieConfig = getPieOptions({
    angleField: 'value',
    colorField: 'state',
    data: [
      {
        state: isRent ? State.LEASED : State.SOLD,
        value: data.sold,
      },
      {
        state: State.RESERVED,
        value: data.reserved,
      },
      {
        state: State.AVAILABLE,
        value: data.available,
      },
    ],
    legend: {
      itemName: {
        formatter: (value) => t(`enums.state.${value}`),
      },
    },
    color: [SOLD, RESERVED, AVAILABLE],
    label: false,
    tooltip: {
      customContent: (title, values) =>
        `<div class="bm-tooltip-2">
          <div class="bmt-title">${t(`enums.state.${title}`)}</div>
          <div class="bmt-subtitle">${values[0]?.value}/${total}</div>
          <div class="bmt-content">${formatPercent(
            (values[0]?.value ?? 0) / total,
          )}</div>
        </div>`,
    },
  });

  return (
    <ChartCard
      title={t(`dashboard.${isRent ? 'rent' : 'sale'}_overview.title`)}
      subtitle={t(`dashboard.${isRent ? 'rent' : 'sale'}_overview.subtitle`)}
      chart={<Pie {...config} />}
    />
  );
};
