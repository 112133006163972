/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DisappearedUnitsPriceHistoryLayoutResponseSchemav2,
} from '../models/index';
import {
    DisappearedUnitsPriceHistoryLayoutResponseSchemav2FromJSON,
    DisappearedUnitsPriceHistoryLayoutResponseSchemav2ToJSON,
} from '../models/index';

export interface BuiltmindApiApiDisappearedUnits7DaysLayoutRequest {
    reportId: string;
    startDate: string
    endDate: string
}

/**
 * 
 */
export class DevelopmentApi extends runtime.BaseAPI {

    /**
     * Get disappeared units price history by layout
     * Get disappeared units price history by layout
     */
    async disappearedUnits7DaysLayoutRaw(requestParameters: BuiltmindApiApiDisappearedUnits7DaysLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedUnitsPriceHistoryLayoutResponseSchemav2>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiDisappearedUnits7DaysLayout.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiDisappearedUnits7DaysLayout.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiDisappearedUnits7DaysLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/disappeared_units_7_days_layout/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedUnitsPriceHistoryLayoutResponseSchemav2FromJSON));
    }

    /**
     * Get disappeared units price history by layout
     * Get disappeared units price history by layout
     */
    async disappearedUnits7DaysLayout(requestParameters: BuiltmindApiApiDisappearedUnits7DaysLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedUnitsPriceHistoryLayoutResponseSchemav2>> {
        const response = await this.disappearedUnits7DaysLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
