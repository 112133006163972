import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Button } from '../../button/Button';
import { FormContainer } from '../FormContainer';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { DateSelect } from '../../inputs/dateSelect/DateSelect';
import { TargetsSection } from '../../salesTargets/targets/Targets';
import { Target } from '../../../../api/mockData/sales';
import { BackButton } from '../../button/BackButton';

export const SalesTargetsPlans: FC = () => {
  const { t } = useTranslation();

  const { handleNext, setSaleEnd, setSaleStart, setTargets, handleBack } =
    useStoreActions((actions) => actions.onboarding);
  const { saleStart, saleEnd, projectType, targets } = useStoreState(
    (state) => state.onboarding,
  );
  const [targetsData, setTargetsData] = useState<Partial<Target>[]>(targets);
  const isRental = projectType.rental;

  useEffect(() => {
    if (dayjs(saleStart) > dayjs(saleEnd)) {
      setSaleEnd(null);
    }
  }, [saleStart]);

  const handleClick = (): void => {
    handleNext();
    setTargets(targetsData);
  };

  return (
    <FormContainer>
      <div className='flex flex-col'>
        <BackButton handleClick={handleBack} />
        <Heading className='mt-4'>
          {t('onboarding.sales_targets.title', '{{type}} Targets & Plans', {
            type: isRental
              ? t('onboarding.common.rent', 'Rent')
              : t('onboarding.common.sales', 'Sales'),
          })}
        </Heading>
        <Subtitle className='mt-4'>
          {t(
            'onboarding.sale_targets.subtitle',
            'BuiltMind will automatically adjust the prices based on your {{type}} targets and plans.',
            {
              type: isRental
                ? t('onboarding.common.rent', 'Rent').toLowerCase()
                : t('onboarding.common.sales', 'Sales').toLowerCase(),
            },
          )}
        </Subtitle>
        <DateSelect
          className='my-8'
          label={
            isRental
              ? t('onboarding.date_select.start_of_leasing', 'Start of Leasing')
              : t('onboarding.date_select.sale_start', 'Sale Start')
          }
          setSelectedDate={setSaleStart}
          selectedDate={saleStart ?? undefined}
        />
        <DateSelect
          className='mb-8'
          selectedDate={saleEnd ?? undefined}
          label={
            isRental
              ? t(
                  'onboarding.date_select_full_occupancy_target',
                  'Full Occupancy Target',
                )
              : t('onboarding.date_select.sale_end', 'Sale End')
          }
          setSelectedDate={setSaleEnd}
          disabled={!!!saleStart}
          disableDate={saleStart ?? dayjs().subtract(1)}
        />
        <TargetsSection targets={targetsData} setTargets={setTargetsData} />
      </div>
      <div className='flex justify-center'>
        <Button disabled={!(saleEnd && saleStart)} handleClick={handleClick}>
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
