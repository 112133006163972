/* eslint-disable import/no-extraneous-dependencies */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string | undefined;
  setValue: any;
  items: string[];
  className?: string;
};

export const Dropdown: FC<Props> = ({ value, setValue, items, className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div className='cursor-pointer'>
            {!value?.length ? (
              <div className='flex items-center justify-between gap-2'>
                <span className='text-bmgray font-lg'>
                  {t('onboarding.common.select', 'Select')}
                </span>
                <ChevronDownIcon width='12px' color='#828282' />
              </div>
            ) : (
              <span className='text-bmblue font-lg font-bold'>{value}</span>
            )}
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content sideOffset={2}>
            <div className='flex flex-col h-auto py-1 bg-white drop-shadow-xl rounded-md'>
              {items.map((item) => (
                <DropdownMenu.Item
                  key={item}
                  onSelect={() => setValue(item)}
                  asChild
                >
                  <span
                    className={clsx(
                      value === item
                        ? 'font-bold bg-[#CCD9EC]'
                        : 'hover:bg-[#D9D9D9]',
                      'outline-none text-xl cursor-pointer py-1 text-bmblue w-auto px-6 mx-1 rounded-md transition-all',
                    )}
                  >
                    {item}
                  </span>
                </DropdownMenu.Item>
              ))}
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};
