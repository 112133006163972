/* eslint-disable no-console */
/* eslint-disable no-loop-func */
import { FC, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import {
  DrawingManagerF,
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import clsx from 'clsx';
import { ProjectDetail } from './ProjectDetail';
import { MarketProjectExtended, Mode, Territory } from '../../store/types';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import {
  calculateProjectData,
  getBounds,
  getCountryIsoCode,
  getGeometry,
  getMapPin,
  parseGeometry,
  simplifyPolygon,
} from '../../utils/utils';
import { GOOGLE_MAPS_LIBRARIES } from '../../constants';
import { CustomPolygon } from './CustomPolygon';
import { CenteredSpinner } from '../CenteredSpinner';
import { TerritoryAutocomplete } from './TerritoryAutocomplete';
import { territoriesApi } from '../../api/secondary';
import {
  BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum,
  MultiPolygonalSchema,
  TerritoryGeomsResponseSchema,
} from '../../api/secondaryClient';
import { LatLng } from '../../utils/types';
import { SimplifiedProjects } from '../../store/market';
import { PricePerSmCalcType } from '../TopBar/SettingsPopover';
import { getProject } from '../../api';
import { findClosestLocationToCenter } from '../dailyNews/Map';

enum CountryEnum {
  Cz = 'CZ',
  Sk = 'SK',
}

interface MapProps {
  data: SimplifiedProjects[];
}

export const Map: FC<MapProps> = ({ data }) => {
  /* STORE ACTIONS */
  // const fetchProjects = useStoreActions((actions) => actions.market.fetchProjects);
  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );
  const setPolygons = useStoreActions((actions) => actions.market.setPolygons);
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setSimplifiedProjects = useStoreActions(
    (actions) => actions.market.setSimplifiedProjects,
  );
  const setParamsSM = useStoreActions((actions) => actions.filters.setParamsSM);

  /* STORE STATES */
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const polygons = useStoreState((state) => state.market.polygons);
  const primaryParameters = useStoreState(
    (state) => state.market.primaryParameters,
  );
  const { profile } = useStoreState((store) => store.user);
  const { mode } = useStoreState((store) => store.market);
  const projectsLoading = useStoreState(
    (state) => state.market.projectsLoading,
  );
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const selectedProjectId = useStoreState(
    (state) => state.market.selectedProjectId,
  );
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  /* LOCAL STATES */
  const [mouseOverProjectId, setMouseOverProjectId] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localProjects, _setLocalProjects] = useState<SimplifiedProjects[]>([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [mapsLoading, setMapsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const projects = data.filter(
    ({ gps_latitude, gps_longitude, include }) => gps_latitude && gps_longitude && include,
  );
  const [center] = useState({ lat: 48.1323412, lng: 17.1119535 });
  const [shownProject, setShownProject] = useState<
    MarketProjectExtended | undefined
  >();
  const [localPolygons, setLocalPolygons] = useState<Record<string, LatLng[]>>(
    {},
  );
  const [googleMaps, setGoogleMaps] = useState<
    typeof google.maps | undefined
  >();
  const [selectedProject, setProjectDetail] =
    useState<MarketProjectExtended | null>(null);
  const [sameLocationProjects, setSameLoactionProjects] = useState<
    MarketProjectExtended[]
  >([]);
  const [projectIsLoading, setProjectIsLoading] = useState(false);

  const checkInPolygon = useCallback(
    (marker: LatLng, polygon: LatLng[]): boolean => {
      if (!googleMaps) {
        return false;
      }
      const newPolygon = new googleMaps.Polygon({ paths: polygon });
      return googleMaps.geometry.poly.containsLocation(marker, newPolygon);
    },
    [googleMaps],
  );

  /* LOCAL HOOKS */
  const selectedProjectTimeout = useRef<ReturnType<typeof setTimeout>>();
  const { calculateVatPrice, getMinMaxProjectPrice } = useRegionFormatting();

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const fetchData = async (
    projectId: number | null,
  ): Promise<MarketProjectExtended | null> => {
    if (projectId && !Number.isNaN(projectId)) {
      try {
        const fetchedData = await getProject(projectId, pricePerSmCalculation);
        return fetchedData.data as MarketProjectExtended;
      } catch (error) {
        console.error(`Error fetching project with id ${projectId}:`, error);
        return null;
      }
    }
    return null;
  };

  // useEffect to handle project fetching logic
  useEffect(() => {
    setProjectDetail(null);
    setSameLoactionProjects([]);
    setProjectIsLoading(true);
    const projectToFetch = data.find(
      (project) => project.project_id === selectedProjectId,
    );
    if (!projectToFetch) return;

    const filteredProjects = data.filter(
      (p) =>
        p.gps_latitude === projectToFetch.gps_latitude &&
        p.gps_longitude === projectToFetch.gps_longitude,
    );

    if (filteredProjects.length > 1) {
      // Fetch all projects in parallel
      Promise.all(
        filteredProjects.map((project) => fetchData(project.project_id)),
      )
        .then((projectDataArray) => {
          // Filter out any null values
          const validProjectDataArray = projectDataArray.filter(
            (projectData) => projectData !== null,
          );
          setSameLoactionProjects(
            validProjectDataArray as MarketProjectExtended[],
          );
        })
        .catch((error) => {
          console.error('Error fetching multiple projects:', error);
        });
    }
    if (selectedProjectId) {
      // Fetch a single project
      fetchData(selectedProjectId).then((projectData) => {
        if (projectData) {
          setProjectDetail(projectData);
        }
      });
    }
    setProjectIsLoading(false);
  }, [selectedProjectId, pricePerSmCalculation]);

  useEffect(() => {
    if (!projectsLoading && isLoading) {
      const preparedData = data.filter(
        ({ gps_latitude, gps_longitude }) => gps_latitude && gps_longitude,
      );
      _setLocalProjects(preparedData);
      setIsLoading(false);
    }
  }, [projectsLoading, isLoading, data, isSold]);

  useEffect(() => {
    const polys = Object.values(polygons);
    const keys = Object.keys(polygons);
    if (polys.length > 0 && googleMaps) {
      const newPolygons = polys.map((p, i) => ({
        key: keys[i],
        values: [...p],
      }));
      const finalObj = newPolygons.reduce((obj, curr) => {
        obj[curr.key] = curr.values;
        return obj;
      }, {} as Record<string, LatLng[]>);
      setLocalPolygons(finalObj);
    } else {
      setLocalPolygons({});
    }
  }, [polygons, googleMaps]);

  useEffect(() => {
    if (mode === Mode.CREATE && !initialLoad && data.length > 0) {
      setInitialLoad(true);
      setLocalPolygons({});
    }
  }, [mode, initialLoad, data.length]);

  useEffect(() => {
    if (selectedProject) {
      const calculatedProjectData = calculateProjectData(
        selectedProject,
        getMinMaxProjectPrice,
        calculateVatPrice,
      );
      setShownProject(calculatedProjectData);
      setOpen(true);
    }
  }, [calculateVatPrice, selectedProject, profile]);

  // Store new polygons to primaryParameters and secondaryParameters
  useEffect(() => {
    if (
      (mode === Mode.CREATE || mode === Mode.EDIT) &&
      Object.values(polygons).length > 0 &&
      googleMaps
    ) {
      const newPolygons = Object.values(polygons).map((p) => [
        ...p.map((point) => new googleMaps.LatLng(point)),
      ]);
      const geometry = getGeometry(newPolygons);
      if (primaryParameters) {
        const newParams = {
          ...primaryParameters,
          geometry,
        };
        setPrimaryParameters(JSON.parse(JSON.stringify(newParams)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons, mode, googleMaps]);

  const hoveredProject = useMemo(
    () => data.find((p) => p.project_id === mouseOverProjectId),
    [mouseOverProjectId, data],
  );

  // checks if marker is in polygon
  useEffect(() => {
    if (!googleMaps) return;
    // check only when mode is edit
    if (mode !== Mode.READ && !!googleMaps?.geometry?.poly) {
      // get coordinates from all polygons on map
      const vals = Object.values(polygons);
      const newData = [
        ...data
          .filter((p) => p.gps_longitude && p.gps_latitude)
          .map((p) => {
            // create bounds
            const bounds = { lat: p.gps_latitude, lng: p.gps_longitude };
            // also checks if projects is already included
            let include = Boolean(p.include);
            // if it's included skip loop
            if (vals.length === 0) {
              return {
                ...p,
                include: false,
              };
            }

            for (let i = 0; i <= vals.length - 1; i++) {
              const is_in_polygon = checkInPolygon(bounds, vals[i]);
              // on first match break the loop
              if (is_in_polygon) {
                include = true;
                break;
              } else {
                include = false;
              }
            }
            // return updated market project
            return {
              ...p,
              include,
            };
          }),
      ];
      setSimplifiedProjects(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, polygons, checkInPolygon, googleMaps, googleMaps?.geometry?.poly]);

  useEffect(() => {
    if (primaryParameters) {
      const newParams = {
        ...primaryParameters,
        included_project_ids: data
          .filter((p) => p.include)
          .map((p) => p.project_id),
      };
      setPrimaryParameters(newParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPrimaryParameters]);

  /* HANDLERS */
  // Closes the modal
  const handleClose = (): void => {
    setOpen(false);
  };

  const mapRef = useRef<google.maps.Map | null>(null);

  const updateMapBounds = (mapParam: google.maps.Map): void => {
    setGoogleMaps(google.maps);
    const mapsData = mode !== Mode.READ ? data.filter(
      ({ gps_latitude, gps_longitude }) => gps_latitude && gps_longitude,
    ) : projects;
    if (mapsData.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      mapsData.forEach((project) => {
        bounds.extend(
          new window.google.maps.LatLng(
            project.gps_latitude,
            project.gps_longitude,
          ),
        );
      });
      mapParam.fitBounds(bounds);
    } else {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          mapParam.setCenter({ lat: latitude, lng: longitude });
        },
      );
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      updateMapBounds(mapRef.current);
    }
  }, [data]);

  const onLoad = (mapParam: google.maps.Map): void => {
    mapRef.current = mapParam;
    updateMapBounds(mapParam);
  };

  // Store new polygon to polygons
  const handlePolygonCreate = (
    polygon: google.maps.Polygon,
    index: number,
  ): void => {
    const polygonName = `polygon-${index}`;
    const polygonPaths = polygon.getPath().getArray();
    // const parsedPolygons = polygonPaths.map((path) => ([path.lat(), path.lng()]));
    const newPolygons = {
      ...polygons,
      [polygonName]: polygonPaths,
    };
    setPolygons(JSON.parse(JSON.stringify(newPolygons)));
  };

  // Store edited polygon to polygons
  const handlePolygonEdit = (
    polygon: google.maps.Polygon,
    name: string,
  ): void => {
    const polygonPaths = polygon.getPath().getArray();
    // const parsedPolygons = polygonPaths.map((path) => ([path.lat(), path.lng()]));
    const newPolygons = {
      ...polygons,
      [name]: polygonPaths,
    };
    setPolygons(JSON.parse(JSON.stringify(newPolygons)));
    if (paramsSM && !paramsSM.polygonsEdited) {
      setParamsSM({ ...paramsSM, polygonsEdited: true });
    }
  };

  // handle selection of area from autocomplete
  const handleTerritorySelect = async (territory: Territory): Promise<void> => {
    // if there is no profile return
    if (!profile) return;
    // initialize array, where we store polygons
    const newPolygons: { key: string; polygon: LatLng[] }[] = [];
    // default index of polygon is 0
    let lastNum = 0;
    // initialize array of ids, where we will store ids for limiting num of requests
    let requests: string[] = [];
    let fetching = false;

    // Territory IDs
    for (const [index, id] of (territory?.territoryId ?? []).entries()) {
      if (
        (requests.length === 5 && !fetching) ||
        (territory?.territoryId.length ?? 1) - 1 === index
      ) {
        // if array length is 1
        if ((territory?.territoryId.length ?? 1) - 1 === index) {
          requests.push(id);
        }
        fetching = true;
        // eslint-disable-next-line no-await-in-loop
        const datasets = (await Promise.allSettled([
            ...requests.map(async (req) => {
              try {
                const response =
                  territoriesApi.territoriesEndpointsGetMultipolygonByTerritoryId(
                    {
                      countryIsoCode: getCountryIsoCode(
                        profile.localization,
                      ) as BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum,
                      territoryId: req,
                    },
                  );
                return response;
              } catch (err) {
                console.log(err);
                return {} as TerritoryGeomsResponseSchema;
              }
            }),
          ])
        ).map((res) => {
          if (res.status === 'fulfilled') {
            return res.value;
          }
          return {} as TerritoryGeomsResponseSchema;
        });
        datasets.forEach((dataset) => {
          const currentPolygons = Object.keys(polygons);
          if (currentPolygons.length > 0) {
            const lastPoly = currentPolygons[currentPolygons.length - 1];
            if (lastNum === 0) {
              lastNum = Number(lastPoly.split('polygon-')[1]);
            }
          }
          lastNum += 1;
          dataset.geom.coordinates = [
            [simplifyPolygon(dataset.geom.coordinates[0][0])],
          ];
          const polygon = parseGeometry(dataset.geom as MultiPolygonalSchema)[
            'polygon-0'
          ];
          newPolygons.push({
            key: `polygon-${lastNum}`,
            polygon,
          });
        });
        requests = [id];
        fetching = false;
      } else {
        requests.push(id);
      }
    }

    const finalObj = newPolygons.reduce((result, curr) => {
      result[curr.key] = curr.polygon;
      return result;
    }, {} as Record<string, LatLng[]>);
    if (Object.keys(polygons).length > 0) {
      const newPoly = {
        ...polygons,
        ...finalObj,
      };
      setPolygons(JSON.parse(JSON.stringify(newPoly)));
    } else {
      setPolygons(JSON.parse(JSON.stringify(finalObj)));
    }
  };

  const isEditingReport = mode === Mode.EDIT || mode === Mode.CREATE;
  const loading = (projectsLoading) && data.length > 0;

  return isLoaded && !loading && !mapsLoading && window.google ? (
    <GoogleMap
      zoom={8}
      options={{
        disableDefaultUI: false,
        clickableIcons: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
        // restriction: {
        //   latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        // },
      }}
      mapContainerClassName={clsx('map-container', {
        'map-info-window-hidden-exit-button': !!mouseOverProjectId,
      })}
      onLoad={onLoad}
      onClick={() => setSelectedProjectId(null)}
      center={center}
    >
      {data
        .filter(
          ({ gps_latitude, gps_longitude }) => gps_latitude && gps_longitude,
        )
        .map((project) => ({
          ...project,
          available_units: project.available_units ?? 0,
        }))
        .filter((project) => !!project.include || isEditingReport)
        .map((project) => {
          const {
            available_units,
            project_id,
            gps_latitude,
            gps_longitude,
            include,
          } = project;
          const onMouseOver = (): void => setMouseOverProjectId(project_id);
          const onMouseOut = (): void => setMouseOverProjectId(undefined);
          const handleClick = (): void => {
            onMouseOut();
            clearTimeout(selectedProjectTimeout.current);
            selectedProjectTimeout.current = setTimeout(
              () => setSelectedProjectId(project_id),
              0,
            );
          };
          const filteredProjects = data.filter(
            (p) =>
              p.gps_latitude === gps_latitude &&
              p.gps_longitude === gps_longitude,
          );
          return (
            <Marker
              key={project_id}
              position={{ lat: gps_latitude, lng: gps_longitude }}
              onClick={handleClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              icon={getMapPin(available_units, isEditingReport, !!include)}
            >
              {shownProject &&
              selectedProject?.project_id === project_id &&
              open ? (
                <InfoWindow
                  position={{
                    lat: selectedProject.gps_latitude,
                    lng: selectedProject.gps_longitude,
                  }}
                >
                  <ProjectDetail
                    selectedProject={shownProject}
                    sameLocationProjects={sameLocationProjects}
                    onClose={handleClose}
                  />
                </InfoWindow>
              ) : (
                hoveredProject?.project_id === project_id && (
                  <InfoWindow
                    position={{
                      lat: hoveredProject.gps_latitude,
                      lng: hoveredProject.gps_longitude,
                    }}
                  >
                    <div className='map-info-window-hover-content'>
                      {filteredProjects.map(({ project_name }) => (
                        <div key={project_name}>{project_name}</div>
                      ))}
                    </div>
                  </InfoWindow>
                )
              )}
            </Marker>
          );
        })}
      {isEditingReport && (
        <TerritoryAutocomplete onSelect={handleTerritorySelect} />
      )}
      {isEditingReport && (
        <DrawingManagerF
          options={{
            drawingControlOptions: {
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              fillColor: '#5A72B1',
              fillOpacity: 0.5,
              strokeColor: '#5A72B1',
              editable: true,
            },
          }}
          onPolygonComplete={(polygon) =>
            handlePolygonCreate(polygon, Object.values(polygons).length)}
          onOverlayComplete={(e) => {
            e.overlay?.setMap(null);
          }}
        />
      )}
      {Object.values(localPolygons).length > 0 &&
        Object.entries(localPolygons).map(([name, polygon]) => (
          <CustomPolygon
            key={name}
            paths={polygon}
            onUpdate={handlePolygonEdit}
            name={name}
          />
        ))}
    </GoogleMap>
  ) : (
    <CenteredSpinner />
  );
};
