/* eslint-disable @typescript-eslint/ban-ts-comment */
//  @ts-nocheck
import { FC, useEffect, useState, useMemo, useRef } from 'react';
import { Spin } from 'antd';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ProjectDetail } from './ProjectDetail';
import { useStoreActions, useStoreState } from '../../hooks';
import { getMapPin } from '../../utils/utils';
import { SimplifiedProjects, Mode } from '../../store/types';
import { GOOGLE_MAPS_LIBRARIES } from '../../constants';
import { getProject } from '../../api';

interface Coordinates {
  latitude: number;
  longitude: number;
}

// Function to calculate the distance between two points
function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number {
  const earthRadius = 6371; // Earth's radius in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    // eslint-disable-next-line max-len
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c; // Distance in kilometers
  return distance;
}

// Function to calculate the average coordinates
function calculateAverageCoordinates(coordinates: Coordinates[]): Coordinates {
  const totalCoordinates = coordinates.length;
  const sumLatitudes = coordinates.reduce(
    (sum, coord) => sum + coord.latitude,
    0,
  );
  const sumLongitudes = coordinates.reduce(
    (sum, coord) => sum + coord.longitude,
    0,
  );

  const avgLatitude = sumLatitudes / totalCoordinates;
  const avgLongitude = sumLongitudes / totalCoordinates;

  return { latitude: avgLatitude, longitude: avgLongitude };
}

// Function to find the closest location to the average coordinates
export function findClosestLocationToCenter(data: Coordinates[]): Coordinates | null {
  const coordinates = data;

  const avgCoordinates = calculateAverageCoordinates(coordinates);

  let closestLocation = null;
  let minDistance = Infinity;

  coordinates.forEach((coord) => {
    const distance = calculateDistance(
      avgCoordinates.latitude,
      avgCoordinates.longitude,
      coord.latitude,
      coord.longitude,
    );
    if (distance < minDistance) {
      minDistance = distance;
      closestLocation = coord;
    }
  });

  return closestLocation;
}

interface MapProps {
  data: SimplifiedProjects[];
}

export const Map: FC<MapProps> = ({ data }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const selectedProjectTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [mouseOverProjectId, setMouseOverProjectId] = useState<number>();
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const projects = data.filter(
    ({ gps_latitude, gps_longitude }) => gps_latitude && gps_longitude,
  );
  const [open, setOpen] = useState(false);
  const [center] = useState(
    findClosestLocationToCenter(
      data.map((d) => ({
        latitude: d.gps_latitude,
        longitude: d.gps_longitude,
      })),
    ),
  );
  const { t } = useTranslation();
  const selectedProjectId = useStoreState(
    (state) => state.market.selectedProjectId,
  );
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );
  const isEditingReport = useStoreState(
    (state) => state.market.mode === Mode.EDIT,
  );
  const [selectedProject, setProjectDetail] =
    useState<MarketProjectExtended | null>(null);
  const [sameLocationProjects, setSameLoactionProjects] = useState<
    MarketProjectExtended[]
  >([]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const fetchData = async (
    projectId: number | null,
  ): Promise<MarketProjectExtended | null> => {
    if (projectId && !Number.isNaN(projectId)) {
      try {
        const fetchedData = await getProject(projectId, pricePerSmCalculation);
        return fetchedData.data as MarketProjectExtended;
      } catch (error) {
        console.error(`Error fetching project with id ${projectId}:`, error);
        return null;
      }
    }
    return null;
  };

  // useEffect to handle project fetching logic
  useEffect(() => {
    setProjectDetail(null);
    setSameLoactionProjects([]);
    const projectToFetch = data.find(
      (project) => project.project_id === selectedProjectId,
    );
    if (!projectToFetch) return;

    const filteredProjects = data.filter(
      (p) =>
        p.gps_latitude === projectToFetch.gps_latitude &&
        p.gps_longitude === projectToFetch.gps_longitude,
    );

    if (filteredProjects.length > 1) {
      // Fetch all projects in parallel
      Promise.all(
        filteredProjects.map((project) => fetchData(project.project_id)),
      )
        .then((projectDataArray) => {
          // Filter out any null values
          const validProjectDataArray = projectDataArray.filter(
            (projectData) => projectData !== null,
          );
          setSameLoactionProjects(
            validProjectDataArray as MarketProjectExtended[],
          );
          setOpen(true);
        })
        .catch((error) => {
          console.error('Error fetching multiple projects:', error);
        });
    } else if (selectedProjectId) {
      // Fetch a single project
      fetchData(selectedProjectId).then((projectData) => {
        if (projectData) {
          setProjectDetail(projectData);
          setOpen(true);
        }
      });
    }
  }, [selectedProjectId, pricePerSmCalculation]);

  const handleClose = (): void => {
    setOpen(false);
  };

  const updateMapBounds = (mapParam: google.maps.Map): void => {
    if (projects.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      projects.forEach((project) => {
        bounds.extend(
          new window.google.maps.LatLng(
            project.gps_latitude,
            project.gps_longitude,
          ),
        );
      });
      mapParam.fitBounds(bounds);
    } else {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          mapParam.setCenter({ lat: latitude, lng: longitude });
        },
      );
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      updateMapBounds(mapRef.current);
    }
  }, [data]);

  const onLoad = (mapParam: google.maps.Map): void => {
    mapRef.current = mapParam;
    updateMapBounds(mapParam);
  };

  useEffect(
    () => () => {
      setSelectedProjectId(null);
    },
    [setSelectedProjectId],
  );

  const hoveredProject = useMemo(
    () => projects.find((p) => p.project_id === mouseOverProjectId),
    [mouseOverProjectId, projects],
  );

  return isLoaded ? (
    <GoogleMap
      options={{
        disableDefaultUI: false,
        clickableIcons: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        },
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        },
      }}
      mapContainerClassName={clsx('map-container', {
        'map-info-window-hidden-exit-button': !!mouseOverProjectId,
      })}
      zoom={11}
      onLoad={onLoad}
      onClick={() => setSelectedProjectId(null)}
      center={center}
    >
      {projects
        .filter((project) => !!project.include || isEditingReport)
        .map((project) => {
          const {
            available_units,
            project_id,
            gps_latitude,
            gps_longitude,
            include,
          } = project;
          const onMouseOver = (): void => setMouseOverProjectId(project_id);
          const onMouseOut = (): void => setMouseOverProjectId(undefined);
          const handleClick = (): void => {
            onMouseOut();
            clearTimeout(selectedProjectTimeout.current);
            selectedProjectTimeout.current = setTimeout(
              () => setSelectedProjectId(project_id),
              0,
            );
          };
          const filteredProjects = projects.filter(
            (p) =>
              p.gps_latitude === gps_latitude &&
              p.gps_longitude === gps_longitude,
          );
          return (
            <Marker
              key={project_id}
              position={{ lat: gps_latitude, lng: gps_longitude }}
              onClick={handleClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              icon={getMapPin(available_units, isEditingReport, include)}
            >
              {selectedProject &&
              selectedProject?.project_id === project_id &&
              open ? (
                <InfoWindow
                  position={{
                    lat: selectedProject.gps_latitude,
                    lng: selectedProject.gps_longitude,
                  }}
                >
                  <ProjectDetail
                    selectedProject={selectedProject}
                    sameLocationProjects={sameLocationProjects}
                    onClose={handleClose}
                  />
                </InfoWindow>
              ) : (
                hoveredProject?.project_id === project_id && (
                  <InfoWindow
                    position={{
                      lat: hoveredProject.gps_latitude,
                      lng: hoveredProject.gps_longitude,
                    }}
                  >
                    <div className='map-info-window-hover-content'>
                      {filteredProjects.map(({ project_name }) => (
                        <div key={project_name}>{project_name}</div>
                      ))}
                    </div>
                  </InfoWindow>
                )
              )}
            </Marker>
          );
        })}
    </GoogleMap>
  ) : (
    <Spin spinning size='large' tip={t('loading')} />
  );
};
