/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LayoutUnits
 */
export interface LayoutUnits {
    /**
     * 
     * @type {number}
     * @memberof LayoutUnits
     */
    countOfRegularRooms: number;
    /**
     * 
     * @type {number}
     * @memberof LayoutUnits
     */
    units: number;
}

/**
 * Check if a given object implements the LayoutUnits interface.
 */
export function instanceOfLayoutUnits(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countOfRegularRooms" in value;
    isInstance = isInstance && "units" in value;

    return isInstance;
}

export function LayoutUnitsFromJSON(json: any): LayoutUnits {
    return LayoutUnitsFromJSONTyped(json, false);
}

export function LayoutUnitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LayoutUnits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countOfRegularRooms': json['count_of_regular_rooms'],
        'units': json['units'],
    };
}

export function LayoutUnitsToJSON(value?: LayoutUnits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count_of_regular_rooms': value.countOfRegularRooms,
        'units': value.units,
    };
}

