/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { css } from '@emotion/react/macro';
import { useStoreActions, useStoreState } from '../../../../hooks';

const styles = css({ width: '100%' });

type Props = {
  dates: string[];
};

export const DateSelect: FC<Props> = ({ dates }) => {
  const selectedDateRedux = useStoreState((state) => state.market.selectedDate);
  const setSelectedDateRedux = useStoreActions(
    (state) => state.market.setSelectedDate,
  );
  const [selectedDate, setSelectedDate] = useState({
    month: selectedDateRedux
      ? String(new Date(selectedDateRedux).toLocaleDateString('en', {
        month: '2-digit',
      }))
      : '',
    year: selectedDateRedux
      ? String(new Date(selectedDateRedux).getFullYear())
      : '',
  });
  const [firstRender, setFirstRender] = useState(true);

  const years = useMemo(
    () =>
      [
        ...new Set(dates.map((date) => String(new Date(date).getFullYear()))),
      ].map((year) => ({
        label: year,
        value: year,
      })),
    [dates],
  );

  const months = useMemo(() => {
    const currentDates = dates.filter(
      (d) => String(new Date(d).getFullYear()) === selectedDate.year,
    );
    return [
      ...new Set(
        currentDates.map((date) => ({
          label: new Date(date).toLocaleDateString('en-GB', {
            month: 'long',
          }),
          value: new Date(date).toLocaleDateString('en-GB', {
            month: '2-digit',
          }),
        })),
      ),
    ].sort((a, b) => Number(a.value) - Number(b.value));
  }, [dates, selectedDate.year]);

  useEffect(() => {
    if (!firstRender && months) {
      setSelectedDate((prev) => ({
        ...prev,
        month: months.find((m) => m.value === prev.month)?.value || months[0]?.value,
      }));
    }
  }, [selectedDate.year]);

  useEffect(() => {
    if (firstRender) {
      const currentDates = dates.filter(
        (d) => String(new Date(d).getFullYear()) === years[years.length - 1].value,
      );
      const month = [
        ...new Set(
          currentDates.map((date) => ({
            label: new Date(date).toLocaleDateString('default', {
              month: 'long',
            }),
            value: new Date(date).toLocaleDateString('default', {
              month: '2-digit',
            }),
          })),
        ),
      ].sort((a, b) => Number(a.value) - Number(b.value));
      const isValidSelectedDateRedux = dates.includes(selectedDateRedux || '');
      if (!selectedDateRedux || !isValidSelectedDateRedux) {
        setSelectedDate({
          month: month[month.length - 1].value,
          year: years[years.length - 1].value,
        });
      }
      setFirstRender(false);
    }
  }, [years, firstRender, dates, selectedDateRedux]);

  useEffect(() => {
    setSelectedDateRedux(`${selectedDate.year}-${selectedDate.month}`);
  }, [selectedDate, setSelectedDateRedux]);

  const handleChange = (value: string, type: 'month' | 'year'): void => {
    setSelectedDate((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleClear = (): void => {
    setSelectedDate({
      month: '',
      year: '',
    });
  };

  return (
    <div className='market-share-date-picker'>
      <Select<string>
        css={styles}
        value={selectedDate.month}
        onChange={(e) => handleChange(e, 'month')}
        placeholder=''
        optionFilterProp='label'
        options={months}
        onClear={handleClear}
        maxTagCount={5}
      />
      <Select<string>
        css={styles}
        value={selectedDate.year}
        onChange={(e) => handleChange(e, 'year')}
        placeholder=''
        optionFilterProp='label'
        options={years}
        onClear={handleClear}
        maxTagCount={5}
      />
    </div>
  );
};
