import {
  HomeIcon,
  BuildingOfficeIcon,
  TagIcon,
  KeyIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ActionCreator } from 'easy-peasy';

type Props<T extends Record<string, boolean>> = {
  type: keyof T;
  label: string;
  selected?: boolean;
  className?: string;
  state: T;
  setState: ActionCreator<T>;
};

export const ImageSwitch = <T extends Record<string, boolean>>({
  type,
  label,
  selected = false,
  setState,
  state,
  className,
}: Props<T>): JSX.Element => {
  const color = selected ? '#313B5E' : '#828282';

  const getImage = (): React.ReactNode => {
    switch (type) {
      case 'house':
        return <HomeIcon color={color} />;
      case 'sale':
        return <TagIcon color={color} />;
      case 'rental':
        return <KeyIcon color={color} />;
      case 'unit':
        return <BuildingOfficeIcon color={color} />;
      default:
        return null;
    }
  };

  const handleClick = (): void => {
    const keys = Object.keys(state) as (keyof T)[];
    const updatedState = {
      [keys[0]]: keys[0] === type,
      [keys[1]]: keys[1] === type,
    } as T;
    setState(updatedState);
  };

  return (
    <div
      className={clsx(className, 'flex flex-col items-center cursor-pointer')}
      onClick={handleClick}
    >
      <div
        className={clsx(
          'w-32 p-8 mb-4 rounded-[50%]',
          selected ? 'bg-[#9EB9DA]' : 'bg-[#D9D9D9]',
        )}
      >
        {getImage()}
      </div>
      <span
        className={clsx(
          'text-lg',
          selected ? 'text-bmblue font-bold' : 'text-bmgray',
        )}
      >
        {label}
      </span>
    </div>
  );
};
