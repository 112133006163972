import { FC, MouseEventHandler } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';

interface IncludeIconProps {
  value: boolean;
  onClick: MouseEventHandler<HTMLSpanElement>;
}
export const IncludeIcon: FC<IncludeIconProps> = ({ value, onClick }) => (
  <CheckCircleFilled className={`include-icon ${value}`} onClick={onClick} />
);
