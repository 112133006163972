/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomTerritoryResponseSchema,
  TerritoryGeomsResponseSchema,
  TerritoryResponseSchema,
} from '../models/index';
import {
    CustomTerritoryResponseSchemaFromJSON,
    CustomTerritoryResponseSchemaToJSON,
    TerritoryGeomsResponseSchemaFromJSON,
    TerritoryGeomsResponseSchemaToJSON,
    TerritoryResponseSchemaFromJSON,
    TerritoryResponseSchemaToJSON,
} from '../models/index';

export interface BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryRequest {
    countryIsoCode: BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryCountryIsoCodeEnum;
}

export interface BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdRequest {
    territoryId: string;
    countryIsoCode: BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum;
}

export interface BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesRequest {
    countryIsoCode: BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum;
}

/**
 * 
 */
export class TerritoriesApi extends runtime.BaseAPI {

    /**
     * Get all cadastral Territories
     * Get all cadastral Territories
     */
    async territoriesEndpointsGetAllTerritoryIdsByCountryRaw(requestParameters: BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TerritoryResponseSchema>>> {
        if (requestParameters.countryIsoCode === null || requestParameters.countryIsoCode === undefined) {
            throw new runtime.RequiredError('countryIsoCode','Required parameter requestParameters.countryIsoCode was null or undefined when calling builtmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/territory/{country_iso_code}/get_cadastral_territories/`.replace(`{${"country_iso_code"}}`, encodeURIComponent(String(requestParameters.countryIsoCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(TerritoryResponseSchemaFromJSON));
    }

    /**
     * Get all cadastral Territories
     * Get all cadastral Territories
     */
    async territoriesEndpointsGetAllTerritoryIdsByCountry(requestParameters: BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TerritoryResponseSchema>> {
        const response = await this.territoriesEndpointsGetAllTerritoryIdsByCountryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Geom by Territory ID
     * Get Geom by Territory ID
     */
    async territoriesEndpointsGetMultipolygonByTerritoryIdRaw(requestParameters: BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TerritoryGeomsResponseSchema>> {
        if (requestParameters.territoryId === null || requestParameters.territoryId === undefined) {
            throw new runtime.RequiredError('territoryId','Required parameter requestParameters.territoryId was null or undefined when calling builtmindApiTerritoriesEndpointsGetMultipolygonByTerritoryId.');
        }

        if (requestParameters.countryIsoCode === null || requestParameters.countryIsoCode === undefined) {
            throw new runtime.RequiredError('countryIsoCode','Required parameter requestParameters.countryIsoCode was null or undefined when calling builtmindApiTerritoriesEndpointsGetMultipolygonByTerritoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/territory/{country_iso_code}/get_geometry_by_territory_id/{territory_id}`.replace(`{${"territory_id"}}`, encodeURIComponent(String(requestParameters.territoryId))).replace(`{${"country_iso_code"}}`, encodeURIComponent(String(requestParameters.countryIsoCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TerritoryGeomsResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get Geom by Territory ID
     * Get Geom by Territory ID
     */
    async territoriesEndpointsGetMultipolygonByTerritoryId(requestParameters: BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TerritoryGeomsResponseSchema> {
        const response = await this.territoriesEndpointsGetMultipolygonByTerritoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get custom territories
     * Get custom territories
     */
    async territoriesEndpointsReturnCustomTerritoriesRaw(requestParameters: BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomTerritoryResponseSchema>>> {
        if (requestParameters.countryIsoCode === null || requestParameters.countryIsoCode === undefined) {
            throw new runtime.RequiredError('countryIsoCode','Required parameter requestParameters.countryIsoCode was null or undefined when calling builtmindApiTerritoriesEndpointsReturnCustomTerritories.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/territory/{country_iso_code}/get_custom_territories/`.replace(`{${"country_iso_code"}}`, encodeURIComponent(String(requestParameters.countryIsoCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(CustomTerritoryResponseSchemaFromJSON));
    }

    /**
     * Get custom territories
     * Get custom territories
     */
    async territoriesEndpointsReturnCustomTerritories(requestParameters: BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomTerritoryResponseSchema>> {
        const response = await this.territoriesEndpointsReturnCustomTerritoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryCountryIsoCodeEnum = {
    Cz: 'CZ',
    Sk: 'SK',
    De: 'DE',
    At: 'AT'
} as const;
export type BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryCountryIsoCodeEnum = typeof BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryCountryIsoCodeEnum[keyof typeof BuiltmindApiTerritoriesEndpointsGetAllTerritoryIdsByCountryCountryIsoCodeEnum];
/**
 * @export
 */
export const BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum = {
    Cz: 'CZ',
    Sk: 'SK',
    De: 'DE',
    At: 'AT'
} as const;
export type BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum = typeof BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum[keyof typeof BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum];
/**
 * @export
 */
export const BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum = {
    Cz: 'CZ',
    Sk: 'SK',
    De: 'DE',
    At: 'AT'
} as const;
export type BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum = typeof BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum[keyof typeof BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum];
