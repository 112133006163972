/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { DaysOnMarketChart } from './DaysOnMarketChart';
import { styles } from '../styles';
import { ChartCard } from '../../../ChartCard';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { fetchBlockDaysOnMarketData } from '../../../../api';
import { DaysOnMarketData } from '../../../../api/types';
import { DateSelect } from './DateSelect';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

export const DaysOnMarket: FC = () => {
  const [data, setData] = useState<DaysOnMarketData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { paramsPM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const pngRef = useRef(null);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { selectedDate } = useStoreState((state) => state.market);
  const dates = useMemo(
    () => [...new Set((data || []).map((d) => d.month))],
    [data],
  );

  useEffect(() => {
    if (paramsPM?.dashboardId && paramsPM?.date) {
      setLoading(true);
      fetchBlockDaysOnMarketData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then((response) => setData(response.data.days_on_the_market || []))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation]); // Ensure dependencies are up-to-date

  // useEffect(() => {
  //   if (reportId && paramsSM?.date) {
  //     setLoading(true);
  //     const date = new Date(paramsSM.date);
  //     date.setDate(date.getDate() - 180);
  //     const startDate = date.toISOString().split('T')[0];
  //     const endDate = paramsSM.date;
  //     const payload = {
  //       reportId,
  //       startDate,
  //       endDate,
  //     };
  //     switch (filterValue) {
  //       case FilterEnum.OVERALL:
  //         statisticsApi.getDaysOnTheMarket(payload)
  //           .then((response) => {
  //             setData(response.daysOnTheMarket.map((d) => ({
  //               ...d,
  //               meanDaysOnMarket: d.meanDaysOnMarket ?? 0,
  //             })));
  //           }).finally(() => {
  //             setLoading(false);
  //           });
  //         break;
  //       default:
  //         statisticsApi.getDaysOnTheMarket(payload)
  //           .then((response) => {
  //             setData(response.daysOnTheMarket.map((d) => ({
  //               ...d,
  //               meanDaysOnMarket: d.meanDaysOnMarket ?? 0,
  //             })));
  //           }).finally(() => {
  //             setLoading(false);
  //           });
  //         break;
  //     }
  //   }
  // }, [filterValue, reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.days_on_market.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.days_on_market.title', 'Days on the Market')}
        subtitle={t(
          'market.reports.days_on_market.subtitle',
          'The average number of days it took to sell units sold in the given month.',
        )}
        extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
        chart={
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : (
            <DaysOnMarketChart
              date={selectedDate}
              data={data
                .sort((a, b) => a.month.localeCompare(b.month))
                .map((d) => ({ ...d, meanDaysOnMarket: Math.round(d.mean_days_on_market) }))}
              isOverall={isOverall}
            />
          )
        }
        controls={
          <div className='days-on-market-pm'>
            <Segmented
              css={{ width: 'min-content' }}
              value={filterValue}
              options={[
                {
                  value: FilterEnum.OVERALL,
                  label: t('dashboard.sale_progress.overall', 'Overall'),
                },
                {
                  value: FilterEnum.LAYOUTS,
                  label: t('dashboard.sale_progress.layouts', 'Layouts'),
                },
              ]}
              onChange={handleChange}
            />
            {!isOverall && <DateSelect dates={dates} />}
          </div>
        }
      />
    </div>
  );
};
