/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisappearedUnitsAveragePriceResponseSchema
 */
export interface DisappearedUnitsAveragePriceResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof DisappearedUnitsAveragePriceResponseSchema
     */
    disappearedUnitsAveragePrice: number | null;
}

/**
 * Check if a given object implements the DisappearedUnitsAveragePriceResponseSchema interface.
 */
export function instanceOfDisappearedUnitsAveragePriceResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disappearedUnitsAveragePrice" in value;

    return isInstance;
}

export function DisappearedUnitsAveragePriceResponseSchemaFromJSON(json: any): DisappearedUnitsAveragePriceResponseSchema {
    return DisappearedUnitsAveragePriceResponseSchemaFromJSONTyped(json, false);
}

export function DisappearedUnitsAveragePriceResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsAveragePriceResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disappearedUnitsAveragePrice': json['disappeared_units_average_price'],
    };
}

export function DisappearedUnitsAveragePriceResponseSchemaToJSON(value?: DisappearedUnitsAveragePriceResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disappeared_units_average_price': value.disappearedUnitsAveragePrice,
    };
}

