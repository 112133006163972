/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableUnitsAveragePriceResponseSchema
 */
export interface AvailableUnitsAveragePriceResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof AvailableUnitsAveragePriceResponseSchema
     */
    availableUnitsAveragePrice: number | null;
}

/**
 * Check if a given object implements the AvailableUnitsAveragePriceResponseSchema interface.
 */
export function instanceOfAvailableUnitsAveragePriceResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableUnitsAveragePrice" in value;

    return isInstance;
}

export function AvailableUnitsAveragePriceResponseSchemaFromJSON(json: any): AvailableUnitsAveragePriceResponseSchema {
    return AvailableUnitsAveragePriceResponseSchemaFromJSONTyped(json, false);
}

export function AvailableUnitsAveragePriceResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableUnitsAveragePriceResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableUnitsAveragePrice': json['available_units_average_price'],
    };
}

export function AvailableUnitsAveragePriceResponseSchemaToJSON(value?: AvailableUnitsAveragePriceResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_units_average_price': value.availableUnitsAveragePrice,
    };
}

