import { RefObject } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';
// eslint-disable-next-line import/no-extraneous-dependencies
import jsPDF from 'jspdf';
import { message } from 'antd';
import dayjs from 'dayjs';

const getRatio = (
  pdfDimensions: { width: number, height: number },
  imgDimensions: { width: number, height: number },
): number => Math.min(pdfDimensions.width / imgDimensions.width, pdfDimensions.height / imgDimensions.height);

const getXY = (
    pdfDimensions: { width: number, height: number },
    imgDimensions: { width: number, height: number },
  ): { imgX: number, imgY: number, ratio: number } => {
    const ratio = getRatio(pdfDimensions, imgDimensions);
    return {
      imgX: (pdfDimensions.width - imgDimensions.width * ratio) / 2,
      imgY: (pdfDimensions.height - imgDimensions.height * ratio) / 2,
      ratio,
    };
  };

const pdfAddImage = (pdf: jsPDF, canvas: HTMLCanvasElement, addPage?: boolean): void => {
  const imgData = canvas.toDataURL('image/png', 1);
  const pdfDimensions = {
    width: pdf.internal.pageSize.width,
    height: pdf.internal.pageSize.height,
  };
  const imgDimensions = {
    width: canvas.width,
    height: canvas.height,
  };
  const { imgX, imgY, ratio } = getXY(pdfDimensions, imgDimensions);
  if (addPage) {
    pdf.addPage();
  }
  pdf.addImage(imgData, 'PNG', imgX, imgY, imgDimensions.width * ratio, imgDimensions.height * ratio);
};

export const downloadPDF = (
  fileName: string,
  ref: RefObject<HTMLElement>,
  ref2?: RefObject<HTMLElement>,
  ref3?: RefObject<HTMLElement>,
): void => {
  message.loading('Creating PDF...', 0);
  const fileNameFormated = `${fileName}-${dayjs().format('YYYY-MM-DD')}`;
  const input = ref.current;
  const input2 = ref2 && ref2.current;
  const input3 = ref3 && ref3.current;
  // @ts-ignore
  html2canvas(input, { allowTaint: true, useCORS: true, scale: 2 }).then((canvas) => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('l', 'px', 'a4', true);
    pdfAddImage(pdf, canvas);
    if (!input2) {
      pdf.save(`${fileNameFormated}.pdf`);
      message.destroy();
      message.success('PDF created', 3);
      return;
    }
    // @ts-ignore
    html2canvas(input2, { allowTaint: true, useCORS: true, scale: 2 }).then((canvas2) => {
      pdfAddImage(pdf, canvas2, true);
      if (!input3) {
        pdf.save(`${fileNameFormated}.pdf`);
        message.destroy();
        message.success('PDF created', 3);
        return;
      }
      html2canvas(input3, { allowTaint: true, useCORS: true, scale: 2 }).then((canvas3) => {
        pdfAddImage(pdf, canvas3, true);
        pdf.save(`${fileNameFormated}.pdf`);
        message.destroy();
        message.success('PDF created', 3);
      });
    });
  });
};
