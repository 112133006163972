import { Col, Row } from 'antd';
import { FC, useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chevron } from '../../../secondaryMarket/blocks/Overview/Chevron';
import { ProjectDetailTableWithHistory } from '../../../map/ProjectDetailWithHistory/Content/ProjectDetailTable';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { styles } from './styles';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { Availability } from '../../../../store/types';
import { fetchBlockSupplyData } from '../../../../api';
import {
  getReportExportFileName,
} from '../../../../utils/utils';
import { Card } from '../../../Card';
import { SupplyData } from '../../../../api/types';
import { SupplyCardPopover } from './SupplyCardPopover';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { HistoryPopover } from '../../../map/ProjectDetailWithHistory/Content/HistoryPopover';

export interface Data {
  date: string;
  value: number;
  series: string | number;
  layout: string | null;
  availability: string;
  count?: number;
}

export const Supply: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const { formatCurrency, calculateVatPrice, formatAreaUnits } =
    useRegionFormatting();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const pngRef = useRef(null);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const [data, setData] = useState<SupplyData>();
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  function getPreviousMonth(dateStr?: string, monthsAgo = 0): string {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    // Set the date to the first day of the current month
    date.setDate(1);
    // Move to the specified number of months ago
    date.setMonth(date.getMonth() - monthsAgo);
    date.setHours(-1);
    // Move to the last day of that month
    return date.toISOString().split('T')[0];
  }

  const formatDate = (dateString?: string): string => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    if (Number.isNaN(date.getTime())) {
      return '';
    }
    return `${date.toLocaleString(language, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })}`;
  };

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockSupplyData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then((response) => setData(isSold ? response.data.reserved_sold : response.data.reserved_available))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation, isSold]);

  const {
    tableData,
  } = useMemo(() => ({
    tableData: data?.metrics?.map((metric) => ({
      availability: Availability.SOLD,
      available: {
        old: metric.total.old,
        current: metric.total.current,
        change: metric.total.change,
      },
      baths: [0],
      exterior_sm: null,
      garages: [0],
      interior_sm: {
        old: metric.average_interior.old,
        current: metric.average_interior.current,
        change: metric.average_interior.change,
      },
      layout: metric.layout.toLowerCase(),
      nonNullInteriorSM: 1,
      nonNullPrice: 1,
      nonNullPricePerSmCount: 1,
      price: {
        old: calculateVatPrice(
          metric.average_price.old,
          120,
        ),
        current: calculateVatPrice(
          metric.average_price.current,
          120,
        ),
        change: metric.average_price.change,
      },
      price_per_sm: {
        old: calculateVatPrice(
          metric.average_price_per_sm.old,
          120,
        ),
        current: calculateVatPrice(
          metric.average_price_per_sm.current,
          120,
        ),
        change: metric.average_price_per_sm.change,
      },
    })) || [],
  }), [data,
    calculateVatPrice,
  ]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('daily_news.kpi.demand'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <Card
        css={styles.card}
        loading={isLoading}
        dataMissing={data?.metrics === undefined && !isLoading}
        dataMissingMessage={t('market.reports.sale_speed.detail.data_unavailable', 'Detailed data for this date are not available.')}
      >
        <SupplyCardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />
        <Row className='reports_overview_header'>
          <Col xs={6}>
            <Row justify='center' className='title bold'>
              {t('daily_news.kpi.supply')}
            </Row>
            <Row justify='center' className='icon'>
              <div className='supply daily_news_kpi_icon'>
                <img
                  src='/assets/building_icon_dark_blue_compressed.png'
                  alt='house'
                />
              </div>
            </Row>
          </Col>
          <Col xs={9}>
            <Row justify='center'>
              {t('market.supply.available_units')}
            </Row>
            <Row justify='center' className='title big'>
              {data?.total_units.current}
            </Row>
            <Row justify='center' className='subtitle'>
            {data?.total_units.change ? (
                <div>
                  <HistoryPopover
                    values={data?.total_units}
                    currentDate={formatDate(paramsPM?.date)}
                    previousDate={formatDate(
                      getPreviousMonth(paramsPM?.date),
                    )}
                    isOverview
                  >
                    <p className='flex'>
                      <Chevron
                        number={data?.total_units.change}
                        label={formatDate(paramsPM?.date)}
                      />
                    </p>
                  </HistoryPopover>
                </div>
              ) : undefined}
            </Row>
          </Col>
          <Col xs={9}>
            <Row justify='center'>
              {t('daily_news.kpi.price_per_sm')}
            </Row>
            <Row justify='center' className='title big'>
              {formatCurrency(
                calculateVatPrice(data?.average_price_per_sm.current || null, 120),
                false)}
            </Row>
            <Row justify='center' className='subtitle'>
            {data?.average_price_per_sm.change ? (
                <div>
                  <HistoryPopover
                    values={{
                      old: formatCurrency(
                        calculateVatPrice(
                          data?.average_price_per_sm.old || null,
                          120,
                        ),
                      ),
                      current: formatCurrency(
                        calculateVatPrice(
                          data?.average_price_per_sm.current || null,
                          120,
                        ),
                      ),
                      change: data?.average_price_per_sm.change || null,
                    }}
                    currentDate={formatDate(paramsPM?.date)}
                    previousDate={formatDate(
                      getPreviousMonth(paramsPM?.date),
                    )}
                    isOverview
                  >
                    <p className='flex'>
                      <Chevron
                        number={data?.average_price_per_sm.change}
                        label={formatDate(paramsPM?.date)}
                      />
                    </p>
                  </HistoryPopover>
                </div>
              ) : undefined}
            </Row>
          </Col>
        </Row>
        <div>
          <ProjectDetailTableWithHistory
            currentDate={formatDate(paramsPM?.date)}
            previousDate={formatDate(getPreviousMonth(paramsPM?.date))}
            loading={isLoading}
            dataSource={tableData}
            customSummary={[
              {
                key: 'price',
                value: {
                  old: formatCurrency(calculateVatPrice(data?.average_price.old || null, 120)),
                  current: formatCurrency(calculateVatPrice(data?.average_price.current || null, 120)),
                  change: data?.average_price.change || null,
                },
              },
              {
                key: 'price_per_sm',
                value: {
                  old: formatCurrency(calculateVatPrice(data?.average_price_per_sm.old || null, 120)),
                  current: formatCurrency(calculateVatPrice(data?.average_price_per_sm.current || null, 120)),
                  change: data?.average_price_per_sm.change || null,
                },
              },
              {
                key: 'available',
                value: {
                  old: data?.total_units.old || null,
                  current: data?.total_units.current || null,
                  change: data?.total_units.change || null,
                },
              },
              {
                key: 'interior_sm',
                value: {
                  old: formatAreaUnits(data?.average_interior.old || null),
                  current: formatAreaUnits(data?.average_interior.current || null),
                  change: data?.average_interior.change || null,
                },
              },
            ]}
          />
        </div>
      </Card>
    </div>
  );
};
