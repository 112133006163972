/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  fetchBlockPriceComparisonData,
  fetchBlockSizeComparisonData,
} from '../../../../api';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { CompetitorSelect } from '../CompetitorSelect';
import { PriceComparisonChart } from './PriceComparisonChart';
import { PriceComparisonData } from '../../../../api/types';
import { ChartCard } from '../../../ChartCard';
import { getReportExportFileName, mergeObjectsByKeys } from '../../../../utils/utils';
import { useReservedData } from '../../../../hooks/useReservedData';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  PRICE_PER_SM,
  PRICE,
}

export const PriceComparison: FC = () => {
  const [data, setData] = useState<PriceComparisonData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.PRICE_PER_SM);
  const { areaUnit, calculateVatPrice } = useRegionFormatting();
  const { transformCompetitorsData } = useReservedData();
  const pngRef = useRef(null);

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      Promise.all([
        fetchBlockPriceComparisonData(
          paramsPM.dashboardId,
          paramsPM.date,
          competitors,
          pricePerSmCalculation,
        ),
        fetchBlockSizeComparisonData(
          paramsPM.dashboardId,
          paramsPM.date,
          competitors,
          pricePerSmCalculation,
        ),
      ])
        .then((response) => {
          const prices = response[0].data;
          const sizes = response[1].data;
          setData(
            mergeObjectsByKeys(prices, sizes, [
              'availability',
              'layout',
              'project_name',
            ]).map((obj) => ({
              ...obj,
              market: {
                ...obj.market,
                price:
                  calculateVatPrice(
                    obj.market.price,
                    obj.market.floor_area,
                    obj.market.exterior_area,
                  ) ?? 0,
                price_per_sm:
                  calculateVatPrice(
                    obj.market.price_per_sm,
                    obj.market.floor_area,
                    obj.market.exterior_area,
                  ) ?? 0,
              },
            })),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, calculateVatPrice, pricePerSmCalculation]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.price_comparison.title', 'Price Comparison'));
  const chartData = transformCompetitorsData(data, competitors);

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={t('market.reports.price_comparison.title', 'Price Comparison')}
      extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      subtitle={t(
        'market.reports.price_comparison.subtitle',
        'The comparison of the average price of units in the selected projects.',
      )}
      infoPopoverContent={
        <div css={styles.saleSpeed.popoverContainer}>
          <p>
            {t(
              'market.reports.price_comparison.popover.description',
              'The price is calculated from the values of the last 30 days.',
            )}
          </p>
        </div>
      }
      chart={
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          <PriceComparisonChart
            data={chartData}
            isPrice={filterValue === FilterEnum.PRICE}
          />
        )
      }
      controls={
        <>
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.PRICE_PER_SM,
                label: t('common.price_per_unit', 'Price per {{unit}}', {
                  unit: areaUnit,
                }),
              },
              {
                value: FilterEnum.PRICE,
                label: t('common.price', 'Price'),
              },
            ]}
            onChange={handleChange}
          />
          <div css={styles.competitorsContainer}>
            <CompetitorSelect />
          </div>
        </>
      }
    />
    </div>
  );
};
