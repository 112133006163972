import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, InfoPopover } from '../../components';
import styles from './ComparisonBreakdown.module.css';

type BreakdownRowProps = {
  housePartKey: string;
};

export const ComparisonBreakdown: FC = () => {
  const { t } = useTranslation();

  const BreakdownRow: FC<BreakdownRowProps> = ({ housePartKey }) =>
      <div className='flex items-center justify-between h-10'>
      <div>
        {t(`enums.house_parts.${housePartKey}`, housePartKey)}
      </div>
        <InfoPopover
          popoverProps={{
            content:
              <div>
                <p>{t(`price_list.hints.${housePartKey}.desc`, housePartKey)}</p>
              </div>,
          }}
        />
      </div>;

  return (
    <Card
      title={t('price_list.detail.price_breakdown.title')}
    >
      <div className='space-y-10 sm:w-[37rem]'>
      <div className={styles.imageSpace} />
      <div className='grid auto-rows-auto gap-10'>
        {/* INTERIOR */}
        <div className='grid grid-cols-2'>
          <div className='font-semibold row-span-5'>
            {t('enums.house_areas.interior')}
          </div>
          <BreakdownRow housePartKey='layout' />
          <BreakdownRow housePartKey='layout_class' />
          <BreakdownRow housePartKey='floor_area' />
          <BreakdownRow housePartKey='difference_floor_area' />
        </div>
        {/* EXTERIOR */}
        <div className='grid grid-cols-2'>
          <div className='font-semibold row-span-4'>
            {t('enums.house_areas.exterior')}
          </div>
          <BreakdownRow housePartKey='balcony_area' />
          <BreakdownRow housePartKey='terrace_area' />
          <BreakdownRow housePartKey='garden_area' />
          <BreakdownRow housePartKey='has_no_exterior' />
        </div>
        {/* FLOOR */}
        <div className='grid grid-cols-2'>
          <div className='font-semibold row-span-3'>
            {t('enums.house_areas.floor')}
          </div>
          <BreakdownRow housePartKey='floor' />
          <BreakdownRow housePartKey='top_floor' />
          <BreakdownRow housePartKey='bottom_floor' />
        </div>
        {/* ORIENTATION */}
        <div className='grid grid-cols-2'>
          <div className='font-semibold row-span-4'>
            {t('enums.house_areas.orientation')}
          </div>
          <BreakdownRow housePartKey='cardinal_direction' />
          <BreakdownRow housePartKey='view' />
          <BreakdownRow housePartKey='distance' />
          <BreakdownRow housePartKey='num_sides' />
        </div>
        {/* LAYOUT */}
        <div className='grid grid-cols-2'>
          <div className='font-semibold row-span-9 max-w-[5rem]'>
            {t('enums.house_areas.layout')}
          </div>
          <BreakdownRow housePartKey='num_of_bathrooms' />
          <BreakdownRow housePartKey='walk_through_room' />
          <BreakdownRow housePartKey='kitchen_luminosity' />
          <BreakdownRow housePartKey='next_to_elevator' />
          <BreakdownRow housePartKey='ratio_bedrooms' />
          <BreakdownRow housePartKey='ratio_living' />
          <BreakdownRow housePartKey='noise' />
          <BreakdownRow housePartKey='permit_regular' />
          <BreakdownRow housePartKey='category' />
        </div>
      </div>
      </div>
    </Card>
  );
};
