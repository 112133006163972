/* eslint-disable consistent-return */
import { Action, action, thunk, Thunk } from 'easy-peasy';
import { createSalesTarget, deleteSalesTarget, fetchSalesPlan, fetchSalesTargets, updateSalesPlan, updateSalesPlanDate, updateSalesTargets } from '../api';
import { StoreModel } from './types';
import { ProjectPlans, ProjectTargets, Targets } from '../api/mockData/sales';
import { CreateSalesTarget, DeleteSalesTarget, UpdateSalesPlan, UpdateSalesPlanDate, UpdateSalesTargets } from '../api/types';
import { logError } from '../utils/utils';

export interface SalesStore {
  targetsData?: { projects: Targets[] };
  planData?: ProjectPlans;
  isEditingSales?: boolean;
  setTargets: Action<SalesStore, ProjectTargets>;
  setPlanData: Action<SalesStore, ProjectPlans>;
  setIsEditingSales: Action<SalesStore, boolean>;
  fetchTargets: Thunk<SalesStore, undefined, unknown, StoreModel>;
  fetchPlanData: Thunk<SalesStore, undefined, unknown, StoreModel>;
  updateTargets: Thunk<SalesStore, UpdateSalesTargets, unknown, StoreModel>;
  updatePlan: Thunk<SalesStore, UpdateSalesPlan, unknown, StoreModel>;
  updatePlanDate: Thunk<SalesStore, UpdateSalesPlanDate, unknown, StoreModel>;
  createTarget: Thunk<SalesStore, CreateSalesTarget, unknown, StoreModel>;
  deleteTarget: Thunk<SalesStore, DeleteSalesTarget, unknown, StoreModel>;
}

export const salesStore: SalesStore = {
  setTargets: action((state, data: ProjectTargets) => {
    state.targetsData = data;
  }),
  setPlanData: action((state, data: ProjectPlans) => {
    state.planData = data;
  }),
  setIsEditingSales: action((state, isEditingSales: boolean) => {
    state.isEditingSales = isEditingSales;
  }),
  fetchTargets: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await fetchSalesTargets();
      actions.setTargets(data);
    } catch (error) {
      logError('No sales targets found.');
    }
  }),
  fetchPlanData: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await fetchSalesPlan();
      actions.setPlanData(data);
    } catch (error) {
      logError('No sales plan found.');
    }
  }),
  updateTargets: thunk(async (actions, payload, helpers) => {
    try {
      const { data, status } = await updateSalesTargets({ ...payload });
      return { data, status };
    } catch (error) {
      logError('Failed to update sales target.');
    }
  }),
  updatePlan: thunk(async (actions, payload, helpers) => {
    try {
      const { data, status } = await updateSalesPlan({ ...payload });
      return { data, status };
    } catch (error) {
      logError('Failed to update sales plan.');
    }
  }),
  updatePlanDate: thunk(async (actions, payload, helpers) => {
    try {
      const { data, status } = await updateSalesPlanDate({ ...payload });
      return { data, status };
    } catch (error) {
      logError('Failed to update sales plan date.');
    }
  }),
  createTarget: thunk(async (actions, payload, helpers) => {
    try {
      const { data, status } = await createSalesTarget({ ...payload });
      return { data, status };
    } catch (error) {
      logError('Failed to create sales target.');
    }
  }),
  deleteTarget: thunk(async (actions, payload, helpers) => {
    try {
      const { status } = await deleteSalesTarget({ ...payload });
      return { status };
    } catch (error) {
      logError('Failed to delete sales target.');
    }
  }),
};
