/** @jsxImportSource @emotion/react */
import { FC, ReactNode, RefObject } from 'react';
import { Card as AntdCard, CardProps as AntdCardProps } from 'antd';
import { styles } from './styles';
import { InfoPopover } from '../InfoPopover';
import { NoDataContainer } from '../NoDataContainer';

export interface CardProps extends AntdCardProps {
  subtitle?: ReactNode;
  titleExtra?: ReactNode;
  titleClassname?: string;
  infoPopoverTitle?: string;
  infoPopoverContent?: ReactNode;
  dataMissing?: boolean;
  dataMissingMessage?: string;
  ref?: RefObject<HTMLDivElement>;
}
export const Card: FC<CardProps> = ({
  infoPopoverContent,
  infoPopoverTitle,
  title,
  subtitle,
  titleExtra,
  titleClassname,
  dataMissing = false,
  dataMissingMessage,
  ref,
  ...rest
}) => (
  dataMissing ? (
    <AntdCard
      ref={ref}
      css={styles.card}
    >
      <NoDataContainer message={dataMissingMessage} />
    </AntdCard>
    ) : (
      <AntdCard
        ref={ref}
        css={styles.card}
        title={
          (title || subtitle || titleExtra) && (
          <>
          <div css={styles.titleContainer} className={titleClassname}>
            <div css={styles.title}>{title}</div>
            <div css={styles.extra}>{titleExtra}</div>
          </div>
          <div css={styles.subtitle}>
            {subtitle}
            {
              (infoPopoverTitle || infoPopoverContent) && (
                <InfoPopover
                  popoverProps={{
                  content: infoPopoverContent,
                  title: infoPopoverTitle,
                }}
                />
                )
              }
          </div>
          </>
          )
        }
        {...rest}
      />
    )
);
