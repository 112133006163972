import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { Input } from '../../inputs/Input';
import { Button } from '../../button/Button';
import { FormContainer } from '../FormContainer';
import { useStoreActions, useStoreState } from '../../../../hooks';

export const GetStarted: FC = () => {
  const { t } = useTranslation();

  const { handleNext, setProjectName } = useStoreActions(
    (actions) => actions.onboarding,
  );
  const { projectName } = useStoreState((state) => state.onboarding);

  return (
    <FormContainer>
      <div className='flex flex-col'>
        <Heading className='mt-4'>
          {t('onboarding.get_started.title', 'Let’s Get Started.')}
        </Heading>
        <Subtitle className='mt-4'>
          {t(
            'onboarding.get_started.subtitle',
            'What’s the name of your project?',
          )}
        </Subtitle>
        <Input
          className='mt-10'
          placeholder='Project Name'
          value={projectName}
          setValue={setProjectName}
        />
      </div>
      <div className='flex justify-center'>
        <Button disabled={!projectName.length} handleClick={handleNext}>
        {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
