import { message } from 'antd';
import axios from 'axios';
import { Action, action, thunk, Thunk } from 'easy-peasy';
import { t } from 'i18next';
import { fetchOverviewData, fetchProfile, login, updateProfile } from '../api';
import { LoginValues, Profile, UpdateProfile } from '../api/types';
import { StorageKeys } from '../utils';
import { getCountryIsoCode, logError } from '../utils/utils';
import { StoreModel } from './types';
import { territoriesApi } from '../api/secondary';
import { BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum } from '../api/secondaryClient';

export interface UserStore {
  profile?: Profile;
  updateProfile: Thunk<UserStore, UpdateProfile, Promise<UpdateProfile>>;
  setProfile: Action<UserStore, Profile>;
  isLoggedIn: boolean;
  setLoggedIn: Action<UserStore, boolean>;
  isLoading: boolean;
  setLoading: Action<UserStore, boolean>;
  login: Thunk<UserStore, LoginValues, unknown, StoreModel>;
}

export const userStore: UserStore = {
  setProfile: action((state, payload) => {
    state.profile = payload;
  }),
  isLoggedIn: false,
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  login: thunk(async (actions, payload, helpers) => {
    const { secondaryMarket, filters } = helpers.getStoreActions();
    try {
      actions.setLoading(true);
      localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
      const {
        data: { access_token },
      } = await login(payload);
      localStorage.setItem(StorageKeys.ACCESS_TOKEN, access_token);
      const { data } = await fetchProfile();
      localStorage.setItem(StorageKeys.USER_UUID, data.sub);
      let profileData = {
        ...data,
        daily_news_enabled: true,
      };

      secondaryMarket.fetchReports();
      const countryIsoCode = getCountryIsoCode(
        data.localization.toLowerCase(),
      ) as BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum;

      territoriesApi
        .territoriesEndpointsGetAllTerritoryIdsByCountry({ countryIsoCode })
        .then((response) =>
          response.map((teritory) => ({
            name: teritory.name,
            territoryId: [teritory.territoryId],
          })),
        )
        .then((newTerritories) => {
          territoriesApi
            .territoriesEndpointsReturnCustomTerritories({ countryIsoCode })
            .then((response) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const mappedResponse = [
                ...response.map((res) => ({
                  name: res.customTerritoryName,
                  territoryId: res.territoryIds,
                })),
              ];
              const territories = [];
              territories.push(...mappedResponse);
              territories.push(...newTerritories);
              filters.setTerritories(
                territories.map((d, i) => ({ ...d, key: `${d.name}-${i}` })),
              );
            });
        });

      const overviewData = await fetchOverviewData(data.developer_id);
      if (
        !overviewData.data.cities ||
        overviewData.data.cities.length === 0 ||
        !overviewData.data.market_dashboards ||
        overviewData.data.market_dashboards.length === 0
      ) {
        profileData = {
          ...profileData,
          daily_news_enabled: false,
        };
      }
      actions.setProfile({
        ...profileData,
        country_iso: countryIsoCode,
      });
      localStorage.setItem(
        StorageKeys.LAST_ACTIVE_TIME,
        new Date().getTime().toString(),
      );
      actions.setLoggedIn(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          message.error(t('login.invalid_credentials'));
          return;
        }
      }
      logError(err, true);
    } finally {
      actions.setLoading(false);
    }
  }),
  updateProfile: thunk(async (actions, payload, { getState }) => {
    try {
      await updateProfile(payload);
      const { profile } = getState();
      const newData = {
        ...profile,
        ...payload,
      } as Profile;
      const countryIsoCode = getCountryIsoCode(
        newData.localization.toLowerCase(),
      ) as BuiltmindApiTerritoriesEndpointsReturnCustomTerritoriesCountryIsoCodeEnum;
      actions.setProfile({
        ...newData,
        country_iso: countryIsoCode,
      });
    } catch (error) {
      logError(error);
    }
  }),
};
