import { css } from '@emotion/react/macro';
import { BLUE_5 } from '../../styles/constants';

export const styles = {
  container: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0.3rem',
  }),
  link: css({
    fontSize: '1.8rem',
    color: BLUE_5,
  }),
};
