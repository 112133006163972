import dayjs from 'dayjs';
import { Action, action, thunk, Thunk } from 'easy-peasy';
import { Target } from '../api/mockData/sales';
import { StoreModel } from './types';
import { createOnboardingProject } from '../api';

export type ProjectType = {
  sale: boolean;
  rental: boolean;
};

export type BuildingType = {
  house: boolean;
  unit: boolean;
};

export enum DataUpdates {
  CRM = 'crm',
  WEB = 'web',
  MANUAL = 'manual',
  EMPTY = 'empty',
}

export type StartingPrice = { layout: string; price: number | null };
export type OnboardingPriceList = { [key: string]: string | number | null };

export interface OnboardingStore {
  currentPage: number;
  setCurrentPage: Action<OnboardingStore, number>;
  projectName: string;
  setProjectName: Action<OnboardingStore, string>;
  buildingType: BuildingType;
  setBuildingType: Action<OnboardingStore, BuildingType>;
  projectType: ProjectType;
  setProjectType: Action<OnboardingStore, ProjectType>;
  dataUpdates: DataUpdates;
  setDataUpdates: Action<OnboardingStore, DataUpdates>;
  pageUrl: string;
  setPageUrl: Action<OnboardingStore, string>;
  emails: string[];
  setEmails: Action<OnboardingStore, string[]>;
  handleNext: Action<OnboardingStore>;
  handleBack: Action<OnboardingStore>;
  saleStart: dayjs.Dayjs | null;
  setSaleStart: Action<OnboardingStore, dayjs.Dayjs | null>;
  saleEnd: dayjs.Dayjs | null;
  setSaleEnd: Action<OnboardingStore, dayjs.Dayjs | null>;
  targets: Partial<Target>[];
  setTargets: Action<OnboardingStore, Partial<Target>[]>;
  startingPrices: StartingPrice[];
  setStartingPrices: Action<OnboardingStore, StartingPrice[]>;
  startingPricesOverall: number | null;
  setStartingPricesOverall: Action<OnboardingStore, number | null>;
  priceList: OnboardingPriceList[];
  setPriceList: Action<OnboardingStore, OnboardingPriceList[]>;
  fileName?: string;
  setFileName: Action<OnboardingStore, string>;
  skipPriceList: boolean;
  setSkipPriceList: Action<OnboardingStore, boolean>;
  createOnboardingProject: Thunk<
    OnboardingStore,
    undefined,
    unknown,
    StoreModel
  >;
  resetOnboardingStore: Action<OnboardingStore>;
  selectedFiles: { name: string; file: File }[];
  setSelectedFiles: Action<OnboardingStore, { name: string; file: File }[]>;
}

export const onboardingStore: OnboardingStore = {
  currentPage: 0,
  setCurrentPage: action((state, value) => {
    state.currentPage += value;
  }),
  projectName: '',
  setProjectName: action((state, value) => {
    state.projectName = value;
  }),
  buildingType: {
    house: false,
    unit: false,
  },
  setBuildingType: action((state, value) => {
    state.buildingType = value;
  }),
  projectType: {
    sale: false,
    rental: false,
  },
  setProjectType: action((state, value) => {
    state.projectType = value;
  }),
  dataUpdates: DataUpdates.CRM, // Default value
  setDataUpdates: action((state, value) => {
    state.dataUpdates = value;
  }),
  pageUrl: '',
  setPageUrl: action((state, value) => {
    state.pageUrl = value;
  }),
  emails: [],
  setEmails: action((state, value) => {
    state.emails = value;
  }),
  handleNext: action((state) => {
    state.currentPage += 1;
  }),
  handleBack: action((state) => {
    if (state.currentPage > 0) {
      state.currentPage -= 1;
    }
  }),
  saleStart: null,
  setSaleStart: action((state, value) => {
    state.saleStart = value;
  }),
  saleEnd: null,
  setSaleEnd: action((state, value) => {
    state.saleEnd = value;
  }),
  targets: [],
  setTargets: action((state, value) => {
    state.targets = value;
  }),
  startingPrices: [],
  setStartingPrices: action((state, value) => {
    state.startingPrices = value;
  }),
  startingPricesOverall: null,
  setStartingPricesOverall: action((state, value) => {
    state.startingPricesOverall = value;
  }),
  priceList: [],
  setPriceList: action((state, value) => {
    state.priceList = value;
  }),
  setFileName: action((state, value) => {
    state.fileName = value;
  }),
  skipPriceList: false,
  setSkipPriceList: action((state, value) => {
    state.skipPriceList = value;
  }),
  createOnboardingProject: thunk(async (actions, payload, helpers) => {
    const {
      projectName,
      projectType,
      priceList,
      startingPrices,
      targets,
      saleEnd,
      saleStart,
      dataUpdates,
      buildingType,
      startingPricesOverall,
      emails,
    } = helpers.getState();

    const {
      user: { profile },
    } = helpers.getStoreState();

    const project = {
      developer: {
        id: 123,
        emails,
      },
      project: {
        name: projectName,
        category: buildingType.house ? 'house' : 'unit',
        project_type: projectType.rental ? 'rental' : 'sale',
        price_level: startingPricesOverall
          ? { layout: 'overall', value: startingPricesOverall }
          : startingPrices,
        update_source: {
          type: dataUpdates,
        },
        sale_targets: {
          sale_end: saleEnd?.format('YYYY-MM-DD'),
          sale_start: saleStart?.format('YYYY-MM-DD'),
          targets_data: targets.map((target) => ({
            target_value: target.target_value,
            target_type: target.target_type,
            deadline: dayjs(target.target_month)
              .endOf('month')
              .format('YYYY-MM-DD'),
          })),
        },
        units: priceList.map((item) => {
          if (item.date_leased) {
            return {
              ...item,
              date_leased: dayjs(item.date_leased).format('YYYY-MM-DD'),
            };
          }
          return item;
        }),
      },
    };

    try {
      const data = createOnboardingProject({
        // developer_id: profile?.developer_id,
        developer_id: '123',
        project,
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }),
  resetOnboardingStore: action(() => ({
    currentPage: 0,
    projectName: '',
    projectType: { rental: false, sale: false },
    pageUrl: '',
    emails: [],
    dataUpdates: DataUpdates.EMPTY,
    buildingType: { house: false, unit: false },
    saleEnd: null,
    saleStart: null,
    targets: [],
    startingPrices: [],
    priceList: [],
    skipPriceList: false,
    startingPricesOverall: null,
    selectedFiles: [],
  })),
  selectedFiles: [],
  setSelectedFiles: action((state, files) => {
    state.selectedFiles = files;
  }),
};
