/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next';
import { FC } from 'react';
import moment, { MomentBuiltinFormat } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import simplify from 'simplify-js';
import dayjs from 'dayjs';
import { LayoutEnum, State } from '../api/enums';
import { PriceListTableData } from '../api/mockData/priceListData';
import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { PriceList } from '../api/types';
import {
  Availability,
  ExtendedProjectDetailTableData,
  MarketProjectExtended,
} from '../store/types';
import { LatLng, StorageKeys } from './types';
import activeSelected from '../assets/map-pins/active_selected.svg';
import activeUnselected from '../assets/map-pins/active_unselected.svg';
import archivedSelected from '../assets/map-pins/archived_selected.svg';
import archivedUnselected from '../assets/map-pins/archived_unselected.svg';
import { formatDailyNewsObjectHelper } from '../components/dailyNews/DailyNewsTrans';
import { CZECHIA_BOUNDS, DEFAULT_BOUNDS, SLOVAKIA_BOUNDS } from '../constants';
import { MultiPolygonalSchema } from '../api/secondaryClient';
import { Project } from '../api/mockData/projectData';
import { SaleProgressData } from '../api/mockData/saleProgressData';

const {
  LAYOUT_1,
  LAYOUT_1_5,
  LAYOUT_2,
  LAYOUT_3,
  LAYOUT_4,
  LAYOUT_5,
  LAYOUT_6,
  LAYOUT_7,
} = LayoutEnum;

export const getNumericSorter =
  <T extends { [key: string]: any }>(key: keyof T) =>
  (a: T, b: T) =>
    a[key] - b[key];

export const getRegExp = (path: string): RegExp =>
  new RegExp(path.replace(/:\w+/g, '[^/]+'));

export const getSum = (values: number[]): number =>
  values.reduce((prev, curr) => prev + curr, 0);

export const getAvg = (values: (number | null)[]): number => {
  const filteredValues = values.filter(
    (val) => val !== null && val !== undefined,
  ) as number[];
  return getSum(filteredValues) / filteredValues.length;
};

export const getAccessToken = (): string | null =>
  localStorage.getItem(StorageKeys.ACCESS_TOKEN);

export const getLayoutOrderUS = (layout: string): number => {
  switch (layout) {
    case LAYOUT_1:
      return 1;
    case LAYOUT_2:
      return 2;
    case LAYOUT_1_5:
      return 3;
    case LAYOUT_3:
      return 4;
    case LAYOUT_4:
      return 5;
    case LAYOUT_5:
      return 6;
    case LAYOUT_6:
      return 7;
    case LAYOUT_7:
      return 8;
    default:
      return -1;
  }
};

export const getLayoutOrderEU = (layout: string): number => {
  switch (layout) {
    case LAYOUT_1:
      return 1;
    case LAYOUT_1_5:
      return 2;
    case LAYOUT_2:
      return 3;
    case LAYOUT_3:
      return 4;
    case LAYOUT_4:
      return 5;
    case LAYOUT_5:
      return 6;
    case LAYOUT_6:
      return 7;
    case LAYOUT_7:
      return 8;
    default:
      return -1;
  }
};

export const convertFileToBase64String = async (
  file: File,
): Promise<string | ArrayBuffer> => {
  const result_base64 = await new Promise<string | ArrayBuffer>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.result) {
        resolve(fileReader.result);
      }
    };
    fileReader.readAsDataURL(file);
  });
  return result_base64;
};

export const getSumFromNotNullValues = (
  list: (number | null)[],
): number | null => {
  const filteredList = list.filter((v) => v !== null) as number[];
  if (filteredList.length > 0) {
    return getSum(filteredList);
  }
  return null;
};
export const getAvgFromNotNullValues = (
  list: { value: number | null; count: number | null }[],
): number | null => {
  const filteredList = list.filter((v) => v.value !== null) as {
    value: number;
    count: number;
  }[];

  if (filteredList.length > 0) {
    return (
      getSum(filteredList.map((v) => v.value * v.count)) /
      getSum(filteredList.map((v) => v.count))
    );
  }
  return null;
};

export const getMinMax = (
  list: (number | null)[],
  formattingFn?: (value: number) => string | null,
): string => {
  if (list.length === 1) {
    return `${list.pop()}`;
  }
  const filteredList = list.filter((v) => v !== null) as number[];
  const min = Math.min(...filteredList);
  const max = Math.max(...filteredList);
  if (filteredList.length > 0) {
    if (formattingFn) {
      return `${formattingFn(min)} - ${formattingFn(max)}`;
    }
    return `${min} - ${max}`;
  }
  return '';
};

export const getSafeSuffix = (suffix: string): string => {
  if (/^.+[.].+$/.test(suffix)) {
    return suffix;
  }
  return `${suffix}.jpg`;
};

export const sortByString = (a: string | null, b: string | null): number =>
  (a ?? '').localeCompare(b ?? '');

export const getUniqueValues = <T>(array: T[]): T[] =>
  array.filter((x, i, a) => a.indexOf(x) === i);

export const transformPriceListData = ({
  description: {
    availability,
    building_internal_id,
    exterior_area,
    floor,
    floor_area,
    internal_id,
    layout,
    orientation,
  },
  price: {
    current_price: {
      price: current_price,
      price_per_sm: current_price_per_sm,
      from: current_price_from,
    },
    new_price: {
      price: new_price,
      price_per_sm: new_price_per_sm,
      difference: price_difference,
    },
    currency,
  },
  id,
  images,
  insights,
}: PriceListDetailData): PriceListTableData => ({
  availability,
  building_internal_id,
  current_price,
  current_price_from,
  current_price_per_sm,
  exterior_area,
  floor,
  floor_area,
  id,
  images,
  insights,
  internal_id,
  layout,
  new_price,
  new_price_per_sm,
  orientation,
  currency,
  price_difference,
  building_id: 0,
});

const getCompletionDateSecondPartOrder = (value: string): number => {
  switch (value) {
    case '01':
      return 1;
    case '02':
      return 2;
    case '03':
      return 3;
    case 'Q1':
      return 4;
    case '04':
      return 5;
    case '05':
      return 6;
    case '06':
      return 7;
    case 'Q2':
      return 8;
    case '07':
      return 9;
    case '08':
      return 10;
    case '09':
      return 11;
    case 'Q3':
      return 12;
    case '10':
      return 13;
    case '11':
      return 14;
    case '12':
      return 15;
    case 'Q4':
      return 16;
    case '':
      return 17;
    default:
      return -1;
  }
};

export const sortCompletionDates = (
  a: string | null,
  b: string | null,
): number => {
  if (a === b) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  const yearA = a.substring(0, 4);
  const yearB = b.substring(0, 4);
  if (yearA === yearB) {
    const secondPartA = a.length > 4 ? a.substring(5, 7) : '';
    const secondPartB = b.length > 4 ? b.substring(5, 7) : '';
    return (
      getCompletionDateSecondPartOrder(secondPartA) -
      getCompletionDateSecondPartOrder(secondPartB)
    );
  }
  return Number(yearA) - Number(yearB);
};

export const countDigits = (value: number): number =>
  Math.abs(value).toString().split('.')[0].length || 0;

export const aggregatePriceListData = (
  data: PriceList[],
): ExtendedProjectDetailTableData[] =>
  data.reduce<ExtendedProjectDetailTableData[]>(
    (
      previousValue,
      {
        availability,
        exterior_area: currExteriorArea,
        floor_area: currFloorArea,
        layout,
        price: currPrice,
        price_per_sm: currPricePerSM,
        garages: currGarages,
        baths: currBaths,
      },
    ) => {
      const index = previousValue.findIndex(
        (v) => v.layout === layout && v.availability === availability,
      );
      if (index > -1) {
        const {
          available: prevAvailable,
          exterior_sm: prevExteriorSM,
          interior_sm: prevInteriorSM,
          price: prevPrice,
          price_per_sm: prevPricePerSM,
          baths: prevBaths,
          garages: prevGarages,
          nonNullPricePerSmCount,
          nonNullInteriorSM,
          nonNullPrice,
        } = previousValue[index];
        const newArray = [...previousValue];
        let newPricePerSM;
        if (currPricePerSM !== null) {
          if (prevPricePerSM !== null) {
            newPricePerSM =
              (prevPricePerSM * nonNullPricePerSmCount + currPricePerSM) /
              (nonNullPricePerSmCount + 1);
          } else newPricePerSM = currPricePerSM;
        } else {
          newPricePerSM = prevPricePerSM;
        }
        let newPrice;
        if (currPrice !== null) {
          if (prevPrice !== null) {
            newPrice =
              (prevPrice * nonNullPrice + currPrice) / (nonNullPrice + 1);
          } else newPrice = currPrice;
        } else {
          newPrice = prevPrice;
        }
        let newInteriorSM;
        if (currFloorArea !== null) {
          if (prevInteriorSM !== null) {
            newInteriorSM =
              (prevInteriorSM * nonNullInteriorSM + currFloorArea) /
              (nonNullInteriorSM + 1);
          } else newInteriorSM = currFloorArea;
        } else {
          newInteriorSM = prevInteriorSM;
        }
        const newValue: ExtendedProjectDetailTableData = {
          availability,
          available: prevAvailable + 1,
          exterior_sm:
            prevExteriorSM === null && currExteriorArea === null
              ? null
              : (prevExteriorSM ?? 0) + (currExteriorArea ?? 0),
          interior_sm: newInteriorSM,
          layout,
          nonNullPricePerSmCount:
            currPricePerSM !== null
              ? nonNullPricePerSmCount + 1
              : nonNullPricePerSmCount,
          nonNullInteriorSM:
            currFloorArea !== null ? nonNullInteriorSM + 1 : nonNullInteriorSM,
          nonNullPrice: currPrice !== null ? nonNullPrice + 1 : nonNullPrice,
          price: newPrice,
          price_per_sm: newPricePerSM,
          baths: getUniqueValues([...prevBaths, currBaths]),
          garages: getUniqueValues([...prevGarages, currGarages]),
        };
        newArray[index] = newValue;
        return newArray;
      }
      const transformed: ExtendedProjectDetailTableData = {
        availability,
        available: 1,
        exterior_sm: currExteriorArea,
        interior_sm: currFloorArea,
        layout,
        nonNullPricePerSmCount: currPricePerSM === null ? 0 : 1,
        nonNullInteriorSM: currFloorArea === null ? 0 : 1,
        nonNullPrice: currPrice === null ? 0 : 1,
        price: currPrice,
        price_per_sm: currPricePerSM,
        baths: [currBaths],
        garages: [currGarages],
      };
      const newArr = [...previousValue, transformed];
      return newArr;
    },
    [],
  );

export const translateBlockChartValue = (
  value: string,
  t: TFunction,
  isRentals?: boolean,
): string => {
  if ([State.AVAILABLE, State.SOLD].includes(value as State)) {
    return value === 'sold' && isRentals
      ? t('charts.state.leased')
      : t(`charts.state.${value}`);
  }
  return value;
};

type MergeObjects<T, U> = T & U;
type mergeNestedObjects<
  T extends Record<string, any>,
  U extends Record<string, any>,
> = T & U;

export const mergeObjectsByKeys = <
  T extends Record<string, any>,
  U extends Record<string, any>,
  K extends keyof T & keyof U,
>(
  arr1: T[],
  arr2: U[],
  keys: K[],
): MergeObjects<T, U>[] => {
  const keyMap = new Map<string, U>();

  for (const obj of arr2) {
    const key = keys.map((k) => obj[k]).join('');
    keyMap.set(key, obj);
  }

  const mergeNestedObjects = (source: T, target: U): any => {
    const merged = { ...target } as mergeNestedObjects<T, U>;

    for (const prop in source) {
      if (source[prop]) {
        const sourceValue = source[prop];
        const targetValue = (target as Record<keyof U, unknown>)[
          prop as unknown as keyof U
        ];

        if (
          typeof sourceValue === 'object' &&
          sourceValue !== null &&
          typeof targetValue === 'object' &&
          targetValue !== null
        ) {
          merged[prop as keyof T & keyof U] = mergeNestedObjects(
            sourceValue,
            targetValue as any,
          ) as MergeObjects<T, U>[keyof T & keyof U];
        } else if (targetValue === undefined) {
          merged[prop as keyof mergeNestedObjects<T, U>] =
            sourceValue as T[keyof T];
        }
      }
    }
    return merged;
  };

  const result = arr1.map((obj1) => {
    const key = keys.map((k) => String(obj1[k])).join('');
    const merged = mergeNestedObjects(obj1, {
      ...((keyMap.get(key) as U) || {}),
    }) as MergeObjects<T, U>;
    return merged;
  }) as MergeObjects<T, U>[];

  return result;
};

export const logError = (error: any, hideError = false): void => {
  if (!hideError) {
    // eslint-disable-next-line no-console
    console.error('Unexpected error!');
  }
  // eslint-disable-next-line no-console
  console.error(error);
};

export const getMapPin = (
  available_units: number,
  isEditingReport: boolean,
  include?: boolean,
): string => {
  const isActive = available_units > 0;
  const isSelected = include;
  if (isActive) {
    if (isEditingReport) {
      return isSelected ? activeSelected : activeUnselected;
    }
    return activeSelected;
  }

  if (isEditingReport) {
    return isSelected ? archivedSelected : archivedUnselected;
  }
  return archivedSelected;
};

export type FormatDailyNewsObjectProps = {
  [date: string]: {
    [id: number]: string;
  };
};

export type FormatDailyNewsObjectOutput = {
  date: string;
  value: FC;
  project_id: number;
};

type Item = {
  variables: Record<string, string>[];
};

export const formatDailyNewsObject = (
  obj: FormatDailyNewsObjectProps,
  i18key: string,
  language: string,
  t: TFunction,
  icon: {
    source: string;
    color: string;
  },
  onClick?: (item: Item) => void,
): FormatDailyNewsObjectOutput[] =>
  formatDailyNewsObjectHelper(
    Object.keys(obj).map((key) => ({
      date: key,
      icon,
      language,
      items: [
        ...Object.entries(obj[key]).map(([nestedKey, value]) => ({
          project_id: Number(nestedKey),
          variables: [
            {
              project_name: value,
            },
          ],
          i18key,
          onClick,
        })),
      ],
    })),
    t,
  );

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return [...array];
}

export const getLastUpdateText = (daysAgo: number): string => {
  const formattedDate = moment()
    .subtract(daysAgo, 'days')
    .format('MMMM DD YYYY');
  return `The last update: ${formattedDate}`;
};

export type UpdateFrequency =
  | 'daily'
  | 'weekly'
  | 'biweekly'
  | 'monthly'
  | 'quarterly'
  | null;

export const getUpdateFrequencyDates = (
  dates: string[],
  frequency: UpdateFrequency,
): string[] => {
  if (!frequency || frequency === 'daily') {
    return dates;
  }

  if (frequency === 'weekly') {
    const weeklyDates = dates.filter((date) => {
      const day = date.slice(-2);
      return (
        day === '01' ||
        day === '08' ||
        day === '15' ||
        day === '22' ||
        day === '29'
      );
    });
    if (weeklyDates.length > 0) {
      return weeklyDates;
    }
  }

  if (frequency === 'biweekly') {
    const biweeklyDates = dates.filter((date) => {
      const day = date.slice(-2);
      return day === '01' || day === '15';
    });

    if (biweeklyDates.length > 0) {
      return biweeklyDates;
    }
  }

  if (frequency === 'monthly') {
    const monthlyDates = dates.filter((date) => date.slice(-2) === '01');
    if (monthlyDates.length > 0) {
      return monthlyDates;
    }
  }

  if (frequency === 'quarterly') {
    const quarterlyDates = dates.filter((date) => {
      const day = date.slice(-2);
      const month = date.slice(5, 7);
      return (
        day === '01' &&
        (month === '01' || month === '04' || month === '07' || month === '10')
      );
    });

    if (quarterlyDates.length > 0) {
      return quarterlyDates;
    }
  }

  return [dates[0]];
};

export const getValidPriceListDate = (
  frequency: UpdateFrequency | null,
  numberOfDays: number,
): string => {
  if (!frequency) {
    return moment().subtract(1, 'day').format('YYYY-MM-DD');
  }

  // Create last X days array
  const dates: string[] = [];

  for (let i = 0; i < numberOfDays; i++) {
    const date = moment()
      .subtract(i - 1, 'day')
      .format('YYYY-MM-DD');
    dates.push(date);
  }
  return getUpdateFrequencyDates(dates, frequency)[0];
};

export const capitalize = (text: string): string => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const getGeometry = (
  polygons: google.maps.LatLng[][],
): MultiPolygonalSchema => {
  const geoJson: MultiPolygonalSchema = {
    type: 'MultiPolygon',
    coordinates: polygons.reduce(
      (
        acc: number[][][][],
        polygon: google.maps.LatLng[],
        currentIndex: number,
      ) => {
        const newCoordinates = [...acc];
        const singleCoordinates = polygon.map((point) => [
          point.lng(),
          point.lat(),
        ]);
        singleCoordinates.push(singleCoordinates[0]);
        if (!newCoordinates[currentIndex]) {
          newCoordinates.push([singleCoordinates]);
        } else if (newCoordinates[currentIndex][0]) {
          (newCoordinates[currentIndex][0] as unknown as number[][][]).push(
            singleCoordinates,
          );
        }
        return newCoordinates;
      },
      [],
    ),
  };
  return geoJson;
};

export const parseGeometry = (
  geoJson: MultiPolygonalSchema,
): Record<string, LatLng[]> => {
  const flattenedCoordinates = geoJson?.coordinates.flat();
  const parsedPolygons = flattenedCoordinates.reduce((acc, curr, index) => {
    const name = `polygon-${index}`;
    const coordinates = curr.map((v) => ({ lat: v[1], lng: v[0] }));
    return {
      ...acc,
      [name]: coordinates,
    };
  }, {} as { [key: string]: LatLng[] });
  return parsedPolygons;
};

export const getCountryCode = (
  country?: string,
): 'CZ' | 'SK' | 'invalid country' => {
  switch (country) {
    case 'slovakia':
      return 'SK';
    case 'czechia':
      return 'CZ';
    default:
      return 'invalid country';
  }
};

export const getUserUuid = (): string | null =>
  localStorage.getItem(StorageKeys.USER_UUID);

export const getBounds = (
  country?: string,
): google.maps.LatLngBoundsLiteral => {
  switch (country) {
    case 'slovakia':
      return SLOVAKIA_BOUNDS;
    case 'czechia':
      return CZECHIA_BOUNDS;
    default:
      return DEFAULT_BOUNDS;
  }
};

export const simplifyPolygon = (polygon: number[][]): number[][] =>
  simplify(
    polygon.map((p) => ({ x: p[0], y: p[1] })),
    0.001,
    true,
  ).map((p) => [p.x, p.y]);

type Point = {
  lat: number;
  lng: number;
};

export const checkInPoly = (point: Point, polygon: number[][]): boolean => {
  const { lat, lng } = point;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect =
      yi > lng !== yj > lng &&
      // eslint-disable-next-line no-mixed-operators
      lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const slugifyString = (str: string): string =>
  String(str)
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

export const getReportExportFileName = (
  reportName: string,
  reportTypeName: string,
): string => `${slugifyString(reportName)}-${slugifyString(reportTypeName)}`;

export const moveItemInArray = <T>(
  array: T[],
  from: number,
  to: number,
): T[] => {
  const newArray = [...array];
  const [removed] = newArray.splice(from, 1);
  newArray.splice(to, 0, removed);
  return newArray;
};

export const momentMonthsArrayGenerate = (
  numberOfMonths: number,
  format: string,
): string[] =>
  Array.from({ length: numberOfMonths }, (_, i) =>
    moment().add(i, 'months').format(format),
  );

export const calculateProjectData = (
  project: MarketProjectExtended,
  getMinMaxProjectPrice: (data: Project | MarketProjectExtended | null) => {
    minPrice: number;
    maxPrice: number;
  },
  calculateVatPrice: (
    value: number | null,
    floor_area: number | null,
    exterior_area?: number | null,
  ) => number | null,
): MarketProjectExtended => {
  const avgFloorArea =
    project.price_list?.reduce((acc, cur) => acc + cur.floor_area, 0) ?? null;
  const avgExteriorArea =
    project.price_list?.reduce(
      (acc, cur) => acc + Number(cur.exterior_area),
      0,
    ) ?? null;
  const { minPrice, maxPrice } = getMinMaxProjectPrice(project);

  return {
    ...project,
    avg_price: calculateVatPrice(
      project.avg_price,
      avgFloorArea,
      avgExteriorArea,
    ),
    avg_price_per_sm: calculateVatPrice(
      project.avg_price_per_sm ?? null,
      avgFloorArea,
      avgExteriorArea,
    ),
    min_price_per_sm: minPrice,
    max_price_per_sm: maxPrice,
    price_list: project.price_list?.map((obj) => ({
      ...obj,
      price: calculateVatPrice(
        obj.price,
        obj.floor_area,
        Number(obj.exterior_area),
      ),
      price_per_sm: calculateVatPrice(
        obj.price_per_sm,
        obj.floor_area,
        Number(obj.exterior_area),
      ),
    })),
    parking_indoor_price: calculateVatPrice(project.parking_indoor_price, 119),
    parking_outdoor_price: calculateVatPrice(
      project.parking_outdoor_price,
      119,
    ),
  };
};

export const shouldUseShorten = (
  value: number,
): { shortenThousands: boolean; shortenMillions: boolean } => {
  const valueLenght = String(Math.trunc(value)).length;
  const shortenThousands = valueLenght >= 4 && valueLenght < 7;
  const shortenMillions = valueLenght >= 7;
  return { shortenThousands, shortenMillions };
};

export const isWithinLast12Months = (dateString: string): boolean => {
  const date = dayjs(`${dateString}-01`);
  const currentDate = dayjs();
  const pastDate = currentDate.subtract(12, 'month');
  return date.isAfter(pastDate) && date.isBefore(currentDate.add(1, 'day'));
};

const generatePast12Months = (): string[] => {
  const months = [];
  const currentDate = dayjs();
  for (let i = 0; i < 12; i++) {
    months.push(currentDate.subtract(i, 'month').format('YYYY-MM'));
  }
  return months.reverse();
};

const transformDate = (date: string): string => dayjs(date).format('YYYY-MM');

export const fillMissingMonths = <T extends { date: string }>(
  data: T[],
  createEmptyItem: (date: string) => T,
): T[] => {
  const past12Months = generatePast12Months();
  const dataMap = new Map<string, T[]>(
    past12Months.map((month) => [month, []]),
  );

  data.forEach((item) => {
    const month = transformDate(item.date);
    if (dataMap.has(month)) {
      dataMap.get(month)!.push(item);
    }
  });

  const filledData: T[] = [];
  let firstDataFound = false;
  past12Months.forEach((month) => {
    if (dataMap.get(month)!.length > 0) {
      firstDataFound = true;
      filledData.push(...dataMap.get(month)!);
    } else if (firstDataFound) {
      filledData.push(createEmptyItem(month));
    }
  });

  return filledData;
};

export const sortReportsByOrder = <T extends { name: string }>(
  reports: T[],
  order: string[],
): T[] => {
  if (order.length === 0) return reports;
  const orderMap = new Map(order.map((name, index) => [name, index]));
  return reports.sort(
    (a, b) =>
      (orderMap.get(a.name) ?? Number.MAX_SAFE_INTEGER) -
      (orderMap.get(b.name) ?? Number.MAX_SAFE_INTEGER),
  );
};

export const getCountryIsoCode = (localization: string): string => {
  switch (localization.toLowerCase()) {
    case 'sk':
    case 'de':
    case 'at':
      return localization.toUpperCase();
    default:
      return 'CZ';
  }
};
