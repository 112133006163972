import { RefObject } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';
import { message } from 'antd';
import dayjs from 'dayjs';

export const downloadPNG = (
  fileName: string,
  ref: RefObject<HTMLElement>,
): void => {
  message.loading('Creating PNG...', 0);
  const fileNameFormated = `${fileName}-${dayjs().format('YYYY-MM-DD')}`;
  const input = ref.current;
  // @ts-ignore
  html2canvas(input, { allowTaint: true, useCORS: true, scale: 3 }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png', 1);
    const a = document.createElement('a');
    a.href = imgData;
    a.download = `${fileNameFormated}.png`;
    try {
      a.click();
      message.destroy();
      message.success('PNG created', 2);
    } catch (error) {
      message.error('Error downloading PNG', 2);
    }
  });
};
