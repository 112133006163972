import { FC, useEffect, useMemo, useState } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSaleProgressChartData } from '../../../api';
import {
  BLUE_1,
  GREY_1,
  GREY_2,
  GREY_3,
  RESERVED,
} from '../../../styles/constants';
import { getColumnOptions } from '../../../utils/defaultChartConfig';
import { useReservedData } from '../../../hooks/useReservedData';
import { Availability } from '../../../store/types';
import { fillMissingMonths, isWithinLast12Months } from '../../../utils/utils';
import { SaleProgressData } from '../../../api/mockData/saleProgressData';
import { useStoreState } from '../../../hooks';

type SaleSpeedProps = {
  availability: Availability;
};

const colorMap: { [key: string]: string } = {
  private: GREY_2,
  public: GREY_1,
  b2b: BLUE_1,
};
const fallbackColor = GREY_3;
const getColorForType = (type: string): string =>
  colorMap[type] ?? fallbackColor;

export const SaleSpeed: FC<SaleSpeedProps> = ({
  availability,
}: SaleSpeedProps) => {
  const [data, setData] = useState<SaleProgressData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { displayValues } = useStoreState((state) => state.filters);
  const { projectId } = useParams();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { mapReservedData } = useReservedData();

  const processData = (
    inputData: SaleProgressData[],
  ): {
    date: string;
    type: string;
    count: number;
    availability: Availability;
  }[] =>
    inputData.flatMap((d) => {
      if (d.counts_by_type || d.availability === 'reserved') {
        return Object.entries(d.counts_by_type).map(([type, count]) => ({
          date: d.date,
          type,
          count,
          availability: d.availability,
        }));
      }
      // Assume all sales are 'public' if counts_by_type is not present
      return [
        {
          date: d.date,
          type: 'public',
          count: d.value,
          availability: d.availability,
        },
      ];
    });

  const mappedData = useMemo(() => {
    const processedData = processData(mapReservedData(data));
    return fillMissingMonths<{
      date: string;
      type: string;
      count: number;
      availability: Availability;
    }>(processedData, (date) => ({
      date,
      count: 0,
      availability,
      type: availability,
    }));
  }, [data, mapReservedData]);

  useEffect(() => {
    const convertedProjectId = Number(projectId);
    if (!Number.isNaN(convertedProjectId)) {
      setLoading(true);
      getSaleProgressChartData(convertedProjectId)
        .then(({ data: receivedData }) => {
          const filteredData = receivedData
            .filter(
              (d) =>
                d.availability === availability && isWithinLast12Months(d.date),
            )
            .sort((a, b) => a.date.localeCompare(b.date));
          setData(filteredData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [projectId, availability]);

  const config: ColumnConfig = getColumnOptions({
    data: mappedData,
    isStack: true, // Enable stacking
    xField: 'date',
    yField: 'count',
    seriesField: availability === Availability.RESERVED ? undefined : 'type',
    color:
      availability === Availability.RESERVED
        ? RESERVED
        : ({ type }) => getColorForType(type),
    label: displayValues ? {
      position: availability === Availability.RESERVED ? 'top' : 'middle',
      formatter: ({ count }) => count !== 0 ? count : '',
      style: {
        fill: '#000',
        opacity: 0.6,
      },
    } : undefined,
    tooltip: {
      formatter: (datum) => ({
        name: datum.type,
        value: datum.count ?? '-',
      }),
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),

      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color ?? ''
              }"></div>
              <div class="bmt-label">${
                availability === Availability.RESERVED
                  ? t('common.reservations')
                  : t(`common.sale_type.${item?.name}`, item?.name) ?? ''
              }:</div>
              <div class="bmt-value">${item?.value ?? ''}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const date = new Date(value);
          return date.toLocaleDateString(language, {
            month: 'long',
            year: 'numeric',
          });
        },
      },
    },
    yAxis: {
      max: Math.max(...data.map((d) => d.value)) * 1.1,
      label: {
        style: { textAlign: 'left' },
      },
    },
    legend: {
      itemName: {
        formatter: (value) => t(`common.sale_type.${value}`, value),
      },
    },
  });

  const chartConfig = {
    ...config,
    columnStyle: {
      radius: availability === Availability.RESERVED ? [7, 7, 0, 0] : 0,
    },
  };

  if (isLoading) {
    return <Spin className='center' spinning />;
  }
  return mappedData.length > 0 ? (
    <Column className='chart-container' {...chartConfig} />
  ) : (
    <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
