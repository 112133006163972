/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import TagIcon from '@heroicons/react/24/outline/TagIcon';
import dayjs from 'dayjs';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import ClockIcon from '@heroicons/react/24/outline/ClockIcon';
import ChartBarIcon from '@heroicons/react/24/outline/ChartBarIcon';
import { useTranslation } from 'react-i18next';
import { styles } from '../styles';
import { ProjectType } from '../../../store/types';
import { useStoreState } from '../../../hooks';

type Props = {
  unavailable_flats?: number;
  created_at: string;
  updated_at: string;
  total_flats?: number;
  revenueIncreaseText?: string | 0 | null | undefined;
  unavailableFlatsPercentage?: string | 0 | null | undefined;
  elapsedSaleDaysPercentage?: string | 0 | null | undefined;
  hideRevenue?: boolean;
  sold_units?: number;
  reserved_units?: number;
  revenue_increase: number;
  initial_revenue: number;
};

export const SubtitleContainer: FC<Props> = ({
  unavailable_flats,
  total_flats,
  created_at,
  updated_at,
  revenueIncreaseText,
  unavailableFlatsPercentage,
  elapsedSaleDaysPercentage,
  hideRevenue = false,
  sold_units,
  reserved_units,
  revenue_increase,
  initial_revenue,
}) => {
  const { t } = useTranslation();
  const revenue_increase_calculated =
    (revenue_increase / initial_revenue) * 100;
  const revenueIncreasePercentage = revenue_increase_calculated.toFixed(1);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const createdDate = dayjs(created_at).format('MMM DD, YYYY');
  const updateDate = dayjs(updated_at).format('MMM DD, YYYY');
  return (
    <div
      css={!hideRevenue ? styles.subtitleWrapper : styles.subtitleWrapperModal}
    >
      <div css={styles.subtitleContainer}>
        <TagIcon css={styles.icon} />

        <h4 css={styles.subtitleTitle}>
          {isRent
            ? t('price_update.subtitle_container.leased', 'Leased')
            : t(
                'price_update.subtitle_container.sold_and_reserved',
                'Sold + Reserved',
              )}
        </h4>
        <h2 css={styles.subtitleValue}>{unavailableFlatsPercentage}%</h2>
        {/* <div css={styles.subtitleData}>
          <div css={styles.subtitleDataLeft}>
            <p>{t('enums.state.sold')}</p>
            <p>{t('enums.state.reserved')}</p>
            <p>{t('project.unit_mix.total')}</p>
          </div>
          <div css={styles.subtitleDataRight}>
            <p>{sold_units}/{total_flats}</p>
            <p>{reserved_units}/{total_flats}</p>
            <p>{unavailable_flats}/{total_flats}</p>
          </div>
        </div> */}
      </div>
      <Divider type='vertical' css={styles.divider} />
      <div css={styles.subtitleContainer}>
        <ClockIcon css={styles.icon} />
        <h4 css={styles.subtitleTitle} className='text-center'>
          {isRent
            ? t(
                'price_update.subtitle_container.full_occupancy_target',
                'Full Occupancy Target',
              )
            : t(
                'price_update.subtitle_container.time_progress',
                'Time Progress',
              )}
        </h4>
        <h2 css={styles.subtitleValue}>{elapsedSaleDaysPercentage}%</h2>
        {/* <div css={styles.subtitleData}>
          <div css={styles.subtitleDataLeft}>
            <p>{t('enums.state.start', 'Start')}</p>
            <p>{t('enums.state.update', 'Update')}</p>
          </div>
          <div css={styles.subtitleDataRight}>
          <p>{createdDate}</p>
          <p>{updateDate}</p>
          <p css={styles.subtitle}>{elapsed_sale_days}/{total_sale_days} {t('price_update.subtitle_container.days', 'DAYS')}</p>
          </div>
        </div> */}
      </div>
      {!hideRevenue && (
        <>
          <Divider type='vertical' css={styles.divider} />
          <div css={styles.subtitleContainer}>
            <ChartBarIcon css={styles.icon} />
            <h4 css={styles.subtitleTitle}>
              {isRent
                ? t(
                    'price_update.subtitle_container.annual_revenue_change',
                    'Annual Revenue Change',
                  )
                : t(
                    'price_update.subtitle_container.revenue_increase',
                    'Revenue Increase',
                  )}
            </h4>
            <h2 css={styles.subtitleValue}>{revenueIncreaseText}</h2>
            <div css={styles.subtitleData}>
              {revenue_increase_calculated > 0.1 && (
                <CaretUpFilled css={styles.subtitleRevenue} />
              )}
              {revenue_increase_calculated < -0.1 && (
                <CaretDownFilled css={styles.subtitleRevenue} />
              )}
              <p css={styles.subtitleRevenue}>{revenueIncreasePercentage}%</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
