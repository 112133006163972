import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../components';
import { useRegionFormatting, useStoreActions, useStoreState } from '../hooks';
import { Paths } from '../routes/Paths';
import {
  Preview,
  Overview,
  ProgressCharts,
  UnitMix,
  Comparison,
  Export,
} from '../components/project';
import { BackLink } from '../components/BackLink';
import { Project } from '../api/mockData/projectData';
import { getProject } from '../api';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PDFExport } from '../components/project/Export';
import { downloadPDF } from './market/pdfDownload';
import { slugifyString } from '../utils/utils';

export const ProjectDetailPage: FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [data, setData] = useState<Project | null>(null);
  const { projectId } = useParams();
  const pdfPage1Ref = useRef(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(true);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  useEffect(() => {
    const fetchData = async (): Promise<Project | null> => {
      const convertedProjectId = Number(projectId);
    if (!Number.isNaN(convertedProjectId)) {
      const fetchedData = await getProject(convertedProjectId, pricePerSmCalculation);
      setData(fetchedData.data as Project);
    }
    return null;
  };
  fetchData();
  }, [projectId, pricePerSmCalculation]);
  const { calculateVatPrice, getMinMaxProjectPrice } = useRegionFormatting();
  const { profile } = useStoreState((state) => state.user);
  useEffect(() => {
    if (!data) setProject(null);
    const avgFloorArea = data?.price_list.reduce(
      (acc, cur) =>
        acc + cur.floor_area,
      0,
    ) ?? 0;
    const avgExteriorArea = data?.price_list.reduce(
      (acc, cur) =>
        acc + Number(cur.exterior_area),
      0,
    ) ?? 0;
    const { minPrice, maxPrice } = getMinMaxProjectPrice(data);
    const projectToLoad = !data
      ? null
      : {
        ...data,
        avg_price: calculateVatPrice(
          data.avg_price,
          avgFloorArea,
          avgExteriorArea,
        ),
        avg_price_per_sm: calculateVatPrice(
          data.avg_price_per_sm,
          avgFloorArea,
          avgExteriorArea,
        ),
        price_list: data.price_list?.map((obj) => ({
          ...obj,
          price: calculateVatPrice(
            obj.price,
            obj.floor_area,
            Number(obj.exterior_area),
          ),
          price_per_sm: calculateVatPrice(
            obj.price_per_sm,
            obj.floor_area,
            Number(obj.exterior_area),
          ),
        })),
        min_price_per_sm: minPrice,
        max_price_per_sm: maxPrice,
        discounts: data?.discounts?.map(
          (d) => ({ date_start: new Date(d.date_start).getTime(), discount_text: d.discount_text }),
          ).sort((a, b) => b.date_start - a.date_start)
          .map((d) => ({ ...d, date_start: d.date_start.toString() })) ?? [],
        parking_indoor_price: calculateVatPrice(data.parking_indoor_price, 1),
        parking_outdoor_price: calculateVatPrice(data.parking_outdoor_price, 1),
        cellar_price: calculateVatPrice(data.cellar_price, 119),
      };
    setProject(projectToLoad);
  }, [data, calculateVatPrice, profile, projectId]);
  const showProject = !!data;
  const { t } = useTranslation();
  const fetchProject = useStoreActions(
    (actions) => actions.project.fetchProject,
  );
  useEffect(() => {
    const convertedProjectId = Number(projectId);
    if (!Number.isNaN(convertedProjectId)) {
      fetchProject(convertedProjectId);
    }
  }, [fetchProject, projectId]);

  useEffect(() => {
    setTimeout(() => setIsLoadingDownload(false), 5000);
  });

  const fileName = `bm-project-${slugifyString(data?.project_name || '')}`;

  return (
    <Page
      pageClassname='project-detail'
      title={
        <>
          <BackLink to={Paths.MARKET_PROJECTS}>
            <ArrowLeftOutlined /> {t('market.title')}
          </BackLink>
          {project && ` / ${project.project_name}`}
        </>
      }
      filters={
        <Row justify='end'>
          <PDFExport onDownloadPDF={() => downloadPDF(fileName, pdfPage1Ref)} isLoadingDownload={isLoadingDownload} marginRight />
          <Export />
        </Row>
      }
    >
      {project && (
        <Row gutter={[16, 16]} ref={pdfPage1Ref}>
          <Col xs={24} xl={12} xxl={8}>
            {showProject && <Preview data={project} />}
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            {showProject && <Overview data={project} />}
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <UnitMix />
          </Col>
          <Col xs={24} xl={12}>
            <ProgressCharts />
          </Col>
          <Col xs={24} xl={12}>
            <Comparison />
          </Col>
        </Row>
      )}
    </Page>
  );
};
