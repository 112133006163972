import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowData, InfoPopover } from '../../../../../InfoPopover';
import { useRegionFormatting } from '../../../../../../hooks';

interface Props {
  parking_indoor_price?: number | null;
  parking_outdoor_price?: number | null;
}

export const Parking: FC<Props> = ({
  parking_indoor_price,
  parking_outdoor_price,
}) => {
  const { formatCurrency } = useRegionFormatting();
  const { t } = useTranslation();

  const rows: RowData[] = useMemo(
    () => [
      {
        label: t('project.overview.parking_indoor', 'Parking indoor'),
        value: parking_indoor_price
          ? formatCurrency(parking_indoor_price)
          : '-',
      },
      {
        label: t('project.overview.parking_outdoor', 'Parking outdoor'),
        value: parking_outdoor_price
          ? formatCurrency(parking_outdoor_price)
          : '-',
      },
    ],
    [formatCurrency, parking_indoor_price, parking_outdoor_price, t],
  );

  if (!parking_indoor_price && !parking_outdoor_price) {
    return <>-</>;
  }

  return (
    <InfoPopover
      label={`${
        parking_indoor_price ? formatCurrency(parking_indoor_price) : ''
      }${parking_indoor_price && parking_outdoor_price ? ' - ' : ''}${
        parking_outdoor_price ? formatCurrency(parking_outdoor_price) : ''
      }`}
      popoverProps={{ rows }}
    />
  );
};
