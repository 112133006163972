import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import PriceUnitsEU from '../../../../../assets/templates/price_list_template_EU_units.xlsx';
import RentUnitsEU from '../../../../../assets/templates/rent_roll_template_EU_units.xlsx';
import PriceHousesEU from '../../../../../assets/templates/price_list_template_EU_houses.xlsx';
import RentHousesEU from '../../../../../assets/templates/rent_roll_template_EU_houses.xlsx';
import { useStoreState } from '../../../../../hooks';

export const DownloadTemplate: FC = () => {
  const { t } = useTranslation();

  const { projectType, buildingType } = useStoreState(
    (state) => state.onboarding,
  );

  const getTemplate = (): { href: string; fileName: string } => {
    if (projectType.rental) {
      const fileName = 'rent_roll_template';
      return buildingType.unit
        ? { href: RentUnitsEU, fileName }
        : { href: RentHousesEU, fileName };
    }
    if (projectType.sale) {
      const fileName = 'price_list_template';
      return buildingType.house
        ? { href: PriceHousesEU, fileName }
        : { href: PriceUnitsEU, fileName };
    }
    return { href: PriceUnitsEU, fileName: 'price_list_template' };
  };

  const { href, fileName } = getTemplate();

  return (
    <a
      className='hover:text-bmblue'
      href={href}
      download={fileName}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='flex gap-2 text-base'>
        <ArrowDownTrayIcon className='w-6 stroke-2' />
        <span className='font-bold underline'>
          {t(
            'onboarding.price_list.download_template',
            'Download our template',
          )}
        </span>
        {t('onboarding.price_list.see_format', 'to see the format.')}
      </div>
    </a>
  );
};
