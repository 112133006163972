import { InputNumber } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

type Props = {
  planId: number;
  month: string;
  planSpeed: number;
  isEditing: boolean;
  onEdit: (planId: number, planSpeed: number) => void;
}

export const PlanSpeedRow: FC<Props> = ({ planId, month, planSpeed, isEditing, onEdit }) => {
  const handleEdit = (value: number | null): void => {
    onEdit(planId, value || 0);
  };

  return (
    isEditing ? (
      <div className='flex items-center justify-center'>
        {dayjs(month).isBefore(dayjs(), 'month') ? (
          <span>{Math.round(planSpeed)}</span>
        ) : (
          <InputNumber placeholder='0' controls={false} value={planSpeed} type='number' onChange={(value) => handleEdit(value && value < 0 ? 0 : value ?? 0)} className='max-w-[5rem] px-2' />
        )}
      </div>
    ) : (
      <span>{planSpeed}</span>
    )
  );
};
