/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PriceLayoutItem } from './PriceLayoutItem';
import {
    PriceLayoutItemFromJSON,
    PriceLayoutItemFromJSONTyped,
    PriceLayoutItemToJSON,
} from './PriceLayoutItem';

/**
 * 
 * @export
 * @interface DisappearedUnitsPriceHistoryLayoutResponseSchema
 */
export interface DisappearedUnitsPriceHistoryLayoutResponseSchema {
    /**
     * 
     * @type {string}
     * @memberof DisappearedUnitsPriceHistoryLayoutResponseSchema
     */
    createdDate: string;
    /**
     * 
     * @type {Array<PriceLayoutItem>}
     * @memberof DisappearedUnitsPriceHistoryLayoutResponseSchema
     */
    disappearedLayout: Array<PriceLayoutItem>;
}

/**
 * Check if a given object implements the DisappearedUnitsPriceHistoryLayoutResponseSchema interface.
 */
export function instanceOfDisappearedUnitsPriceHistoryLayoutResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "disappearedLayout" in value;

    return isInstance;
}

export function DisappearedUnitsPriceHistoryLayoutResponseSchemaFromJSON(json: any): DisappearedUnitsPriceHistoryLayoutResponseSchema {
    return DisappearedUnitsPriceHistoryLayoutResponseSchemaFromJSONTyped(json, false);
}

export function DisappearedUnitsPriceHistoryLayoutResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsPriceHistoryLayoutResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': json['created_date'],
        'disappearedLayout': ((json['disappeared_layout'] as Array<any>).map(PriceLayoutItemFromJSON)),
    };
}

export function DisappearedUnitsPriceHistoryLayoutResponseSchemaToJSON(value?: DisappearedUnitsPriceHistoryLayoutResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_date': value.createdDate,
        'disappeared_layout': ((value.disappearedLayout as Array<any>).map(PriceLayoutItemToJSON)),
    };
}

