/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { TableProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  getSumFromNotNullValues,
  getAvg,
  getMinMax,
} from '../../../../../../utils/utils';
import { useFlatLayout, useRegionFormatting } from '../../../../../../hooks';
import { styles } from '../../styles';
import { ProjectDetailWithHistoryTableData, ProjectDetailWithHistoryTableProps } from '../types';
import { HistoryPopover } from '../../HistoryPopover';

interface Summary {
  price_per_sm: number;
  interior_sm: number;
  price: number;
}

export const ProjectDetailTable: FC<ProjectDetailWithHistoryTableProps> = ({
  ...rest
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatAreaUnits } = useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const columns: TableProps<ProjectDetailWithHistoryTableData>['columns'] = useMemo(
    () => [
      {
        title: t('project.table.beds', 'Beds'),
        dataIndex: 'layout',
        render: (value) => getLayoutAbbr(value),
        align: 'center',
      },
      {
        title: t('project.table.units', 'Units'),
        dataIndex: 'available',
        render: (value) => <HistoryPopover values={value} />,
        align: 'center',
      },
      {
        title: t('project.table.baths', 'Baths'),
        dataIndex: 'baths',
        render: (value) => getMinMax(value),
        align: 'center',
      },
      {
        title: t('project.table.garages', 'Garages'),
        dataIndex: 'garages',
        render: (value) => getMinMax(value),
        align: 'center',
      },
      {
        title: t('project.table.living_area', 'Living Area'),
        dataIndex: 'interior_sm',
        render: (value) => formatAreaUnits(value.current),
        align: 'center',
      },
      {
        title: t('project.table.price', 'Price'),
        dataIndex: 'price',
        render: (value) => (
          <HistoryPopover values={{
            old: formatCurrency(value.old),
            current: formatCurrency(value.current),
             change: value.change !== null ? String(value.change.toFixed(2)).concat('%') : 'N/A',
          }}
          />
        ),
        align: 'center',
      },
    ],
    [formatAreaUnits, formatCurrency, getLayoutAbbr, t],
  );
  return (
    <Table<ProjectDetailWithHistoryTableData>
      css={styles.table}
      rowKey='layout'
      columns={columns}
      pagination={false}
      size='small'
      // summary={(pageData) => (
      //   <Table.Summary.Row>
      //     <Table.Summary.Cell index={0} />
      //     <Table.Summary.Cell index={1} align='center'>
      //       <b>[{getSumFromNotNullValues(pageData.map((pd) => pd.available))}]</b>
      //     </Table.Summary.Cell>
      //     <Table.Summary.Cell index={2} align='center'>
      //       <b>{getMinMax(pageData.flatMap((pd) => pd.baths))}</b>
      //     </Table.Summary.Cell>
      //     <Table.Summary.Cell index={3} align='center'>
      //       <b>{getMinMax(pageData.flatMap((pd) => pd.garages))}</b>
      //     </Table.Summary.Cell>
      //     <Table.Summary.Cell index={4} align='center'>
      //       <b>{formatAreaUnits(summary.interior_sm)}</b>
      //     </Table.Summary.Cell>
      //     <Table.Summary.Cell index={5} align='center'>
      //       <b>{formatCurrency(summary.price)}</b>
      //     </Table.Summary.Cell>
      //   </Table.Summary.Row>
      // )}
      {...rest}
    />
  );
};
