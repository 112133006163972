export const formatBreaks = (str: string, splitChar: string, breakLine: boolean, boldTextIndex?: number, fontWeight?: number): JSX.Element[] =>
    str.split(splitChar).map((s, index) =>
      <>
        {
          index === boldTextIndex
            ? <div style={{ display: 'flex', fontWeight: fontWeight || 600 }}>{s}</div>
            : <div style={{ display: 'flex' }}>{s}</div>
        }
        {breakLine && <br />}
      </>,
    );
