import { useMemo, useState, useCallback, useRef, FC, useEffect } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  HomeIcon,
  ChartBarIcon,
  TagIcon,
  PresentationChartBarIcon,
  BuildingOfficeIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ArrowTrendingUpIcon,
  LightBulbIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Paths } from '../routes/Paths';
import { useBreakpoints, useRegionFormatting, useStoreState } from '../hooks';
import { SettingsModal } from './SettingsModal';
import { HelpModal } from './HelpModal';
import { MenuItem, SidebarMenu } from './SidebarMenu';
import { ProjectsSubmenu } from './ProjectsSubmenu';
import { ButtonWithTooltip } from './ButtonWithTooltip';

type Props = {
  collapsed: boolean;
  onCollapse: (collapse: boolean) => void;
}

export const SideBar: FC<Props> = ({ collapsed, onCollapse }) => {
  const [sidebarWidth, setSidebarWidth] = useState(() => {
    const cachedWidth = localStorage.getItem('sidebarWidth');
    return cachedWidth ? parseInt(cachedWidth, 10) : 200;
  });
  const isResized = useRef(false);

  const profile = useStoreState((state) => state.user.profile);
  const localization = profile?.localization;
  const marketDashboards = useStoreState(
    (state) => state.filters.overviewData?.market_dashboards,
  );
  const isMarketEnabled = useStoreState(
    (state) => state.user.profile?.market_enabled,
  );
  const hasAnyMarketDashboard = marketDashboards && marketDashboards.length > 0;
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const isDailyNewsEnabled = useStoreState(
    (state) => state.user.profile?.daily_news_enabled,
  );
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const updateData = overviewData?.updates.length;
  const hasNewUpdates =
    overviewData?.updates.length &&
    overviewData?.updates.some((update) => !update.user_seen);
  const updatesCount = overviewData?.updates.filter(
    (update) => !update.user_seen,
  ).length;
  const { isUsRegion } = useRegionFormatting();
  // const profile = useStoreState((state) => state.user.profile);
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  // const language = profile?.language;
  const handleClose = (type: 'settings' | 'help'): void => {
    if (type === 'settings') {
      setModalOpen(false);
    } else {
      setHelpModalOpen(false);
    }
  };

  const location = useLocation();
  const isItemActive = useCallback(
    (item: MenuItem) => {
      if (item.type === 'link') {
        return location.pathname === item.href;
      }
      return false;
    },
    [location],
  );

  const handleOnResizeBarMouseDown = useCallback(() => {
    isResized.current = true;
    const mouseMoveListener = (e: MouseEvent): void => {
      if (!isResized.current) {
        return;
      }
      const newWidth = Math.max(170, Math.min(e.clientX, 400));
      localStorage.setItem('sidebarWidth', newWidth.toString());
      setSidebarWidth(newWidth);
    };

    window.addEventListener('mousemove', mouseMoveListener);
    window.addEventListener('mouseup', () => {
      isResized.current = false;
      window.removeEventListener('mousemove', mouseMoveListener);
    });
  }, [setSidebarWidth, isResized]);

  useEffect(() => {
    if (breakpoints.xl) {
      onCollapse(false);
    } else {
      onCollapse(true);
    }
  }, [breakpoints.xl, onCollapse]);

  const items = useMemo(() => {
    const defaultItems: MenuItem[] = [
      {
        type: 'link',
        key: Paths.DAILYNEWS,
        label: t('daily_news.title'),
        href: Paths.DAILYNEWS,
        icon: <HomeIcon />,
        class: 'font-semibold',
      },
      {
        type: 'link',
        key: Paths.DASHBOARD,
        label: t('dashboard.title'),
        href: Paths.DASHBOARD,
        icon: <ChartBarIcon />,
        inactive: !isDashboardEnabled,
      },
      {
        type: 'link',
        key: Paths.MARKET_ANALYSIS,
        label: t('market_analysis.title'),
        href: Paths.MARKET_ANALYSIS,
        icon: <LightBulbIcon />,
        class: 'font-semibold',
      },
      {
        type: 'link',
        key: Paths.PRICE_LIST,
        label: t('price_list.title'),
        href: Paths.PRICE_LIST,
        icon: <TagIcon />,
        inactive: !isDashboardEnabled,
      },
      {
        type: 'link',
        key: Paths.PRICE_UPDATE,
        label: (
          <div className='flex items-center'>
            {t('price_update.title', 'Price Update')}{' '}
            {hasNewUpdates ? (
              <div
                className='ml-4 flex w-8 h-8 bg-bmblue rounded-xl items-center justify-center'
                style={{
                  backgroundColor: '#9EB9DA',
                }}
              >
                {updatesCount}
              </div>
            ) : (
              ''
            )}
          </div>
        ),
        href: Paths.PRICE_UPDATE,
        inactive: !isDashboardEnabled,
        icon: <ArrowTrendingUpIcon />,
      },
      profile?.reports_hierarchy === 'page' || !isMarketEnabled
        ? {
            type: 'link',
            key: Paths.MARKET_REPORTS,
            label: t('market.submenu.reports', 'Reports'),
            href: Paths.MARKET_REPORTS,
            disabled: !hasAnyMarketDashboard && isMarketEnabled,
            icon: <PresentationChartBarIcon />,
            inactive: !isMarketEnabled,
          }
        : {
            type: 'submenu',
            key: Paths.MARKET_REPORTS,
            label: t('market.submenu.reports', 'Reports'),
            children: <ProjectsSubmenu />,
            icon: <PresentationChartBarIcon />,
            defaultOpened: () =>
              location.pathname.includes(Paths.MARKET_REPORTS),
          },
      {
        type: 'link',
        key: Paths.MARKET_PROJECTS,
        label: isUsRegion
          ? t('market.submenu.communities', 'Communities')
          : t('market.submenu.projects', 'Projects'),
        href: Paths.MARKET_PROJECTS,
        inactive: !isMarketEnabled,
        icon: <BuildingOfficeIcon />,
      },
      {
        type: 'divider',
        key: 'divider',
      },
      {
        type: 'link',
        key: 'settings',
        label: t('sidebar.settings'),
        icon: <Cog8ToothIcon />,
        href: Paths.SETTINGS,
        class: 'text-gray-500',
      },
      {
        type: 'link',
        key: 'tutorials',
        label: t('sidebar.tutorials'),
        href: 'https://builtmind.notion.site/BuiltMind-Wiki-a46b1a7f5d3545c38bc831f9412891c5',
        icon: <QuestionMarkCircleIcon />,
        target: '_blank',
        class: 'text-gray-500 font-normal',
      },
      {
        type: 'button',
        key: 'help',
        label: t('sidebar.help'),
        icon: <ChatBubbleOvalLeftEllipsisIcon />,
        onClick: () => setHelpModalOpen(true),
        class: 'text-gray-500',
      },
    ];
    let menu = defaultItems;
    menu = isDailyNewsEnabled
      ? menu
      : menu.filter((item) => item.key !== Paths.DAILYNEWS);
    menu =
      localization === 'cs' || localization === 'sk' || localization === 'EU'
        ? menu
        : menu.filter((item) => item.key !== Paths.MARKET_ANALYSIS);
    menu =
      !updateData && isDashboardEnabled
        ? menu.filter((item) => item.key !== Paths.PRICE_UPDATE)
        : menu;
    if (localization === 'US') {
      const keysToHide = [
        Paths.DAILYNEWS,
        Paths.MARKET_ANALYSIS,
        Paths.MARKET_REPORTS,
        Paths.MARKET_PROJECTS,
      ];
      menu = menu.filter((item) => !keysToHide.includes(item.key));
    }
    return menu;
  }, [
    t,
    location.pathname,
    profile?.reports_hierarchy,
    localization,
    hasNewUpdates,
    updatesCount,
    hasAnyMarketDashboard,
    isMarketEnabled,
    isUsRegion,
    isDashboardEnabled,
    isDailyNewsEnabled,
    updateData,
  ]);
  return (
    <Layout.Sider
      breakpoint='xl'
      trigger={null}
      collapsible
      className='side-bar'
      width={sidebarWidth}
      collapsed={collapsed}
      collapsedWidth={0}
    >
      <div className='relative flex h-full'>
        <ButtonWithTooltip
          className='absolute top-2 right-2'
          onClick={() => onCollapse(true)}
          buttonContent={
            <ChevronDoubleLeftIcon className='h-8 w-8 stroke-2 text-[#828282]' />
          }
          title={t('menu.close', 'Close menu')}
        />
        <div className='w-full flex flex-col h-full'>
          <div className='logo'>
            {breakpoints.xl ? (
              <img
                className='m-auto'
                width='132'
                height='40'
                src='/assets/logo.png'
                alt='logo'
              />
            ) : (
              <>BM</>
            )}
          </div>
          <div className='flex flex-col px-4 mt-2 overflow-hidden'>
            <SidebarMenu
              items={items}
              itemSize='medium'
              isItemActive={isItemActive}
            />
          </div>
          <SettingsModal
            open={modalOpen}
            onClose={() => handleClose('settings')}
          />
          <HelpModal open={helpModalOpen} onClose={() => handleClose('help')} />
        </div>
        <div className='w-px hover:border-r z-1 pointer-events-auto absolute right-0 top-0 bottom-0'>
          <div
            className='h-full w-4 -ml-2 cursor-col-resize'
            onMouseDown={handleOnResizeBarMouseDown}
          />
        </div>
      </div>
    </Layout.Sider>
  );
};
