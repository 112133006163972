import { FC } from 'react';
import { Typography } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const { Text } = Typography;

type Props = {
  number: number;
  label?: string;
};

export const Chevron: FC<Props> = ({ number, label = undefined }) => (
  <>
    {label && <Text style={{ marginRight: '8px', color: '#828282' }}>{label}</Text>}
    <div className={`chevron ${number > 0 ? 'dark' : 'light'}`}>
      {number > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
      {`${number.toFixed(1)}%`}
    </div>
  </>
);
