/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LayoutDataclass } from './LayoutDataclass';
import {
    LayoutDataclassFromJSON,
    LayoutDataclassFromJSONTyped,
    LayoutDataclassToJSON,
} from './LayoutDataclass';
import type { LayoutDataclassRoom } from './LayoutDataclassRoom';
import {
    LayoutDataclassRoomFromJSON,
    LayoutDataclassRoomFromJSONTyped,
    LayoutDataclassRoomToJSON,
} from './LayoutDataclassRoom';

/**
 * 
 * @export
 * @interface DisappearedUnitsLayoutResponseSchemaWithTotals
 */
export interface DisappearedUnitsLayoutResponseSchemaWithTotals {
    /**
     * 
     * @type {Array<LayoutDataclass>}
     * @memberof DisappearedUnitsLayoutResponseSchemaWithTotals
     */
    disappearedUnitsLayout: Array<LayoutDataclass>;
    /**
     * 
     * @type {LayoutDataclassRoom}
     * @memberof DisappearedUnitsLayoutResponseSchemaWithTotals
     */
    totals: LayoutDataclassRoom;
}

/**
 * Check if a given object implements the DisappearedUnitsLayoutResponseSchemaWithTotals interface.
 */
export function instanceOfDisappearedUnitsLayoutResponseSchemaWithTotals(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disappearedUnitsLayout" in value;
    isInstance = isInstance && "totals" in value;

    return isInstance;
}

export function DisappearedUnitsLayoutResponseSchemaWithTotalsFromJSON(json: any): DisappearedUnitsLayoutResponseSchemaWithTotals {
    return DisappearedUnitsLayoutResponseSchemaWithTotalsFromJSONTyped(json, false);
}

export function DisappearedUnitsLayoutResponseSchemaWithTotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisappearedUnitsLayoutResponseSchemaWithTotals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disappearedUnitsLayout': ((json['disappeared_units_layout'] as Array<any>).map(LayoutDataclassFromJSON)),
        'totals': LayoutDataclassRoomFromJSON(json['totals']),
    };
}

export function DisappearedUnitsLayoutResponseSchemaWithTotalsToJSON(value?: DisappearedUnitsLayoutResponseSchemaWithTotals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disappeared_units_layout': ((value.disappearedUnitsLayout as Array<any>).map(LayoutDataclassToJSON)),
        'totals': LayoutDataclassRoomToJSON(value.totals),
    };
}

