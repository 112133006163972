import { FC, useEffect } from 'react';
import { useStoreActions, useStoreState } from '../hooks';

export const DashboardFlagHandler: FC = () => {
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const setFiltersLoading = useStoreActions(
    (state) => state.filters.setLoading,
  );
  const setGlobalIsLoaded = useStoreActions(
    (state) => state.global.setIsLoaded,
  );
  useEffect(() => {
    if (!isDashboardEnabled) {
      setFiltersLoading(false);
      setGlobalIsLoaded(true);
    }
  }, [isDashboardEnabled, setFiltersLoading, setGlobalIsLoaded]);

  return null;
};
