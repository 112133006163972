import { FC, useMemo, useState } from 'react';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import PlanIcon from '../../icons/planIcon.svg';
import { Card } from '../../../Card';
import { PlanData } from '../../../../api/mockData/sales';
import { getPage, paginatePlan } from './paginatePlan';

type PlanProps = {
  plan: PlanData[];
  onMaximize: () => void;
}

export const Plan: FC<PlanProps> = ({ plan, onMaximize }) => {
  const { t } = useTranslation();
  const pagesResults = useMemo(() => paginatePlan(plan, 4, 'monthly'), [plan]);
  const pages = Object.keys(pagesResults);
  const initialPage = getPage(pagesResults);
  const [page, setPage] = useState(initialPage);

  if (page > pages.length) {
    return <Spin />;
  }

  const handlePaginate = (direction: 'next' | 'prev'): void => {
    if (direction === 'next') {
      setPage(page < pages.length ? page + 1 : page);
    } else {
      setPage(page > 1 ? page - 1 : page);
    }
  };

  const emptyDivsCount = Math.max(0, 4 - (pagesResults[page] ? pagesResults[page].length : 0));

  return (
  <Card bordered={false} className='plan'>
    <div className='flex flex-col space-y-4 flex-1 w-full h-full select-none'>
        <div className='flex justify-between items-center px-[16px] pt-[24px]'>
          <div className='flex space-x-5 items-center font-bold capitalize'>
          <div className='flex items-center h-14 w-14 bg-[#9EB9DA] rounded-full p-2'>
            <img src={PlanIcon} alt='Sales Plan' />
          </div>
          <div>{t('sales.plan.title', 'Sales Plan')}</div>
          </div>
          <ArrowsPointingOutIcon className='w-9 h-9 stroke-2 cursor-pointer' onClick={onMaximize} />
        </div>
        <div className='grid grid-rows-3 xl:text-lg 2xl:text-xl'>
          <div className='grid-cols-7 grid space-x-3 font-bold px-[16px] pb-2 text-center'>
            <span className='col-span-2 text-end cursor-pointer' onClick={() => handlePaginate('prev')}>{page > 1 && '<'}</span>
              {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                <span>{dayjs(planItem.month).format('MM/YY')}</span>
              ))}
              {Array.from({ length: emptyDivsCount }).map(() => (
                <div />
              ))}
            <span className='text-start cursor-pointer' onClick={() => handlePaginate('next')}>{page < pages.length && '>'}</span>
          </div>
            <div className='grid grid-cols-7 px-[16px] border-t-4 py-2 border-[#f8f8f8] text-center'>
              <span className=' col-span-2 font-bold text-start'>{t('sales.plan.subtitle.plan', 'Plan')}</span>
                {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                  <span>{planItem.plan_speed.toFixed(1)}</span>
                ))}
            </div>
            <div className='grid grid-cols-7 px-[16px] border-t py-2 border-[#f8f8f8] text-center'>
              <span className='col-span-2 font-bold text-start'>{t('sales.plan.subtitle.normal_speed', 'Normal Speed')}</span>
                {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                  <span>{planItem.normal_speed.toFixed(1)}</span>
                ))}
            </div>
        </div>
    </div>
  </Card>
  );
};
