import { FC } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { Mode } from '../../store/types';

export const Actions: FC = () => {
  const mode = useStoreState((state) => state.market.mode);

  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const primaryParameters = useStoreState((state) => state.market.primaryParameters);
  const secondaryParameters = useStoreState((state) => state.market.secondaryParameters);
  const setActiveStep = useStoreActions((actions) => actions.market.setAddReportStep);
  const polygons = useStoreState((state) => state.market.polygons);
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const createPrimaryDashboard = useStoreActions(
    (actions) => actions.market.createPrimaryDashboard,
  );
  const createSecondaryDashboard = useStoreActions(
    (actions) => actions.market.createSecondaryDashboard,
  );
  const updateDashboard = useStoreActions(
    (actions) => actions.market.updateDashboard,
  );
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setSecondaryParameters = useStoreActions(
    (actions) => actions.market.setSecondaryParameters,
  );
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const resetTableData = useStoreActions(
    (actions) => actions.market.resetTableData,
  );
  const fetchDashboardSettings = useStoreActions(
    (actions) => actions.market.fetchDashboardSettings,
  );
  const setPolygons = useStoreActions((actions) => actions.market.setPolygons);

  const { t } = useTranslation();
  const handleReset = (): void => {
    setPolygons({});
  };

  const handleSave = (): void => {
    let error = false;
    if (primaryParameters) {
      if (secondaryParameters && !(polygons && Object.values(polygons).length > 0)) {
        message.error(t('market.validations.projects'));
        error = true;
      }
      if (projects.some((p) => !!p.include)) {
        if (mode === Mode.CREATE) {
          createPrimaryDashboard();
        }
        if (mode === Mode.EDIT) {
          updateDashboard();
        }
      } else {
        error = true;
        message.error(t('market.validations.projects'));
      }
    }

    if (paramsPM && mode === Mode.EDIT) {
      if (projects.some((p) => !!p.include)) {
        updateDashboard();
      } else {
        error = true;
        message.error(t('market.validations.projects'));
      }
    }
    if (secondaryParameters && !error) {
      if (polygons && Object.values(polygons).length > 0) {
        if (mode === Mode.CREATE) {
          createSecondaryDashboard();
        }
        if (mode === Mode.EDIT) {
          updateDashboard();
        }
      } else {
        error = true;
        message.error(t('market.validations.projects'));
      }
    }

    if (!error) {
      setActiveStep(null);
      setMode(Mode.READ);
      setPrimaryParameters(null);
      setSecondaryParameters(null);
    }
  };
  const handleCancel = (): void => {
    setPrimaryParameters(null);
    setSecondaryParameters(null);
    setActiveStep(null);
    if (mode === Mode.CREATE) {
      resetTableData();
      if (paramsPM) {
        fetchDashboardSettings(paramsPM);
      }
    }
    if (mode === Mode.EDIT) {
      resetTableData();
      if (paramsPM) {
        fetchDashboardSettings(paramsPM);
      }
    }
    setMode(Mode.READ);
  };
  return (
    <div className='actions-btns'>
      <Button shape='round' onClick={handleCancel}>
        {t('common.cancel')}
      </Button>
      {
        mode !== Mode.READ && Object.values(polygons).length > 0 && (
          <Button
            className='reset-btn'
            shape='round'
            onClick={handleReset}
          >
            {t('common.reset', 'Reset')}
          </Button>
        )
      }
      <Button
        className='save-btn'
        shape='round'
        type='primary'
        onClick={handleSave}
      >
        {t('common.save')}
      </Button>
    </div>
  );
};
