import { z } from 'zod';
import { less2000sm, less500sm, less50sm } from './validators';

const layout = { layout: z.string().min(1, 'Layout is required') };
const bedrooms = { bedrooms: z.string().min(1, 'Bedrooms is required') };
const floor = {
  floor: z
    .number()
    .refine(less50sm, { message: 'Floor must be between 0 and 50' }),
};
const floors = {
  floors: z
    .number()
    .refine(less50sm, { message: 'Floors must be between 0 and 50' }),
};
const stories = {
  stories: z
    .number()
    .refine(less50sm, { message: 'Stories must be between 0 and 50' })
    .optional(),
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getSchema = (isUS: boolean, isHouse: boolean) => {
  const buildingType = isUS ? stories : floors;

  return z.object({
    // Required fields
    id: z.string().min(1, 'ID is required'),
    ...(isUS ? bedrooms : layout),
    floor_area: z
      .number()
      .refine(isUS ? less2000sm : less500sm, {
        message: `Floor area must be between 0 and ${isUS ? 2000 : 500}`,
      }),
    ...(isHouse ? buildingType : floor),
    // Recommended fields
    availability: z.string().optional(),
    price: z.number().optional(),
    rent: z.number().optional(),
    number_of_bathrooms: z.number().optional(),
    balcony_area: z.number().optional(),
    garages: z.number().optional(),
    terrace_area: z.number().optional(),
    garden_area: z.number().optional(),
    lot_size: z.number().optional(),
    room_areas: z.string().optional(),
    custom_fields: z.string().optional(),
  });
};
