import { useEffect, useState } from 'react';

export const useReportActions = (
  itemRef: React.RefObject<HTMLDivElement>,
): { isBottom: boolean } => {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const sidebarElement = document.getElementById('sidebar-reports');
    const itemElement = itemRef.current;

    if (sidebarElement && itemElement) {
      const sidebarRect = sidebarElement.getBoundingClientRect();
      const itemRect = itemElement.getBoundingClientRect();

      const isItemBottom =
        itemRect.top + itemRect.height / 2 >
        sidebarRect.top + sidebarRect.height / 2;
      setIsBottom(isItemBottom);
    }
  }, []);

  return { isBottom };
};
