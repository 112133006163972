import { FC, Suspense } from 'react';
import './styles/styles.css';
import { StoreProvider } from 'easy-peasy';
import { ConfigProvider } from 'antd';
import { store } from './store';
import {
  CenteredSpinner,
  DashboardFlagHandler,
  LanguageHandler,
  RehydrationLoading,
} from './components';
import { OverviewDataProvider } from './api/OverviewDataProvider';
import { Routes } from './routes/Routes';
import { BLUE_4, BLUE_5 } from './styles/constants';
import { PriceUpdateNotifications } from './components/PriceUpdateNotifications/PriceUpdateNotifications';

export const App: FC = () => (
  <Suspense fallback={<CenteredSpinner />}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: BLUE_4,
          colorText: BLUE_5,
          fontFamily: 'Poppins',
          colorLink: BLUE_5,
        },
      }}
    >
      <StoreProvider store={store}>
        <DashboardFlagHandler />
        <RehydrationLoading />
        <OverviewDataProvider />
        <LanguageHandler>
          <Routes />
        </LanguageHandler>
        <PriceUpdateNotifications />
      </StoreProvider>
    </ConfigProvider>
  </Suspense>);
