import { FC, useMemo, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Report } from './Report';
import { AddReport } from './AddReport';
import { useStoreActions, useStoreState } from '../../../hooks';
import { Mode } from '../../../store/types';
import { MarketTypeEnum } from '../../../api/enums';
import { MarketDashboard } from '../../../api/mockData/overviewData';
import { SecondaryReport } from '../../../store/secondary';
import { fetchOverviewData } from '../../../api';

interface ReportsProps {
  showActions?: boolean;
  showAddReport?: boolean;
  onEditClick?: () => void;
}

export interface MergedReport {
  name: string;
  primary: MarketDashboard | null;
  secondary: SecondaryReport | null;
  rental: SecondaryReport | null;
}

export const Reports: FC<ReportsProps> = ({
  showAddReport,
  showActions,
  onEditClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const primaryReports = useStoreState(
    (state) => state.filters.overviewData?.market_dashboards,
  );
  const SMReports = useStoreState((state) => state.secondaryMarket.reports);
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const isEdit = useStoreState((state) => state.market.mode === Mode.EDIT);
  const resetPolygons = useStoreActions((actions) => actions.market.resetPolygons);
  const developerId = useStoreState((state) => state.user.profile?.developer_id);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const [fetchDataTrigger, setFetchDataTrigger] = useState(
    Boolean(overviewData?.market_dashboards.find((d) => d.dates.length === 0)),
  );
  const fetchDataIntervalId = useRef<NodeJS.Timer>();
  const fetchOverview = useStoreActions((actions) => actions.filters.fetchOverviewData);
  const mode = useStoreState((state) => state.market.mode);
  const reportsHierarchy = useStoreState(
    (state) => state.user.profile?.reports_hierarchy,
  );

  const setFetchDataInterval = (interval: number): void => {
    // Clear old interval
    if (fetchDataIntervalId.current) {
      clearInterval(fetchDataIntervalId.current);
      fetchDataIntervalId.current = undefined;
    }
    // Set new interval
    if (interval > 0) {
      fetchDataIntervalId.current = setInterval(() => {
        setFetchDataTrigger(true);
      }, 10000);
    } else {
      setFetchDataTrigger(false);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (developerId && mode === Mode.READ && fetchDataTrigger) {
      fetchOverviewData(developerId)
        .then((res) => {
          const { data } = res;
          const lengthData = data.market_dashboards.find((d) => d.dates.length === 0);

          if (lengthData === undefined || !lengthData) {
            setFetchDataInterval(0);
            fetchOverview();
          } else {
            setFetchDataInterval(1);
          }
        });

      // Clean up for unmount to prevent memory leak
      return () => clearInterval(fetchDataIntervalId.current);
    }
  }, [developerId, fetchDataTrigger, fetchOverview, mode]);

  const handleAddClick = (): void => {
    resetPolygons();
    if (!isEdit) {
      setMode(Mode.START);
    }
    if (location.pathname !== '/market/projects') {
      navigate('/market/projects');
    }
  };

  const secondaryReports = useMemo(() =>
    SMReports?.filter((report) => report.reportType === MarketTypeEnum.SECONDARY),
  [SMReports]);

  const rentalReports = useMemo(() =>
    SMReports?.filter((report) => report.reportType === MarketTypeEnum.RENTAL),
  [SMReports]);

  const mergedReports = useMemo(() => [
    ...(primaryReports || []),
    ...(secondaryReports || []),
    ...(rentalReports || []),
  ].reduce(
    (acc, report) => {
      const mergedReport: MergedReport = {
        name: report.name,
        primary: null,
        secondary: null,
        rental: null,
      };
      if (acc.find((v) => v.name === report.name)) {
        return acc;
      }
      const pReport = primaryReports?.find((v) => v.name === report.name);
      const sReport = secondaryReports?.find((v) => v.name === report.name);
      const rReport = rentalReports?.find((v) => v.name === report.name);
      if (pReport) {
        mergedReport.primary = pReport;
      }
      if (sReport) {
        mergedReport.secondary = sReport;
      }
      if (rReport) {
        mergedReport.rental = rReport;
      }
      return [...acc, mergedReport];
    }, [] as MergedReport[]) ?? [], [primaryReports, secondaryReports, rentalReports]);
  return (
    <Row className='reports' gutter={16} style={{ display: showAddReport && reportsHierarchy !== 'page' ? 'none' : 'inherit' }}>
      {mergedReports?.map((report) => (
        <Col key={report.name}>
          <Report
            key={report.name}
            value={report}
            showActions={showActions}
            onEditClick={onEditClick}
          />
        </Col>
      ))}
      {showAddReport && (
        <Col>
          <AddReport onClick={handleAddClick} />
        </Col>
      )}
    </Row>
  );
};
