/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { DocumentDuplicateIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { styles } from './TopBar/styles';

type Props = {
  open: boolean;
  onClose: () => void;
}

const copyToClipboard = (): void => {
  navigator.clipboard.writeText('info@builtmind.com');
};

export const HelpModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onCancel={onClose} footer={null} width='fit-content'>
      <div>
        <div css={styles.title}>{t('menu.email_us.title', 'Email us')}</div>
        <div css={styles.label} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          info@builtmind.com
          <Button
            onClick={() => copyToClipboard()}
            type='text'
            icon={
              <DocumentDuplicateIcon
                width={24}
                height={24}
              />
              }
          />
          <Button href='mailto:info@builtmind.com' type='text' icon={<EnvelopeIcon width={24} height={24} />} />
        </div>
      </div>
    </Modal>
  );
};
