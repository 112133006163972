/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchBlockSaleSpeedData } from '../../../../api';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { CompetitorSelect } from '../CompetitorSelect';
import { Data, SaleSpeedChart } from './SaleSpeedChart';
import { SaleSpeedData } from '../../../../api/types';
import { sortByString, getUniqueValues, getLastUpdateText, getReportExportFileName } from '../../../../utils/utils';
import { ChartCard } from '../../../ChartCard';
import { Availability } from '../../../../store/types';
import { CardPopover } from '../CardPopover';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

type CountsByType = {
  [key: string]: number;
};

export const SaleSpeed: FC = () => {
  const [data, setData] = useState<SaleSpeedData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showB2B, setShowB2B] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const filterAvailability = useFilter(Availability.SOLD);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const { t } = useTranslation();
  const { sortLayouts } = useRegionFormatting();
  const pngRef = useRef(null);

  const getSaleCount = (counts: CountsByType): number => {
    let count = 0;
    if (showPrivate) {
      count += counts.private ?? 0;
    }
    if (showB2B) {
      count += counts.b2b ?? 0;
    }
    count += counts.public ?? 0;
    return count;
  };

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockSaleSpeedData(
        paramsPM.dashboardId,
        paramsPM.date,
        competitors,
        pricePerSmCalculation,
      )
        .then((response) => {
          const receivedData = [...response.data].filter(
            (d) => typeof d.availability === 'string',
          );
          const layouts = getUniqueValues(receivedData.map((v) => v.layout));
          const dates = getUniqueValues(receivedData.map((v) => v.date));
          dates.forEach((date) => {
            layouts.forEach((layout) => {
              if (
                receivedData.findIndex(
                  (rd) => rd.date === date && rd.layout === layout,
                ) === -1
              ) {
                receivedData.push({
                  date,
                  layout,
                  project_name: 'current_dashboard',
                  series: 'current_dashboard',
                  count: 0,
                  counts_by_type: {},
                  availability: Availability.AVAILABLE,
                });
              }
            });
          });

          const transformedData = receivedData
            .map(({ layout, series, count, ...rest }) => ({
              layout: layout ? layout.toLowerCase() : null,
              series: !series || series === 'current_dashboard' ? 'current_dashboard' : series,
              count: rest.counts_by_type ? getSaleCount(rest.counts_by_type) : count,
              ...rest,
            }))
            .sort(
              (
                { date: dateA, layout: layoutA },
                { date: dateB, layout: layoutB },
              ) => {
                if (dateA === dateB) {
                  return sortLayouts(String(layoutA), String(layoutB));
                }
                return sortByString(dateA, dateB);
              },
            );
          setData(transformedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, sortLayouts, pricePerSmCalculation, showPrivate, showB2B]);

  const chartData: Data[] = useMemo(() => {
    switch (filterValue) {
      case FilterEnum.OVERALL:
        return data
          .map((value) => ({
            ...value,
            series:
              value.series === 'current_dashboard'
                ? value.availability
                : value.project_name,
            value: value.count,
          }))
          .reduce<Data[]>((prev, curr) => {
            const index = prev.findIndex(
              (p) =>
                p.date === curr.date &&
                p.series === curr.series &&
                p.availability === curr.availability,
            );
            if (index > -1) {
              const newArr: Data[] = [...prev];
              const newCnt = newArr[index].count ?? 0 + 1;
              const prevValue = newArr[index].value;
              if (prevValue !== null && curr.value !== null) {
                newArr[index].value =
                  (prevValue * (newArr[index].count ?? 0) + curr.value) /
                  newCnt;
              }
              newArr[index].count = newCnt;
              // Summing counts_by_type
              const updatedCountsByType = { ...newArr[index].counts_by_type };
              if (curr.counts_by_type) {
                for (const key in curr.counts_by_type) {
                  if (Object.prototype.hasOwnProperty.call(curr.counts_by_type, key)) {
                    if (updatedCountsByType[key]) {
                      updatedCountsByType[key] += curr.counts_by_type[key];
                    } else {
                      updatedCountsByType[key] = curr.counts_by_type[key];
                    }
                  }
                }
              }
              newArr[index].counts_by_type = updatedCountsByType;
              return newArr;
            }
            return [...prev, { ...curr, count: 1 }];
          }, [])
          .filter((d) => d.availability === filterAvailability.filterValue);
      case FilterEnum.LAYOUTS:
        return data
          .filter(
            (d) =>
              d.series === 'current_dashboard' && typeof d.layout === 'string',
          )
          .map((v) => ({ ...v, value: v.count }))
          .filter((d) => d.availability === filterAvailability.filterValue);

      default:
        return [];
    }
  }, [filterValue, data, filterAvailability.filterValue]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.sale_speed.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.sale_speed.title', 'Sale Speed')}
        subtitle={`${t(
          'market.reports.sale_speed.subtitle',
          'The number of sold units in the last 30 days in the selected projects.',
        )} ${getLastUpdateText(5)}`}
        extra={
          !isLoading && <CardPopover
            showValues
            onDownloadPNG={() => downloadPNG(fileName, pngRef)}
            extraComponents={[
              <div className='actions' role='presentation' onClick={() => setShowPrivate(!showPrivate)}>
                <div>
                  {t('market.reports.sale_speed.show_private', 'Show private')}
                </div>
                <Checkbox checked={showPrivate} />
              </div>,
              <div className='actions' role='presentation' onClick={() => setShowB2B(!showB2B)}>
                <div>
                  {t('market.reports.sale_speed.show_b2b', 'Show B2B')}
                </div>
                <Checkbox checked={showB2B} />
              </div>,
            ]}
          />
        }
        infoPopoverContent={
          <div css={styles.saleSpeed.popoverContainer}>
            <p>
              {t(
                'market.reports.sale_speed.popover.description',
                "This chart doesn't display reservations as sales regardless of your settings.",
              )}
            </p>
          </div>
        }
        chart={
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : (
            <SaleSpeedChart
              data={chartData}
              isSold={filterAvailability.filterValue === Availability.SOLD}
              isOverall={isOverall}
              showPrivate={showPrivate}
              showB2B={showB2B}
            />
          )
        }
        controls={
          <>
            <Segmented
              style={{ marginRight: '1.2rem' }}
              value={filterAvailability.filterValue}
              options={[
                {
                  value: Availability.SOLD,
                  label: t('enums.state.sold'),
                },
                {
                  value: Availability.RESERVED,
                  label: t('enums.state.reserved'),
                },
              ]}
              onChange={filterAvailability.handleChange}
            />
            <Segmented
              value={filterValue}
              options={[
                {
                  value: FilterEnum.OVERALL,
                  label: t('dashboard.sale_progress.overall', 'Overall'),
                },
                {
                  value: FilterEnum.LAYOUTS,
                  label: t('dashboard.sale_progress.layouts', 'Layouts'),
                },
              ]}
              onChange={handleChange}
            />
            {isOverall && (
              <div css={styles.competitorsContainer}>
                <CompetitorSelect />
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
