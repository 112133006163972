import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../hooks';

const { Option } = Select;

export const PhaseSelect: FC<SelectProps> = (props) => {
  const { overviewData, phaseId, projectId } = useStoreState(
    (state) => state.filters,
  );
  const { setPhaseId } = useStoreActions((actions) => actions.filters);
  const { t } = useTranslation();
  return (
    <Select
      bordered={false}
      value={phaseId}
      onChange={setPhaseId}
      placeholder={t('header.phase')}
      dropdownMatchSelectWidth={false}
      {...props}
    >
      {overviewData?.projects
        .find((v) => v.project_id === projectId)
        ?.phases.map((v) => (
          <Option key={v.phase_id} value={v.phase_id}>
            {v.phase_name}
          </Option>
        ))}
    </Select>
  );
};
