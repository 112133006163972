/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BuildingConstructionPhase = {
    New: 'NEW',
    Unknown: 'UNKNOWN'
} as const;
export type BuildingConstructionPhase = typeof BuildingConstructionPhase[keyof typeof BuildingConstructionPhase];


export function BuildingConstructionPhaseFromJSON(json: any): BuildingConstructionPhase {
    return BuildingConstructionPhaseFromJSONTyped(json, false);
}

export function BuildingConstructionPhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingConstructionPhase {
    return json as BuildingConstructionPhase;
}

export function BuildingConstructionPhaseToJSON(value?: BuildingConstructionPhase | null): any {
    return value as any;
}

