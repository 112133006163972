/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableUnitsAveragePriceHistoryResponseSchema
 */
export interface AvailableUnitsAveragePriceHistoryResponseSchema {
    /**
     * 
     * @type {string}
     * @memberof AvailableUnitsAveragePriceHistoryResponseSchema
     */
    createdDate: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableUnitsAveragePriceHistoryResponseSchema
     */
    availableUnitsAveragePrice: number | null;
}

/**
 * Check if a given object implements the AvailableUnitsAveragePriceHistoryResponseSchema interface.
 */
export function instanceOfAvailableUnitsAveragePriceHistoryResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "availableUnitsAveragePrice" in value;

    return isInstance;
}

export function AvailableUnitsAveragePriceHistoryResponseSchemaFromJSON(json: any): AvailableUnitsAveragePriceHistoryResponseSchema {
    return AvailableUnitsAveragePriceHistoryResponseSchemaFromJSONTyped(json, false);
}

export function AvailableUnitsAveragePriceHistoryResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableUnitsAveragePriceHistoryResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': json['created_date'],
        'availableUnitsAveragePrice': json['available_units_average_price'],
    };
}

export function AvailableUnitsAveragePriceHistoryResponseSchemaToJSON(value?: AvailableUnitsAveragePriceHistoryResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_date': value.createdDate,
        'available_units_average_price': value.availableUnitsAveragePrice,
    };
}

