/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { css } from '@emotion/react/macro';
import { useStoreActions, useStoreState } from '../../../../hooks';

const styles = css({ width: '100%' });

type Props = {
  dates: string[];
};

export const DateSelect: FC<Props> = ({ dates }) => {
  const formatDate = (date: string, month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'): string =>
    new Date(date).toLocaleDateString('en-GB', {
      month,
    });
  const selectedDatesRedux = useStoreState((state) => state.market.selectedDates);
  const setSelectedDatesRedux = useStoreActions(
    (state) => state.market.setSelectedDates,
  );
  const [selectedDates, setSelectedDates] = useState({
    months: selectedDatesRedux
      ? [String(formatDate(selectedDatesRedux[selectedDatesRedux.length - 1], '2-digit'))]
      : [],
    year: selectedDatesRedux
      ? String(new Date(selectedDatesRedux[0]).getFullYear())
      : '',
  });
  const [firstRender, setFirstRender] = useState(true);

  const years = useMemo(
    () =>
      [
        ...new Set(dates.map((date) => String(new Date(date).getFullYear()))),
      ].map((year) => ({
        label: year,
        value: year,
      })),
    [dates],
  );

  const months = useMemo(() => {
    const currentDates = dates.filter(
      (d) => String(new Date(d).getFullYear()) === selectedDates.year,
    );
    return [
      ...new Set(
        currentDates.map((date) => ({
          label: formatDate(date, 'long'),
          value: formatDate(date, '2-digit'),
        })),
      ),
    ];
  }, [dates, selectedDates.year]);

  useEffect(() => {
    if (!firstRender && months) {
        const existingDates = selectedDates.months.filter((sd) => months.map((m) => m.value).includes(sd));
        if (existingDates.length === 0) {
          setSelectedDates((prev) => ({
            ...prev,
            months: [months[months.length - 1].value],
          }));
        } else {
          setSelectedDates((prev) => ({
            ...prev,
            months: existingDates,
          }));
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates.year]);

  useEffect(() => {
    if (firstRender) {
      const currentDates = dates.filter(
        (d) => String(new Date(d).getFullYear()) === years[0].value,
      );
      const monthsFirstRender = [
        ...new Set(
          currentDates.map((date) => ({
            label: formatDate(date, 'long'),
            value: formatDate(date, '2-digit'),
          })),
        ),
      ].sort((a, b) => Number(a.value) - Number(b.value));
      if (!selectedDatesRedux) {
        setSelectedDates({
          months: [monthsFirstRender[monthsFirstRender.length - 1].value],
          year: years[0].value,
        });
      }
      setFirstRender(false);
    }
  }, [years, firstRender, dates, selectedDatesRedux]);

  useEffect(() => {
    setSelectedDatesRedux(selectedDates.months.map((m) => `${selectedDates.year}-${m}`));
  }, [selectedDates, setSelectedDatesRedux]);

  const handleChange = (value: string[] | string, type: 'months' | 'year'): void => {
    if (type === 'months' && value.length === 0) {
      return;
    }
    setSelectedDates((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleClear = (): void => {
    setSelectedDates({
      months: [months[months.length - 1].value],
      year: selectedDates.year,
    });
  };

  return (
    <div className='market-share-date-picker'>
      <Select<string[]>
        css={styles}
        value={selectedDates.months}
        onChange={(e) => handleChange(e, 'months')}
        placeholder=''
        optionFilterProp='label'
        mode='multiple'
        options={months.sort((a, b) => Number(a.value) - Number(b.value))}
        onClear={handleClear}
        allowClear
      />
      <Select<string>
        css={styles}
        value={selectedDates.year}
        onChange={(e) => handleChange(e, 'year')}
        placeholder=''
        optionFilterProp='label'
        options={years.sort((a, b) => Number(b.value) - Number(a.value))}
        onClear={handleClear}
        maxTagCount={5}
      />
    </div>
  );
};
