import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { ChartCard } from '../../../ChartCard';
import { Availability } from '../../../../store/types';
import { SaleDetailChart } from './SaleDetailChart';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { fetchBlockSaleDetailData } from '../../../../api';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { SaleDetailData } from '../../../../api/types';

export interface Data {
  category: string;
  state: Availability;
  value: number;
}

export const SaleDetail: FC = () => {
  const { t } = useTranslation();
  const { paramsPM } = useStoreState((state) => state.filters);
  const [data, setData] = useState<SaleDetailData[]>([]);
  const [loading, setLoading] = useState(true);
  const pngRef = useRef(null);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  useEffect(() => {
    if (paramsPM?.dashboardId && paramsPM?.date) {
      setLoading(true);
      fetchBlockSaleDetailData(
        paramsPM.dashboardId,
        paramsPM.date,
        pricePerSmCalculation,
      )
        .then((response) => {
          setData(response.data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation]); // Ensure dependencies are up-to-date

  const fileName = getReportExportFileName(
    paramsPM?.name || 'report',
    t('dashboard.sale_detail.title'),
  );

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        extra={
          !loading && (
            <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />
          )
        }
        loading={loading}
        title={t('dashboard.sale_detail.title')}
        subtitle={t('dashboard.sale_detail.subtitle')}
        chart={data.length > 0 ? <SaleDetailChart data={data} /> : null}
      />
    </div>
  );
};
