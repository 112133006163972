import { FC, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../routes/Paths';
import styles from './ExportDropdown.module.css';
import { useStoreState } from '../../hooks';
import { ProjectType } from '../../store/types';
import { CountryEnum } from '../../api/enums';

type Props = {
  type: 'priceList' | 'marketData';
  onDownload: (format: string) => void;
};

export const ExportDropdown: FC<Props> = ({ type, onDownload }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  const profile = useStoreState((state) => state.user.profile);
  const showRealpad =
    profile?.country === CountryEnum.CZECHIA ||
    profile?.country === CountryEnum.SLOVAKIA;

  const { pathname } = useLocation();
  const showMarketExport = pathname === Paths.MARKET_REPORTS;

  const options = [
    { value: 'xlsx', label: 'XLSX' },
    { value: 'csv', label: 'CSV' },
    ...(showRealpad && !showMarketExport ? [{ value: 'realpad', label: 'Realpad XLSX' }] : []),
  ];

  return (
    <div
      className={styles.container}
      style={{ width: type === 'priceList' ? '13rem' : '16rem' }}
    >
      <div
        className={styles.dropdown}
        style={{ maxHeight: isOpen ? 'unset' : 30 }}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div
          className={styles.button}
          onClick={() => setIsOpen(!isOpen)}
          aria-label='button'
        >
          <ArrowDownTrayIcon className='w-7' />
          {type === 'priceList'
            ? t(
                `price_list.${isRent ? 'download' : 'title'}`,
                `${isRent ? 'Rent Roll' : 'Pricing'}`,
              )
            : t('market_data.title', 'Market Data')}
          <ChevronDownIcon
            className={`w-5 text-[#bdbdbd] stroke-[4px] ${
              isOpen && 'rotate-180'
            }`}
          />
        </div>
        {isOpen && (
          <div className={styles.menu}>
            {options.map((option) => (
              <span
                className={styles.option}
                onClick={() => onDownload(option.value)}
              >
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
