/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { SupplyHistoryChart } from './SupplyHistoryChart';
import { styles } from '../styles';
import { ChartCard } from '../../../ChartCard';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { fetchBlockSupplyHistoryData } from '../../../../api';
import { SupplyHistoryDataResponse } from '../../../../api/types';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';

export enum FilterEnum {
  OVERALL,
  NEW,
}

const formatter = (dateStr: string): string => {
  const d = new Date(dateStr);
  return `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-01`;
};

export const SupplyHistory: FC = () => {
  const [data, setData] = useState<SupplyHistoryDataResponse>();
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const pngRef = useRef(null);
  const { t } = useTranslation();
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  useEffect(() => {
    if (paramsPM?.dashboardId && paramsPM?.date) {
      setLoading(true);
      fetchBlockSupplyHistoryData(paramsPM.dashboardId, paramsPM.date, pricePerSmCalculation)
        .then((response) => {
          const newData = response.data.new.map((newItem) => ({
            ...newItem,
            date: formatter(newItem.date),
          }));

          const overallData = response.data.overall.map((item) => ({
              ...item,
              available: isSold ? item.available : item.available + item.reserved,
              date: formatter(item.date),
          }));

          const formattedData = {
            new: newData,
            overall: overallData,
          };

          setData(formattedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation, isSold]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.supply_history.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.supply_history.title', 'Supply History')}
        subtitle={t(
          'market.reports.supply_history.subtitle',
          'The history of the number of available units.',
        )}
        extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
        chart={
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : (
            data ?
              <SupplyHistoryChart
                filter={filterValue as FilterEnum}
                data={data}
              />
              : null
          )
        }
        controls={
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: FilterEnum.NEW,
                label: t('dashboard.supply_history.new', 'New'),
              },
            ]}
            onChange={handleChange}
          />
        }
      />
    </div>
  );
};
