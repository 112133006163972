import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SubscribePage.module.css';
import dashboardBanner from './SubscribeBanners/dashboardBanner.png';
import marketBanner from './SubscribeBanners/marketBanner.png';
import { formatBreaks } from '../../utils/formatBreaks';

type FeatureType = 'dashboard' | 'market';

type Feature = {
  img: string;
  title: string;
}

type Props = {
  featureType: FeatureType;
}

const features: { [key in FeatureType]: Feature } = {
  dashboard: {
    img: dashboardBanner,
    title: 'Dashboard',
  },
  market: {
    img: marketBanner,
    title: 'Market Data',
  },
};

export const SubscribePage: FC<Props> = ({ featureType }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={features[featureType].img} className={styles.img} alt='Dasboard banner' />
        <div className={styles.infoContainer}>
          <div className={styles.badge}>
          {t('common.subscribe_page.badge')}
          </div>
          <h1 className={styles.title}>
            {t(`common.subscribe_page.${featureType}.title`)}
          </h1>
          <p className={styles.info}>
            {t(`common.subscribe_page.${featureType}.description`)}
          </p>
          <p className={styles.readMore}>
            {formatBreaks(t('common.subscribe_page.read_more'), '***', false, 1, 700)}
          </p>
        </div>
      </div>
    </div>
  );
};
