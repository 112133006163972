import { useState } from 'react';
import { PolygonF, PolygonProps } from '@react-google-maps/api';
import { useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { LatLng } from '../../utils/types';

type CustomPolygonProps = PolygonProps & {
    name: string;
    paths: LatLng[];
    onUpdate?: (polygon: google.maps.Polygon, name: string) => void;
}

export const CustomPolygon = (props: CustomPolygonProps): JSX.Element => {
    const { name, paths, onUpdate } = props;
    const mode = useStoreState((state) => state.market.mode);
    const [polygonRef, setPolygonRef] = useState<google.maps.Polygon | null>(null);
    const [isPolygonBeingEdited, setIsPolygonBeingEdited] = useState<boolean>(false);
    const polygonOptions: google.maps.PolygonOptions = {
        editable: mode !== Mode.READ,
        fillColor: '#5A72B1',
        fillOpacity: 0.5,
        strokeColor: '#5A72B1',
    };

    const handleOnMouseDown = (e: google.maps.MapMouseEvent): void => {
        const originalPath = [e.latLng?.lat(), e.latLng?.lng()];
        const parsedPaths = paths?.map((path) => [path.lat, path.lng]);
        if (originalPath[0] && originalPath[1]) {
            const coordinateChanged = (parsedPaths as number[][]).some((path) =>
            path[0] === originalPath[0] &&
            path[1] === originalPath[1],
        );
            if (coordinateChanged) {
                setIsPolygonBeingEdited(true);
            }
        }
    };

    const handleOnMouseUp = (): void => {
        const polygonPaths = polygonRef?.getPath()?.getArray();
        const coordinateAdded = (polygonPaths || []).length > (paths || []).length;

        if (onUpdate && polygonRef && (isPolygonBeingEdited || coordinateAdded)) {
            onUpdate(polygonRef, name);
            setIsPolygonBeingEdited(false);
        }
    };

    return (
        <PolygonF
          options={polygonOptions}
          paths={paths}
          onLoad={(initialPolygon) => setPolygonRef(initialPolygon)}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
        />
    );
};
