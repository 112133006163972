/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LayoutUnits } from './LayoutUnits';
import {
    LayoutUnitsFromJSON,
    LayoutUnitsFromJSONTyped,
    LayoutUnitsToJSON,
} from './LayoutUnits';

/**
 * 
 * @export
 * @interface AvailableUnitsLayoutUnitMixResponseSchema
 */
export interface AvailableUnitsLayoutUnitMixResponseSchema {
    /**
     * 
     * @type {Array<LayoutUnits>}
     * @memberof AvailableUnitsLayoutUnitMixResponseSchema
     */
    availableUnitsLayout180: Array<LayoutUnits>;
    /**
     * 
     * @type {Array<LayoutUnits>}
     * @memberof AvailableUnitsLayoutUnitMixResponseSchema
     */
    availableUnitsLayout?: Array<LayoutUnits>;
}

/**
 * Check if a given object implements the AvailableUnitsLayoutUnitMixResponseSchema interface.
 */
export function instanceOfAvailableUnitsLayoutUnitMixResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableUnitsLayout180" in value;

    return isInstance;
}

export function AvailableUnitsLayoutUnitMixResponseSchemaFromJSON(json: any): AvailableUnitsLayoutUnitMixResponseSchema {
    return AvailableUnitsLayoutUnitMixResponseSchemaFromJSONTyped(json, false);
}

export function AvailableUnitsLayoutUnitMixResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableUnitsLayoutUnitMixResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableUnitsLayout180': ((json['available_units_layout_180'] as Array<any>)?.map(LayoutUnitsFromJSON)),
        'availableUnitsLayout': ((json['available_units_layout'] as Array<any>)?.map(LayoutUnitsFromJSON)),
    };
}

export function AvailableUnitsLayoutUnitMixResponseSchemaToJSON(value?: AvailableUnitsLayoutUnitMixResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_units_layout_180': ((value.availableUnitsLayout180 as Array<any>).map(LayoutUnitsToJSON)),
    };
}

