import { Dispatch, FC, SetStateAction, useState } from 'react';
import { message } from 'antd';
import {
  DocumentChartBarIcon,
  FlagIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { TargetRow } from './TargetRow';
import { Target } from '../../../../api/mockData/sales';
import { useStoreState } from '../../../../hooks';

type Props = {
  targets: Partial<Target>[];
  setTargets: Dispatch<SetStateAction<Partial<Target>[]>>;
};

export const TargetsSection: FC<Props> = ({ targets, setTargets }) => {
  const { t } = useTranslation();
  const { saleEnd, saleStart, projectType } = useStoreState(
    (state) => state.onboarding,
  );
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState<{
    isEditing: boolean;
    index: number | null;
  }>({ isEditing: false, index: null });
  const isRental = projectType.rental;

  const addNew = (): void => {
    setIsCreating(true);
  };

  const handleAddNewClick = (): void => {
    if (!!!saleEnd || !!!saleStart) {
      message.info('Define sale start and sale end first');
      return;
    }
    if (isCreating || isEditing.isEditing) {
      message.info(
        isCreating
          ? t(
              'sales.error.finish_creating',
              'Please, finish creating target first.',
            )
          : t(
              'sales.error.finish_editing',
              'Please, finish editing target first.',
            ),
      );
      return;
    }
    addNew();
  };

  const onEdit = (value: boolean, index: number): void => {
    if (isCreating) {
      message.info(
        t(
          'sales.error.finish_creating',
          'Please, finish creating target first.',
        ),
      );
      return;
    }
    if (value) {
      setIsEditing({ isEditing: true, index });
    } else {
      setIsEditing({ isEditing: false, index: null });
    }
  };

  return (
    <div className='flex flex-col px-8 py-7 border-[1px] border-[#BDBDBD] rounded-[25px]'>
      <span className='select-none text-bmblue font-bold text-xl pb-6'>
        {t('sales.targets.title', 'Sales Targets')}
      </span>
      <div className='space-y-2 max-w-fit text-lg'>
        <div className='grid grid-cols-3 gap-6 border-b-[3px] pl-4 pb-2 border-[#F1F1F1] text-bmblue w-[30rem]'>
          <div className='flex space-x-2 items-center'>
            <DocumentChartBarIcon className='text-bmblue w-7 h-7 stroke-2' />
            <span className='font-bold'>
              {t('sales.targets.subtitle.type', 'Type')}
            </span>
          </div>
          <div className='flex space-x-2 items-center'>
            <FlagIcon className='text-bmblue w-7 h-7 stroke-2' />
            <span className='font-bold'>
              {t('sales.targets.subtitle.target', 'Target')}
            </span>
          </div>
          <div className='flex space-x-2 items-center justify-start'>
            <CalendarIcon className='text-bmblue w-7 h-7 stroke-2' />
            <span className='font-bold'>
              {t('sales.targets.subtitle.deadline', 'Deadline')}
            </span>
          </div>
        </div>
        {targets.map((target, index) => (
          <TargetRow
            key={target.id}
            target={target}
            targets={targets}
            setTargets={setTargets}
            isEditing={isEditing.isEditing && isEditing.index === index}
            onEdit={() => onEdit(true, index)}
            onEditCancel={() => onEdit(false, index)}
          />
        ))}
        {isCreating && (
          <TargetRow
            targets={targets}
            setTargets={setTargets}
            setIsCreating={setIsCreating} // Pass the setIsCreating callback here
          />
        )}
        <div className='flex space-x-4 pl-8 py-2'>
          <span
            className={`text-bmgray cursor-pointer ${
              (isCreating ||
                isEditing.isEditing ||
                !!!saleEnd ||
                !!!saleStart) &&
              'opacity-25'
            }`}
            onClick={() => handleAddNewClick()}
          >
            + {t('sales.targets.add_new', 'Add New')}
          </span>
        </div>
      </div>
    </div>
  );
};
