import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Props = {
  className?: string;
};

export const Subtitle: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}: PropsWithChildren<Props>) => (
  <span className={clsx(className, 'text-bmgray text-base')}>
    {children}
  </span>
);
