import { FC, useEffect, useState } from 'react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../Heading';
import { Subtitle } from '../../Subtitle';
import { FormContainer } from '../FormContainer';
import { Button } from '../../button/Button';
import { RadioButton } from '../../button/RadioButton';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { BackButton } from '../../button/BackButton';
import { DataUpdates } from '../../../../store/onboarding';

export const DataUpdatesComponent: FC = () => {
  const { t } = useTranslation();

  const { dataUpdates, pageUrl, skipPriceList, projectType } = useStoreState(
    (state) => state.onboarding,
  );
  const { handleNext, setDataUpdates, setPageUrl, setCurrentPage } =
    useStoreActions((actions) => actions.onboarding);

  const [urlError, setUrlError] = useState<string | null>(null);
  const isRental = projectType.rental;

  const urlSchema = z
    .string()
    .min(1, {
      message: t('onboarding.data_updates.url.required', 'URL is required'),
    })
    .url({
      message: t(
        'onboarding.data_updates.url.wrong_format',
        'URL is in wrong format',
      ),
    });

  useEffect(() => {
    setUrlError(null);
  }, [pageUrl]);

  const validateUrl = (url: string): boolean => {
    try {
      urlSchema.parse(url);
      setUrlError(null);
      return true;
    } catch (e) {
      if (e instanceof z.ZodError) {
        setUrlError(e.errors[0].message);
      }
      return false;
    }
  };

  const handleBackClick = (): void => {
    if (skipPriceList) {
      setCurrentPage(-2);
      return;
    }
    setCurrentPage(-1);
  };

  const handleClick = (updateType: DataUpdates): void => {
    setDataUpdates(dataUpdates === updateType ? DataUpdates.EMPTY : updateType);
  };

  const handleUrlChange = (url: string): void => {
    setPageUrl(url);
  };

  const handleContinueClick = (): void => {
    if (dataUpdates === DataUpdates.WEB) {
      if (validateUrl(pageUrl)) {
        handleNext();
      }
    } else {
      handleNext();
    }
  };

  const isContinueDisabled =
    dataUpdates === DataUpdates.EMPTY ||
    (dataUpdates === DataUpdates.WEB && !!urlError);

  return (
    <FormContainer>
      <div>
        <BackButton handleClick={handleBackClick} />
        <Heading className='pb-6'>
          {t('onboarding.data_updates.title', 'Data updates')}
        </Heading>
        <Subtitle>
          {t(
            'onboarding.data_updates.subtitle',
            'How should we update the availability and {{type}} of units in your project?',
            {
              type: isRental
                ? t('onboarding.common.rent', 'Rent').toLowerCase()
                : t('onboarding.common.price', 'Price').toLowerCase(),
            },
          )}
        </Subtitle>
        <div className='flex flex-col gap-4 mt-8'>
          <RadioButton
            title={t('onboarding.data_updates.crm.title', 'CRM')}
            tags={[t('onboarding.common.recommended', 'Recommended')]}
            subtitle={t(
              'onboarding.data_updates.crm.subtitle',
              'CRM We’ll load the data directly from your CRM on a daily basis. We’ll follow up by asking for credentials.',
            )}
            selected={dataUpdates === DataUpdates.CRM}
            handleClick={() => {
              handleClick(DataUpdates.CRM);
              setPageUrl('');
              setUrlError(null);
            }}
          />
          <RadioButton
            title={t('onboarding.data_updates.web.title', 'Web')}
            tags={[t('onboarding.common.recommended', 'Recommended')]}
            subtitle={t(
              'onboarding.data_updates.web.subtitle',
              'We’ll load the data directly from your website on a daily basis.',
            )}
            input={{
              placeholder: t(
                'onboarding.data_updates.url.placeholder',
                'Type in the URL ...',
              ),
              label: t('onboarding.data_updates.url.label', 'URL'),
              value: pageUrl,
              setValue: handleUrlChange,
            }}
            selected={dataUpdates === DataUpdates.WEB}
            handleClick={() => handleClick(DataUpdates.WEB)}
          />
          {urlError && dataUpdates === DataUpdates.WEB && (
            <div className='text-red-500'>{urlError}</div>
          )}
          <RadioButton
            title={t('onboarding.data_updates.manual.title', 'Manual')}
            subtitle={t(
              'onboarding.data_updates.manual.subtitle',
              'You’ll manually send us a spreadsheet on a regular basis.',
            )}
            selected={dataUpdates === DataUpdates.MANUAL}
            handleClick={() => {
              handleClick(DataUpdates.MANUAL);
              setPageUrl('');
              setUrlError(null);
            }}
          />
        </div>
      </div>
      <div className='flex justify-center'>
        <Button handleClick={handleContinueClick} disabled={isContinueDisabled}>
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
