import React from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import { AppLayout } from '../components';
import { useStoreState } from '../hooks';
import {
  DashboardPage,
  LoginPage,
  NotFoundPage,
  PriceListDetailPage,
  PriceListPage,
  ProjectDetailPage,
} from '../pages';
import { MarketDashboard } from '../pages/market/MarketDashboard';
import { MarketProjects } from '../pages/market/MarketProjects';
import { Paths } from './Paths';
import { PrivateRoute } from './PrivateRoute';
import { DailyNewsPage } from '../pages/DailyNews';
import { PriceUpdatePage } from '../pages/PriceUpdate';
import { MarketAnalysisPage } from '../pages/MarketAnalysis';
import { SettingsPage } from '../pages/Settings';
import { DynamicPricingOnboarding } from '../pages/DynamicPricingOnboarding';

export const Routes: React.FC = () => {
  const isDailyNewsEnabled = useStoreState(
    (state) => state.user.profile?.daily_news_enabled,
  );
  const localization = useStoreState((state) => state.user.profile?.localization);

  return (
    <ReactRoutes>
      <Route index element={<Navigate to={Paths.LOGIN} />} />
      <Route path={Paths.LOGIN} element={<LoginPage />} />
      <Route element={<AppLayout />}>
        {
          isDailyNewsEnabled && (
            <Route
              path={Paths.DAILYNEWS}
              element={
                <PrivateRoute>
                  <DailyNewsPage />
                </PrivateRoute>
              }
            />
          )
        }
        {
          (localization === 'sk' || localization === 'cs' || localization === 'EU') && (
            <Route
              path={Paths.MARKET_ANALYSIS}
              element={
              <PrivateRoute>
              <MarketAnalysisPage />
              </PrivateRoute>
          }
            />
        )
      }
        <Route
          index
          element={
            <Navigate
              to={Paths.DASHBOARD}
            />
          }
        />
        <Route
          path={Paths.SETTINGS}
          element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          }
        />
          <Route
            path={Paths.DASHBOARD}
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.PRICE_LIST}
            element={
              <PrivateRoute>
                <PriceListPage />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.PRICE_LIST_DETAIL}
            element={
              <PrivateRoute>
                <PriceListDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.PRICE_UPDATE}
            element={
              <PrivateRoute>
                <PriceUpdatePage />
              </PrivateRoute>
            }
          />
        <Route
          path={Paths.PROJECT_DETAIL}
          element={
            <PrivateRoute>
              <ProjectDetailPage />
            </PrivateRoute>
          }
        />
          <Route
            path={Paths.MARKET_REPORTS}
            element={
              <PrivateRoute>
                <MarketDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.MARKET_REPORTS_SECONDARY}
            element={
              <PrivateRoute>
                <MarketDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.MARKET_REPORTS_RENTALS}
            element={
              <PrivateRoute>
                <MarketDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.MARKET_PROJECTS}
            element={
              <PrivateRoute>
                <MarketProjects />
              </PrivateRoute>
            }
          />
          <Route
            path={Paths.DYNAMIC_PRICING_ONBOARDING}
            element={
              <PrivateRoute>
                <DynamicPricingOnboarding />
              </PrivateRoute>
            }
          />
      </Route>
      <Route path={Paths.NOT_FOUND} element={<NotFoundPage />} />
      <Route path='*' element={<Navigate to='/' />} />
    </ReactRoutes>
  );
};
