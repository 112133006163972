import { FC } from 'react';
import { Tabs } from 'antd';
import { MarketProjectExtended } from '../../../store/types';
import { Footer } from './Footer';
import { Content } from './Content';
import { Header } from './Header';

const { TabPane } = Tabs;

interface ProjectDetailProps {
  selectedProject: MarketProjectExtended;
  sameLocationProjects: MarketProjectExtended[];
  onClose: () => void;
}

export const ProjectDetail: FC<ProjectDetailProps> = ({
  selectedProject,
  sameLocationProjects,
  onClose,
}) => (
  <div className='market-project-detail'>
    {sameLocationProjects.length > 1 ? (
      <Tabs
        defaultActiveKey={selectedProject.project_id.toString()}
        type='card'
        size='small'
      >
        {sameLocationProjects.map((project) => (
          <TabPane
            className='tab'
            tab={project.project_name}
            key={project.project_id}
          >
            <Header data={project} onClose={onClose} />
            <Content data={project} />
            <Footer project_id={project.project_id} include={project.include} />
          </TabPane>
        ))}
      </Tabs>
    ) : (
      <div className='single-project'>
        <Header data={selectedProject} onClose={onClose} />
        <Content data={selectedProject} />
        <Footer
          project_id={selectedProject.project_id}
          include={selectedProject.include}
        />
      </div>
    )}
  </div>
);
