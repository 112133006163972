/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MultiPolygonalSchema } from './MultiPolygonalSchema';
import {
    MultiPolygonalSchemaFromJSON,
    MultiPolygonalSchemaFromJSONTyped,
    MultiPolygonalSchemaToJSON,
} from './MultiPolygonalSchema';

/**
 * 
 * @export
 * @interface TerritoryGeomsResponseSchema
 */
export interface TerritoryGeomsResponseSchema {
    /**
     * 
     * @type {string}
     * @memberof TerritoryGeomsResponseSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TerritoryGeomsResponseSchema
     */
    territoryId: string;
    /**
     * 
     * @type {MultiPolygonalSchema}
     * @memberof TerritoryGeomsResponseSchema
     */
    geom: MultiPolygonalSchema;
}

/**
 * Check if a given object implements the TerritoryGeomsResponseSchema interface.
 */
export function instanceOfTerritoryGeomsResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "territoryId" in value;
    isInstance = isInstance && "geom" in value;

    return isInstance;
}

export function TerritoryGeomsResponseSchemaFromJSON(json: any): TerritoryGeomsResponseSchema {
    return TerritoryGeomsResponseSchemaFromJSONTyped(json, false);
}

export function TerritoryGeomsResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerritoryGeomsResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'territoryId': json['territory_id'],
        'geom': MultiPolygonalSchemaFromJSON(json['geom']),
    };
}

export function TerritoryGeomsResponseSchemaToJSON(value?: TerritoryGeomsResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'territory_id': value.territoryId,
        'geom': MultiPolygonalSchemaToJSON(value.geom),
    };
}

