/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchBlockSizeComparisonData } from '../../../../api';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { CompetitorSelect } from '../CompetitorSelect';
import { Data, SizeComparisonChart } from './SizeComparisonChart';
import { SizeComparisonData } from '../../../../api/types';
import { ChartCard } from '../../../ChartCard';
import { useReservedData } from '../../../../hooks/useReservedData';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { CardPopover } from '../CardPopover';
import { getReportExportFileName } from '../../../../utils/utils';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  FLOOR_AREA,
  EXTERIOR_AREA,
}

export const SizeComparison: FC = () => {
  const [data, setData] = useState<SizeComparisonData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.FLOOR_AREA);
  const { mapReservedData } = useReservedData();
  const pngRef = useRef(null);

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockSizeComparisonData(
        paramsPM.dashboardId,
        paramsPM.date,
        competitors,
        pricePerSmCalculation,
      )
        .then((response) => {
          setData(
            response.data.sort((a, b) => {
              if (
                a.project_name === 'current_dashboard' &&
                b.project_name !== 'current_dashboard'
              ) {
                return -1;
              }
              if (
                a.project_name !== 'current_dashboard' &&
                b.project_name === 'current_dashboard'
              ) {
                return 1;
              }
              return 0;
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, pricePerSmCalculation]);

  const reservedData = mapReservedData(data);
  const chartData: Data[] = reservedData
    .map(
      ({
        layout,
        availability,
        market: { floor_area, exterior_area },
        series,
        project_name,
      }) => ({
        value:
          filterValue === FilterEnum.FLOOR_AREA ? floor_area : exterior_area,
        layout,
        series:
          !series || series === 'current_dashboard'
            ? availability
            : project_name,
      }),
    )
    .reduce((results, current) => {
      const newResults = [...results];
      const existingIndex = results.findIndex(
        ({ layout, series }) =>
          layout === current.layout && series === current.series,
      );
      if (newResults[existingIndex]) {
        newResults[existingIndex] = {
          ...newResults[existingIndex],
          value: (newResults[existingIndex].value + current.value) / 2,
        };
        return newResults;
      }
      newResults.push(current);
      return newResults;
    }, [] as Data[]);

  const fileName = getReportExportFileName(
    paramsPM?.name || 'report',
    t('market.reports.size_comparison.title'),
  );

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.size_comparison.title', 'Size Comparison')}
        extra={
          !isLoading && (
            <CardPopover
              showValues
              onDownloadPNG={() => downloadPNG(fileName, pngRef)}
            />
          )
        }
        subtitle={t(
          'market.reports.size_comparison.subtitle',
          'The comparison of the average interior and exterior area of units in the selected projects.',
        )}
        infoPopoverContent={
          <div css={styles.saleSpeed.popoverContainer}>
            <p>
              {t(
                'market.reports.size_comparison.popover.description',
                'The price is calculated from the values of the last 30 days.',
              )}
            </p>
          </div>
        }
        chart={
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : (
            <SizeComparisonChart data={chartData} />
          )
        }
        controls={
          <>
            <Segmented
              value={filterValue}
              options={[
                {
                  value: FilterEnum.FLOOR_AREA,
                  label: t(
                    'market.size_comparison.switch.floor_area',
                    'Floor Area',
                  ),
                },
                {
                  value: FilterEnum.EXTERIOR_AREA,
                  label: t(
                    'market.reports.size_comparison.switch.exterior_area',
                    'Exterior Area',
                  ),
                },
              ]}
              onChange={handleChange}
            />
            <div css={styles.competitorsContainer}>
              <CompetitorSelect />
            </div>
          </>
        }
      />
    </div>
  );
};
