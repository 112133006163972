/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomTerritoryResponseSchema
 */
export interface CustomTerritoryResponseSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTerritoryResponseSchema
     */
    territoryIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomTerritoryResponseSchema
     */
    customTerritoryName: string;
}

/**
 * Check if a given object implements the CustomTerritoryResponseSchema interface.
 */
export function instanceOfCustomTerritoryResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "territoryIds" in value;
    isInstance = isInstance && "customTerritoryName" in value;

    return isInstance;
}

export function CustomTerritoryResponseSchemaFromJSON(json: any): CustomTerritoryResponseSchema {
    return CustomTerritoryResponseSchemaFromJSONTyped(json, false);
}

export function CustomTerritoryResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomTerritoryResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'territoryIds': json['territory_ids'],
        'customTerritoryName': json['custom_territory_name'],
    };
}

export function CustomTerritoryResponseSchemaToJSON(value?: CustomTerritoryResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'territory_ids': value.territoryIds,
        'custom_territory_name': value.customTerritoryName,
    };
}

