import clsx from 'clsx';
import React from 'react';
import { State } from '../../../api/enums';

export const Flat = React.memo(
  ({
    layoutAbbreviation,
    availability,
    isSelected,
    isDisabled,
    id,
    onClick,
  }: {
    layoutAbbreviation: string;
    availability: string;
    isSelected: boolean;
    isDisabled: boolean;
    id: number;
    onClick: () => void;
  }) => (
    <div
      key={id}
      role='presentation'
      className={clsx('flat', 'availability', {
        sold: availability === State.SOLD,
        reserved: availability === State.RESERVED,
        available: availability === State.AVAILABLE,
        disabled: isDisabled,
        selected: isSelected,
      })}
      onClick={onClick}
    >
      {layoutAbbreviation}
    </div>
  ),
);
