import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { Action } from 'easy-peasy';
import { UserStore } from './user';
import { DashboardStore } from './dashboard';
import { FilterStore } from './filters';
import { PriceListStore } from './priceList';
import { MarketStore } from './market';
import { GlobalStore } from './global';
import { ProjectStore } from './project';
import { ProjectDetailTableData } from '../components/map/ProjectDetail/Content/ProjectDetailTable/types';
import { PriceListDetailStore } from './priceListDetail';
import { PriceListTableData } from '../api/mockData/priceListData';
import { MarketProject } from '../api/types';
import { DailyNewsStore } from './dailyNews';
import { Discount } from '../api/mockData/projectData';
import { SecondaryMarketStore } from './secondary';
import { MarketTypeEnum } from '../api/enums';
import { SubscribeStore } from './subscribe';
import { ProjectDetailWithHistoryTableData } from '../components/map/ProjectDetailWithHistory/Content/ProjectDetailTable/types';
import { SalesStore } from './sales';
import { OnboardingStore } from './onboarding';

export interface MarketProjectExtended extends MarketProject {
  include?: boolean;
  news?: string;
  sold_avg_price: number | null;
  sold_avg_price_per_sm: number | null;
  discounts?: Discount[];
  project_id: number;
}
export interface StoreModel {
  user: UserStore;
  dashboard: DashboardStore;
  filters: FilterStore;
  priceList: PriceListStore;
  priceListDetail: PriceListDetailStore;
  market: MarketStore;
  global: GlobalStore;
  project: ProjectStore;
  dailyNews: DailyNewsStore;
  reset: Action<StoreModel>;
  secondaryMarket: SecondaryMarketStore;
  subscribe: SubscribeStore;
  sales: SalesStore;
  onboarding: OnboardingStore;
}

export enum Availability {
  AVAILABLE = 'available',
  SOLD = 'sold',
  RESERVED = 'reserved',
}

export enum ProjectType {
  SALE = 'sale',
  RENT = 'rent'
}
export interface ExtendedProjectDetailTableData extends ProjectDetailTableData {
  availability: Availability;
  nonNullPricePerSmCount: number;
  nonNullInteriorSM: number;
  nonNullPrice: number;
}
export interface ExtendedProjectDetailWithHistoryTableData
  extends ProjectDetailWithHistoryTableData {
  availability: Availability;
  nonNullPricePerSmCount: number;
  nonNullInteriorSM: number;
  nonNullPrice: number;
}
export enum Mode {
  READ,
  CREATE,
  EDIT,
  EDITSTART,
  START,
}

export interface BuildingData {
  id: number;
  name: string;
  floors: {
    floorNumber: number;
    flats: PriceListTableData[];
  }[];
}

export interface TableData<RecordType> {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<RecordType>;
  filteredData: RecordType[];
}

export interface MarketParams {
  dashboardId: number;
  date: string;
  name: string;
  projects?: number[];
}
export interface SecondaryMarketParams {
  reportId: string;
  date?: string;
  name: string;
  reportTypes: MarketTypeEnum[];
  polygonsEdited?: boolean;
}

export interface Territory {
  name: string;
  territoryId: string[];
  key: string;
}
