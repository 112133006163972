import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../routes/Paths';

export const AddProject: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className='flex gap-2 px-4 cursor-pointer text-[#313B5E]'
      onClick={() => navigate(Paths.DYNAMIC_PRICING_ONBOARDING)}
    >
      <DocumentPlusIcon className='w-8 stroke-2' />
      <span className='whitespace-nowrap font-bold'>
        {t('common.add_project', 'Add Project')}
      </span>
    </div>
  );
};
