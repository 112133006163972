/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { TableProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useRegionFormatting } from '../../../../../../hooks';
import { styles } from '../../styles';
import { ProjectDetailWithHistoryTableData, ProjectDetailWithHistoryTableProps } from '../types';
import { HistoryPopover } from '../../HistoryPopover';

export const ProjectDetailTableWithHistory: FC<ProjectDetailWithHistoryTableProps> = ({
  customSummary = [],
  dataSource,
  currentDate,
  previousDate,
  ...rest
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatAreaUnits, areaUnit } = useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const columns: TableProps<ProjectDetailWithHistoryTableData>['columns'] = useMemo(
    () => [
      {
        title: t('project.table.layout', 'Layout'),
        dataIndex: 'layout',
        render: (value) => getLayoutAbbr(value),
        align: 'center',
      },
      {
        title: t('project.table.units', 'Units'),
        dataIndex: 'available',
        render: (value) => <HistoryPopover values={value} currentDate={currentDate} previousDate={previousDate} />,
        align: 'center',
      },
      {
        title: t('project.table.interior_unit', 'Interior {{unit}}', {
          unit: areaUnit,
        }),
        dataIndex: 'interior_sm',
        render: (value) => (
          <HistoryPopover
            currentDate={currentDate}
            previousDate={previousDate}
            values={{
              old: formatAreaUnits(value.old),
              current: formatAreaUnits(value.current),
              change: value.change !== null ? String(value.change.toFixed(2)).concat('%') : 'N/A',
            }}
          />
        ),
        align: 'center',
      },
      {
        title: t('project.table.price_per_unit', 'AVG Price per {{unit}}', {
          unit: areaUnit,
        }),
        dataIndex: 'price_per_sm',
        render: (value) => (
          <HistoryPopover
            currentDate={currentDate}
            previousDate={previousDate}
            values={{
              old: formatCurrency(value.old),
              current: formatCurrency(value.current),
              change: value.change !== null ? String(value.change.toFixed(2)).concat('%') : 'N/A',
            }}
          />
        ),
        align: 'center',
      },
      {
        title: t('project.table.price', 'Price'),
        dataIndex: 'price',
        render: (value) => (
          <HistoryPopover
            currentDate={currentDate}
            previousDate={previousDate}
            values={{
              old: formatCurrency(value.old),
              current: formatCurrency(value.current),
              change: value.change !== null ? String(value.change.toFixed(2)).concat('%') : 'N/A',
            }}
          />
        ),
        align: 'center',
      },
    ],
    [areaUnit, formatAreaUnits, formatCurrency, getLayoutAbbr, t],
  );

  return (
    <Table<ProjectDetailWithHistoryTableData>
      css={styles.table}
      rowKey='layout'
      columns={columns}
      pagination={false}
      size='small'
      dataSource={dataSource}
      summary={() => {
        const summaryItems = {
          available: customSummary.find((item) => item.key === 'available'),
          interior_sm: customSummary.find((item) => item.key === 'interior_sm'),
          price_per_sm: customSummary.find((item) => item.key === 'price_per_sm'),
          price: customSummary.find((item) => item.key === 'price'),
        };

        if (dataSource?.length === 0) {
          return <></>;
        }

        return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} />
            <Table.Summary.Cell index={1} align='center'>
              <HistoryPopover
                currentDate={currentDate}
                previousDate={previousDate}
                isSummary
                values={{
                  old: summaryItems.available?.value.old || 0,
                  current: summaryItems.available?.value.current || 0,
                  change: summaryItems.available?.value.change || 0,
                }}
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align='center'>
              <HistoryPopover
                currentDate={currentDate}
                previousDate={previousDate}
                isSummary
                values={{
                  old: summaryItems.interior_sm?.value.old || 0,
                  current: summaryItems.interior_sm?.value.current || 0,
                  change: summaryItems.interior_sm?.value.change || 0,
                }}
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} align='center'>
              <HistoryPopover
                currentDate={currentDate}
                previousDate={previousDate}
                isSummary
                values={{
                  old: summaryItems.price_per_sm?.value.old || 0,
                  current: summaryItems.price_per_sm?.value.current || 0,
                  change: summaryItems.price_per_sm?.value.change || 0,
                }}
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} align='center'>
              <HistoryPopover
                currentDate={currentDate}
                previousDate={previousDate}
                isSummary
                values={{
                  old: summaryItems.price?.value.old || 0,
                  current: summaryItems.price?.value.current || 0,
                  change: summaryItems.price?.value.change || 0,
                }}
              />
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      {...rest}
    />

  );
};
