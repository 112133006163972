/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { formatPercent } from '../../utils';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { round } from '../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-price_list`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

// const styles = {
//   download: css({
//     display: 'flex',
//     alignItems: 'center',
//     borderRadius: '15px',
//     fontSize: '14px',
//     fontWeight: 700,
//     padding: '0px 10px',
//   }),
//   downloadIcon: css({
//     width: '20px',
//     height: '20px',
//     strokeWidth: '2px',
//     marginRight: '5px',
//   }),
//   downloadExport: css({
//     display: 'flex',
//     alignItems: 'center',
//     borderRadius: '15px',
//     fontSize: '14px',
//     fontWeight: 700,
//     padding: '0px 10px',
//   }),
//   exportModal: css({
//     width: '300px',
//   }),
//   modalContent: css({
//     display: 'flex',
//     alignItems: 'center',
//     flexDirection: 'row', // inline items
//     justifyContent: 'center', // center items horizontally
//     marginBottom: '20px', // space before the button
//   }),
//   modalTitle: css({
//     marginRight: '20px', // or however much space you want between the title and the select
//     fontWeight: 'bold',
//     fontSize: '16px', // adjust as needed
//   }),
//   modalFooter: css({
//     display: 'flex',
//     justifyContent: 'center',
//     width: '100%', // ensure the footer takes up the full width
//   }),
// };

export const csvExporter = new ExportToCsv(options);

export const PriceListExportButton: FC = () => {
  const { calculateVatPrice, getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayoutAbbr } = useFlatLayout();
  // const { t } = useTranslation();
  // const [open, setOpen] = useState(false);
  const data = useStoreState((state) => state.priceList.data);
  const mappedData = data?.map(
    ({
      internal_id,
      layout,
      orientation,
      floor_area,
      exterior_area,
      floor,
      availability,
      new_price,
      current_price,
      price_difference,
    }) => ({
      id: internal_id,
      layout: getLayoutAbbr(layout),
      orientation: orientation.join(','),
      floor_area,
      exterior_area: exterior_area ?? 0,
      floor,
      availability,
      ...{
        ...withVAT ? {
        new_price_with_vat: round(calculateVatPrice(new_price, floor_area, Number(exterior_area)) || 0, 2),
        current_price_with_vat: round(calculateVatPrice(current_price, floor_area, Number(exterior_area)) || 0, 2),
        } : {
          new_price_without_vat: round(calculateVatPrice(new_price, floor_area, Number(exterior_area)) || 0, 2),
          current_price_without_vat: round(calculateVatPrice(current_price, floor_area, Number(exterior_area)) || 0, 2),
        },
      },
      currency: getCurrency(),
      price_difference: formatPercent((price_difference ?? 0) / 100),
    }),
  );
  const handleClick = (format: string): void => {
    if (mappedData) {
      if (format === 'csv') {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(data);
        return;
      }
      if (format === 'xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}.xlsx`);
        return;
      }
      const CRMData = data?.map((d) => ({
        internalId: d.internal_id,
        buildingNo: d.building_internal_id,
        floorNo: d.floor,
        price: calculateVatPrice(
          d.current_price,
          d.floor_area,
          Number(d.exterior_area),
          false,
        ),
        priceVAT: calculateVatPrice(
          d.current_price,
          d.floor_area,
          Number(d.exterior_area),
          true,
        ),
      }));
      if (CRMData) {
        const worksheet = XLSX.utils.json_to_sheet(CRMData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}.xlsx`);
      }
    }
  };
  return (
    <>
      <ExportDropdown type='priceList' onDownload={handleClick} />
      {/* <Button
        css={styles.download}
        icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
        onClick={() => setOpen(true)}
      >
        {label || 'CSV'}
      </Button> */}
      {/* <Modal
        width={300}
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
          <div css={styles.modalFooter}>
            <Button key='download' css={styles.download} onClick={handleClick} icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}>
              {t('header.download')}
            </Button>
          </div>,
        ]}
      >
        <div css={styles.modalContent}>
          <div css={styles.modalTitle}>{t('export.format')}</div>
          <Select
            defaultValue='csv'
            style={{ width: 120 }}
            onChange={(value) => setFormat(value)}
            options={optionsProps}
          />
        </div>
      </Modal> */}
    </>
  );
};
