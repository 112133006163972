import { FC, PropsWithChildren, ReactNode } from 'react';
import { Spin } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../hooks';

interface PageProps {
  title?: ReactNode;
  subMenu?: ReactNode;
  pageClassname: string;
  filters?: ReactNode;
  headerClass?: string;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
  title,
  subMenu,
  pageClassname,
  headerClass = 'justify-between',
  filters,
  children,
}) => {
  const { isLoading, isLoaded } = useStoreState((state) => state.global);
  const { t } = useTranslation();
  return (
    <div className={clsx('page', pageClassname)}>
      <div className={clsx('header', headerClass)}>
        <div className='title'>{title}</div>
        {subMenu}
        <div className='filters'>{filters}</div>
      </div>
      <div className='content'>
        {isLoading || !isLoaded ? (
          <div className='global-loading-wrapper'>
            <Spin spinning size='large' tip={t('loading')} />
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{children}</>
        )}
      </div>
    </div>
  );
};
