import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  title: string;
  subtitle: string;
  className?: string;
  tags?: string[];
  selected?: boolean;
  input?: {
    placeholder: string;
    label?: string;
    value: string;
    setValue: (value: string) => void;
  };
  handleClick: () => void;
};

export const RadioButton: FC<Props> = ({
  title,
  subtitle,
  className,
  tags = [],
  selected,
  input,
  handleClick,
}: Props) => (
  <div
    onClick={handleClick}
    className={clsx(
      className,
      selected ? 'bg-[#CCD9EC] border-bmblue' : 'bg-white border-[#BDBDBD]',
      'border-[1px] rounded-[25px] py-5 px-6 inline-block w-[50rem] cursor-pointer',
    )}
  >
    <div className='flex gap-2 items-center'>
      <h1 className='text-bmblue font-bold text-lg'>{title}</h1>
      {tags.length ? (
        <div className='rounded-[25px] bg-[#9EB9DA] py-1 px-3'>
          {tags.map((tag) => (
            <span key={tag} className='text-bmblue font-semibold text-sm'>
              {tag}
            </span>
          ))}
        </div>
      ) : null}
    </div>
    <p className='text-bmgray text-lg'>{subtitle}</p>
    {input ? (
      <div
        className={clsx(
          className,
          'gap-0 flex flex-col  w-[600px] rounded-[25px] text-bmblue',
          input.label ? 'pt-3' : '',
        )}
      >
        {input.label ? (
          <span className='text-sm leading-none'>{input.label}</span>
        ) : null}
        <input
          className={clsx(
            'w-[40rem] outline-none',
            selected ? 'bg-[#CCD9EC]' : 'bg-white',
          )}
          placeholder={input.placeholder}
          value={input.value}
          onChange={(e) => input.setValue(e.target.value)}
          onClick={(e) => (selected ? e.stopPropagation() : null)}
        />
      </div>
    ) : null}
  </div>
);
