/* tslint:disable */
/* eslint-disable */
/**
 * SecondaryMarket JSON API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailableMarketShare,
  AvailableUnitsAveragePriceHistoryResponseSchema,
  AvailableUnitsAveragePriceResponseSchema,
  AvailableUnitsAverageSmResponseSchema,
  AvailableUnitsLayoutResponseSchemaWithTotals,
  AvailableUnitsLayoutUnitMixResponseSchema,
  AvailableUnitsPriceHistoryLayoutResponseSchema,
  AvailableUnitsPricePerSmResponseSchema,
  AvailableUnitsResponseSchema,
  DaysOnTheMarketResponseSchema,
  DisappearedMarketShare,
  DisappearedUnitsAveragePriceHistoryResponseSchema,
  DisappearedUnitsAveragePriceResponseSchema,
  DisappearedUnitsAverageSmResponseSchema,
  DisappearedUnitsLayoutResponseSchemaWithTotals,
  DisappearedUnitsLayoutUnitMixResponseSchema,
  DisappearedUnitsLayoutsResponseSchema,
  DisappearedUnitsPriceHistoryLayoutResponseSchema,
  DisappearedUnitsPricePerSmResponseSchema,
  DisappearedUnitsResponseSchema,
  MultiPolygonalSchema,
  NewUnitsResponseSchema,
  SaleSpeedSchema,
  SupplyHistoryResponseSchema,
} from '../models/index';
import {
    AvailableMarketShareFromJSON,
    AvailableMarketShareToJSON,
    AvailableUnitsAveragePriceHistoryResponseSchemaFromJSON,
    AvailableUnitsAveragePriceHistoryResponseSchemaToJSON,
    AvailableUnitsAveragePriceResponseSchemaFromJSON,
    AvailableUnitsAveragePriceResponseSchemaToJSON,
    AvailableUnitsAverageSmResponseSchemaFromJSON,
    AvailableUnitsAverageSmResponseSchemaToJSON,
    AvailableUnitsLayoutResponseSchemaWithTotalsFromJSON,
    AvailableUnitsLayoutResponseSchemaWithTotalsToJSON,
    AvailableUnitsLayoutUnitMixResponseSchemaFromJSON,
    AvailableUnitsLayoutUnitMixResponseSchemaToJSON,
    AvailableUnitsPriceHistoryLayoutResponseSchemaFromJSON,
    AvailableUnitsPriceHistoryLayoutResponseSchemaToJSON,
    AvailableUnitsPricePerSmResponseSchemaFromJSON,
    AvailableUnitsPricePerSmResponseSchemaToJSON,
    AvailableUnitsResponseSchemaFromJSON,
    AvailableUnitsResponseSchemaToJSON,
    DaysOnTheMarketResponseSchemaFromJSON,
    DaysOnTheMarketResponseSchemaToJSON,
    DisappearedMarketShareFromJSON,
    DisappearedMarketShareToJSON,
    DisappearedUnitsAveragePriceHistoryResponseSchemaFromJSON,
    DisappearedUnitsAveragePriceHistoryResponseSchemaToJSON,
    DisappearedUnitsAveragePriceResponseSchemaFromJSON,
    DisappearedUnitsAveragePriceResponseSchemaToJSON,
    DisappearedUnitsAverageSmResponseSchemaFromJSON,
    DisappearedUnitsAverageSmResponseSchemaToJSON,
    DisappearedUnitsLayoutResponseSchemaWithTotalsFromJSON,
    DisappearedUnitsLayoutResponseSchemaWithTotalsToJSON,
    DisappearedUnitsLayoutUnitMixResponseSchemaFromJSON,
    DisappearedUnitsLayoutUnitMixResponseSchemaToJSON,
    DisappearedUnitsLayoutsResponseSchemaFromJSON,
    DisappearedUnitsLayoutsResponseSchemaToJSON,
    DisappearedUnitsPriceHistoryLayoutResponseSchemaFromJSON,
    DisappearedUnitsPriceHistoryLayoutResponseSchemaToJSON,
    DisappearedUnitsPricePerSmResponseSchemaFromJSON,
    DisappearedUnitsPricePerSmResponseSchemaToJSON,
    DisappearedUnitsResponseSchemaFromJSON,
    DisappearedUnitsResponseSchemaToJSON,
    MultiPolygonalSchemaFromJSON,
    MultiPolygonalSchemaToJSON,
    NewUnitsResponseSchemaFromJSON,
    NewUnitsResponseSchemaToJSON,
    SaleSpeedSchemaFromJSON,
    SaleSpeedSchemaToJSON,
    SupplyHistoryResponseSchemaFromJSON,
    SupplyHistoryResponseSchemaToJSON,
} from '../models/index';

export interface BuiltmindApiApiAddedUnitsOverTimeRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiAvailableUnitsPriceHistoryByLayoutRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiAvailableUnitsPriceHistoryOverallRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiAvailableUnitsUnitMixRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiDisappearedUnitsPriceHistoryByLayoutRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiDisappearedUnitsPriceHistoryOverallRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiDisappearedUnitsUnitMixRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetAvailableMarketShareRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiGetAvailableUnitsRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetAvailableUnitsAveragePriceRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetAvailableUnitsAverageSmRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetAvailableUnitsLayoutRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetAvailableUnitsPricePerSmRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetDaysOnTheMarketRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiGetDisappearedMarketShareRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiGetDisappearedUnitsRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetDisappearedUnitsAveragePriceRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetDisappearedUnitsAverageSmRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetDisappearedUnitsLayoutRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetDisappearedUnitsPricePerSmRequest {
    reportId: string;
    date: string;
}

export interface BuiltmindApiApiGetMultipolygonByReportIdRequest {
    reportId: string;
}

export interface BuiltmindApiApiSaleSpeedByLayoutRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiSaleSpeedByLayoutMonthRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiSaleSpeedOverallRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

export interface BuiltmindApiApiSupplyHistoryRequest {
    reportId: string;
    startDate: string;
    endDate: string;
}

/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     * Get added units over time
     * Get added units over time
     */
    async addedUnitsOverTimeRaw(requestParameters: BuiltmindApiApiAddedUnitsOverTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewUnitsResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiAddedUnitsOverTime.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiAddedUnitsOverTime.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiAddedUnitsOverTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/added_units_over_time/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewUnitsResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get added units over time
     * Get added units over time
     */
    async addedUnitsOverTime(requestParameters: BuiltmindApiApiAddedUnitsOverTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewUnitsResponseSchema> {
        const response = await this.addedUnitsOverTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units price history by layout
     * Get available units price history by layout
     */
    async availableUnitsPriceHistoryByLayoutRaw(requestParameters: BuiltmindApiApiAvailableUnitsPriceHistoryByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AvailableUnitsPriceHistoryLayoutResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryByLayout.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryByLayout.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryByLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/available_units_price_history_by_layout/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(AvailableUnitsPriceHistoryLayoutResponseSchemaFromJSON));
    }

    /**
     * Get available units price history by layout
     * Get available units price history by layout
     */
    async availableUnitsPriceHistoryByLayout(requestParameters: BuiltmindApiApiAvailableUnitsPriceHistoryByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AvailableUnitsPriceHistoryLayoutResponseSchema>> {
        const response = await this.availableUnitsPriceHistoryByLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units price history
     * Get available units price history
     */
    async availableUnitsPriceHistoryOverallRaw(requestParameters: BuiltmindApiApiAvailableUnitsPriceHistoryOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AvailableUnitsAveragePriceHistoryResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryOverall.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryOverall.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiAvailableUnitsPriceHistoryOverall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/available_units_price_history_overall/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(AvailableUnitsAveragePriceHistoryResponseSchemaFromJSON));
    }

    /**
     * Get available units price history
     * Get available units price history
     */
    async availableUnitsPriceHistoryOverall(requestParameters: BuiltmindApiApiAvailableUnitsPriceHistoryOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AvailableUnitsAveragePriceHistoryResponseSchema>> {
        const response = await this.availableUnitsPriceHistoryOverallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units unit mix
     * Get available units unit mix
     */
    async availableUnitsUnitMixRaw(requestParameters: BuiltmindApiApiAvailableUnitsUnitMixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsLayoutUnitMixResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiAvailableUnitsUnitMix.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiAvailableUnitsUnitMix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/available_units_unit_mix/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsLayoutUnitMixResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get available units unit mix
     * Get available units unit mix
     */
    async availableUnitsUnitMix(requestParameters: BuiltmindApiApiAvailableUnitsUnitMixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsLayoutUnitMixResponseSchema> {
        const response = await this.availableUnitsUnitMixRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units price history by layout
     * Get disappeared units price history by layout
     */
    async disappearedUnitsPriceHistoryByLayoutRaw(requestParameters: BuiltmindApiApiDisappearedUnitsPriceHistoryByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedUnitsPriceHistoryLayoutResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryByLayout.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryByLayout.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryByLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/disappeared_units_price_history_by_layout/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedUnitsPriceHistoryLayoutResponseSchemaFromJSON));
    }

    /**
     * Get disappeared units price history by layout
     * Get disappeared units price history by layout
     */
    async disappearedUnitsPriceHistoryByLayout(requestParameters: BuiltmindApiApiDisappearedUnitsPriceHistoryByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedUnitsPriceHistoryLayoutResponseSchema>> {
        const response = await this.disappearedUnitsPriceHistoryByLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units price history
     * Get disappeared units price history
     */
    async disappearedUnitsPriceHistoryOverallRaw(requestParameters: BuiltmindApiApiDisappearedUnitsPriceHistoryOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedUnitsAveragePriceHistoryResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryOverall.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryOverall.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiDisappearedUnitsPriceHistoryOverall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/disappeared_units_price_history_overall/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedUnitsAveragePriceHistoryResponseSchemaFromJSON));
    }

    /**
     * Get disappeared units price history
     * Get disappeared units price history
     */
    async disappearedUnitsPriceHistoryOverall(requestParameters: BuiltmindApiApiDisappearedUnitsPriceHistoryOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedUnitsAveragePriceHistoryResponseSchema>> {
        const response = await this.disappearedUnitsPriceHistoryOverallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units unit mix
     * Get disappeared units unit mix
     */
    async disappearedUnitsUnitMixRaw(requestParameters: BuiltmindApiApiDisappearedUnitsUnitMixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsLayoutUnitMixResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiDisappearedUnitsUnitMix.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiDisappearedUnitsUnitMix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/disappeared_units_unit_mix/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsLayoutUnitMixResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get disappeared units unit mix
     * Get disappeared units unit mix
     */
    async disappearedUnitsUnitMix(requestParameters: BuiltmindApiApiDisappearedUnitsUnitMixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsLayoutUnitMixResponseSchema> {
        const response = await this.disappearedUnitsUnitMixRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available market share
     * Get available market share
     */
    async getAvailableMarketShareRaw(requestParameters: BuiltmindApiApiGetAvailableMarketShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AvailableMarketShare>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableMarketShare.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiGetAvailableMarketShare.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiGetAvailableMarketShare.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/available_market_share/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(AvailableMarketShareFromJSON));
    }

    /**
     * Get available market share
     * Get available market share
     */
    async getAvailableMarketShare(requestParameters: BuiltmindApiApiGetAvailableMarketShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AvailableMarketShare>> {
        const response = await this.getAvailableMarketShareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units
     * Get available units
     */
    async getAvailableUnitsRaw(requestParameters: BuiltmindApiApiGetAvailableUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableUnits.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetAvailableUnits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_available_units/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get available units
     * Get available units
     */
    async getAvailableUnits(requestParameters: BuiltmindApiApiGetAvailableUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsResponseSchema> {
        const response = await this.getAvailableUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units average price
     * Get available units average price
     */
    async getAvailableUnitsAveragePriceRaw(requestParameters: BuiltmindApiApiGetAvailableUnitsAveragePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsAveragePriceResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableUnitsAveragePrice.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetAvailableUnitsAveragePrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_available_units_average_price/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsAveragePriceResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get available units average price
     * Get available units average price
     */
    async getAvailableUnitsAveragePrice(requestParameters: BuiltmindApiApiGetAvailableUnitsAveragePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsAveragePriceResponseSchema> {
        const response = await this.getAvailableUnitsAveragePriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units average sm
     * Get available units average sm
     */
    async getAvailableUnitsAverageSmRaw(requestParameters: BuiltmindApiApiGetAvailableUnitsAverageSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsAverageSmResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableUnitsAverageSm.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetAvailableUnitsAverageSm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_available_units_average_sm/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsAverageSmResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get available units average sm
     * Get available units average sm
     */
    async getAvailableUnitsAverageSm(requestParameters: BuiltmindApiApiGetAvailableUnitsAverageSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsAverageSmResponseSchema> {
        const response = await this.getAvailableUnitsAverageSmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units layout
     * Get available units layout
     */
    async getAvailableUnitsLayoutRaw(requestParameters: BuiltmindApiApiGetAvailableUnitsLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsLayoutResponseSchemaWithTotals>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableUnitsLayout.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetAvailableUnitsLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_available_units_layout/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsLayoutResponseSchemaWithTotalsFromJSON(jsonValue));
    }

    /**
     * Get available units layout
     * Get available units layout
     */
    async getAvailableUnitsLayout(requestParameters: BuiltmindApiApiGetAvailableUnitsLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsLayoutResponseSchemaWithTotals> {
        const response = await this.getAvailableUnitsLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available units price per sm
     * Get available units price per sm
     */
    async getAvailableUnitsPricePerSmRaw(requestParameters: BuiltmindApiApiGetAvailableUnitsPricePerSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvailableUnitsPricePerSmResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetAvailableUnitsPricePerSm.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetAvailableUnitsPricePerSm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_available_units_price_per_sm/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvailableUnitsPricePerSmResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get available units price per sm
     * Get available units price per sm
     */
    async getAvailableUnitsPricePerSm(requestParameters: BuiltmindApiApiGetAvailableUnitsPricePerSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvailableUnitsPricePerSmResponseSchema> {
        const response = await this.getAvailableUnitsPricePerSmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get days on the market
     * Get days on the market
     */
    async getDaysOnTheMarketRaw(requestParameters: BuiltmindApiApiGetDaysOnTheMarketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DaysOnTheMarketResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDaysOnTheMarket.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiGetDaysOnTheMarket.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiGetDaysOnTheMarket.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_days_on_the_market/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DaysOnTheMarketResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get days on the market
     * Get days on the market
     */
    async getDaysOnTheMarket(requestParameters: BuiltmindApiApiGetDaysOnTheMarketRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DaysOnTheMarketResponseSchema> {
        const response = await this.getDaysOnTheMarketRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared market share
     * Get disappeared market share
     */
    async getDisappearedMarketShareRaw(requestParameters: BuiltmindApiApiGetDisappearedMarketShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedMarketShare>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedMarketShare.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiGetDisappearedMarketShare.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiGetDisappearedMarketShare.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/disappeared_market_share/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedMarketShareFromJSON));
    }

    /**
     * Get disappeared market share
     * Get disappeared market share
     */
    async getDisappearedMarketShare(requestParameters: BuiltmindApiApiGetDisappearedMarketShareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedMarketShare>> {
        const response = await this.getDisappearedMarketShareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units
     * Get disappeared units
     */
    async getDisappearedUnitsRaw(requestParameters: BuiltmindApiApiGetDisappearedUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedUnits.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetDisappearedUnits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_disappeared_units/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get disappeared units
     * Get disappeared units
     */
    async getDisappearedUnits(requestParameters: BuiltmindApiApiGetDisappearedUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsResponseSchema> {
        const response = await this.getDisappearedUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units average price
     * Get disappeared units average price
     */
    async getDisappearedUnitsAveragePriceRaw(requestParameters: BuiltmindApiApiGetDisappearedUnitsAveragePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsAveragePriceResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedUnitsAveragePrice.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetDisappearedUnitsAveragePrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_disappeared_units_average_price/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsAveragePriceResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get disappeared units average price
     * Get disappeared units average price
     */
    async getDisappearedUnitsAveragePrice(requestParameters: BuiltmindApiApiGetDisappearedUnitsAveragePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsAveragePriceResponseSchema> {
        const response = await this.getDisappearedUnitsAveragePriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units average sm
     * Get disappeared units average sm
     */
    async getDisappearedUnitsAverageSmRaw(requestParameters: BuiltmindApiApiGetDisappearedUnitsAverageSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsAverageSmResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedUnitsAverageSm.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetDisappearedUnitsAverageSm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_disappeared_units_average_sm/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsAverageSmResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get disappeared units average sm
     * Get disappeared units average sm
     */
    async getDisappearedUnitsAverageSm(requestParameters: BuiltmindApiApiGetDisappearedUnitsAverageSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsAverageSmResponseSchema> {
        const response = await this.getDisappearedUnitsAverageSmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units layout
     * Get disappeared units layout
     */
    async getDisappearedUnitsLayoutRaw(requestParameters: BuiltmindApiApiGetDisappearedUnitsLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsLayoutResponseSchemaWithTotals>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedUnitsLayout.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetDisappearedUnitsLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_disappeared_units_layout/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsLayoutResponseSchemaWithTotalsFromJSON(jsonValue));
    }

    /**
     * Get disappeared units layout
     * Get disappeared units layout
     */
    async getDisappearedUnitsLayout(requestParameters: BuiltmindApiApiGetDisappearedUnitsLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsLayoutResponseSchemaWithTotals> {
        const response = await this.getDisappearedUnitsLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get disappeared units price per sm
     * Get disappeared units price per sm
     */
    async getDisappearedUnitsPricePerSmRaw(requestParameters: BuiltmindApiApiGetDisappearedUnitsPricePerSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisappearedUnitsPricePerSmResponseSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetDisappearedUnitsPricePerSm.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling builtmindApiApiGetDisappearedUnitsPricePerSm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_disappeared_units_price_per_sm/{report_id}/{date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisappearedUnitsPricePerSmResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Get disappeared units price per sm
     * Get disappeared units price per sm
     */
    async getDisappearedUnitsPricePerSm(requestParameters: BuiltmindApiApiGetDisappearedUnitsPricePerSmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisappearedUnitsPricePerSmResponseSchema> {
        const response = await this.getDisappearedUnitsPricePerSmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multipolygon by report id
     * Get multipolygon by report id
     */
    async getMultipolygonByReportIdRaw(requestParameters: BuiltmindApiApiGetMultipolygonByReportIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultiPolygonalSchema>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiGetMultipolygonByReportId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/get_multipolygon_by_report_id/{report_id}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultiPolygonalSchemaFromJSON(jsonValue));
    }

    /**
     * Get multipolygon by report id
     * Get multipolygon by report id
     */
    async getMultipolygonByReportId(requestParameters: BuiltmindApiApiGetMultipolygonByReportIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultiPolygonalSchema> {
        const response = await this.getMultipolygonByReportIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get sale speed by layout
     * Get sale speed by layout
     * @deprecated
     */
    async saleSpeedByLayoutRaw(requestParameters: BuiltmindApiApiSaleSpeedByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedUnitsLayoutsResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiSaleSpeedByLayout.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiSaleSpeedByLayout.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiSaleSpeedByLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/sale_speed_by_layout/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedUnitsLayoutsResponseSchemaFromJSON));
    }

    /**
     * Get sale speed by layout
     * Get sale speed by layout
     * @deprecated
     */
    async saleSpeedByLayout(requestParameters: BuiltmindApiApiSaleSpeedByLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedUnitsLayoutsResponseSchema>> {
        const response = await this.saleSpeedByLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get sale speed by layout
     * Get sale speed by layout
     */
    async saleSpeedByLayoutMonthRaw(requestParameters: BuiltmindApiApiSaleSpeedByLayoutMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisappearedUnitsLayoutsResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiSaleSpeedByLayoutMonth.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiSaleSpeedByLayoutMonth.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiSaleSpeedByLayoutMonth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/sale_speed_by_layout_month/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(DisappearedUnitsLayoutsResponseSchemaFromJSON));
    }

    /**
     * Get sale speed by layout
     * Get sale speed by layout
     */
    async saleSpeedByLayoutMonth(requestParameters: BuiltmindApiApiSaleSpeedByLayoutMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisappearedUnitsLayoutsResponseSchema>> {
        const response = await this.saleSpeedByLayoutMonthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get sale speed overall
     * Get sale speed overall
     */
    async saleSpeedOverallRaw(requestParameters: BuiltmindApiApiSaleSpeedOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SaleSpeedSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiSaleSpeedOverall.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiSaleSpeedOverall.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiSaleSpeedOverall.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/sale_speed_overall/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(SaleSpeedSchemaFromJSON));
    }

    /**
     * Get sale speed overall
     * Get sale speed overall
     */
    async saleSpeedOverall(requestParameters: BuiltmindApiApiSaleSpeedOverallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SaleSpeedSchema>> {
        const response = await this.saleSpeedOverallRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get supply history
     * Get supply history
     */
    async supplyHistoryRaw(requestParameters: BuiltmindApiApiSupplyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SupplyHistoryResponseSchema>>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling builtmindApiApiSupplyHistory.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling builtmindApiApiSupplyHistory.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling builtmindApiApiSupplyHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("AuthBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Cognito-Access-Token"] = this.configuration.apiKey("X-Cognito-Access-Token"); // CognitoAccessTokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/supply_history/{report_id}/{start_date}/{end_date}`.replace(`{${"report_id"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"start_date"}}`, encodeURIComponent(String(requestParameters.startDate))).replace(`{${"end_date"}}`, encodeURIComponent(String(requestParameters.endDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue?.map(SupplyHistoryResponseSchemaFromJSON));
    }

    /**
     * Get supply history
     * Get supply history
     */
    async supplyHistory(requestParameters: BuiltmindApiApiSupplyHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SupplyHistoryResponseSchema>> {
        const response = await this.supplyHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
