import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FileSelector } from '../../../inputs/FileSelector';
import { Button } from '../../../button/Button';
import { FormContainer } from '../../FormContainer';
import { Heading } from '../../../Heading';
import { Subtitle } from '../../../Subtitle';
import { List } from '../../../List';
import { BackButton } from '../../../button/BackButton';
import { useStoreActions, useStoreState } from '../../../../../hooks';
import { ErrorsBox } from './ErrorsBox';
import { usePriceListUploader } from '../../../../../hooks/usePriceListUpload';
import { DownloadTemplate } from './DownloadTemplate';

export const UploadPriceList: FC = () => {
  const { t } = useTranslation();

  const {
    handleNext,
    handleBack,
    setStartingPrices,
    setPriceList,
    setSkipPriceList,
    setFileName,
  } = useStoreActions((actions) => actions.onboarding);

  const {
    priceList,
    fileName: selectedFileName,
    projectType,
    buildingType,
  } = useStoreState((state) => state.onboarding);
  const { profile } = useStoreState((state) => state.user);

  const isUS = profile?.localization === 'US';
  const isRental = projectType.rental;
  const isHouse = buildingType.house;

  const { selectedFileNames, errors, warnings, handleFileChange } =
    usePriceListUploader(
      isUS,
      isHouse,
      setPriceList,
      setStartingPrices,
      setFileName,
    );

  const houseFloors = isUS
    ? t('onboarding.upload_prices.items.stories', 'Stories')
    : t('onboarding.upload_prices.items.floors', 'Floors');

  const recommendedItems = [
    {
      title: t('onboarding.upload_prices.items.id', 'ID'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.id',
        'A unique identifier of a unit.',
      ),
    },
    {
      title: isUS
        ? t('onboarding.upload_prices.items.bedroom', 'Bedroom')
        : t('onboarding.upload_prices.items.layout', 'Layout'),
      tooltip: isUS
        ? t(
            'onboarding.upload_prices.tooltips.layout_us',
            'A number of bedrooms in a unit.',
          )
        : t(
            'onboarding.upload_prices.tooltips.layout_eu',
            'A number of rooms in a unit.',
          ),
    },
    {
      title: t('onboarding.upload_prices.items.floor_area', 'Floor Area'),
      tooltip: isUS
        ? t(
            'onboarding.upload_prices.tooltips.floor_area_us',
            'An interior area of a unit in square feet.',
          )
        : t(
            'onboarding.upload_prices.tooltips.floor_area_eu',
            'An interior area of a unit in square meters.',
          ),
    },
    {
      title: isHouse
        ? houseFloors
        : t('onboarding.upload_prices.items.floor', 'Floor'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.floor',
        'A floor on which a unit is located.',
      ),
    },
  ];

  const requiredItems = [
    {
      title: t('onboarding.upload_prices.items.availability', 'Availability'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.availability',
        'Select one of the values “available”, “reserved”, or “sold” based on a unit’s availability.',
      ),
    },
    {
      title: isRental
        ? t('onboarding.upload_prices.items.date_leased', 'Date Leased')
        : t('onboarding.upload_prices.items.date_reserved', 'Date Reserved'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.date_reserved',
        'A date at which a unit was reserved (a moment at which its price was fixed).',
      ),
    },
    {
      title: isRental
        ? t('onboarding.upload_prices.items.rent', 'Rent')
        : t('onboarding.upload_prices.items.price', 'Price'),
      tooltip: isUS
        ? t('onboarding.upload_prices.tooltips.price_us', 'A price of a unit.')
        : t(
            'onboarding.upload_prices.tooltips.price_eu',
            'A price of a unit including VAT.',
          ),
    },
    {
      title: t(
        'onboarding.upload_prices.items.number_of_bathrooms',
        'Number of Bathrooms',
      ),
      tooltip: isUS
        ? t(
            'onboarding.upload_prices.tooltips.number_of_bathrooms_us',
            'A number of bathrooms in a unit. Use 0.5 for half-baths. E.g. 1.5 for a unit with a bathroom and a separate restroom.',
          )
        : t(
            'onboarding.upload_prices.tooltips.number_of_bathrooms_eu',
            'A number of bathrooms in a unit. Use 0.5 for toilets. E.g. 1.5 for a unit with a bathroom and a separate toilet.',
          ),
    },
    {
      title: isHouse
        ? t('onboarding.upload_prices.items.terrace_area', 'Terrace Area')
        : '',
      tooltip: t(
        'onboarding.upload_prices.tooltips.terrace_area',
        'A terrace area of a unit.',
      ),
    },
    {
      title: isHouse
        ? t('onboarding.upload_prices.items.balcony_area', 'Balcony Area')
        : t('onboarding.upload_prices.items.lot_size', 'Lot Size'),
      tooltip: isHouse
        ? t(
            'onboarding.upload_prices.tooltips.balcony_area',
            'A balcony area of a unit.',
          )
        : '',
    },
    {
      title: isHouse
        ? t('onboarding.upload_prices.items.garages', 'Garages')
        : t('onboarding.upload_prices.items.balcony_area', 'Balcony Area'),
      tooltip: isHouse
        ? ''
        : t(
            'onboarding.upload_prices.tooltips.balcony_area',
            'A balcony area of a unit.',
          ),
    },
    {
      title: t('onboarding.upload_prices.items.room_areas', 'Room Areas'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.room_areas',
        'Areas of rooms within a unit.',
      ),
    },
    {
      title: t('onboarding.upload_prices.items.custom_fields', 'Custom fields'),
      tooltip: t(
        'onboarding.upload_prices.tooltips.custom_fields',
        'Any other values which differ among units and may impact their price.',
      ),
    },
  ];

  const handleNextClick = (): void => {
    setSkipPriceList(priceList.some((list) => list.price));
    handleNext();
  };

  return (
    <FormContainer>
      <div>
        <BackButton handleClick={handleBack} />
        <Heading className='pb-6'>
          {isRental
            ? t(
                'onboarding.upload_price_list.rental.title.',
                'Upload rent roll',
              )
            : t('onboarding.upload_price_list.title', 'Upload price list')}
        </Heading>
        <Subtitle>
          {isRental
            ? t(
                'onboarding.upload_price_list.rental.subtitle',
                'Upload the rent roll of units with their features. If anything isn’t clear, we’ll follow up with you.',
              )
            : t(
                'onboarding.upload_price_list.subtitle',
                'Upload the price list of units with their features. If anything isn’t clear, we’ll follow up with you.',
              )}
        </Subtitle>
        <div className='flex w-full justify-around pt-8'>
          <List
            items={recommendedItems}
            bold
            headline={t('onboarding.list_required', 'Required')}
          />
          <List
            items={requiredItems}
            headline={t('onboarding.list_recommended', 'Recommended')}
          />
        </div>
      </div>
      <div>
        <DownloadTemplate />
        <FileSelector
          className='mt-2'
          fileNames={selectedFileNames}
          onFileChange={handleFileChange}
          uploadText={t(
            'onboarding.file_selector.drag_drop',
            'or drag and drop {{files}}.',
            { files: 'XSLX' },
          )}
          uploadTextBold={t(
            'onboarding.file_selector.click_upload',
            'Click to upload',
          )}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'], // Accept .xlsx files
            'application/vnd.ms-excel': ['.xls'], // Accept .xls files
          }}
        />
      </div>
      {(errors.length > 0 || warnings.length > 0) && (
        <ErrorsBox warnings={warnings} errors={errors} />
      )}
      <div className='w-full flex justify-center'>
        <Button
          className='mt-8'
          disabled={!!errors.length || !!!selectedFileNames.length}
          handleClick={handleNextClick}
        >
          {t('onboarding.common.continue', 'Continue')}
        </Button>
      </div>
    </FormContainer>
  );
};
