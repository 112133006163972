import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  uniqueClicks: number;
  averageSessionLength: number;
};

export const AnalyticsPopover: FC<Props> = ({
  uniqueClicks,
  averageSessionLength,
}) => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col'>
      <span className='font-bold'>
        {t('price_list.hints.google.past_days_stats', 'Past 30 Days Stats')}
      </span>
      <div className='flex justify-between gap-4'>
        <span>
          {t('price_list.hints.google.unique_clicks', 'Unique Clicks')}
        </span>
        <span className='font-bold'>{uniqueClicks}</span>
      </div>
      <div className='flex justify-between gap-4'>
        <span>
          {t(
            'price_list.hints.google.average_duration',
            'Average Session Length',
          )}
        </span>
        <span className='font-bold'>{`${averageSessionLength} s`}</span>
      </div>
    </div>
  );
};
