export const Paths = {
  LOGIN: '/login',
  NOT_FOUND: '/not-found',
  DASHBOARD: '/dashboard',
  DAILYNEWS: '/home',
  PRICE_LIST: '/price-list',
  MARKET_ANALYSIS: '/market-analysis',
  PRICE_LIST_DETAIL: '/price-list/:unitId',
  PRICE_UPDATE: '/price-update',
  MARKET_REPORTS: '/market/reports',
  MARKET_REPORTS_SECONDARY: '/market/reports/secondary',
  MARKET_REPORTS_RENTALS: '/market/reports/rentals',
  MARKET_PROJECTS: '/market/projects',
  SETTINGS: '/settings',
  PROJECT_DETAIL: '/project/:projectId',
  DYNAMIC_PRICING_ONBOARDING: '/add-project',
};
