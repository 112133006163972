/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { Data, DaysOnMarketChart } from './DaysOnMarketChart';
import { styles } from '../styles';
import { ChartCard } from '../../../ChartCard';
import { statisticsApi } from '../../../../api/secondary';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

export const DaysOnMarket: FC = () => {
  const { isRentals } = useMarketDetails();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      const startDate = date.toISOString().split('T')[0];
      const endDate = paramsSM.date;
      const payload = {
        reportId,
        startDate,
        endDate,
      };
      switch (filterValue) {
        case FilterEnum.OVERALL:
          statisticsApi.getDaysOnTheMarket(payload)
            .then((response) => {
              setData(response.daysOnTheMarket.map((d) => ({
                ...d,
                meanDaysOnMarket: d.meanDaysOnMarket ?? 0,
              })));
            }).finally(() => {
              setLoading(false);
            });
          break;
        default:
          statisticsApi.getDaysOnTheMarket(payload)
            .then((response) => {
              setData(response.daysOnTheMarket.map((d) => ({
                ...d,
                meanDaysOnMarket: d.meanDaysOnMarket ?? 0,
              })));
            }).finally(() => {
              setLoading(false);
            });
          break;
      }
    }
  }, [filterValue, reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.days_on_market.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={t('market.reports.days_on_market.title', 'Days on the Market')}
      subtitle={isRentals
        ? t('market.reports.rentals_days_on_market.subtitle')
        : t(
        'market.reports.days_on_market.subtitle',
        'The average number of days it took to sell units sold in the given month.',
      )}
      extra={!isLoading && <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      chart={
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          <DaysOnMarketChart
            data={data
              .sort((a, b) => a.month.localeCompare(b.month))
              .map((d) => ({ ...d, meanDaysOnMarket: Math.round(d.meanDaysOnMarket) }))}
            isOverall={isOverall}
          />
        )
      }
      controls={
        <Segmented
          value={filterValue}
          options={[
            {
              value: FilterEnum.OVERALL,
              label: t('dashboard.sale_progress.overall', 'Overall'),
            },
            /* {
              value: FilterEnum.LAYOUTS,
              label: t('dashboard.sale_progress.layouts', 'Layouts'),
            }, */
          ]}
          onChange={handleChange}
        />
      }
    />
    </div>
  );
};
