/** @jsxImportSource @emotion/react */
import { FC, MouseEvent, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Popover, Checkbox, Button } from 'antd';
import { css } from '@emotion/react/macro';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../hooks';

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type Props = {
  showValues?: boolean;
  onDownloadPNG?: () => void;
  extraComponents?: ReactNode[];
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
};

export const CardPopover: FC<Props> = ({ showValues = false, onDownloadPNG, extraComponents }) => {
  const { displayValues } = useStoreState((state) => state.filters);
  const { setDisplayValues } = useStoreActions((action) => action.filters);
  const { t } = useTranslation();

  const handleClick = (e: MouseEvent<HTMLDivElement>): void => {
    handlePropagation(e);
    setDisplayValues(!displayValues);
  };

  return (
    <Popover
      overlayClassName='report-actions'
      placement='bottom'
      content={
        <div css={styles.container}>
          {showValues && (
            <div className='actions' role='presentation' onClick={handleClick}>
              <div>
                {t('common.display_values')}
              </div>
              <Checkbox checked={displayValues} />
            </div>
          )}
          {extraComponents && extraComponents.map((component, index) => (
            <div key={uuidv4()} style={{ marginTop: 10 }}>
              {component}
            </div>
          ))}
          {onDownloadPNG && (
            <Button
              css={styles.download}
              icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
              onClick={onDownloadPNG}
              style={{ marginTop: showValues ? 10 : 0 }}
              type='text'
            >
              PNG
            </Button>
          )}
          {/* {exportFilter !== undefined && (<ReportExport filter={exportFilter} />)} */}
        </div>
      }
    >
      <div
        role='button'
        tabIndex={0}
        className='actions-dots'
        onClick={handlePropagation}
      >
        &middot;&middot;&middot;
      </div>
    </Popover>
  );
};
